import React,
{
	useEffect,
	useState,
} from 'react'

import {
	Sidebar,
	Footer,
	LoadingView,

} from '../../../components/common'

import {
	useCustomDataStoringStates,
	useCustomLoadingStates,
	useCustomNullStates
} from '../../../hooks/customStates'

import { useCustomDispatch } from '../../../hooks'
import { authToken } from '../../../storage/authToken'
import { ErrorMessages } from '../../../components/error/alertMessages'

import { initFlowbite } from 'flowbite'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'
import { initatedGroupId } from '../../../storage'
import { initiatedChatGroup } from '../../../services/actions/chat/initiateChatGroupActions'

import { fetchAllChatUserList } from '../../../services/actions/chat/allUserListActions'
import { subscribeGroupMessages } from '../../../utils/miscellaneous/ably'
import { closeOverlay } from '../../../assets/js/custome'

import Cookies from 'js-cookie'
import InitatedChatBox from '../../../components/chat/initatedChatBox'

const AllEmployees = () => {

	const showEmployee = 0
	const token = authToken()
	const chatGroupID = initatedGroupId()

	const dispatch = useCustomDispatch()

	const {
		profileData,
		employeeData,
		authData,
		chatUserList } = useCustomSelectors()

	const {
		selectedEmployeeId,
		overlayImage,
		setOverlayImage,
		setSelectedEmployeeId } = useCustomNullStates()

	const {
		apiCallMade,
		setApiCallMade,
		loading,
		setLoading } = useCustomLoadingStates()

	const { chatAppenedMessages,
		setChatAppenedMessages,
		myInputMessages,
		setMyInputMessages,
		allGroupMessages,
		setAllGroupMessages } = useCustomDataStoringStates()

	const [chatInput, setChatInput] = useState('')
	const [employeesOnlineStatus, setEmployeesOnlineStatus] = useState(null)

	useEffect(() => {
		if (!apiCallMade && token) {
			setLoading(true)
			dispatch(fetchAllChatUserList(null, setLoading))
			setApiCallMade(true)
		}
	}, [])

	useEffect(() => {
		initFlowbite()
	}, [employeeData])

	function openChatbox(value) {
		Cookies.remove('previous')
		setAllGroupMessages([])

		if (value.user_id) {
			Cookies.remove('initated')
			dispatch(initiatedChatGroup('one_to_one', [value.user_id]))
		}

		setSelectedEmployeeId(value.user_id)
		setMyInputMessages([])
		setChatAppenedMessages([])
		setChatInput('')
		setEmployeesOnlineStatus(value)
	}

	useEffect(() => {
		const ablyConnection = subscribeGroupMessages(
			chatGroupID,
			setChatAppenedMessages,
			setMyInputMessages)

		return () => {
			ablyConnection.close();
		};

	}, [chatGroupID])

	function openOverlay(image) {
		document.getElementById('overlay').style.display = 'flex';
		setOverlayImage(image)
	}

	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar showEmployee={showEmployee} />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="allEmployee">
								<div className="container mt-5 sm:mt-5 md:mt-5 lg:mt-0">
									<div className="block sm:flex md:flex lg:flex items-center">
										<div>
											<h2 className="rubik-500 fs-30">Employees</h2>
										</div>
									</div>
								</div>

								{loading ?
									<div className="flex justify-center items-center mt-60">
										<LoadingView />
									</div> :

									chatUserList?.error !== null ?
										<ErrorMessages />
										:
										chatUserList?.data?.data?.length === 0 ?
											<div className="flex flex-col items-center justify-center mt-60">
												<div className="p-8 text-center rounded-lg shadow-lg" style={{ background: '#3A52BB' }}>
													<h1 className="text-4xl font-semibold text-white">Oops!</h1>
													<p className="text-xl mt-4 text-white">It looks like there are no employees in the list.</p>
													<p className="mt-4 text-sm text-white">Don't worry, you can always add new employees to the list.</p>
												</div>
											</div>
											:
											<div className="container mx-auto mt-2">
												<div className="grid grid-cols-12 gap-4">
													{
														chatUserList?.data?.data?.map((value, index) => {
															const drawerId = `drawer-right-example-${index}`;
															return (
																<React.Fragment key={index}>
																	{value?.user_id === profileData?.user_id ? null :
																		<div className="col-span-12 sm:col-span-12 md:col-span-3 lg:col-span-3" >
																			{/* <a data-drawer-target={drawerId} data-drawer-show={drawerId} data-drawer-placement="right" aria-controls={drawerId} className='btnnn'> */}
																			<a className='btnnn'>
																				<div className="break-all p-3 bg-white border border-gray-200 rounded-lg border dark:bg-gray-800 dark:border-gray-700">
																					<div className="">
																						<div className="relative">
																							<img src={value.photo} className="w-full object-cover h-48" alt="" />
																							{value.online_status === 'online' ?
																								<span className="absolute bottom-2 right-2 fs-14 rubik-500 border-0 ot_chatOnlineStatus bg-green-100 text-green-800 text-xs font-medium px-4 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
																									<span className="flex w-2 h-2 bg-green-500 rounded-full" style={{ position: 'absolute', top: '7px', left: '4px' }}></span>
																									online
																								</span>
																								:
																								value.online_status === 'offline' ?
																									<span className="absolute bottom-2 right-2 fs-14 rubik-500 border-0 ot_chatOnlineStatus bg-green-100 text-green-800 text-xs font-medium px-4 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
																										<span className="flex w-2 h-2 bg-red-500 rounded-full" style={{ position: 'absolute', top: '7px', left: '4px' }}></span>
																										offline
																									</span>
																									:
																									value.online_status === 'idle' ? <span className="absolute bottom-2 right-2 fs-14 rubik-500 border-0 ot_chatOnlineStatus bg-green-100 text-green-800 text-xs font-medium px-4 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
																										<span className="flex w-2 h-2 bg-yellow-500 rounded-full" style={{ position: 'absolute', top: '7px', left: '4px' }}></span>
																										Unverified</span> : null
																							}

																						</div>
																						<div>
																							<h5 className="fs-16 rubik-500 font-bold tracking-tight text-gray-900 dark:text-white py-3 text-center">{value.name}</h5>
																							{/* <button type="button" className="rubik-400  bg-blue-700 hover:bg-blue-800 																			
																							close_btn rounded-md px-5 py-2.5 w-full" data-ripple-light="true" style={{ position: 'relative', overflow: 'hidden' }}>Chat Now</button> */}
																							<button type="button" onClick={() => openChatbox(value)} className="rubik-400  bg-blue-700 hover:bg-blue-800 																			
																							close_btn rounded-md px-5 py-2.5 w-full" style={{ position: 'relative', overflow: 'hidden' }}>Chat Now</button>
																						</div>
																					</div>
																				</div>
																			</a>

																			<div className="fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-96 dark:bg-gray-800" tabIndex="-1" aria-labelledby="drawer-right-label">
																				{/* <div id={drawerId} className="fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-96 dark:bg-gray-800" tabIndex="-1" aria-labelledby="drawer-right-label"> */}
																				<div className="flex items-center border-b pb-4">
																					<div>
																						<h5 id="drawer-navigation-label" className="dark-color rubik-500 fs-22">Employee Details</h5>
																						<p className="grey-color rubik-400 fs-18">{authData?.data?.pharmacyname}</p>
																					</div>

																					{/* <button type="button" data-drawer-hide={drawerId} aria-controls={drawerId} className="ml-auto text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full blueBg-color light-color p-1 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" >
																						<svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
																						<span className="sr-only">Close menu</span>
																					</button> */}

																					<button type="button" className="ml-auto text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full blueBg-color light-color p-1 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" >
																						<svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
																						<span className="sr-only">Close menu</span>
																					</button>

																				</div>
																				<div className="py-6 overflow-y-auto">
																					<div className="text-center">
																						<img className="mx-auto" src={value.photo} alt="" />
																						<div className="pt-3">
																							<h2 className="rubik-500 fs-22">{value.name}</h2>
																						</div>
																					</div>
																					<ul className="font-medium ot_txtSidebar pt-2">
																						<li className="m-0">
																							<div className="flex py-4 border-b">
																								<h4 className="rubik-500 fs-18 dark-color">Email Id</h4>
																								<p className="ml-auto rubik-400 fs-18 grey-color">{value.email}</p>

																							</div>
																						</li>

																						<li className="m-0">
																							<div className="flex py-4 border-b">
																								<h4 className="rubik-500 fs-18 dark-color">Hired Date</h4>
																								<p className="ml-auto rubik-400 fs-18 grey-color">{value.date_hired}</p>
																							</div>
																						</li>

																						{value.phone &&
																							<li className="m-0">
																								<div className="flex py-4 border-b">
																									<h4 className="rubik-500 fs-18 dark-color">Phone</h4>
																									<p className="ml-auto rubik-400 fs-18 grey-color">{value.phone}</p>
																								</div>
																							</li>}

																					</ul>
																				</div>
																			</div>

																			{selectedEmployeeId &&
																				<InitatedChatBox
																					value={value}
																					selectedEmployeeId={selectedEmployeeId}
																					profileData={profileData}
																					employeesOnlineStatus={employeesOnlineStatus}
																					setMyInputMessages={setMyInputMessages}
																					setChatAppenedMessages={setChatAppenedMessages}
																					setChatInput={setChatInput}
																					setAllGroupMessages={setAllGroupMessages}
																					allGroupMessages={allGroupMessages}
																					chatInput={chatInput}
																					myInputMessages={myInputMessages}
																					chatAppenedMessages={chatAppenedMessages}
																					openOverlay={openOverlay}
																					closeOverlay={closeOverlay}
																					setSelectedEmployeeId={setSelectedEmployeeId}
																				/>}

																		</div>}
																</React.Fragment>
															)
														})}
												</div>
											</div>
								}
							</div>
						</div>
					</div>
				</div>
				{/* <ChatBox /> */}



				<Footer />

			</div>

			<div id="overlay">
				<span id="close-btn" onClick={closeOverlay}>&times;</span>
				<img src={overlayImage} className="rounded-full" alt="Large Image" />
			</div>
		</>
	)
}

export default AllEmployees