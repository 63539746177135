import React, {
    useEffect,
    useState
} from 'react'

import { initalCounsellingData } from '../../../utils/constants/formInitialStates'
import { FetchPatientCounsellingList } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'
import { useDispenseSelectors } from '../../../services/selectors/dispenseSelectors'
import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'

import * as Dispense from '../../../components/pharmacy/dispense/dispense'
import * as Common from '../../../components/common'
import * as Hooks from '../../../hooks'
import * as routesNames from '../../../routes/routeName'
import ChatBox from '../../../components/chat/chatbox'

const counsellingTableHeading = [
    { text: "PATIENT NAME", classNames: ['p-3'] },
    { text: "DRUG", classNames: ['p-3'] },
    { text: "DRUG", classNames: ['p-3'] },
    { text: "COUNSELLING TIME", classNames: ['p-3'] },
];

const Counselling = () => {

    const [formData, setFormData] = React.useState(initalCounsellingData)

    const { dispenseListDataResponse } = useDispenseSelectors();
    const counsellingList = dispenseListDataResponse?.data?.data

    const dispatch = Hooks.useCustomDispatch()
    const location = Hooks.useCustomLocation()
    const navigate = Hooks.useCustomNavigate()


    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;
    const initalDate = new URLSearchParams(location.search).get('date') || formData?.date

    const [pageNumber, setPageNumber] = useState(initialPage)

    useEffect(() => {
        setPageNumber(initialPage)
        setFormData({ ...formData, date: initalDate })
    }, [initialPage, initalDate])

    const handleFilterData = (event, value) => {
        const params = {
            search_date: formData.date,
            page: value
        }
        const searchParams = new URLSearchParams()
        searchParams.append('date', formData?.date);

        if (value !== undefined) {
            searchParams.append('page', value);
            setPageNumber(value)
        }

        dispatch(FetchPatientCounsellingList(params, 'counselling_list'))

        const newUrl = `${routesNames.pharmacy_patient_counselling}?${searchParams.toString()}`
        navigate(newUrl);
    }

    useEffect(() => {
        if (formData?.date) {
            const params = {
                search_date: initalDate ? initalDate : formData.date,
                page: pageNumber
            }
            dispatch(FetchPatientCounsellingList(params, 'counselling_list'))
        }
        return () => { }
    }, [])

    const handleResetFilteredData = () => {
        const searchParams = new URLSearchParams();
        searchParams.delete('page');
        searchParams.delete('date');
        const newUrl = `${routesNames.pharmacy_patient_counselling}?${searchParams.toString()}`;
        navigate(newUrl);
    }

    return (
        <React.Fragment>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        {/* <Common.Sidebar /> */}
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="flex flex-wrap lg:flex-nowrap gap-3">
                                            <div className='flex-0 lg:w-3/12 md:w-4/12 sm:w-6/12 w-full'>

                                                <Dispense.StartDate formData={formData}
                                                    setFormData={setFormData} />
                                            </div>

                                            <div className='flex-0 lg:w-1/12 md:w-2/12 sm:w-4/12 w-full flex'>
                                                <button type="button" style={{ backgroundColor: '#3B82F6' }} onClick={handleFilterData}
                                                    className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
                                                            border border-gray-300 			focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Filter
                                                </button>

                                                <button type="button" style={{ backgroundColor: 'White', width: '66px' }} onClick={handleResetFilteredData}
                                                    className="flex ml-2 w-full h-full items-center justify-center text-gray-500 bg-white 
                                                            border border-gray-300 			focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Reset
                                                </button>
                                            </div>

                                        </div>

                                        {dispenseListDataResponse?.loading && dispenseListDataResponse?.type === 'counselling_list' ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            dispenseListDataResponse?.error !== null && dispenseListDataResponse?.type === 'counselling_list' ?
                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div> :
                                                counsellingList?.data?.length === 0 && dispenseListDataResponse?.type === 'counselling_list' ?
                                                    <div className="bg-white pt-4 rounded-md">
                                                        <p className="text-gray-500">No Record Available.</p>
                                                    </div>
                                                    : dispenseListDataResponse?.type === 'counselling_list' ?
                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                            <div className="shadow-md border rounded-lg relative overflow-x-auto">
                                                                <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                    <thead className="text-xs text-white uppercase text-gray-900 dark:bg-gray-700 dark:text-gray-400"
                                                                        style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>

                                                                        <tr>
                                                                            {counsellingTableHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                        
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            counsellingList?.data?.map((list, idx) => (
                                                                                <React.Fragment key={idx}>
                                                                                    {list?.patientsWinRxDataHas?.map((value, index) => {
                                                                                        return (<React.Fragment key={index}>
                                                                                            <tr style={{ border: '1px solid lightgray' }}>
                                                                                                <td scope="row" className="p-3">
                                                                                                    <p>{value.patient}</p>
                                                                                                </td>

                                                                                                <td scope="row" className="p-3">
                                                                                                    <p>{value.drug}</p>
                                                                                                </td>

                                                                                                <td scope="row" className="p-3">
                                                                                                    <p>{value.counselling}</p>
                                                                                                </td>

                                                                                                <td scope="row" className="p-3">
                                                                                                    <p>{value.counselling_time}</p>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </React.Fragment>)
                                                                                    })}
                                                                                </React.Fragment>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>




                                                                {dispenseListDataResponse?.error !== null ? null : <>
                                                                    {counsellingList?.data?.length !== 0 && <>
                                                                        {counsellingList?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={counsellingList?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={(event, value) => {
                                                                                                handleFilterData(event, value)
                                                                                            }}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}
                                                            </div>

                                                        </div> : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />

            <Common.Footer />
        </React.Fragment>
    )
}

export default Counselling