import React, {
	useEffect,
	useState
} from 'react'

import {
	initalPharmacyInformationUpdateForm,
	initalShowToastMessage
} from '../../../utils/constants/formInitialStates'

import {
	BreadCrumb,
	Footer,
	Sidebar
} from '../../../components/common'

import { useCustomSelectors } from '../../../services/selectors/allSelectors'
import { useCustomDispatch } from '../../../hooks'
import { Errors } from '../../../components/error/alertMessages'
import { Loader } from '../../../components/common/loadingView'

import { useCustomPatientSelectors } from '../../../services/selectors/patientSelectors'
import { onhandlePharmacyFormDataChange } from '../../../utils/data/handleFormDataChange'
import { handlePharmacyInformationUpdateForm } from '../../../utils/validations/formsValidations'

import { useFormsSelectors } from '../../../services/selectors/formsSelector'
import { SuccessToastMessage } from '../../../components/loaders/toastMessage'

import * as Pharmacy from '../../../components/pharmacy/form/form'

import ChatBox from '../../../components/chat/chatbox'
import { previewPdfFailure } from '../../../services/actions/pharmacy/forms/previewPdf'
import { faxFormFailure } from '../../../services/actions/pharmacy/forms/pharmacyUpdateNotificationsSrFaxForm'

const PharmacyInformationUpdateForm = () => {

	const dispatch = useCustomDispatch()

	const { pharmacyLoginData } = useCustomSelectors()
	const { updateNotificationsClinicsResponse } = useCustomPatientSelectors()
	const { updateNoficationSRFaxResponse } = useFormsSelectors()

	const [formData, setFormData] = useState(initalPharmacyInformationUpdateForm)
	const [errorMessages, setErrorMessages] = useState(initalPharmacyInformationUpdateForm)
	const [showToast, setShowToast] = useState(initalShowToastMessage)

	const handleFormDataChange = (e) => {
		onhandlePharmacyFormDataChange(e, setFormData, formData, setErrorMessages, errorMessages)
	}

	useEffect(() => {
		if (pharmacyLoginData) {
			setFormData({
				...formData,
				pharmacyManager: pharmacyLoginData.manager_name,
				pharmacyFax: pharmacyLoginData.fax,
				pharmacyPhone: pharmacyLoginData.phone,
				pharmacyEmail: pharmacyLoginData.email,
				pharmacyName: pharmacyLoginData.name
			})
		}
	}, [pharmacyLoginData])

	useEffect(() => {
		dispatch(previewPdfFailure(null))
		dispatch(faxFormFailure(null))
	}, [])

	return (
		<>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content" id="timeSheets">

								<div className="grid grid-cols-12 gap-4 w-full">
									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6">
											<Pharmacy.Date formData={formData}
												setFormData={setFormData} />
											<ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
												<Pharmacy.FormWinRxPatients
													formData={formData}
													handleFormDataChange={handleFormDataChange} />
											</ul>
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
											<Pharmacy.PatientPhn
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setErrorMessages={setErrorMessages}
												setFormData={setFormData} />

											<Pharmacy.PatientName
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages}
												setFormData={setFormData}
												setErrorMessages={setErrorMessages} />

											<Pharmacy.PatientDob
												setErrorMessages={setErrorMessages}
												formData={formData}
												setFormData={setFormData}
												handleFormDataChange={handleFormDataChange}
												errorMessages={errorMessages} />
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
											<div className="relative ot_addNoteText">
												<div className="relative did-floating-label-content">
													<Pharmacy.ClinicName
														formData={formData}
														errorMessages={errorMessages}
														setFormData={setFormData}
														setErrorMessages={setErrorMessages} />

													<Pharmacy.AddClinic />
												</div>
											</div>

											<div className="relative ot_addNoteText">
												<div className="relative did-floating-label-content">
													<Pharmacy.DoctorList
														formData={formData}
														errorMessages={errorMessages}
														setFormData={setFormData}
														setErrorMessages={setErrorMessages} />

													<Pharmacy.AddDoctor />

												</div>
											</div>

											<Pharmacy.ClinicFaxNumber
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages} />

										</div>
										<br />

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
											<Pharmacy.LoginPharmacyName
												setFormData={setFormData}
												handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages} />

											<Pharmacy.LoginPharmacyManger handleFormDataChange={handleFormDataChange}
												setFormData={setFormData}
												formData={formData}
												errorMessages={errorMessages} />

											<Pharmacy.LoginPharmacyFax handleFormDataChange={handleFormDataChange}
												formData={formData}
												errorMessages={errorMessages} />
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">

											<Pharmacy.LoginPharmacyPhone
												handleFormDataChange={handleFormDataChange}
												formData={formData} errorMessages={errorMessages} />

											<Pharmacy.LoginPharmacyEmail
												handleFormDataChange={handleFormDataChange}
												formData={formData} errorMessages={errorMessages} />

											<Pharmacy.Designation
												setFormData={setFormData}
												handleFormDataChange={handleFormDataChange}
												formData={formData} errorMessages={errorMessages} />
										</div>

										{updateNotificationsClinicsResponse?.error !== null &&
											<>
												<br />
												<Errors error={'Something went wrong when generating the PDF. Please try to regenerate again.'} />
											</>
										}

										{updateNoficationSRFaxResponse?.error !== null &&
											<>
												<br />
												<Errors error={updateNoficationSRFaxResponse?.error.message ?
													updateNoficationSRFaxResponse?.error?.message :
													'Something went wrong when sending Fax. Please try again'} />
											</>
										}

										<div className='flex'>
											<div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
												<button style={{ height: '40px' }} type="button"

													onClick={() => handlePharmacyInformationUpdateForm(
														formData,
														setErrorMessages,
														errorMessages,
														dispatch,
														'PDF')}

													className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center">

													{updateNotificationsClinicsResponse?.loading ? <>
														Genrating PDF
														&nbsp;<Loader /> </> : 'Preview PDF'}

												</button>
											</div>

											{pharmacyLoginData?.srFax_active === 1 && pharmacyLoginData?.srFax_status === 1 && 
												<div className="pl-2 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-2 mt-4 mb-0 block text-left">
													<button style={{ height: '40px', backgroundColor: 'lightseagreen' }} type="button"

														onClick={() => handlePharmacyInformationUpdateForm(
															formData,
															setErrorMessages,
															errorMessages,
															dispatch, 'FAX',
															showToast,
															setShowToast)}

														className="ti_heightScheduleInnerDateBtn blueBg-color  sm:text-center md:text-start 
												 				lg:text-start rubik-400 fs-14 text-white rounded-lg text-sm px-3 py-1 flex justify-center items-center" >

														{updateNoficationSRFaxResponse?.loading ? <>
															Sending
															&nbsp;<Loader /> </> : 'Send Fax'}

													</button>
												</div>}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ChatBox />
			<Footer />
			{showToast.showToast && <SuccessToastMessage status={'Fax sent'} />}

		</>
	)
}

export default PharmacyInformationUpdateForm