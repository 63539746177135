import React, {
    useEffect,
    useState
} from "react";

import {
    ErrorToast,
    SuccessToastMessage
} from "../../../components/loaders/toastMessage";

import {
    AddNotes,
    StatusUpdateConfirmation,
} from '../../../models/patients';

import {
    UpdateOatData,
    oatUpdateStatus
} from "../../../services/actions/OAT/addOATData";

import { initalShowToastMessage } from "../../../utils/constants/formInitialStates";
import { useFormsSelectors } from "../../../services/selectors/formsSelector";
import { utils, writeFile } from 'xlsx';
import { StatusOATModalPopUp } from "../../../models/oats/oats";

import { RXModal } from '../../../models/dispense/rxListModal'
import { pharmacyPreviewPdf } from "../../../services/actions/pharmacy/other/pharmacyPatientNotifications";

import { openPatientNoteModal } from '../../../assets/js/custome';
import { fetchPatientMedicineList } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'
import { oatFetchList } from "../../../services/actions/OAT/oatFETCH";
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";

import { initDropdowns } from "flowbite";
import { useOATSelector } from "../../../services/selectors/oatSelectors";
import { useCustomLoadingStates } from '../../../hooks/customStates';
import { BlisterDropDown } from '../../../components/pharmacy/form/form'

import { TextInput } from "../../../components/pharmacy/dispense/dispense"

import * as Hooks from "../../../hooks";
import * as Icons from "../../../assets/svg/icons";
import * as Modals from "../../../assets/js/custome";
import * as routeNames from "../../../routes/routeName";
import * as path from '../../../networking/urlEndPoints';
import * as Common from "../../../components/common";

import ChatBox from "../../../components/chat/chatbox";

const PatientRoutesLIst = [
    { text: "ROUTE", classNames: ["p-3"] },
    { text: "NAME", classNames: ["p-3"] },
    { text: "PHN", classNames: ["p-3"] },
    { text: "PHONE", classNames: ["p-3"] },
    { text: "ADDRESS", classNames: ["p-3"] },
    { text: "DL NOTE", classNames: ["p-3"] },
    { text: "PDF NOTE", classNames: ["p-3"] },
    { text: "STATUS", classNames: ["p-3"] },
    { text: "ACTION", classNames: ["p-3"] },
];

const TableRowData = ({ value, className1 = "p-3" }) => {
    return (
        <td scope="row" className={className1}>
            <p>{value}</p>
        </td>
    );
};


export const exportToExcel = (data, fileName) => {
    const formattedData = data?.map(value => ({
        'ROUTE': value.route,
        'NAME': value.name,
        'PHN': value.phn,
        'PHONE': value.phone,
        'ADDRESS': value.address,

    }));

    const worksheet = utils.json_to_sheet(formattedData);

    worksheet['!cols'] = [
        { wch: 10 },
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 70 }
    ];

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `${fileName}.xlsx`);
};


const PatientRoute = () => {

    const [showToast, setShowToast] = useState(initalShowToastMessage);

    const { previewPdfResponse } = useFormsSelectors();

    const location = Hooks.useCustomLocation();
    const navigate = Hooks.useCustomNavigate();
    const dispatch = Hooks.useCustomDispatch();

    const { oatListResponse } = useOATSelector();

    const routeList = oatListResponse?.data?.data;
    const routeListForDropdown = oatListResponse?.data?.data?.routes

    const searchParams = new URLSearchParams();
    const initialPage = parseInt(new URLSearchParams(location.search).get("page")) || 1;

    const { showToastMessage,
        setShowToastMessage,
        setShowErrorToastMessage,
        showModal, setShowModal
    } = useCustomLoadingStates()

    const [pageNumber, setPageNumber] = useState(initialPage);
    const [oatStatus, setoatStatus] = useState(null);
    const [formData, setFormData] = useState({ search: "" });

    const [reset, setReset] = useState(false);
    const [patientProfileData, setPatientProfileData] = useState(null)
    const [toggleIndex, setToggleIndex] = useState(null)

    const handleUpdate = (idx, routeid, setShowToast, type, data) => {
        dispatch(UpdateOatData(routeid, setShowToast, type, data, setShowModal))
    };

    useEffect(() => {
        setPageNumber(initialPage);
    }, [initialPage]);

    useEffect(() => {
        const params = {
            page: pageNumber,
            no_of_records: 10,
            sortlist: 'latest'
        };

        dispatch(oatFetchList("pharmacy_patient_route", params));

        return () => { };
    }, []);

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
            no_of_records: 10,
            sortlist: 'latest'
        };


        if (formData.search) params.search = formData.search;
        if (formData.route) params.route = formData.route;

        if (value !== undefined) {
            searchParams.append("page", value);
            setPageNumber(value);
        }

        dispatch(oatFetchList("pharmacy_patient_route", params));
        const newUrl = `${location.pathname}?${searchParams.toString()}`;

        navigate(newUrl);
    };

    const handleResetFilteredData = () => {
        const searchParams = new URLSearchParams();
        setReset(true)
        setFormData({ ...formData, search: '', route: '' })
        setTimeout(() => {
            setReset(false)
        }, 500);
        const newUrl = `${routeNames.pharmacy_patient_route}?${searchParams.toString()}`;
        dispatch(oatFetchList("pharmacy_patient_route", searchParams));
        navigate(newUrl);
    }

    const handleUpdateStatusToggle = async () => {
        if (toggleIndex !== null) {
            dispatch(oatUpdateStatus("pharmacy_patient_route", searchParams, setShowToastMessage, setShowErrorToastMessage, oatStatus, toggleIndex, setShowToast, setShowModal, showModal));
        }
    }

    useEffect(() => {
        initDropdowns();
    }, [routeList?.data]);

    return (
        <>
            <div className="BodyBgColor">
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className={`lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12`}>

                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div
                                        className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left"
                                    >
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="col-span-12 flex">
                                            <div className="flex w-full flex-wrap gap-y-3">

                                                <div className='w-34'>
                                                    <TextInput
                                                        label="Search"
                                                        name="search"
                                                        formData={formData}
                                                        setFormData={setFormData}
                                                        height="42px"
                                                    />
                                                </div>

                                                <div className='flex-0 ml-1 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '104px' }}>
                                                    <BlisterDropDown formData={formData}
                                                        setFormData={setFormData}
                                                        name="route"
                                                        value={formData.route}
                                                        filterList={routeListForDropdown}
                                                        reset={reset}
                                                        placeholder='Route' />
                                                </div>



                                                {/* <div className='md:flex-1 lg:w-2/12 md:w-4/12 sm:w-6/12 sm:pr-3 md:pr-0 w-full  ml-2'>
                                                    <Dispense.RouteList formData={formData}
                                                        setFormData={setFormData} />
                                                </div> */}
                                                <div className='flex-0  w-auto ml-1'>
                                                    <button onClick={handlePagination} style={{ backgroundColor: '#3B82F6', color: 'white' }} type="button"
                                                        className="flex text-white ml-0 w-full h-full pl-6 py-4 items-center justify-center text-gray-900 bg-white border border-gray-300 
                                                        focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                                        Filter
                                                    </button>
                                                </div>

                                                <div className='flex-0 lg:w-2/12 md:w-2/12 sm:w-4/12 w-full ml-1'>
                                                    <button onClick={() => exportToExcel(routeList?.data, 'route-list')} style={{ color: 'white' }} type="button"
                                                        className="flex text-white  w-full h-full ml-0 pl-6 py-4 items-center justify-center text-gray-900 bg-green-500 border border-gray-300 
                                                        focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm  px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                                        Export Sheet
                                                    </button>
                                                </div>

                                                <div className='flex-0 lg:w-2/12 md:w-6/12 sm:w-4/12 w-full ml-1'>
                                                    <button type="button"
                                                        onClick={() => dispatch(pharmacyPreviewPdf(path.pharmacy_route_patient, formData))}
                                                        className="w-full bg-red-500 hover:blueBg-color ml-0 flex py-4  items-center justify-center text-white bg-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white">
                                                        {previewPdfResponse?.loading ?
                                                            <p className='flex'>Genrating PDF...</p>
                                                            :
                                                            'Export PDF'
                                                        }

                                                    </button>
                                                </div>

                                                <div className='flex-0 lg:w-1/12 md:w-6/12 sm:w-4/12 w-full ml-1'>
                                                    <button onClick={handleResetFilteredData} type="button" className="flex ml-0 w-full h-full  py-4 items-center justify-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                                        Reset
                                                    </button>
                                                </div>
                                                <div className="ml-auto">
                                                    <button onClick={() => navigate(routeNames.pharmacy_add_patient_route)} type="button"
                                                    className="rubik-500 rounded-md flex fs-14   text-white blueBg-color  focus:ring-4 
                                                        focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 text-center
                                                        dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 justify-center items-center">
                                                    <span className="flex items-center">Add New Patient</span>
                                                </button>
                                                </div>
                                            </div>
                                        </div>

                                        {oatListResponse?.loading &&
                                            oatListResponse?.type === "pharmacy_patient_route" ? (
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div>
                                        ) : oatListResponse?.error !== null &&
                                            oatListResponse?.type === "pharmacy_patient_route" ? (
                                            <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                <h1 className="text-4xl font-bold text-red-600 mb-2">
                                                    Oops!
                                                </h1>
                                                <p className="text-lg text-gray-800 mb-2">
                                                    Something went wrong while fetching the data.
                                                </p>
                                                <button
                                                    onClick={() => window.location.reload()}
                                                    className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500"
                                                >
                                                    Please Try Again.
                                                </button>
                                            </div>
                                        ) : routeList?.data?.length === 0 &&
                                            oatListResponse?.type === "pharmacy_patient_route" ? (
                                            <div className="bg-white rounded-md">
                                                <p className="text-gray-500">
                                                    Route lists are not available.
                                                </p>
                                            </div>
                                        ) : oatListResponse?.type === "pharmacy_patient_route" ? (
                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                <div className="shadow-md border rounded-lg  relative overflow-x-auto">
                                                    <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                        <thead
                                                            className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900"
                                                            style={{
                                                                borderBottom: "1px solid lightgray",
                                                                background: "#f6f4f4",
                                                            }}
                                                        >
                                                            <tr>
                                                                {PatientRoutesLIst?.map((header, index) => (
                                                                    <th
                                                                        key={index}
                                                                        scope="col"
                                                                        className={header?.classNames?.join(" ")}
                                                                    >
                                                                        <p className="rubik-500 fs-14">
                                                                            {header?.text}
                                                                        </p>
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {routeList?.data?.map((value, idx) => (
                                                                <React.Fragment key={idx}>
                                                                    <tr
                                                                        style={{
                                                                            borderBottom: `${"1px solid lightgray"}`,
                                                                        }}
                                                                    >
                                                                        <td className="p-3">
                                                                            <textarea defaultValue={value.route}
                                                                                className="p-2 box-shadow-none border-t-0 border-r-0 border-l-0" style={{ width: "70px" }}
                                                                                onChange={(e) => setTimeout(() => {
                                                                                    handleUpdate(idx + "route", value.id, setShowToast, "route", e.target.value)
                                                                                }, 1000)}
                                                                            ></textarea>

                                                                        </td>
                                                                        <TableRowData value={value.name} />
                                                                        <TableRowData value={value.phn} />
                                                                        <TableRowData value={value.phone} />
                                                                        <TableRowData value={value.address} />
                                                                        <td className="p-3">
                                                                            <textarea defaultValue={value.delivery_note}
                                                                                className="p-2 box-shadow-none border-t-0 border-r-0 border-l-0 "
                                                                                onChange={(e) => setTimeout(() => {
                                                                                    handleUpdate(idx + "delivery_note", value.id, setShowToast, "delivey_note", e.target.value)
                                                                                }, 1000)}
                                                                            ></textarea>

                                                                        </td>
                                                                        <td className="p-3">
                                                                            <textarea style={{ shadow: 'none' }} defaultValue={value.pdf_note}
                                                                                className="!focus:shadow-none box-shadow-none p-2 border-t-0 border-r-0 border-l-0 "
                                                                                onChange={(e) => setTimeout(() => {
                                                                                    handleUpdate(idx + "pdf_note", value.id, setShowToast, "pdf_note", e.target.value)
                                                                                }, 1000)}
                                                                            ></textarea>

                                                                        </td>
                                                                        <td className="px-6 py-2">
                                                                            <label className="relative inline-flex items-center cursor-pointer">
                                                                                <input type="checkbox" onChange={() => {
                                                                                    return setToggleIndex(idx), setoatStatus(value.id), Modals.handleShowModal(
                                                                                        setShowModal,
                                                                                        showModal,
                                                                                        "statusOATModal"
                                                                                    )
                                                                                }} className="sr-only peer" checked={value.status === 1 ? true : false} />
                                                                                <div
                                                                                    className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 mr-2 p-0.5">
                                                                                </div>
                                                                                <span
                                                                                    className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 "></span>
                                                                            </label>
                                                                        </td>
                                                                        <td scope="row" className="p-3">
                                                                            <button id={`dropdownDefaultButton_${idx}`} data-dropdown-toggle={`dropdown_${idx}`} className="inline-flex items-center text-white p-2 text-sm font-medium text-center text-gray-900 	bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
                                                                                <Icons.EditIcon />
                                                                            </button>

                                                                            <div id={`dropdown_${idx}`} className="z-10 relative border border-gray-100 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                                                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby={`dropdownDefaultButton_${idx}`}>

                                                                                    <li className='btnnn' onClick={() => { return Modals.handleShowModal(setShowModal, showModal, 'rxModal'), dispatch(fetchPatientMedicineList(value.id, 'patient_pending_list')) }}>
                                                                                        <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                            <span className="pl-2">View Medicine</span></a>
                                                                                    </li>

                                                                                    <li onClick={() => { return setPatientProfileData(value) }} className='btnnn'>
                                                                                        <a onClick={() => openPatientNoteModal(setShowModal, showModal)} className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">

                                                                                            <span className="pl-2">Add Note</span></a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                </React.Fragment>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    {oatListResponse?.error !== null ? null : (
                                                        <>
                                                            {routeList.data?.length !== 0 && (
                                                                <>
                                                                    {routeList.pagination?.last_page !== 1 && (
                                                                        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                            <nav aria-label="Page navigation example">
                                                                                <ul className="inline-flex -space-x-px text-base h-10">
                                                                                    <CustomPagination
                                                                                        count={
                                                                                            routeList.pagination?.last_page
                                                                                        }
                                                                                        page={pageNumber}
                                                                                        onChange={(event, value) => {
                                                                                            handlePagination(event, value);
                                                                                        }}
                                                                                    />
                                                                                </ul>
                                                                            </nav>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <StatusOATModalPopUp
                showModal={showModal}
                OATID={oatStatus}
                onClose={() =>
                    Modals.handleHideModal(setShowModal, showModal, "statusOATModal")
                }
                setShowModal={setShowModal}
                formData={formData}
                pageNumber={pageNumber}
                setShowToast={setShowToast}


            />

            {
                showModal.statusUpdateModal &&
                <StatusUpdateConfirmation
                    setShowModal={setShowModal}
                    showModal={showModal}
                    handleUpdateStatusToggle={handleUpdateStatusToggle}
                />
            }
            {showModal?.showToast &&
                <SuccessToastMessage status='Updated Successfully' />
            }

            <RXModal showModal={showModal} onClose={() => Modals.handleHideModal(setShowModal, showModal, 'rxModal')} setShowModal={setShowModal} />
            <ChatBox />

            {previewPdfResponse?.error !== null && (
                <>

                    <ErrorToast status={"Something went wrong when generating the PDF"} />

                </>
            )}

            {
                showToastMessage &&
                <SuccessToastMessage status='Updated Successfully' />
            }



            {showToast.errorToast && (
                <ErrorToast status={"Something went wrong."} />
            )}
            {
                showModal.patientNoteModal &&
                <AddNotes
                    showToast={showToast}
                    setShowToast={setShowToast}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    patientProfileData={patientProfileData}
                    paginationNumber={pageNumber}
                />
            }
            {showToast?.patientNoteToast && <SuccessToastMessage status='Note added sucessfully' />}
            <Common.Footer />

        </>
    );
};

export default PatientRoute;
