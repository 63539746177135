import React, {
    useEffect,
    useState
} from 'react'

import { currentDate } from '../../utils/constants/formInitialStates';

import * as Hooks from '../../hooks'
import * as Common from '../../components/common'

import ChatBox from '../../components/chat/chatbox'
import { useAdminSelectors } from '../../services/selectors/adminModuleSelectors';
import * as Pharmacy from "../../components/pharmacy/form/form";
import { fetchDefaultpharmacistSettingsList } from '../../services/actions/admin/team/fetchTeamsList';
import { handleDefaultPharmacistFormValidation } from '../../utils/validations/formsValidations';
import { useCustomSelectors } from '../../services/selectors/allSelectors';
import { useARVSelectors } from '../../services/selectors/arvSelectors';
import { fetchPharmacyTeamsUsersDropdownList } from '../../services/actions/pharmacy/arv/patientARVList';

export const appUSerListHeading = [
    { text: "Initial Date", classNames: ['p-3'] },
    { text: "End Date", classNames: ['p-3'] },
    // { text: "Begin Time", classNames: ['p-3'] },
    { text: "Pharmacist Name", classNames: ['p-3'] },
    { text: "Action", classNames: ['p-3'] },
];

export const TableRowData = ({ value, className1 = 'p-3' }) => {
    return (<td scope="row" className={className1}>
        <p>{value}</p>
    </td>)
}

export const AssignTaskSelect = ({ formData, setFormData, errorMessage, employeeList, placeholder = 'Select pharmacist', padding = 'pb-2.5 pt-4' }) => {

    return (
        <div className="relative w-full">
            <select
                id="pharmacist_id"
                name="pharmacist_id"
                value={formData.pharmacist_id}
                placeholder="Payroll period"
                onChange={(e) => setFormData({ ...formData, pharmacist_id: e.target.value })}
                className={`block ${errorMessage?.pharmacist_id && 'border-red-500'} scroll-box pl-4 w-full px-2.5 ${padding}  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
          dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer 
          bg-white `}
            >
                <option value="" disabled>{placeholder}</option>
                {employeeList.length > 0 && employeeList?.map((option, index) => (
                    <React.Fragment key={index}>
                        <option value={option.value}>
                            {option.label}
                        </option>
                    </React.Fragment>
                ))}
            </select>
        </div>
    );
};

const Defaultpharmacist = () => {
    const [formVisible, setFormVisible] = useState(null);
    const [formData, setFormData] = useState({
        date: currentDate,
        pharmacist_id: ''
    });
    const [errorMessage, setErrorMessages] = useState(null)
    const { arvListListResponse } = useARVSelectors();

    const dispatch = Hooks.useCustomDispatch()

    const { TeamListResponse, addTeamDataResponse, taskCommentsResponse } = useAdminSelectors()
    // const { userProfileResponse } = useCustomSelectors()

    const appUserListResponse = TeamListResponse
    // const appUserList = TeamListResponse?.data?.data?.pharmacist_setting
    const [appUserList,setappUserList] = useState([])
    // const pharmistList = userProfileResponse && userProfileResponse?.profileData
    const fetchType = 'default_pharmacist_settings'
    const [desstructuredata, setdesstructuredata] = useState([])
    const filterpharmistData = []

    useEffect(() => {
        dispatch(fetchDefaultpharmacistSettingsList(fetchType))
        dispatch(fetchPharmacyTeamsUsersDropdownList('fetch_pharmacy_teacm_user_list'))

        return () => { }

    }, [])

    useEffect(() => {
        if ( TeamListResponse.type === 'default_pharmacist_settings') {
            //console.log("bohemia"+TeamListResponse?.data)
            setappUserList(TeamListResponse?.data?.data?.pharmacist_setting || [])
           
        }
      


    }, [TeamListResponse]);

    useEffect(() => {
       
        if (arvListListResponse.type === 'fetch_pharmacy_teacm_user_list') {
            //console.log(arvListListResponse?.data?.data?.user)
            const filterpharmistData = arvListListResponse?.data?.data?.user || [];

            const filtData = filterpharmistData.map((itm) => ({
                value: itm.id,
                label: itm.name
            }));
            setdesstructuredata(filtData)
           
        }


    }, [arvListListResponse]);
    
    const handleButtonClick = (idx) => {
        setFormVisible(idx);
    };

    const handleSubmit = () => {
        handleDefaultPharmacistFormValidation(formData, setErrorMessages, errorMessage, dispatch, setFormVisible)
    }

    return (
        <React.Fragment>
            <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">

                <div className="content">

                    <div className="container mt-2">
                        <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

                            {appUserListResponse?.loading && appUserListResponse?.type === fetchType ?
                                <div className="flex justify-center items-center mt-60 mb-80">
                                    <Common.LoadingView />
                                </div> :
                                appUserListResponse?.error !== null && appUserListResponse?.type === fetchType ?
                                    <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                        <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                        <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                        <button onClick={() => window.location.reload()}
                                            className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                            Please Try Again.
                                        </button>
                                    </div> :
                                    appUserList?.length === 0 && appUserListResponse?.type === fetchType ?
                                        <div className="bg-white rounded-md mt-4">
                                            <p className="text-gray-500">No Data Found.</p>
                                        </div>
                                        : appUserListResponse?.type === fetchType ?
                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                <div className="shadow-md border rounded-lg relative">
                                                    <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                        <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                            <tr>
                                                                {appUSerListHeading?.map((header, index) => (
                                                                    <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                        <p className="rubik-500 fs-14">{header?.text}</p>
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                appUserList?.map((value, idx) => {
                                                                    const isLastIndex = idx === appUserList.length - 1;
                                                                    return (<React.Fragment key={idx}>
                                                                        <tr style={{ borderBottom: '1px solid lightgray' }}>
                                                                            <TableRowData value={value.initial_date} />
                                                                            <TableRowData value={value.end_date} />
                                                                            <TableRowData value={value.pharmacist_name} />
                                                                            {/* <TableRowData value={value.begin_time} /> */}
                                                                            {(!formVisible && isLastIndex) && <td className='p-3'>
                                                                                <button type="button"
                                                                                    onClick={() => handleButtonClick(idx)}
                                                                                    className="flex justify-center items-center text-white bg-blue-500 hover:bg-blue-600 w-full text-sm px-3 py-2 text-center rounded-lg">
                                                                                    Add</button>
                                                                            </td>}
                                                                        </tr>
                                                                    </React.Fragment>)
                                                                })}
                                                            {(formVisible == 0 || formVisible) && (
                                                                <tr>
                                                                    <td className="p-3" colSpan={'6'}>
                                                                        <div className='grid grid-cols-3 gap-2'>
                                                                            <AssignTaskSelect
                                                                                formData={formData}
                                                                                setFormData={setFormData}
                                                                                errorMessage={errorMessage}
                                                                                employeeList={desstructuredata}
                                                                            />

                                                                            <div>
                                                                                <Pharmacy.Date
                                                                                    formData={formData}
                                                                                    setFormData={setFormData}
                                                                                />
                                                                                {errorMessage && <p className='text-red-500 fs-12 rubik-400'>{errorMessage?.start_date?.[0]}</p>}
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center gap-3 w-56 mt-2">
                                                                            <button type="button"
                                                                                onClick={() => setFormVisible(null)}
                                                                                className="flex justify-center items-center w-32 text-white bg-blue-500 hover:bg-blue-600 w-1/3 text-sm px-3 py-2 text-center rounded-lg">
                                                                                Back
                                                                            </button>
                                                                            <button type="button" onClick={() => handleSubmit()}
                                                                                className="flex justify-center items-center text-white bg-green-500 hover:bg-green-600 w-1/3 text-sm px-3 py-2 text-center rounded-lg">
                                                                                {addTeamDataResponse?.loading && addTeamDataResponse?.type === 'update-delivery-time-setting' ? 'Processing...' : 'Submit'}
                                                                            </button>

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> : null}
                        </div>
                    </div>

                </div>
            </div>

            <ChatBox />
            <Common.Footer />
        </React.Fragment>
    )
}

export default Defaultpharmacist