import React, {
	useEffect,
	useRef, useState
} from 'react';

import { handleHideModal } from '../../assets/js/custome';
import { ModalCloseIcon } from '../../assets/svg/icons';
import { useCustomDispatch } from '../../hooks';
import { useDispenseSelectors } from '../../services/selectors/dispenseSelectors';
import { pharmacyPatientArchievedAddNoteDelivery, pharmacyUpdatedDailyDispenselogStatus } from '../../services/actions/pharmacy/dispense/addDispenseData';

import * as Pharmacy from '../../components/pharmacy/form/form';
import * as Dispense from '../../services/actions/pharmacy/dispense';
import * as useEffectHelper from '../../utils/helper/useEffectsHelper';
import { fetchRxAdded } from '../../services/actions/pharmacy/arv/patientARVList';
import { useARVSelectors } from '../../services/selectors/arvSelectors';
import { LoadingView } from '../../components/common';

export const MoveToNextDateModal = ({ text, setShowModal, showModal, path, type, patient_id }) => {

	const modalRef = useRef(null);
	const dispatch = useCustomDispatch()
	const { addDispenseDataResponse } = useDispenseSelectors();

	useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'openMoveToNextDateModal', modalRef)

	const requestedData = {
		log_id: patient_id
	}

	return (
		<>{showModal.openMoveToNextDateModal &&
			<div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">

				<div ref={modalRef} className="relative w-full max-w-md max-h-full">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<button onClick={() => handleHideModal(setShowModal, showModal, 'openMoveToNextDateModal')} type="button"
							className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg 
              				text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
							<ModalCloseIcon />
						</button>
						<div className="p-6 text-center pt-12">
							<h1 className='rubik-500'>
								Are you sure ?
							</h1>
							<h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
								You want to move this to next date ?
							</h3>
							<button onClick={() => dispatch(Dispense.moveToNextDateDispneseList(`${path}`, requestedData, setShowModal, 'pharmacy-move-to-next-date'))} style={{ backgroundColor: '#3A52BB' }} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 
                    			dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">

								{addDispenseDataResponse?.loading ? 'Changing Date...' : 'Yes'}

							</button>
							<button onClick={() => handleHideModal(setShowModal, showModal, 'openMoveToNextDateModal')} type="button"
								className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border 
                				border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 
                				dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel</button>
						</div>
					</div>
				</div>
			</div>}
		</>
	);
};


export const ViewDrugModal = ({ setShowModal, showModal, onClose = null }) => {

	const modalRef = useRef(null);

	useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'drugModalVisible', modalRef)

	return (
		<React.Fragment>

			{showModal.drugModalVisible && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center 
                      justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100" style={{ zIndex: '99999999999999999999999999' }}>
					<div ref={modalRef} className="relative w-full max-w-md max-h-full">
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
							<div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
								<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
									Drug Details
								</h3>
								<button
									onClick={onClose ? onClose : () => { }}
									type="button"
									className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
								>
									<ModalCloseIcon />
									<span className="sr-only">Close modal</span>
								</button>
							</div>

							<div className="p-4">
								<div className="relative">
									<h1 className="rubik-500 mb-2 text-gray-800 text-xl ">Oat Form Drugs</h1>
									<div className='mb-2 rubik-500 text-gray-600'>


										<textarea
											maxLength="255"
											autoFocus

											name="reason"
											className="fs-18 rounded-md rubik-400 border border-gray-300 text-gray-900"
											id="reason"
										></textarea>

									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

export const ReasonStatusModal = ({ setShowModal, showModal, onClose = null, status = 'Missed', patient_id }) => {

	const modalRef = useRef(null);
	const dispatch = useCustomDispatch()
	const [reasonNote, setReasonNote] = useState('')
	const { addDispenseDataResponse } = useDispenseSelectors()
	useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'reasonModalVisible', modalRef)

	return (
		<React.Fragment>
			{showModal.reasonModalVisible && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center 
                      justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100" style={{ zIndex: '9999999' }}>
					<div ref={modalRef} className="relative w-full max-w-md max-h-full">
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
							<div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
								<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
									Reason For {status}
								</h3>
								<button
									onClick={onClose ? onClose : () => { }}
									type="button"
									className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
								>
									<ModalCloseIcon />
									<span className="sr-only">Close modal</span>
								</button>
							</div>

							<div className="p-4">
								<div className="did-floating-label-content relative">
									<label
										htmlFor="Phn"
										className="ml-2 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
												-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
												peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
												peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
									>
										Reason
									</label>

									<div
										id="cancellationNotesContainer"
										className="form-group full"
									>
										<textarea
											maxLength="255"
											autoFocus
											onChange={(e) => {
												setReasonNote(e.target.value)
											}}
											name="reason"
											className="fs-18 rounded-md rubik-400 border border-gray-300 text-gray-900"
											id="reason"
										></textarea>
									</div>
								</div>
								<div className='flex flex-wrap gap-4 mt-2'>
									<button onClick={() => { dispatch(pharmacyUpdatedDailyDispenselogStatus(patient_id, 'Missed', setShowModal, 'pharmacy-update-log-status', reasonNote)) }} className="rubik-500 fs-16 h-12 rounded-md text-white blueBg-color w-full flex-1 text-sm px-5 py-2.5">
										{addDispenseDataResponse?.loading ? 'Saving...' : 'Save'}
									</button>
									<button onClick={onClose ? onClose : () => { }} className="rubik-500 fs-16 h-12 rounded-md text-blue-800 border border-blue-900 w-full flex-1 text-sm px-5 py-2.5">
										Cancel
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

export const EditNoteModal = ({ activeTab, setShowModal, showModal, onClose = null, patientData, formData, pageNumber, setFormData }) => {

	const modalRef = useRef(null);
	const dispatch = useCustomDispatch()
	const { addDispenseDataResponse } = useDispenseSelectors()
	useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'editNoteModalVisible', modalRef)

	return (
		<React.Fragment>
			{showModal.editNoteModalVisible && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center 
                      justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100" style={{ zIndex: '9999999' }}>
					<div ref={modalRef} className="relative w-full max-w-md max-h-full">
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
							<div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
								<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
									Patient Delivery Notes
								</h3>
								<button
									onClick={onClose ? onClose : () => { }}
									type="button"
									className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
								>
									<ModalCloseIcon />
									<span className="sr-only">Close modal</span>
								</button>
							</div>

							<div className="p-4  py-8">
								<div className="did-floating-label-content relative">
									<label
										htmlFor="Phn"
										className="ml-2 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
												-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
												peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
												peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
									>
										Notes
									</label>

									<div id="note-container" className="form-group full">
										<textarea
											maxLength="255"
											autoFocus
											onChange={(e) => {
												setFormData({ ...formData, addNote: e.target.value })
											}}
											name="note"
											className="w-full pt-2 pl-4 pr-4 fs-16 rounded-md rubik-400 border border-gray-300 text-gray-700"
											id="note"
											rows={8}
											defaultValue={formData.addNote}
										></textarea>
									</div>
								</div>

								<div className='flex flex-wrap gap-4 mt-2'>
									<button onClick={() => {
										patientData.patient_id &&
											dispatch(pharmacyPatientArchievedAddNoteDelivery(patientData.patient_id, formData.addNote, 'add_note', formData, pageNumber, setShowModal, activeTab))
									}}

										className="rubik-500 fs-16 h-12 rounded-md text-white blueBg-color w-full flex-1 text-sm px-5 py-2.5">
										{addDispenseDataResponse?.loading && addDispenseDataResponse.type === 'add_note' ? 'Saving...' : 'Save'}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

export const EditDispenseList = ({ setShowModal, showModal, onClose = null }) => {

	const modalRef = useRef(null);
	const dispatch = useCustomDispatch()
	useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'editDispenseListModalVisible', modalRef)
	const statusList = [
		"Delivered",
		"Pending",
		"Missed"
	]
	return (
		<React.Fragment>
			{showModal.editDispenseListModalVisible && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center 
                      justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100" style={{ zIndex: '9999999' }}>
					<div ref={modalRef} className="relative w-full max-w-md max-h-full">
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
							<div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
								<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
									Update Status
								</h3>
								<button
									onClick={onClose ? onClose : () => { }}
									type="button"
									className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
								>
									<ModalCloseIcon />
									<span className="sr-only">Close modal</span>
								</button>
							</div>

							<div className="p-4">
								<h5 className='text-blue-800 rubik-600'>Select Status</h5>
								<ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
									{
										statusList.map((item, index) => (

											<li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
												<div className="flex items-center pl-3">
													<input
														id={'status-${index}'}
														name="status[]"
														onChange={(val) => { }}
														defaultChecked
														value={item}
														type="radio"
														className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
													/>
													<label
														htmlFor={'status-${index}'}
														className="cursor-pointer w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400"
													>
														{item}
													</label>
												</div>
											</li>
										))
									}
								</ul>
								<h5 className='text-blue-800 rubik-600 mt-3'>Enter Time</h5>
								<Pharmacy.Date />
								<div className='flex flex-wrap gap-4 mt-5'>
									<button onClick={() => { }} className="rubik-500 fs-16 h-12 rounded-md text-white blueBg-color w-full flex-1 text-sm px-5 py-2.5">
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}



export const GetDelDif = ({ activeTab, setShowModal, showModal, onClose = null, patientData, formData, pageNumber, setFormData, dataid }) => {

	const modalRef = useRef(null);
	const dispatch = useCustomDispatch()
	const { addDispenseDataResponse } = useDispenseSelectors()
	useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'GetDelDif', modalRef)
	const { arvListListResponse } = useARVSelectors();
	const arvLIST = arvListListResponse?.data?.data;
	const [rxData, setrxData] = useState([])
	useEffect(() => {
		const fetchData = async () => {
			await dispatch(fetchRxAdded("get_rx_patient_data", dataid, formData.date));
		};

		fetchData();
	}, [dispatch, dataid, formData.date]);

	useEffect(() => {
		console.log(arvListListResponse?.data);
		setrxData(arvListListResponse?.data)
	}, [arvListListResponse]);

	return (
		<React.Fragment>
			{showModal.GetDelDif && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center 
                      justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100" style={{ zIndex: '9999999' }}>
					<div ref={modalRef} className="relative w-full max-w-md max-h-full" style={{ maxWidth: "45rem" }}>
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
							<div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
								<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
									Rx Added
								</h3>
								<button
									onClick={onClose ? onClose : () => { }}
									type="button"
									className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
								>
									<ModalCloseIcon />
									<span className="sr-only">Close modal</span>
								</button>
							</div>

							<div className="p-4  py-8 pt-0">

								{arvListListResponse?.loading &&
									arvListListResponse?.type === "get_rx_patient_data" ? (
									<div className="flex justify-center items-center mt-60 mb-80">
										<LoadingView />
									</div>
								) : arvListListResponse?.error !== null &&
									arvListListResponse?.type === "get_rx_patient_data" ? (
									<div className="flex flex-col items-center justify-center mt-60 mb-80">
										<h1 className="text-4xl font-bold text-red-600 mb-2">
											Oops!
										</h1>
										<p className="text-lg text-gray-800 mb-2">
											Something went wrong while fetching the data.
										</p>
										<button
											onClick={() => window.location.reload()}
											className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500"
										>
											Please Try Again.
										</button>
									</div>
								) : arvLIST?.data?.length === 0 &&
									arvListListResponse?.type === "get_rx_patient_data" ? (
									<div className="bg-white rounded-md">
										<p className="text-gray-500">
											ARV lists are not available.
										</p>
									</div>
								) : arvListListResponse?.type === "get_rx_patient_data" ? (
									<div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
											{rxData?.rxAdded?.length > 0 ? (
												<div>		<div className='text-green-800' style={{ fontSize: '20px' }}>Rx Added</div>
													<div className="shadow-md border rounded-lg  relative">

														<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
															<thead
																className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900"
																style={{
																	borderBottom: "1px solid lightgray",
																	background: "#f6f4f4",
																}}
															>
																<tr>

																	<th
																		class="p-2"
																		scope="col"

																	>
																		<p className="rubik-500 fs-14">
																			Rx Number
																		</p>
																	</th>
																	<th
																		class="p-2"
																		scope="col"

																	>
																		<p className="rubik-500 fs-14">
																			Rx Signature
																		</p>
																	</th>
																	<th
																		class="p-2"
																		scope="col"

																	>
																		<p className="rubik-500 fs-14">
																			Drug
																		</p>
																	</th>
																	<th
																		class="p-2"
																		scope="col"

																	>
																		<p className="rubik-500 fs-14">
																			DIN
																		</p>
																	</th>
																</tr>
															</thead>

															<tbody>

																{rxData?.rxAdded?.map((value, idx) => (
																	<React.Fragment key={idx}>
																		<tr
																			className={value.highlighted === 'true' ? 'highlighted' : ''}
																			style={{
																				borderBottom: `${"1px solid lightgray"}`,
																			}}
																		>
																			<td scope="row" class="p-2">{value.rxnumber}</td>
																			<td scope="row" class="p-2">{value.rxsig}</td>
																			<td scope="row" class="p-2">{value.drug}</td>
																			<td scope="row" class="p-2">{value.din}</td>

																		</tr>
																	</React.Fragment>
																))}
															</tbody>
														</table>
													</div>
												</div>) : null}
											<div></div>
											{rxData?.rxSubtracted?.length > 0 && (
												<div>
													<div className='text-red-800 mt-4' style={{ fontSize: '20px' }}>Rx Subtracted</div>
													<div className="shadow-md border rounded-lg relative">
														<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto">
															<thead className="uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: "1px solid lightgray", background: "#f6f4f4" }}>
																<tr>
																	<th className="p-2" scope="col">
																		<p className="rubik-500 fs-14">Rx Number</p>
																	</th>
																	<th className="p-2" scope="col">
																		<p className="rubik-500 fs-14">Rx Signature</p>
																	</th>
																	<th className="p-2" scope="col">
																		<p className="rubik-500 fs-14">Drug</p>
																	</th>
																	<th className="p-2" scope="col">
																		<p className="rubik-500 fs-14">DIN</p>
																	</th>
																</tr>
															</thead>
															<tbody>
																{rxData?.rxSubtracted?.map((value, idx) => (
																	<tr key={idx} className={value.highlighted === 'true' ? 'highlighted' : ''} style={{ borderBottom: "1px solid lightgray" }}>
																		<td scope="row" className="p-2">{value.rxnumber}</td>
																		<td scope="row" className="p-2">{value.rxsig}</td>
																		<td scope="row" className="p-2">{value.drug}</td>
																		<td scope="row" className="p-2">{value.din}</td>
																	</tr>
																))}
															</tbody>
														</table>
													</div>
												</div>
											)}

										</div>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}