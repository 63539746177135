import React, {
    useEffect,
    useState
} from 'react'

import {
    fetchTransferPrescriptionList,
    prescriptionListFailure
} from '../../../services/actions/pharmacy/app/prescriptionList'

import { useBlisterSelectors } from '../../../services/selectors/blisterSelectors'
import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'

import { TextInput } from '../../../components/pharmacy/dispense/dispense'
import { EyeIcon } from '../../../assets/svg/icons'
import { BlisterDropDown } from '../../../components/pharmacy/form/form'

import * as Common from '../../../components/common'
import * as Hooks from '../../../hooks'
import * as routesNames from '../../../routes/routeName'

import Button from '../../../components/common/button'

export const prescriptionsListHeading = [
    { text: "ORDER ID", classNames: ['p-3'] },
    { text: "PHN", classNames: ['p-3'] },
    { text: "NAME", classNames: ['p-3'] },
    { text: "ORDER DATE", classNames: ['p-3'] },
    { text: "STATUS", classNames: ['p-3 pl-10'] }
];

export const TableRowData = ({ value, className1 = 'p-3' }) => {
    return (<td scope="row" className={className1}>
        <p>{value}</p>
    </td>)
}

const PrescriptionList = () => {

    const location = Hooks.useCustomLocation()
    const navigate = Hooks.useCustomNavigate()
    const dispatch = Hooks.useCustomDispatch()

    const { patientBlisterPackListResponse } = useBlisterSelectors()
    const prescriptionListResponse = patientBlisterPackListResponse

    const prescriptionList = prescriptionListResponse?.data?.data
    const statusList = prescriptionListResponse?.data?.data?.orders

    const searchParams = new URLSearchParams();
    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)
    const [formData, setFormData] = useState({ search: '', status: '' })
    const [reset, setReset] = useState(false);

    const transferPrescriptionLocation = location.pathname === routesNames.pharmacy_order_transfer
    const fetchType = transferPrescriptionLocation ? 'transfer_prescription' : 'new_prescription'

    useEffect(() => {
        if (initialPage) {
            setPageNumber(initialPage)
        }
    }, [initialPage])

    useEffect(() => {
        dispatch(prescriptionListFailure(null, null))
    }, [])

    useEffect(() => {
        const params = {
            page: pageNumber,
            no_of_records: 10,
            order_type: fetchType
        }
        dispatch(fetchTransferPrescriptionList(fetchType, params))
        return () => { }
    }, [])

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
            no_of_records: 10,
            order_type: fetchType
        };

        if (formData.status) params.status = formData.status;
        if (formData.search) params.search = formData.search;

        if (value) {
            setPageNumber(value);
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl);
            dispatch(fetchTransferPrescriptionList(fetchType, params))
        }

        if (formData.status || formData.search) dispatch(fetchTransferPrescriptionList(fetchType, params))
    };


    const handleReset = () => {
        searchParams.delete('page');
        setReset(true)
        setFormData({ ...formData, search: '', status: '' })
        setTimeout(() => {
            setReset(false)
        }, 500);

        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);

        const params = {
            page: pageNumber,
            no_of_records: 10,
            order_type: fetchType
        }

        if (formData.status || formData.search) dispatch(fetchTransferPrescriptionList(fetchType, params))
    }

    return (
        <React.Fragment>

            <div className="container mt-2">
                <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                    <div className="flex flex-wrap lg:flex-nowrap gap-3">
                        <div className='flex-0 lg:w-3/12 md:w-4/12 sm:w-12/12' >
                            <TextInput label='Search' name='search' formData={formData}
                                setFormData={setFormData} />
                        </div>

                        <div className='flex-0 lg:w-3/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' >
                            <BlisterDropDown formData={formData}
                                setFormData={setFormData}
                                name="status"
                                value={formData.status}
                                filterList={statusList}
                                reset={reset}
                                placeholder='Select Status' />
                        </div>

                        <div className='flex gap-2'>
                            <Button bgColor='#3B82F6' width='70px' handleFilter={handlePagination} color='white' />
                            <Button bgColor='White' width='70px' handleFilter={handleReset} text='Reset'/>
                        </div>

                    </div>

                    {prescriptionListResponse?.loading && prescriptionListResponse?.type === fetchType ?
                        <div className="flex justify-center items-center mt-60 mb-80">
                            <Common.LoadingView />
                        </div> :
                        prescriptionListResponse?.error !== null && prescriptionListResponse?.type === fetchType ?
                            <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                <button onClick={() => window.location.reload()}
                                    className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                    Please Try Again.
                                </button>
                            </div> :
                            prescriptionList?.data?.length === 0 && prescriptionListResponse?.type === fetchType ?
                                <div className="bg-white rounded-md mt-4">
                                    <p className="text-gray-500">No Orders Found.</p>
                                </div>
                                : prescriptionListResponse?.type === fetchType ?
                                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                        <div className="shadow-md border rounded-lg relative">
                                            <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                    <tr>
                                                        {prescriptionsListHeading?.map((header, index) => (
                                                            <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                <p className="rubik-500 fs-14">{header?.text}</p>
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        prescriptionList?.data?.map((value, idx) => {
                                                            return (<React.Fragment key={idx}>
                                                                <tr style={{ borderBottom: '1px solid lightgray' }}>
                                                                    <TableRowData value={`#${value.order_id}`} />
                                                                    <TableRowData value={value.phn} />
                                                                    <TableRowData value={value.name} />
                                                                    <TableRowData value={value.order_date} />

                                                                    <td scope="row" className='p-3'>
                                                                        <div className='flex gap-2'>
                                                                            <div className='btnnn' onClick={() => navigate(`${routesNames.pharmacy_view_order_details}/${value.order_id}`)}>
                                                                                <EyeIcon />
                                                                            </div>
                                                                            {value.status === 'Processing' ?
                                                                                <p style={{ background: 'orange', width: '50%' }} className='text-white p-0.5 rounded-md fs-12 text-center'>{value.status}</p> :
                                                                                value.status === 'Delivered' ?
                                                                                    <p style={{ width: '50%' }} className='text-white bg-green-500 p-0.5 rounded-md fs-12 text-center'>Delivered</p> :
                                                                                    <p style={{ background: 'orange', width: '50%' }} className='text-white p-0.5 rounded-md fs-12 text-center'>{value.status}</p>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>)
                                                        })}
                                                </tbody>
                                            </table>

                                            {prescriptionListResponse?.error !== null ? null : <>
                                                {prescriptionList?.data?.length !== 0 && <>
                                                    {prescriptionList?.pagination?.last_page !== 1 &&
                                                        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                            <nav aria-label="Page navigation example">
                                                                <ul className="inline-flex -space-x-px text-base h-10">
                                                                    <CustomPagination
                                                                        count={prescriptionList?.pagination?.last_page}
                                                                        page={pageNumber}
                                                                        onChange={handlePagination}
                                                                    />
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    }
                                                </>}
                                            </>}
                                        </div>

                                    </div> : null}

                </div>
            </div>

        </React.Fragment>
    )
}

export default PrescriptionList