import React, {
    useEffect,
    useState
} from 'react'

import {
    AddNotes,
} from '../../../models/patients'

import {
    ErrorToast,
    SuccessToastMessage
} from '../../../components/loaders/toastMessage'

import {
    initalModals,
    initalShowToastMessage
} from '../../../utils/constants/formInitialStates'

import { useBlisterSelectors } from '../../../services/selectors/blisterSelectors'
import { fetchBlisterPacksList } from '../../../services/actions/pharmacy/blisters/patientBlisterPackList'

import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'
import { initDropdowns } from 'flowbite'

import { fetchPatientDrugList } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'
import { BlisterPackDrugList } from '../../../models/blisters/patientPrescritiptionsDetails'

import { openPatientNoteModal } from '../../../assets/js/custome'
import { TextInput } from '../../../components/pharmacy/dispense/dispense'

import { weekday, exportToExcel, blisterListHeading, TableRowData } from '../blisters/blisterPacks'

import { BlisterDropDown } from '../../../components/pharmacy/form/form'
import { ArchivedDelveriesDateRangePicker } from '../../../components/pharmacy/form/dateRangePicker'

import UpdateBlisterlist from '../../../models/blisters/updateBlisterList'
import ChatBox from '../../../components/chat/chatbox'

import * as Common from '../../../components/common'
import * as Hooks from '../../../hooks'
import * as Icons from '../../../assets/svg/icons'

import * as Modals from '../../../assets/js/custome'
import * as useEffectHelper from '../../../utils/helper/useEffectsHelper'
import * as urlEndPoint from '../../..//networking/urlEndPoints'

import DeleteBlisterPackModal from '../../../models/blisters/deleteBlisterModal'

const VialsPackList = () => {

    const location = Hooks.useCustomLocation()
    const navigate = Hooks.useCustomNavigate()
    const dispatch = Hooks.useCustomDispatch()

    const startDateRef = React.useRef()
    const endDateRef = React.useRef()

    const { patientBlisterPackListResponse, AddBlisterPackResponse } = useBlisterSelectors()
    const blisterList = patientBlisterPackListResponse?.data?.data
    const routeList = patientBlisterPackListResponse?.data?.data?.routes
    const facilityList = patientBlisterPackListResponse?.data?.data?.facility
    const dayList = patientBlisterPackListResponse?.data?.data?.day
    const searchParams = new URLSearchParams();

    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)
    const [showModal, setShowModal] = useState(initalModals)
    const [patientProfileData, setPatientProfileData] = useState(null)
    const [showToast, setShowToast] = useState(initalShowToastMessage)
    const [formData, setFormData] = useState({ search: '', route: '', facility: '', day: '', weekday: '', startDate: '', endDate: '' })
    const [reset, setReset] = useState(false);

    useEffect(() => {
        if (initialPage) {
            setPageNumber(initialPage)
        }
    }, [initialPage])

    useEffect(() => {
        const params = {
            page: pageNumber,
            no_of_records: 10
        }
        dispatch(fetchBlisterPacksList('Vials_pack_list', params, urlEndPoint.vials_patient_pack))
        return () => { }
    }, [])

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
            no_of_records: 10
        };

        if (formData.route) params.route = formData.route;
        if (formData.search) params.search = formData.search;
        if (formData.day) params.rec_day = formData.day.match(/\d+/)[0];
        if (formData.weekday) params.rec_weekday = formData.weekday;
        if (formData.facility) params.facility = formData.facility;
        if (formData.startDate) params.from_date = formData.startDate;
        if (formData.endDate) params.to_date = formData.endDate;

        if (value) {
            setPageNumber(value);
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl);
        }

        dispatch(fetchBlisterPacksList('Vials_pack_list', params, urlEndPoint.vials_patient_pack))
    };

    useEffect(() => {
        initDropdowns()
    }, [blisterList?.data])

    const handleReset = () => {
        searchParams.delete('page');
        setReset(true)
        setFormData({ ...formData, search: '', route: '', facility: '', day: '', weekday: '', startDate: '', endDate: '' })
        setTimeout(() => {
            setReset(false)
        }, 500);

        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);

        const params = {
            page: pageNumber,
            no_of_records: 10
        }

        dispatch(fetchBlisterPacksList('Vials_pack_list', params, urlEndPoint.vials_patient_pack))
    }

    useEffectHelper.useArchivedDelveriesDateRangePickerEffect(formData, setFormData, startDateRef, endDateRef)

    return (
        <React.Fragment>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        {/* <Common.Sidebar /> */}
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">

                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="flex flex-wrap lg:flex-nowrap gap-3">
                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-12/12' style={{ width: '250px' }}>
                                                <TextInput label='Search' name='search' formData={formData}
                                                    setFormData={setFormData} />
                                            </div>

                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '104px' }}>
                                                <BlisterDropDown formData={formData}
                                                    setFormData={setFormData}
                                                    name="route"
                                                    value={formData.route}
                                                    filterList={routeList}
                                                    reset={reset}
                                                    placeholder='Route' />
                                            </div>

                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '112px' }}>
                                                <BlisterDropDown formData={formData}
                                                    setFormData={setFormData}
                                                    name='facility'
                                                    value={formData.facility}
                                                    filterList={facilityList}
                                                    reset={reset}
                                                    placeholder='Facility' />
                                            </div>

                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '110px' }}>
                                                <BlisterDropDown formData={formData}
                                                    setFormData={setFormData}
                                                    name='day'
                                                    value={formData.day}
                                                    filterList={dayList}
                                                    reset={reset}
                                                    placeholder='Day' />
                                            </div>

                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '142px' }}>
                                                <BlisterDropDown formData={formData}
                                                    setFormData={setFormData}
                                                    name='weekday'
                                                    value={formData.weekday}
                                                    filterList={weekday}
                                                    reset={reset}
                                                    placeholder='Week Day' />
                                            </div>

                                            <div className='flex flex-0 lg:w-3/12 md:w-6/12 sm:w-8/12 w-full' style={{ gridTemplateColumns: '2fr 2fr 7px 2fr 2fr' }}>
                                                <ArchivedDelveriesDateRangePicker
                                                    startDateRef={startDateRef}
                                                    endDateRef={endDateRef}
                                                    formData={formData}
                                                    reset={reset}
                                                    setFormData={setFormData}

                                                />
                                            </div>

                                            <div className='flex  gap-2'>
                                            <button type="button" style={{ backgroundColor: '#3B82F6', width: '70px',  }} onClick={handlePagination}
                                                className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
                                                            border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Filter
                                            </button>
                                            <button type="button" style={{ backgroundColor: 'White', width: '70px',  }} onClick={handleReset}
                                                className="flex w-full h-full items-center justify-center text-gray-500 bg-white 
																border border-gray-300 			focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
												dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Reset
                                            </button>

                                            <button style={{  }} type="button" onClick={() => exportToExcel(blisterList?.data, 'OAT')}
                                                className="flex  w-34 h-full items-center justify-center text-white bg-red-500
																border border-gray-300 focus:outline-none hover:bg-red-400 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-gray-800 ">Export Sheet
                                            </button>
                                        </div>

                                        </div>

                                        

                                        {patientBlisterPackListResponse?.loading && patientBlisterPackListResponse?.type === 'Vials_pack_list' ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            patientBlisterPackListResponse?.error !== null && patientBlisterPackListResponse?.type === 'Vials_pack_list' ?
                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div> :
                                                blisterList?.data?.length === 0 && patientBlisterPackListResponse?.type === 'Vials_pack_list' ?
                                                    <div className="bg-white rounded-md mt-4">
                                                        <p className="text-gray-500">Vials are not available.</p>
                                                    </div>
                                                    : patientBlisterPackListResponse?.type === 'Vials_pack_list' ?
                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                            <div className="shadow-md border rounded-lg relative">
                                                                <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                    <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {blisterListHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            blisterList?.data?.map((value, idx) => {
                                                                                const isLastIndex = idx === blisterList?.data?.length - 1;
                                                                                return (<React.Fragment key={idx}>
                                                                                    <tr style={{ borderBottom: '1px solid lightgray' }}>

                                                                                        <td scope="row" className={'p-3 btnnn'}
                                                                                            onClick={() => { return setPatientProfileData(value), Modals.handleShowModal(setShowModal, showModal, 'rxModal'), dispatch(fetchPatientDrugList(value.patient_id, 'patient_drug_list')) }}
                                                                                        >
                                                                                            <p className='bg-gray-50 p-1 rounded-lg no-wrap'>{value.patient_name}</p>
                                                                                        </td>

                                                                                        <TableRowData value={value.route} />

                                                                                        <TableRowData value={value.facility} />
                                                                                        <TableRowData value={value.s_date} />
                                                                                        <TableRowData value={value.p_date} />
                                                                                        <TableRowData value={value.recurrence} />
                                                                                        <TableRowData value={value.deductible} />
                                                                                        <TableRowData value={value.bill} />
                                                                                        <TableRowData value={value.notes} />

                                                                                        <td scope="row" className="p-3">
                                                                                            <button id={`dropdownDefaultButton_${idx}`} data-dropdown-toggle={`dropdown_${idx}`} className="inline-flex items-center text-white p-2 text-sm font-medium text-center text-gray-900 	bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
                                                                                                <Icons.EditIcon />
                                                                                            </button>

                                                                                            <div id={`dropdown_${idx}`} className="z-10 relative border border-gray-100 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                                                                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby={`dropdownDefaultButton_${idx}`}>

                                                                                                    <li className='btnnn' onClick={() => { return setPatientProfileData(value), Modals.handleShowModal(setShowModal, showModal, 'updateBlisterlist') }}>
                                                                                                        <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                                            <span className="pl-2">Update</span>
                                                                                                        </a>
                                                                                                    </li>

                                                                                                    <li className='btnnn'
                                                                                                        onClick={() => { return setPatientProfileData(value), Modals.handleShowModal(setShowModal, showModal, 'rxModal'), dispatch(fetchPatientDrugList(value.patient_id, 'patient_drug_list')) }}
                                                                                                    >
                                                                                                        <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                                            <span className="pl-2">View Medicine</span></a>
                                                                                                    </li>

                                                                                                    <li onClick={() => { return setPatientProfileData(value) }} className='btnnn'>
                                                                                                        <a onClick={() => openPatientNoteModal(setShowModal, showModal)} className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">

                                                                                                            <span className="pl-2">Add Pharmacy Note</span></a>
                                                                                                    </li>

                                                                                                    <li onClick={() => { return setPatientProfileData(value), Modals.handleShowModal(setShowModal, showModal, 'deleteBlisterModal') }} className='btnnn'>
                                                                                                        <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                                            <span className="pl-2">Delete</span></a>
                                                                                                    </li>
                                                                                                </ul>

                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </React.Fragment>)
                                                                            })}
                                                                    </tbody>
                                                                </table>

                                                                {patientBlisterPackListResponse?.error !== null ? null : <>
                                                                    {blisterList?.data?.length !== 0 && <>
                                                                        {blisterList?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={blisterList?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={handlePagination}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}
                                                            </div>

                                                        </div> : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />
            <Common.Footer />

            {showToast?.patientNoteToast && <SuccessToastMessage status='Note added sucessfully' />}
            {showToast.showToast && AddBlisterPackResponse?.type === 'update_vials_pack' && <SuccessToastMessage status='Updated sucessfully' />}
            {showToast.showToast && AddBlisterPackResponse?.type === 'delete_blister_pack' && <SuccessToastMessage status='Deleted sucessfully' />}
            {showToast.errorToast && AddBlisterPackResponse?.type === 'update_vials_pack' && <ErrorToast status={'Oops! Got an issue.please try to add again.'} />}
            {showToast.errorToast && AddBlisterPackResponse?.type === 'delete_blister_pack' && <ErrorToast status={'Oops! Got an issue.please try again.'} />}

            {showToast.showToast && AddBlisterPackResponse?.type === 'check_refills' && <SuccessToastMessage status='Updated sucessfully' />}
            {showToast.errorToast && AddBlisterPackResponse?.type === 'check_refills' && <ErrorToast status={'Oops! Got an issue.please try again.'} />}
            {showToast.showToast && AddBlisterPackResponse?.type === 'add_patient_note' && <SuccessToastMessage status='Note added sucessfully' />}
            {showToast.errorToast && AddBlisterPackResponse?.type === 'add_patient_note' && <SuccessToastMessage status='Oops! Got an issue while saving Note.please try again.' />}


            {showToast.showToast && AddBlisterPackResponse?.type === 'add_billing_note' && <SuccessToastMessage status='Note added sucessfully' />}
            {showToast.errorToast && AddBlisterPackResponse?.type === 'add_billing_note' && <SuccessToastMessage status='Oops! Got an issue while adding Billing Details.please try again.' />}

            <BlisterPackDrugList patientProfileData={patientProfileData}
                showModal={showModal}
                onClose={() => Modals.handleHideModal(setShowModal, showModal, 'rxModal')}
                setShowToast={setShowToast}
                setShowModal={setShowModal} />

            {
                showModal.patientNoteModal &&
                <AddNotes
                    showToast={showToast}
                    setShowToast={setShowToast}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    patientProfileData={patientProfileData}
                    paginationNumber={pageNumber}
                />
            }

            {
                showModal.updateBlisterlist &&
                <UpdateBlisterlist
                    showToast={showToast}
                    setShowToast={setShowToast}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    patientProfileData={patientProfileData}
                    paginationNumber={pageNumber}
                />
            }

            {showModal.deleteBlisterModal &&
                <DeleteBlisterPackModal
                    showToast={showToast}
                    setShowToast={setShowToast}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    patientProfileData={patientProfileData}
                    paginationNumber={pageNumber}
                    text={'Are you sure you want to delete Vials Pack !'}
                />
            }

        </React.Fragment>
    )
}

export default VialsPackList