export const currentDate = new Date()
  .toLocaleDateString("en-CA", {
    timeZone: "America/Vancouver",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
  .replace(/\//g, "-");


export const initialPatientFormState = {
  phn: "",
  firstName: "",
  lastName: "",
  gender: "",
  phone: "",
  dob: "",
  email: "",
  route: "",
};

export const initalPatientAddressForm = {
  fullAddress: "",
  address: "",
  city: "",
  postalCode: "",
  province: "BC",
  country: "",
  mapUrl: "",
  lat: "",
  long: "",
};

export const intitalPatientFormErrors = {
  phn: "",
  firstName: "",
  lastName: "",
  route: "",
};

export const convertGenderValue = (value) => {
  switch (value) {
    case "M":
      return "Male";
    case "F":
      return "Female";
    default:
      return "";
  }
};

export const initalModals = {
  deletePatientModal: false,
  linkClinkModal: false,
  statusUpdateModal: false,
  patientNoteModal: false,
  doctorListModal: false,
  updateClinicModal: false,
  addDoctorModal: false,
  addClinicModal: false,
  viewAllCoverageLink: false,
  showPatientModle: false,
  showToast: false,
  deleteModal: false,
  showToastMessage: false,
  rxModal: false,
  // showPatientModle: false,
  addBlisterPackModal: false,
  updateBlisterlist: false,
  deleteBlisterModal: false,
  addNoteModal: false,
  printLabelModel: false,
  addBillingModal: false,
  deleteRevenueList: false
};

export const initalShowToastMessage = {
  clinicToast: false,
  clinicToastError: false,
  patientNoteToast: false,
  patientNoteToastError: false,
  showToast: false,
  errorToast: false,
  avrToast: false,
};

export const initalPatientNotes = {
  notes: "",
  recurrence: 0,
  selectedNoteOptions: "",
  remind: "",
  message: null,
};

export const initalAddDoctorForm = {
  lastName: "",
  firstName: "",
  cps_id: "",
};

export const initalAddDoctorFormError = {
  lastName: "",
  firstName: "",
  cps_id: "",
};

export const initalPharmacyInformationUpdateForm = {
  // date: new Date().toISOString().split("T")[0],
  date: currentDate,
  fetchType: "winrx",
  phn: "",
  name: "",
  patientName: "",
  winrxPatientPhone: "",
  winrxPatientAddress: "",
  winrxPatientCity: "",
  winrxPatientProvince: "",
  winrxPatientPostcode: "",
  dob: "",
  clinicName: "",
  doctorName: "",
  clinicFaxNumber: "",
  pharmacyName: "",
  pharmacyManager: "",
  pharmacyFax: "",
  pharmacyPhone: "",
  pharmacyEmail: "",
  desgination: "Manager",
  toPharmacy: "",
  toPharmacyPhone: "",
  toPharmacyFax: "",
  toPharmacyAddress: "",
  toPharmacyManager: "",
  transferDate: new Date().toISOString().split("T")[0],
  requested_Prescription: "",
  medicationLeftFor: "",
  medicationName: "",
  numberOfDaysSupplyRequested: "",
  planGFormRational: [2],
  planGFormProfession: "Nurse Practitioner",
  planZFormApplicationOption: "",
  planZFormRational: [],

  doctorCommnicationForm: "With Patient",
  doctorCommnicationFormType: "clinic",
  subjectOfFax: "",
  customToFaxNumber: "",
  customDoctor: "",
  customClinicName: "",
  textForFaxValue: "",

  // -----------Pharmacist Prescriber Communication.--------
  prescribingDateRange: "single_date",
  prescribingDate: new Date().toISOString().split("T")[0],
  prescribingStartDate: new Date().toISOString().split("T")[0],
  prescribingEndDate: new Date().toISOString().split("T")[0],
  methadoneDoseDate: "",
  dailyDoseDate: "",
  prescriptionFolio: "",
  prescriptionCPID: "",
  descriptionAuthorized: "",
  returnForm: [],
  patientrecords: [],
  consumedDose: "",
  totalDose: "",
  canvaEditior: "",
  fromdate: "",
  file: "",
  todate: ""

};

export const initalClinicFormData = {
  clinicName: "",
  streetName: "",
  phone: "",
  fax: "",
  city: "",
  province: "BC",
  zip: "",
  phoneExtension: "",
  lat: "",
  long: "",
  country: "",
  mapUrl: "",
  fullAddress: "",
};

export const initalMixingRxCheckEdItems = {
  Date: false,
  patient_name: false,
  drug_name_strength: false,
  quantity_to_dispense: false,
  frequency: false,
  dose_interval: false,
  max_daily_dose: false,
  quantity: false,
  interval_between_refills: false,
  printedname: false,
  uniquesignature: false,
};

export const initalTopHeaderAddNoteData = {
  patientName: "",
  phn: "",
  dob: "",
};

export const initalCompoundsFormData = {
  // date: new Date().toISOString().split("T")[0],
  date: currentDate,
  toPharmacy: "",
  toPharmacyPhone: "",
  toPharmacyFax: "",
  toPharmacyManager: "",
  pharmacyName: "",
  pharmacyManager: "",
  desgination: "Manager",
  provider: "",
  compoundName: "",
  quantity: "",
  packageType: "",
  patientName: [],
  price: "",
  days: "",
  notes: "",
  rational: [],
  compound: "",
};

export const initalDispenseFormData = {
  // date: new Date().toISOString().split("T")[0],
  date: currentDate,
  patientName: "",
  recurrence: false,
  rational: [],
  // patientName: "",
  route: "",
  driver: "",
  drugDetails: "",
  supervisorId: null,
  search: "",
  rxnumber: "",
};

export const initalAddNewDeliveryFormData = {
  name: "",
  email: "",
  phone: "",
  password: "",
  passcode: "",
  image: "",
  supervisorId: null,
};


export const initalArchivedDelveriesData = {
  date: currentDate,
  startDate: new Date().toISOString().split("T")[0],
  endDate: new Date().toISOString().split("T")[0],
  deliveryStatus: null,
  patientRoutes: null,
  searchPatients: "",
  addNote: "",
  rxnumber: "",
};

export const initalDispenseLabelsFormData = {
  date: new Date().toISOString().split("T")[0],
  patientRoutes: "",
  startDate: currentDate,
  endDate: (() => {
    const endDate = new Date(currentDate);
    endDate.setDate(endDate.getDate() + 4);
    return endDate.toISOString().split("T")[0];
  })(),
  rxnumber: "",
  search: "",
  muteValue: "",
};

export const initalExpiringRxFormData = {
  date: currentDate,
  search: "",
};

export const initalCounsellingData = {
  date: currentDate,
};

export const initialFormARVData = {
  date: currentDate,
  phn: "",
  winrxPatientPhone: "",
  name: "",
  fetchType: "winrx",
  pharmacyManager: "",
  patientName: "",
  patient: "",
  clinicName: "",
  drugName: "",
  duein: "",
  drugname: "",
  winrxPatientFirstName: "",
  winrxPatientLastName: "",
  winrxPatientPhone: "",
};

export const initialAVRFormErrors = {
  date: "",
  phn: '',
  name: '',
  winrxPatientPhone: '',

  pharmacyManager: '',
  patientName: '',
  clinicName: '',
}

export const initialARVDrugFormErrors = {
  drugName: '',
  dueInDays: ''
}

export const initalFdaFormData = {
  date: currentDate,
  fetchType: "winrx",
  phn: "",
  name: "",
  patientName: "",
  winrxPatientFirstName: "",
  winrxPatientLastName: "",
  winrxPatientPhone: "",
  pharmacyName: "",
  pharmacyManager: "",
  desgination: "Manager",
  rational: [],
  deductible: '',
  weekly: 'weekly',
  note: '',
  dob: "",
  doctorName: "",
  prescriber: "",
  initiatedDate: currentDate,
  other_name: "",
  doctDate: '',
  doctor: '',
  pharmacyfax: ''
}


export const initalSkinTestFormData = {
  date: currentDate,
  fetchType: 'winrx',
  phn: '',
  name: '',
  phone: '',
  patientName: '',
  winrxpatientphn: '',
  winrxpatientname: '',
  winrxPatientPhone: '',
  winrxPatientAddress: '',
  winrxPatientProvince: '',
  winrxPatientPostcode: '',

  test_information_date: currentDate,
  site: '',
  lot_number: '',
  expiration_date: '',
  administered_by: 'RN',

  test_result_date: '',
  induration: '',
  ppd_mantoux_test_result: '',

  interpreted_by: '',
  date_of_interpretation: currentDate,
  follow_up: '',
  comments: '',
  patient_consent: ''
}


export const initalPharmacySettingData = {
  winrx_pharmacy_id: '',
  winrx_status: '',
  winrx_token: '',

  fax_accound_id: '',
  fax_password: '',
  sender_fax_number: '',
  sender_email: '',
  fax_status: '',
  app_fcm_token_key: ''
}