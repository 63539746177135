import React, { useEffect, useState } from 'react'
import { Sidebar } from '../../../components/common'

import { useCustomDispatch, useCustomLocation, useCustomNavigate } from '../../../hooks'
import { LoadingView } from '../../../components/common'

import { authToken } from '../../../storage/authToken'
import { ErrorMessages } from '../../../components/error/alertMessages'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'
import { useCustomLoadingStates } from '../../../hooks/customStates'

import { fetchTimeOffRequestList } from '../../../services/actions/admin/team/fetchTeamsList'
import { useAdminSelectors } from '../../../services/selectors/adminModuleSelectors'
import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'

import { ArchivedDelveriesDateRangePicker } from '../../../components/pharmacy/form/dateRangePicker'
import { BlisterDropDown } from '../../../components/pharmacy/form/form'
import { UpdateTimeOffRequestStatus } from '../../../models/admin/timeOffRequestConfirmationMesage'

import * as useEffectHelper from '../../../utils/helper/useEffectsHelper'
import * as Common from '../../../components/common'

import Button from '../../../components/common/button'
import ChatBox from '../../../components/chat/chatbox'
import Projects from './taskManagement'

export const requestListTableHeading = [
    { text: "EMPLOYEE", classNames: ['p-3'] },
    { text: "DURATION", classNames: ['p-3'] },
    { text: "TYPE", classNames: ['p-3'] },
    { text: "REASON", classNames: ['p-3'] },
    // { text: "ACTION", classNames: ['p-3'] },
];

export const TableRowData = ({ value, className1 = 'p-3' }) => {
    return (<td scope="row" className={className1}>
        <p>{value}</p>
    </td>)
}

const TimeOffRequest = () => {

    const token = authToken()

    const dispatch = useCustomDispatch()
    const location = useCustomLocation()
    const navigate = useCustomNavigate()
    const startDateRef = React.useRef()
    const endDateRef = React.useRef()

    const { TeamListResponse } = useAdminSelectors()
    const { adminModule } = useCustomSelectors()

    const teamTimeOffRequestList = TeamListResponse?.data?.data

    const { apiCallMade, setApiCallMade } = useCustomLoadingStates()

    const searchParams = new URLSearchParams();
    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)
    const [formData, setFormData] = useState({ startDate: '', endDate: '', status: 'All', allEmployee: 'All_employee' })
    const [reset, setReset] = useState(false);

    useEffect(() => {
        if (!apiCallMade && token && adminModule === 1) {
            dispatch(fetchTimeOffRequestList('time_off_request_list'))
            setApiCallMade(true)
        }
    }, [])

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
        };

        if (value) {
            setPageNumber(value);
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl);
        }

        dispatch(fetchTimeOffRequestList('time_off_request_list', params))
    }

    const handleFilter = () => {
        const params = {
            from_date: formData.startDate,
            to_date: formData.endDate,
            status: formData.status,
            search: formData.allEmployee
        };

        dispatch(fetchTimeOffRequestList('time_off_request_list', params))
    }

    const handleReset = () => {
        searchParams.delete('page');
        setReset(true)
        setFormData({ ...formData, startDate: '', endDate: '', status: 'All', allEmployee: 'Al_employee' })
        setTimeout(() => {
            setReset(false)
        }, 500);

        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);
        dispatch(fetchTimeOffRequestList('time_off_request_list'))
    }

    useEffectHelper.useArchivedDelveriesDateRangePickerEffect(formData, setFormData, startDateRef, endDateRef)

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content" id="allRequest">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="flex flex-wrap lg:flex-nowrap gap-3">

                                            <div className='flex flex-0 lg:w-2/12 md:w-6/12 sm:w-8/12 w-full mb-4' style={{ gridTemplateColumns: '2fr 2fr 7px 2fr 2fr', width: '350px' }}>

                                                <ArchivedDelveriesDateRangePicker startDateRef={startDateRef} endDateRef={endDateRef}
                                                    formData={formData} reset={reset} setFormData={setFormData}
                                                />
                                            </div>

                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '142px' }}>
                                                <BlisterDropDown formData={formData}
                                                    setFormData={setFormData} name='status' value={formData.status} reset={reset} placeholder='All Status'
                                                    filterList={['All Status', 'Approved', 'Rejected', 'Pending']}
                                                />
                                            </div>

                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '200px' }}>
                                                <BlisterDropDown formData={formData} setFormData={setFormData} name='allEmployee' value={formData?.allEmployee} filterList={teamTimeOffRequestList?.users} reset={reset} placeholder='All Employees' />
                                            </div>

                                            <div className='' style={{ height: '42px' }}>
                                                <Button handleFilter={handleFilter} color='white' />
                                            </div>

                                            <div className=' ' style={{ height: '42px' }}>
                                                <Button handleFilter={handleReset} bgColor='white' width='70px' text='Reset' />
                                            </div>

                                        </div>

                                        {
                                            TeamListResponse?.loading && TeamListResponse?.type === 'time_off_request_list' ?
                                                <div className="flex justify-center items-center mt-60 mb-80">
                                                    <LoadingView />
                                                </div>
                                                :

                                                TeamListResponse?.error !== null && TeamListResponse?.type === 'time_off_request_list' ?
                                                    <ErrorMessages />


                                                    : teamTimeOffRequestList?.data?.length === 0 && TeamListResponse?.type === 'time_off_request_list' ?
                                                        <p className='text-gray-500'>No Request Found.</p>

                                                        : TeamListResponse?.type === 'time_off_request_list' ?

                                                            <div className="relative overflow-x-auto border rounded-lg">
                                                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 whitespace-nowrap">
                                                                    <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {requestListTableHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            teamTimeOffRequestList?.data?.map((value, index) => {

                                                                                return (<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                                                                                    <TableRowData value={value?.name?.charAt(0).toUpperCase() + value?.name?.slice(1)} />

                                                                                    <td className="p-3">
                                                                                        {value.whole_day === 0 ?
                                                                                            <p className="rubik-400">{value.n_sdate}, {value.start_time} - {value.n_edate} {value.end_time}</p>
                                                                                            :
                                                                                            <p className="rubik-400">{value.n_sdate === value.n_edate ? value.n_sdate : <>{value.n_sdate} - {value.n_edate} </>}</p>
                                                                                        }
                                                                                    </td>

                                                                                    <td className="p-3">
                                                                                        <p className="">{value.time_of_type === 'UnPaid' ? 'Unpaid' : value.time_of_type === 'Paid' ? 'Paid' : null}</p>
                                                                                    </td>

                                                                                    {/* {value.status === 'Approved' ?
                                                                                        <td className="p-3">
                                                                                            <p className="fs-10 dark-color"><span className=" inline-block greenBg-color  light-color fs-10 bg-green-100 text-xs font-medium mr-2 px-3 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">{value.status}</span>
                                                                                            </p>
                                                                                        </td>
                                                                                        :
                                                                                        value.status === 'Pending' ?
                                                                                            <td className="p-3">
                                                                                                <p className=" fs-10 dark-color">
                                                                                                    <span className="yellowBg-color inline-block  light-color fs-10 bg-green-100 text-xs font-medium mr-2 px-3 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">Pending</span>
                                                                                                </p>

                                                                                            </td> : value.status === 'Rejected' ?
                                                                                                <td className="p-3">
                                                                                                    <p className=" fs-10 dark-color"><span className="redBg-color inline-block  light-color fs-10 bg-green-100 text-xs font-medium mr-2 px-3 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">Rejected</span>
                                                                                                    </p>
                                                                                                </td> : null} */}

                                                                                    <td className="p-3">
                                                                                        {value.status === 'Pending' ?
                                                                                            <div className='flex'>
                                                                                                <p className=" fs-10 dark-color">
                                                                                                    <span className="yellowBg-color inline-block  light-color fs-10 bg-green-100 text-xs font-medium mr-2 px-3 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">Pending</span>
                                                                                                </p>

                                                                                                <p onClick={() => UpdateTimeOffRequestStatus('approve', value.id, 'update-trequest-status', 'Approve', dispatch, pageNumber)} className="btnnn"><span className=" inline-block greenBg-color  light-color fs-12 bg-green-100 text-xs font-medium mr-2 px-3 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 flex">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                                                                    </svg>
                                                                                                </span>
                                                                                                </p>



                                                                                                <p onClick={() => UpdateTimeOffRequestStatus('decline', value.id, 'update-trequest-status', 'Decline', dispatch, pageNumber)} className="fs-10 dark-color btnnn"><span className="inline-block redBg-color  light-color fs-10 bg-green-100 text-xs font-medium mr-2 px-3 py-0.5 rounded-full dark:bg-green-900 flex dark:text-green-300">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                                                                    </svg>

                                                                                                </span>
                                                                                                </p>

                                                                                            </div> :
                                                                                            value.status === 'Rejected' ?
                                                                                                <p className=" fs-10 dark-color"><span className=" inline-block  fs-10 bg-red-500 text-white text-xs font-medium mr-2 px-3 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                                                                    Declined</span>
                                                                                                </p>
                                                                                                :
                                                                                                value.status === 'Approved' ?
                                                                                                    <p className=" fs-10 dark-color"><span className=" inline-block  fs-10 bg-green-200 text-xs font-medium mr-2 px-3 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                                                                        Approved</span>
                                                                                                    </p>

                                                                                                    : null
                                                                                        }
                                                                                    </td>


                                                                                </tr>)
                                                                            })}
                                                                    </tbody>
                                                                </table>

                                                                {TeamListResponse?.error !== null ? null : <>
                                                                    {teamTimeOffRequestList?.data?.length !== 0 && <>
                                                                        {teamTimeOffRequestList?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={teamTimeOffRequestList?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={handlePagination}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}
                                                            </div>
                                                            : null
                                        }
                                    </div>
                                </div>

                                {/* <Projects /> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ChatBox />
        </>
    )
}

export default TimeOffRequest