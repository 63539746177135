export const apiKey = process.env.REACT_APP_ABLY_API_KEY;
export const channelName = process.env.REACT_APP_ABLY_BROADCAST_CHANNEL_NAME;

export const chatChannelName = process.env.REACT_APP_ABLY_CHAT_CHANNEL_NAME;

export const activityStatusChannel = process.env.REACT_APP_ABLY_TU_STATUS_CHANNEL;

export const chatNotifications = process.env.REACT_APP_ABLY_CHAT_NOTIFICATIONS_CHANNEL

export const autoSearchKey = process.env.REACT_APP_ADDRESS_AUTOSEARCH_KEY

export const addTeamNoteKey = process.env.REACT_APP_ABLY_ADD_TEAM_NOTES

export const awsAccessKey = process.env.REACT_APP_AWS_ACCESS_KEY_ID

export const awsSecretKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY

export const awsDefaultRegion = process.env.REACT_APP_AWS_DEFAULT_REGION

export const awsBuketName = process.env.REACT_APP_AWS_BUCKET

export const pharmacy_login_user = 'pharmacy-login-user'

export const pharmacy_user_permission = 'pharmacy-user-permission'

export const pharmacy_user_profile = 'pharmacy-user-profile'

export const pharmacy_user_update_profile = 'pharmacy-user-update-profile'

export const pharmacy_uprofilecover_update = 'pharmacy-uprofilecover-update'

export const pharmacy_stat_holiday = 'pharmacy-stat-holiday'

export const pharmacy_user_checkinout = 'pharmacy-checkin-statholiday'

export const pharmacy_timeoff_request = 'pharmacy-timeoff-request'

export const pharmacy_timeoff_lists = 'pharmacy-timeoff-list'

export const pharmacy_alluser_data = 'pharmacy-alluser-data'

export const pharmacy_user_team_shift = 'pharmacy-user-team-shift'

export const edit_pharmacy_timeoff_list = 'pharmacy-timeoff-list-update'

export const pharmacy_timeoff_list_delete = 'pharmacy-timeoff-list-delete'

export const pharmacy_add_wall_post = 'pharmacy-add-wall-post'

export const pharmacy_wall_post_list = 'pharmacy-wall-post-list'

export const pharmacy_user_wall_post_lists = 'pharmacy-profile-wallpost'

export const pharmacy_user_comments = 'pharmacy-user-comments'

export const pharmacy_user_comments_list = 'pharmacy-single-comment-list'

export const pharmacy_add_fcmtoken = 'pharmacy-add-fcmtoken'

export const pharmacy_user_comments_reply = 'pharmacy-user-comments-reply'

// export const pharmacy_reply_list = 'pharmacy-reply-list'

export const pharmacy_reply_list = 'pharmacy-comment-single-reply-list'

export const pharmacy_brodcast_notifications = 'pharmacy-brodcast-notifications'

export const pharmacy_timesheet_dates = 'pharmacy-timesheet-dates'

export const pharmacy_user_post_likes = 'pharmacy-user-post-likes'

export const pharmacy_likes_list = 'pharmacy-likes-list'

export const pharmacy_wall_post_delete = 'pharmacy-wallpost-delete'

export const pharmacy_user_comment_likes = 'pharmacy-user-comment-likes'

export const pharmacy_group_list = 'pharmacy-chat-user-list'

export const pharmacy_chat_messages = 'pharmacy-chat-messages'

export const pharmacy_message_list = 'pharmacy-message-user'

export const pharmacy_add_chat_group = 'pharmacy-add-chat-group'

export const pharmacy_chat_group_list = 'pharmacy-group-list'

export const pharmacy_intiate_chat = 'pharmacy-intiate-chat'

export const pharmacy_add_messages_to_group = 'pharmacy-add-messages-to-group'

export const pharmacy_get_chat_message = 'pharmacy-get-chat-message'

export const pharmacy_get_previous_messages = 'pharmacy-get-previous-messages'

export const pharmacy_update_tu_online_status = 'pharmacy-update-tu-online-status'

export const pharmacy_user_appreciation = 'pharmacy-user-appreciation'

export const pharmacy_get_recent_chats = 'pharmacy-get-recent-chats'

export const pharmacy_read_messages = 'pharmacy-read-messages'

export const pharmacy_patient_province = 'pharmacy-patient-province'

export const pharmacy_patient_data = 'pharmacy-patient-winrx-data'

export const pharmacy_add_patient = 'pharmacy-add-patient'

export const pharmacy_patient_list = 'pharmacy-patient-list'

export const pharmacy_patient_update_status = 'pharmacy-patient-update-status'

export const pharmacy_update_patient = 'pharmacy-update-patient'

export const pharmacy_delete_patient = 'pharmacy-delete-patient'

export const get_clinic = 'get-clinic'

export const pharmacy_patient_link_clinic = 'pharmacy-patient-link-clinic'

export const pharmacy_get_patient_notes = 'pharmacy-get-patient-notes'

export const p_reminder_nofication = 'p-reminder-nofication'

export const pharmacy_save_patient_notes = 'pharmacy-save-patient-notes'

export const pharmacy_patient_send_photo_link = 'pharmacy-patient-send-photo-link'
export const get_doctors = 'get-doctors'

export const pharmacy_oat_form_pref_update = 'pharmacy-oat-form-pref-update'

export const doctor_new_doctor = 'doctor-add-new'

export const pharmacy_active_patient_list = 'pharmacy-active-patient-list'

export const pharmacy_update_notification_clinic_form = 'pharmacy-update-notification-clinic-form'

export const pharmacy_update_notification_srfax_form = 'pharmacy-update-notification-srfax-form'

export const get_pharmacies_list = 'get-pharmacies'

export const pharmacy_rx_transfer_form = 'pharmacy-rx-transfer-form'

export const pharmacy_new_add_clinic = 'pharmacy-new-add-clinic'

export const pharmacy_rx_transfer_fax_form = 'pharmacy-rx-transfer-fax-form'

export const pharmacy_dr_notification_emergency_supply = 'pharmacy-dr-notification-emergency-supply'

export const pharmacy_dr_notification_emergency_fax_form = 'pharmacy-dr-notification-emergency-fax-form'

export const pharmacy_anti_viral_request_form = 'pharmacy-anti-viral-request-form'

export const pharmacy_anti_viral_srfax_form = 'pharmacy-anti-viral-srfax-form'

export const pharmacy_missing_rx_form = 'pharmacy-missing-rx-form'

export const pharmacy_missing_rx_srfax_form = 'pharmacy-missing-rx-srfax-form'

export const pharmacy_emergency_supply_continuity_form = 'pharmacy-emergency-supply-continuity-form'

export const pharmacy_patient_agreement_for_dosing = 'pharmacy-patient-agreement-for-dosing'

export const pharmacy_plan_g_form = 'pharmacy-plan-g-form'

export const pharmacy_plan_g_srfax_form = 'pharmacy-plan-g-srfax-form'

export const pharmacy_plan_z_form = 'pharmacy-plan-z-form'

export const pharmacy_plan_z_srfax_form = 'pharmacy-plan-z-srfax-form'

export const pharmacy_doctor_comm_withoutout_form = 'pharmacy-doctor-comm-withoutout-form'

export const pharmacy_doctor_communication_letter_head = 'pharmacy-doctor-communication-letter-head'

export const pharmacy_doctor_comm_withoutout_srfax = 'pharmacy-doctor-comm-withoutout-srfax'

export const pharmacy_doctor_communication_srfax = 'pharmacy-doctor-communication-srfax'

export const pharmacy_pharmacist_prescriber_communication_form = 'pharmacy-pharmacist-prescriber-communication-form'

export const pharmacy_pharmacist_prescriber_communication_srfax_form = 'pharmacy-pharmacist-prescriber-communication-srfax-form'

export const pharmacy_team_add_notes = 'pharmacy-team-add-notes'

export const pharmacy_request_original_rx_oat_form = 'pharmacy-request-original-rx-oat-form'

export const pharmacy_request_original_fax_form = 'pharmacy-request-original-fax-form'

export const pharmacy_coverage_patient_list = 'pharmacy-coverage-patient-list'

export const pharmacy_patient_notification_list = 'pharmacy-patient-notification-list'

export const pharmacy_team_notes = 'pharmacy-team-notes'

export const pharmacy_patient_notes_reply = 'pharmacy-patient-notes-reply'

export const pharmacy_patient_notes_markasread = 'pharmacy-patient-notes-markasread'

export const pharmacy_module_search = 'module-search'

export const pharmacy_file_manager_create_folder = 'pharmacy-folder-manager'

export const pharmacy_file_manager_get_files = 'pharmacy-folder-manager-files'

export const pharmacy_srfax_log_list = 'pharmacy-srfax-log-list'

export const pharmacy_compound_list = 'pharmacy-compound-list'

export const pharmacy_add_new_compound = 'pharmacy-add-new-compound'

export const pharmacy_agreement_compound_form = 'pharmacy-agreement-compound-form'

export const pharmacy_agreement_compound_srfax_form = 'pharmacy-agreement-compound-srfax-form'

export const pharmacy_controlled_request_form = 'pharmacy-controlled-request-form'

export const pharmacy_price_compound_list = 'pharmacy-price-compound-list'

export const pharmacy_add_compound_price_list = 'pharmacy-add-compound-price-list'

export const pharmacy_delete_compound = 'pharmacy-delete-compound'

export const pharmacy_price_list_delete = 'pharmacy-price-list-delete'

export const pharmacy_compound_order_form = 'pharmacy-compound-order-form'

export const pharmacy_compound_form = 'pharmacy-compound-form'

export const pharmacy_compound_srfaxform = 'pharmacy-compound-srfaxform'

export const pharmacy_supervisior_search = 'pharmacy-supervisior-search'

export const pharmacy_addnew_sdelivery_driver = 'pharmacy-addnew-sdelivery-driver'

export const pharmacy_addnew_delivery_driver = 'pharmacy-addnew-delivery-driver'

export const pharmacy_driver_list = 'pharmacy-driver-list'

export const pharmacy_update_driver_detail = 'pharmacy-update-driver-detail'

export const pharmacy_delete_driver_detail = 'pharmacy-delete-driver-detail'

export const pharmacy_srfax_log_pdf = 'pharmacy-srfax-log-pdf'

export const pharmacy_addnew_custom_delivery = 'pharmacy-addnew-custom-delivery'

export const pharmacy_patient_recurrence_delivery = 'pharmacy-patient-recurrence-delivery'

export const pharmacy_delete_recurrence_delivery = 'pharmacy-delete-recurrence-delivery'

export const pharmacy_daily_dispense_list = 'pharmacy-daily-dispense-list'

export const pharmacy_dispensedelivery_moveto_next_date = 'pharmacy-dispensedelivery-moveto-next-date'

export const pharmacy_dispensedelivery_update_status = 'pharmacy-dispensedelivery-update-status'

export const patient_archieved_deliveries = 'patient-archieved-deliveries'

export const patient_route_list = 'patient-route-list'

export const patient_archieved_setpin_delivery = 'patient-archieved-setpin-delivery'

export const patient_archieved_patient_updatenotes = 'patient-archieved-patient-updatenotes'

export const pharmacy_patient_prescriptions_details = 'pharmacy-patient-prescriptions-details'

export const pharmacy_rxnumber_archieved_list = 'pharmacy-rxnumber-archieved-list'

export const pharmacy_patient_dlabels = 'pharmacy-patient-dlabels'

export const pharmacy_rx_labels_list = 'rxlables-list'

export const pharmacy_update_rxlabelmute = 'pharmacy-update-rxlabelmute'

export const print_deliveries_labelsheet = 'print-deliveries-labelsheet'

export const delete_rx_labels = 'delete-rxlables'

export const patient_medicine_list = 'patient-medicine-list'

export const expiring_rxsheet = 'expiring-rxsheet'

export const pharmacy_patient_syncdeliverires_winrx = 'pharmacy-patient-syncdeliverires-winrx'

export const pharmacy_update_wit_rx = 'pharmacy-update-wit-rx'

export const patient_counselling = 'patient-counselling'

export const consent_submission_logs = 'consent-submission-logs'

export const patient_blister_pack = 'blister-patient'

export const blister_pack_patient = 'blister-pack-patient'

export const add_blister_pack = 'add-blister-pack'

export const update_blister_pack = 'update-blister-pack'

export const delete_blister_pack = 'delete-blister-pack'

export const print_dispense_blister = 'print-label-dispense-blister'

export const patient_checkrefills = 'patient-checkrefills'

export const add_medrx_notes = 'add-medrx-notes'

export const add_billing = 'add-billing'

export const print_label_dispense_blister = 'print-label-dispense-blister'

export const daily_blister_pack = 'daily-blister-pack'

export const update_blister_log = 'update-blister-log'

export const patient_bpack_onshelf = 'patient-bpack-onshelf'

export const vials_pateint_list = 'vials-pateint-list'

export const add_vials_pack = 'add-vials-pack'

export const vials_patient_pack = 'vials-patient-pack'

export const delete_vials_pack = 'delete-vials-pack'

export const update_vials_pack = 'update-vials-pack'

export const daily_vials_pack = 'daily-vials-pack'

export const update_vials_packlogs = 'update-vials-packlogs'

export const abel_validate_update_inputs = 'label-validate-update-inputs'

export const label_update_schdule_inputs = 'label-update-schdule-inputs'

export const pharmacy_sync_label_delivery = 'pharmacy-sync-labels-delivery'

export const pharmacy_print_lebel_setting = 'pharmacy-print-lebel-setting'

export const sMedication_patient_list = 'sMedication-patient-list'

export const add_sMedication_pack = 'add-sMedication-pack'

export const sMedication_pack_list = 'sMedication-pack-list'

export const update_sMedication_pack = 'update-sMedication-pack'

export const delete_sMedication_pack = 'delete-sMedication-pack'

export const sMedication_daily_log = 'sMedication-daily-log'

export const update_sMedication_log = 'update-sMedication-log'

export const pharmacy_generate_invoice = 'pharmacy-generate-invoice'

export const pharmacy_previous_invoice = 'pharmacy-previous-invoice'

export const pharmacy_doctor_form = 'pharmacy-doctor-form'

export const consent_showimage = 'consent-showimage'

export const add_arv_details = 'add-patient-arv'

export const get_arv_lists = 'patient-arv-list'

export const delete_arv = 'delete-arv'

export const arv_fax_preview = 'arv-fax-preview'

export const arv_send_fax = 'arv-send-fax'

export const pharmacy_drugbilling = 'pharmacy-drugbilling'

export const prescription_dispense = 'prescription-dispense'

export const dispense_memo = 'patient-pending-memo'

export const update_reminder = 'update-pendingmemo-rdate'

export const pharmacy_revenue_list = 'pharmacy-revenue-list'

export const pharmacy_add_revenue = 'pharmacy-add-revenue'

export const add_multiple_revinue = 'add-multiple-revinue'

export const update_revinue_list = 'update-revinue-list'

export const delete_revenue = 'delete-revenue'

export const cash_change_list = 'cash-cashge-list'

export const cash_change_save = 'cash-change-save'

export const deposit_info_save = 'deposit-info-save'

export const pharmacy_deposit_list = 'pharmacy-deposit-list'

export const pharmacy_deposit_print = 'pharmacy-deposit-print'

export const delete_deposit_print = 'delete-deposit-print'

export const pharmacy_handcash = 'pharmacy-handcash'

export const save_handcash = 'save-handcash'

export const print_handcash = 'print-handcash'

export const delete_handcash = 'delete-handcash'

export const frequent_dispensing_autorization_submit = 'frequent-dispensing-autorization-submit'

export const frequent_dispensing_autorization_list = 'frequent-dispensing-autorization-list'

export const frequent_dispensing_authorization_view_pdf = 'frequent-dispensing-view-pdf'

export const update_frequent_deductible = 'update-frequent-deductibe'

export const frequent_dispensing_view_pdf = 'frequent-dispensing-view-pdf'

export const delete_frequent_log = 'delete-frequent-log'

export const pfrequent_expired_dispensing = 'pfrequent-expired-dispensing'

export const pharmacy_add_inventory = 'pharmacy-add-inventory'

export const pharmacy_api_inventory_list = 'pharmacy-api-inventory-list'

export const pharmacy_api_get_drug_by_din = 'pharmacy-api-get-drug-by-din'

export const pharmacy_api_sync_invertory = 'pharmacy-api-sync-invertory'

export const pharmacy_api_clear_inventory = 'pharmacy-api-clear-inventory'

export const pharmacy_authanticate_route = 'pharmacy-authanticate-route'

export const pharmacy_delete_inventory = 'delete-inventory'

export const update_inventory = 'update-inventory'

export const pharmacy_api_update_inventory = 'pharmacy-api-update-inventory'

export const pharmacy_route_patient = 'pharmacy-route-patient'

export const update_patient_delivery_note = 'update-patient-delivery-note'

export const patient_update_route = 'patient-update-route'

export const upload_prescription = 'upload-prescription'

export const pharmacy_travel_records = 'pharmacy-travel-records'

// export const pharmacy_travel_records  = 'pharmacy-travel-records'

export const upload_triplicate = 'upload-triplicate'

export const show_upload_image = 'show-upload-image'

export const pharmacy_reverse_rx = 'pharmacy-reverse-rx'

export const returned_fentanyl_patches = 'returned-fentanyl-patches'

export const returned_fentanyl_pdf = 'returned-fentanyl-pdf'

export const patches_send_fax = 'patches-send-fax'

export const upload_image_prescription = 'upload-image-prescription'

export const pharmacy_reverse_deliveries = 'pharmacy-reverse-deliveries'

export const patient_assign_bshelfpacks = 'patient-assign-bshelfpacks'

export const pharmacy_transfer_prescription = 'pharmacy-app-prescription'

export const pharmacy_order_details = 'pharmacy-order-details'

export const update_order_status_notes = 'update-order-status-notes'

export const p_app_users = 'p-app-users'

export const p_annoucement_list = 'p-annoucement-list'


export const pharmacy_annoucement_list = 'p-annoucement-list'

export const add_p_annoucement = 'add-p-annoucement'

export const p_custom_notification = 'p-custom-notification'

export const add_custom_notification = 'add-custom-notification'

export const update_p_announcement = 'update-p-annoucement'
export const update_order_parcel = 'update-order-parcel'

export const pharmacy_sorder_notes = 'pharmacy-sorder-notes'

export const update_pill_reminder = 'update-pill-reminder'

export const save_preview_fax = 'save-preview-fax'

export const delete_announcement = 'delete-announcement'


export const tb_skin_test_logs = 'pharmacy-tb-skin-log'

export const pharmacy_tb_log_pdf = 'pharmacy-tb-log-pdf'

export const get_my_ip_address = 'https://api.ipify.org/?format=json'

export const p_trequest_users = 'p-trequest-users'

export const pharmacy_task = 'pharmacy-task'

export const puser_attendance_sheet = 'puser-attendance-sheet'

export const pharmacy_save_certifiate = 'pharmacy-save-certificate'

export const update_shift_users = 'update-shift-users'

export const delete_shift_user = 'delete-shift-user'

export const p_cinout_logs = 'p-checkin-outlogs'

export const update_break_setting = 'pharmacy-break-settings'

export const update_payroll_setting = 'pharmacy-payroll-settings'

export const pharmacy_certificate_template = 'p-certificate-template'

export const get_certificate_template = 'get-certificate-template'

export const update_certificate_template = 'update-certificate-template'

export const pharmacy_assign_certificate = 'get-assign-certificate'

export const assign_certificate_pdf = 'assign-certificate-pdf'

export const assign_certificate_employee = 'assign-certificate-employee'

export const pharmacy_team_users = 'pharmacy-team-users'

export const pharmacy_user_departments = 'pharmacy-user-departments'

export const pharmacy_update_department = 'pharmacy-user-departments/saveDepartmentPermissions'

export const pharmacy_user_department = 'pharmacy-user-departments/saveUserDepartment'

export const on_boarding_user_profile = 'pharmacy-team-users/OnboardingUserProfile'

export const on_boarding_user_profile_update = 'pharmacy-team-users/updateOnboardingUserProfile'

export const sign_ai = 'pharmacy-RX-data'

export const patient_rxdelivered_diffrence = 'patient-rxdelivered-diffrence'




















































