import React, { useEffect, useState, useRef } from "react";

import {
    BreadCrumb,
    LoadingView,
    // ProfilePicLoader,
    Sidebar
} from "../../../components/common";

import {
    ErrorToastMessage,
    SuccessToastMessage
} from "../../../components/loaders/toastMessage";

import {
    DeleteList,
    UpdateTaskList
} from "../../../models/admin/editTaskList";

import { fetchAllTaskLists } from "../../../services/actions/admin/team/fetchTeamsList";
import { useAdminSelectors } from "../../../services/selectors/adminModuleSelectors";
import { ErrorMessages, Errors } from "../../../components/error/alertMessages";
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";

import { DropdownSelect } from "../../../components/pharmacy/dispense/dispense";
import { useCustomSelectors } from "../../../services/selectors/allSelectors";
import { AssignTaskSelect } from "./createNewtask";

import { DeleteIcon, EditIcons } from "../../../assets/svg/icons";
import { handleShowModal } from "../../../assets/js/custome";
import { HandleUpdateTaskStatus, handleAddComments } from "../../../services/actions/admin/team/addTeamData";

import * as Hooks from '../../../hooks';

import ChatBox from "../../../components/chat/chatbox";
import Button from "../../../components/common/button";
// import { fetchCommentsListFailure, fetchCommentsListSuccess, fetchTaskCommentsList } from "../../../services/actions/admin/team/fetchTaskComentsList";
// import InfiniteScroll from 'react-infinite-scroll-component';

const TaskListTableHeading = [
    { text: "ASSIGNED TO", classNames: ['px-6 py-3 rounded-s-lg flex no-wrap w-44'] },
    { text: "TASK TITLE", classNames: ['px-6 py-3 w-44'] },
    { text: "DESCRIPTION", classNames: ['px-6 py-3'] },
    { text: "STATUS", classNames: ['px-6 py-3'] },
    { text: "ACTION", classNames: ['px-6 py-3'] },
];

const TableRowData = ({ value, className1 = 'p-3' }) => {
    return (<td scope="row" className={className1}>
        <p>{value}</p>
    </td>)
}

const AllTasksList = () => {

    const searchParams = new URLSearchParams();
    const commentsEndRef = useRef(null);

    const dispatch = Hooks.useCustomDispatch();
    const location = Hooks.useCustomLocation();
    const navigate = Hooks.useCustomNavigate();

    const { TeamListResponse, addTeamDataResponse } = useAdminSelectors()
    const { chatUserList, profileData } = useCustomSelectors()

    const employeeList = chatUserList?.data?.data
    const taskList = TeamListResponse?.data?.data

    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)
    const [formData, setFormData] = useState({ taskStatus: '', assignTask: '' })
    const [errorMessage, setErrorMessages] = useState({ title: '', descripition: '', assignTask: '' })

    const [showModal, setShowModal] = useState({ editTask: false, deleteTask: false })
    const [logData, setLogData] = useState(null)
    const [showToast, setShowToast] = useState({ successToast: '', errorToast: '' })
    const [statuses, setStatuses] = useState([]);
    const [apiCallMade, setApiCallMade] = useState(false)

    const [commentsData, setCommentsData] = useState({ id: null, index: null, reply: '', loading: false })
    const [addCommentsData, setAddCommentsData] = useState([])

    useEffect(() => {
        if (initialPage) {
            setPageNumber(initialPage)
        }
    }, [initialPage])

    useEffect(() => {
        if (!apiCallMade) {
            dispatch(fetchAllTaskLists('task_list'))
            setApiCallMade(true)
        }
    }, [])

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
        };

        if (value) {
            setPageNumber(value);
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl);
        }

        dispatch(fetchAllTaskLists('task_list', params))
    }

    const handleFilter = () => {
        const { assignTask, taskStatus } = formData
        if (assignTask || taskStatus) {
            const params = {
                status: taskStatus,
                login_user_id: assignTask
            }
            dispatch(fetchAllTaskLists('task_list', params))
        }
    }

    useEffect(() => {
        if (taskList && taskList?.tasks) {
            setStatuses(taskList?.tasks?.map(task => task.status));
        }
    }, [taskList]);

    const handleStatusChange = (index, newStatus, id) => {
        const updatedStatuses = [...statuses];
        updatedStatuses[index] = newStatus;
        setStatuses(updatedStatuses);
        dispatch(HandleUpdateTaskStatus('update_task_status', setShowToast, id, newStatus))
    };

    const handleAddCommentsReply = () => {
        const reqdata = {
            message: commentsData.reply,
        }
        if (commentsData.reply) {
            dispatch(handleAddComments('update_coomments', setShowToast, reqdata, commentsData.id, setCommentsData,commentsEndRef))
        }
    }

    useEffect(() => {
        if (addTeamDataResponse?.data?.data) {
            setAddCommentsData(prevData => [...prevData, addTeamDataResponse?.data?.data]);
        }
    }, [addTeamDataResponse]);

    // const fetchMoreData = () => {
    //     if (TeamListResponse?.currentPage < TeamListResponse?.totalPages) {
    //         const params = {
    //             page: TeamListResponse?.currentPage + 1,
    //         }
    //         dispatch(fetchTaskCommentsList('comments_list', commentsData.id, params))
    //     }
    // }

    // useEffect(() => {
    //     if (taskCommentsResponse?.type === 'comments_list' && taskCommentsResponse?.data?.length > 0) {

    //     }
    // }, [taskCommentsResponse?.data]);

    return (
        <>

            {/* {taskCommentsResponse?.data?.length > 0 &&
                <InfiniteScroll
                    dataLength={taskCommentsResponse?.data?.length}
                    next={fetchMoreData}
                    hasMore={taskCommentsResponse?.currentPage <= taskCommentsResponse?.totalPages}
                />
            } */}

            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">

                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className='grid grid-cols-6 gap-2'>
                                            <DropdownSelect
                                                label=""
                                                name="taskStatus"
                                                formData={formData}
                                                setFormData={setFormData}
                                                padding='pb-1.5 pt-2'
                                                options={[
                                                    { value: "", label: "Status" },
                                                    { value: "pending", label: "Pending" },
                                                    { value: "ongoing", label: "Ongoing" },
                                                    { value: "completed", label: "Completed" },
                                                    { value: "priority", label: "Priority" },
                                                    { value: "discussion", label: "Discussion" },
                                                ]}
                                            />

                                            <AssignTaskSelect
                                                formData={formData}
                                                setFormData={setFormData}
                                                employeeList={employeeList}
                                                profileData={profileData}
                                                errorMessage={errorMessage}
                                                placeholder="Assigned To"
                                                padding='pb-1.5 pt-2'
                                            />

                                            <Button text="Filter" color="white" handleFilter={handleFilter} height={'36px'} />


                                        </div>

                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mt-2">
                                            <div className="">

                                                {TeamListResponse?.loading && TeamListResponse.type === 'task_list' ?

                                                    <div className="flex items-center justify-center  mt-60 mb-80">
                                                        <LoadingView />
                                                    </div>

                                                    : TeamListResponse?.error !== null && TeamListResponse.type === 'task_list' ?
                                                        <div className="flex items-center justify-center mb-80">
                                                            <ErrorMessages />
                                                        </div>
                                                        : taskList?.tasks?.length === 0 && TeamListResponse.type === 'task_list' ?
                                                            <h2 className="text-gray-500">
                                                                No Record Available.
                                                            </h2> :

                                                            TeamListResponse?.type === 'task_list' ?

                                                                <div className="shadow-md border rounded-lg relative overflow-x-auto">
                                                                    <table className=" w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400  ">
                                                                        <thead className="text-xs text-white uppercase text-gray-900 dark:bg-gray-700 dark:text-gray-400" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                            <tr>
                                                                                {TaskListTableHeading?.map((header, index) => (
                                                                                    <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                        <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                    </th>
                                                                                ))}
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            {taskList?.tasks?.map((value, index) => {
                                                                                return (<React.Fragment key={index}>
                                                                                    <tr className="bg-white dark:bg-gray-800 border-b dark:border-gray-700">
                                                                                        <TableRowData className1="px-6 py-4"
                                                                                            value={value.login_user.name.charAt(0).toUpperCase() + value?.login_user?.name?.slice(1)} />

                                                                                        <TableRowData className1="px-6 py-4" value={value.title} />
                                                                                        <TableRowData className1="px-6 py-4" value={value.description} />

                                                                                        <td className="px-6 py-4">
                                                                                            <div className="relative w-full">
                                                                                                <select
                                                                                                    style={{ width: '130px' }}
                                                                                                    id="status"
                                                                                                    value={statuses[index]}
                                                                                                    onChange={(e) => handleStatusChange(index, e.target.value, value.id)}
                                                                                                    className="block scroll-box pl-2 w-full px-2.5 pb-1.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer bg-white"
                                                                                                >
                                                                                                    <option value="pending">Pending</option>
                                                                                                    <option value="ongoing">Ongoing</option>
                                                                                                    <option value="completed">Completed</option>
                                                                                                    <option value="priority">Priority</option>
                                                                                                    <option value="discussion">Discussion</option>
                                                                                                </select>
                                                                                            </div>

                                                                                        </td>

                                                                                        <td scope="row" className={'px-6 py-4'}>
                                                                                            <div className='flex'>
                                                                                                <p onClick={() => { return setLogData(value), handleShowModal(setShowModal, showModal, 'editTask') }}>
                                                                                                    <EditIcons color="blue" />
                                                                                                </p>

                                                                                                <div className="btnnn" onClick={() => { return setLogData(value), handleShowModal(setShowModal, showModal, 'deleteTask') }}>
                                                                                                    <DeleteIcon color="red" />
                                                                                                </div>

                                                                                                <div className="btnnn" onClick={() => {
                                                                                                    return setLogData(value),
                                                                                                        // dispatch(fetchCommentsListSuccess([])),
                                                                                                        setAddCommentsData([]),
                                                                                                        // dispatch(fetchCommentsListFailure(null)),
                                                                                                        setTimeout(() => {
                                                                                                            // dispatch(fetchTaskCommentsList('comments_list', value.id))
                                                                                                            commentsEndRef.current?.scrollIntoView({ behavior: 'smooth', block: "end" });
                                                                                                        }, 100),
                                                                                                        setCommentsData({ ...commentsData, index: index, reply: '', id: value.id, loading: true })
                                                                                                }}>

                                                                                                    <svg fill="green" className="w-5 h-5" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <title>reply</title>
                                                                                                        <path d="M4.687 11.119l9.287 8.933v-5.412c2.813 0 9.973 0.062 9.973 7.426 0 3.855-2.734 7.072-6.369 7.816 5.842-0.792 10.359-5.747 10.359-11.806 0-11.256-12.026-11.352-13.963-11.352v-4.606l-9.287 9.001z"></path>
                                                                                                    </svg>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>

                                                                                    </tr>

                                                                                    {commentsData?.index === index &&
                                                                                        <><tr className="w-full">



                                                                                            <td colSpan="5">
                                                                                                <div className="scroll-box" style={{ maxHeight: '150px', overflow: 'auto' }} >
                                                                                                    {/* {taskCommentsResponse?.loading && taskCommentsResponse?.type === 'comments_list' && commentsData?.loading ?
                                                                                                        <div className='flex justify-center items-center p-8'>
                                                                                                            <ProfilePicLoader />
                                                                                                        </div> : taskCommentsResponse?.type === 'comments_list' && taskCommentsResponse?.data?.length > 0 ?
                                                                                                            <>
                                                                                                                <InfiniteScroll
                                                                                                                    dataLength={taskCommentsResponse?.data?.length}
                                                                                                                    next={fetchMoreData}
                                                                                                                    hasMore={taskCommentsResponse.currentPage < taskCommentsResponse.totalPages}
                                                                                                                    scrollableTarget="scrollableDiv"
                                                                                                                    inverse={true}
                                                                                                                />

                                                                                                                {taskCommentsResponse?.data?.map((value, idx) => {
                                                                                                                    return (<React.Fragment key={idx}>
                                                                                                                        <div className="mb-2 p-1 borderLeftBgGreen bg-white rounded-md pl-2" ref={commentsEndRef}>
                                                                                                                            <p className="rubik-500 fs-14 text-gray-700">{`${value?.message}`}</p>
                                                                                                                            <p className=""><span className='fs-10 text-gray-700 rubik-500'>Added by : </span><span className='fs-10 text-gray-700 rubik-500'>{value?.postby_user?.name}</span></p>
                                                                                                                            <p className="rubik-400 fs-8 -mt-2">{value?.created_at}</p>
                                                                                                                        </div>
                                                                                                                    </React.Fragment>)
                                                                                                                })}
                                                                                                            </>
                                                                                                            : null} */}

                                                                                                    {value?.conversations?.map((data, idx) => {
                                                                                                        return (<React.Fragment key={idx}>
                                                                                                            <div className="mb-2 p-1 borderLeftBgGreen bg-white rounded-md pl-2" ref={commentsEndRef}>
                                                                                                                <p className="rubik-500 fs-14 text-gray-700">{`${data?.message}`}</p>
                                                                                                                <p className=""><span className='fs-10 text-gray-700 rubik-500'>Added by : </span><span className='fs-10 text-gray-700 rubik-500'>{data?.postby_user?.name}</span></p>
                                                                                                                <p className="rubik-400 fs-8 -mt-2">{data?.created_at}</p>
                                                                                                            </div>
                                                                                                        </React.Fragment>)
                                                                                                    })}

                                                                                                    {addCommentsData?.length > 0 && addCommentsData?.map((data, idx) => {
                                                                                                        return (<React.Fragment key={idx}>
                                                                                                            <div className="mb-2 p-1 borderLeftBgGreen bg-white rounded-md pl-2">
                                                                                                                <p className="rubik-500 fs-14 text-gray-700">{`${data?.message}`}</p>
                                                                                                                <p className=""><span className='fs-10 text-gray-700 rubik-500'>Added by : </span><span className='fs-10 text-gray-700 rubik-500'>{data?.postby_user?.name}</span></p>
                                                                                                                <p className="rubik-400 fs-8 -mt-2">{data?.created_at}</p>
                                                                                                            </div>
                                                                                                        </React.Fragment>)
                                                                                                    })}
                                                                                                </div>

                                                                                                <div className="lightBgColor mb-2">
                                                                                                    <textarea key={index} id={`message_${index}`} rows="5" value={commentsData?.reply}
                                                                                                        onChange={(e) => setCommentsData({ ...commentsData, reply: e.target.value, id: value.id, loading: false })}
                                                                                                        className="scrollToTop rubik-400 fs-16 resize-none block p-2.5 w-full text-sm text-gray-900 bg-white  
																												border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 
																												dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                                                        placeholder="Type your reply here...">
                                                                                                    </textarea>

                                                                                                    <div className="p-2">
                                                                                                        {addTeamDataResponse?.error !== null && addTeamDataResponse.type === 'update_coomments' &&
                                                                                                            <Errors error='Comment not added please try again.' />
                                                                                                        }
                                                                                                        <button
                                                                                                            type="button" onClick={handleAddCommentsReply}
                                                                                                            className="rubik-500 text-white fs-14 blueBg-color rounded-md text-sm px-3 py-1.5 mr-1">
                                                                                                            {addTeamDataResponse.loading && addTeamDataResponse.type === 'update_coomments' ? 'Processing...' : 'Add Reply'}
                                                                                                        </button>
                                                                                                        <button onClick={() => setCommentsData({ ...commentsData, index: null, reply: '' })}
                                                                                                            type="button"
                                                                                                            className="rubik-500 text-white fs-14 redBg-color rounded-md text-sm px-3 py-1.5">
                                                                                                            Cancel
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        </>
                                                                                    }

                                                                                </React.Fragment>)
                                                                            })}
                                                                        </tbody>
                                                                    </table>

                                                                    {TeamListResponse?.error !== null ? null : <>
                                                                        {taskList?.tasks?.length !== 0 && <>
                                                                            {taskList?.pagination?.last_page !== 1 &&
                                                                                <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                    <nav aria-label="Page navigation example">
                                                                                        <ul className="inline-flex -space-x-px text-base h-10">
                                                                                            <CustomPagination
                                                                                                count={taskList?.pagination?.last_page}
                                                                                                page={pageNumber}
                                                                                                onChange={handlePagination}
                                                                                            />
                                                                                        </ul>
                                                                                    </nav>
                                                                                </div>
                                                                            }
                                                                        </>}
                                                                    </>}

                                                                </div> : null}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal.editTask &&
                <UpdateTaskList logData={logData}
                    paginationNumber={pageNumber}
                    setShowModal={setShowModal}
                    setShowToast={setShowToast} />}

            {showModal.deleteTask &&
                <DeleteList logData={logData}
                    paginationNumber={pageNumber}
                    setShowModal={setShowModal}
                    text={'Are you sure you want to delete this task ?'}
                    setShowToast={setShowToast} />}


            {showToast.successToast && addTeamDataResponse.type === 'update_new_task' && <SuccessToastMessage status={'Task Updated successfully'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'update_new_task' && <ErrorToastMessage status={'Got an issue while updating task.please try again.'} />}

            {showToast.successToast && addTeamDataResponse.type === 'delete_task' && <SuccessToastMessage status={'Task Deleted successfully'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'delete_task' && <ErrorToastMessage status={'Got an issue while deleting task.please try again.'} />}


            {showToast.successToast && addTeamDataResponse.type === 'update_task_status' && <SuccessToastMessage status={'Status updated successfully'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'update_task_status' && <ErrorToastMessage status={'Got an issue while updating status.please try again.'} />}

            <ChatBox />

        </>
    )
}

export default AllTasksList