import {
	PHARMACY_ADD_DATA_REQUEST,
	PHARMACY_ADD_DATA_SUCCESS,
	PHARMACY_ADD_DATA_FAILURE
} from '../../../constants';

import { fetchAllTableListData } from './pharmacyFetchAllTableListData';
import { pharmacy_compound_list } from '../../../../networking/urlEndPoints';

import Axios from '../../../../networking/intraceptor'
import { handleHideModal } from '../../../../assets/js/custome';

export const pharmacyCompoundDataRequest = () => ({
	type: PHARMACY_ADD_DATA_REQUEST
});

export const pharmacyCompoundDataSuccess = (data) => ({
	type: PHARMACY_ADD_DATA_SUCCESS,
	payload: data,
});

export const pharmacyCompoundDataFailure = (error) => ({
	type: PHARMACY_ADD_DATA_FAILURE,
	payload: error,
});

export const pharmacyAddData = (path, requestedData, showModal, setShowModal, type, url) => {
	return async (dispatch) => {
		dispatch(pharmacyCompoundDataRequest());
		try {
			const response = await Axios.post(`${path}`, requestedData);
			if (response?.status === 200) {
				dispatch(pharmacyCompoundDataSuccess(response?.data));
				if (typeof setShowModal === "function" && type === 'pharmacy-compound') {
					dispatch(fetchAllTableListData(pharmacy_compound_list, null, null));
					setShowModal(prevState => ({
						...prevState,
						showToast: true,
						compoundModal: false
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false,
						}));
					}, 2000);
				}
				document.body.style.overflow = 'auto'

				if (typeof setShowModal === "function" && type === 'pharmacy-compound-price-list') {
					dispatch(fetchAllTableListData(url, null, null))
					setShowModal(prevState => ({
						...prevState,
						showToast: true,
						compoundModal: false
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false,
						}));
					}, 2000);
				}
				document.body.style.overflow = 'auto'


			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyCompoundDataFailure(error?.response));
			}
		}
	}
}

