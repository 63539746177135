import React, {
	useCallback,
	useEffect,
	useState
} from 'react';


import {
	useCustomDataStoringStates,
	useCustomLoadingStates,
	useCustomNullStates
} from '../../hooks/customStates';


import {
	subScribeChatNotifications,
	subscribeGroupMessages
} from '../../utils/miscellaneous/ably';

import {
	ContactList,
	ResponsiveContactList
} from './contactList';

import {
	RecentChats,
	ResponsiveRecentChatsView
} from './recentChats';

import { useCustomDispatch } from '../../hooks';
import { initiatedChatGroup } from '../../services/actions/chat/initiateChatGroupActions';
import { useCustomSelectors } from '../../services/selectors/allSelectors';
import { initatedGroupId } from '../../storage';

import { initTabs } from 'flowbite';
import { debounce } from 'lodash';
import { closeChatboxOne, closeOverlay, openChatboxOne, playMessageSound } from '../../assets/js/custome';
import { fetchAllChatUserList } from '../../services/actions/chat/allUserListActions';
import { fetchAllRecentChats } from '../../services/actions/chat/recentChatsListsActions';

import Cookies from "js-cookie";
import * as audio from '../../assets/images/index';
import CreateChatGroup from './createChatGroup';

const ChatBox = () => {

	const chatGroupID = initatedGroupId()
	const dispatch = useCustomDispatch()

	const {
		profileData, recentChatList,
		pharmacyId } = useCustomSelectors()

	const {
		selectedEmployeeId,
		setSelectedEmployeeId } = useCustomNullStates()

	const { isChatboxVisible,
		setIsChatboxVisible,
		chatLoading,
		setChatLoading,
		recentChatLoading,
		setRecenetChatLoading,
		isChatOpen,
		setIsChatOpen,
		apiCallMade,
		setApiCallMade
	} = useCustomLoadingStates()

	const {
		chatAppenedMessages, setChatAppenedMessages,
		myInputMessages, setMyInputMessages,
		allGroupMessages, setAllGroupMessages
	} = useCustomDataStoringStates()

	const {
		employeesOnlineStatus,
		setEmployeesOnlineStatus,
		overlayImage,
		setOverlayImage
	} = useCustomNullStates()

	const [chatInput, setChatInput] = useState('')
	const [clickCount, setClickCount] = useState(0)

	useEffect(() => {
		initTabs()
	}, [profileData])

	const toggleChatbox = () => {
		setIsChatboxVisible(!isChatboxVisible)
		setClickCount(clickCount + 1);
		setSelectedEmployeeId(null)
	}

	useEffect(() => {
		if (clickCount === 1 && !apiCallMade) {
			setRecenetChatLoading(true)
			dispatch(fetchAllChatUserList(null, setChatLoading))
			dispatch(fetchAllRecentChats(setRecenetChatLoading))
			setApiCallMade(true)
		}
	}, [clickCount])

	function openChatbox(value, type) {
		Cookies.remove('previous')
		closeChatboxOne()
		setAllGroupMessages([])
		if (value?.user_id?.length > 1 && value.group_type === 'one_to_many' && type !== 'contact_list') {
			Cookies.remove('initated')
			dispatch(initiatedChatGroup(value.group_type, value.user_id, value, null, 0, value.group_id))
		} else if (value?.user_id && type !== 'contact_list') {
			Cookies.remove('initated')
			dispatch(initiatedChatGroup(value.group_type, value.user_id, value, null, 0, value.group_id))
		}

		if (value?.user_id && type === 'contact_list') {
			Cookies.remove('initated')
			dispatch(initiatedChatGroup('one_to_one', [value.user_id], value, null, 0, value.group_id))
		}

		setSelectedEmployeeId(value.user_id)
		setMyInputMessages([])
		setChatAppenedMessages([])
		setChatInput('')
		setEmployeesOnlineStatus(value)
	}

	const searchRecentChatList = useCallback(
		debounce((e) => {
			const search = e.target.value
			setRecenetChatLoading(true)
			dispatch(fetchAllRecentChats(setRecenetChatLoading, search))
		}, 500),
		[dispatch]
	)

	const searchContactList = useCallback(
		debounce((e) => {
			const search = e.target.value
			setChatLoading(true)
			dispatch(fetchAllChatUserList(search, setChatLoading))
		}, 500),
		[dispatch]
	)

	useEffect(() => {
		const ablyConnection = subscribeGroupMessages(chatGroupID, setChatAppenedMessages, setMyInputMessages, playMessageSound, profileData, dispatch)

		return () => {
			ablyConnection.close();
		};
	}, [chatGroupID, dispatch])

	function openOverlay(image) {
		document.getElementById('overlay').style.display = 'flex';
		setOverlayImage(image)
	}

	const handleRemoveData = (Cookies) => {
		setSelectedEmployeeId(null)
		Cookies.remove('initated')
		Cookies.remove('previous')
	}

	useEffect(() => {
		const ablyConnection = subScribeChatNotifications(pharmacyId, profileData?.user_id, dispatch, playMessageSound)

		return () => {
			ablyConnection.close();
		};
	}, [profileData])

	const openChat = () => {
		setIsChatOpen(!isChatOpen);
		setRecenetChatLoading(true)

		dispatch(fetchAllChatUserList(null, setChatLoading))
		dispatch(fetchAllRecentChats(setRecenetChatLoading))
	};

	const closeChat = () => {
		setIsChatOpen(false);
	};

	const recentChatsResponse = recentChatList?.data?.latest_messages?.reduce((acc, value, index) => {
		return acc + value.unread_count;
	}, 0);


	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.altKey && event.key === 'c') {
				setIsChatboxVisible(prevState => !prevState);
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		}
	}, []);

	return (
		<>
			<audio id="messageSound">
				<source src={audio.chatAudio} type="audio/mpeg" />
			</audio>

			<section className={`chatbox z-10 js-chatbox grey-color hidden md:hidden sm:hidden lg:block ${isChatboxVisible ? 'chatbox--is-visible' : ''}`} style={{ border: '1px solid #e1e1e1', borderRadius: '10px' }}>
				<div className="chatbox__header h-14 bg-white border btnnn z-30" >

					<h3 className="flex items-center gap-3 dark-color rubik-500 fs-18" onClick={toggleChatbox}>
						<div className="relative">
							<img className="w-10 h-10 rounded-full bg-light" src={profileData?.thumbnail} alt="Rounded avatar" />
							<span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-green-500 border-2 border-white dark:border-gray-800 rounded-full"></span>
						</div>
						Messaging

						{recentChatsResponse !== 0 && recentChatsResponse !== undefined && recentChatsResponse !== null &&
							<label className='-ml-2' style={{ color: 'darkslateblue' }}>({recentChatsResponse})</label>}

					</h3>

					<button className="js-chatbox-toggle flex items-center gap-4">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"
							onClick={() => openChatboxOne(setSelectedEmployeeId)}>
							<path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
						</svg>

						<label onClick={toggleChatbox} className='btnnn'>

							{
								isChatboxVisible ?

									<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" >
										<g clipPath="url(#clip0_26_9-807815)">
											<path d="M6.08665 7.35142C6.2197 7.3309 6.34332 7.2662 6.43996 7.16651L11.7728 1.70461C11.8395 1.6446 11.8939 1.57069 11.9328 1.48743C11.9717 1.40417 11.9942 1.31332 11.999 1.22043C12.0038 1.12755 11.9908 1.03458 11.9607 0.94727C11.9306 0.85995 11.8841 0.78012 11.8241 0.71266C11.764 0.6452 11.6917 0.59155 11.6115 0.55499C11.5313 0.51843 11.445 0.49972 11.3578 0.50004C11.2706 0.50036 11.1844 0.51968 11.1045 0.55682C11.0245 0.59397 10.9525 0.64816 10.8929 0.71605L6 5.72992L1.1071 0.716049C1.0475 0.648149 0.975501 0.593969 0.895501 0.556819C0.815601 0.519679 0.729401 0.500359 0.642201 0.500039C0.555001 0.499719 0.468701 0.518429 0.388501 0.554989C0.308301 0.591549 0.236001 0.645199 0.175901 0.712659C0.115901 0.780119 0.0694005 0.859949 0.0393005 0.947269C0.00920052 1.03458 -0.00379905 1.12755 0.00100094 1.22043C0.00580093 1.31332 0.0283002 1.40417 0.0672002 1.48743C0.1061 1.57069 0.1605 1.6446 0.2272 1.70461L5.56004 7.16651C5.62921 7.23748 5.71245 7.29094 5.8036 7.32294C5.89476 7.35495 5.99149 7.36469 6.08665 7.35142Z" fill="#757575" />
										</g>
										<defs>
											<clipPath id="clip0_26_9-807815">
												<rect width="12" height="7" fill="white" transform="translate(0 0.5)" />
											</clipPath>
										</defs>
									</svg>

									:

									<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0_26_9-807815)">
											<path d="M5.91335 0.648581C5.7803 0.669099 5.65668 0.733795 5.56004 0.833489L0.227169 6.29539C0.160538 6.3554 0.106104 6.42931 0.0672067 6.51257C0.0283094 6.59583 0.00576948 6.68668 0.000970184 6.77957C-0.00382911 6.87245 0.00921338 6.96542 0.0392973 7.05273C0.0693813 7.14005 0.115872 7.21988 0.175917 7.28734C0.235962 7.3548 0.308293 7.40845 0.388474 7.44501C0.468654 7.48157 0.554992 7.50028 0.642189 7.49996C0.729385 7.49964 0.815602 7.48032 0.895547 7.44318C0.975492 7.40603 1.04748 7.35184 1.10709 7.28395L6 2.27008L10.8929 7.28395C10.9525 7.35185 11.0245 7.40603 11.1045 7.44318C11.1844 7.48032 11.2706 7.49964 11.3578 7.49996C11.445 7.50028 11.5313 7.48157 11.6115 7.44501C11.6917 7.40845 11.764 7.3548 11.8241 7.28734C11.8841 7.21988 11.9306 7.14005 11.9607 7.05273C11.9908 6.96542 12.0038 6.87245 11.999 6.77957C11.9942 6.68668 11.9717 6.59583 11.9328 6.51257C11.8939 6.42931 11.8395 6.3554 11.7728 6.29539L6.43996 0.833489C6.37079 0.762519 6.28755 0.709063 6.1964 0.677056C6.10524 0.645049 6.00851 0.635309 5.91335 0.648581Z" fill="#757575" />
										</g>
										<defs>
											<clipPath id="clip0_26_9-807815">
												<rect width="12" height="7" fill="white" transform="translate(0 0.5)" />
											</clipPath>
										</defs>
									</svg>
							}

						</label>
					</button>
				</div>

				<div className="scroll-box js-chatbox-display chatbox__display ">
					<div className="bg-light sticky top-0 z-10">
						<div className="border-b border-gray-200 dark:border-gray-700">
							<ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
								<li className="me-2 w-6/12" role="presentation">
									<button className="w-full inline-block p-4 border-b-2 rounded-t-lg" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Recent Chat</button>
								</li>

								<li className="me-2 w-6/12" role="presentation" onClick={() => handleRemoveData(Cookies)}>
									<button className="w-full inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" id="settingss-tab" data-tabs-target="#settingss" type="button" role="tab" aria-controls="settingss" aria-selected="false">Contact</button>
								</li>
							</ul>
						</div>
					</div>

					{/* {/* -----------------------------------------------------------Open ChatBox----------------------------------------------------} */}

					<div id="default-tab-content">
						<div className="hidden  rounded-lg bg-gray-50 dark:bg-gray-800" id="profile"
							role="tabpanel" aria-labelledby="profile-tab">

							<div className="inline-block sticky w-full top-16 z-10 ot_searchBox">
								<svg width="20" height="21" className="absolute left-3 top-3" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clipPath="url(#clip0_2_54-538021)">
										<path d="M19.6681 18.1192L16.0787 14.5544C18.8814 10.965 18.5864 5.85126 15.3657 2.63061C13.6693 0.934235 11.4075 0 8.99816 0C6.58881 0 4.32698 0.934235 2.63061 2.63061C0.934235 4.32698 0 6.58881 0 8.99816C0 11.4075 0.934235 13.6693 2.63061 15.3657C4.32698 17.0621 6.58881 17.9963 8.99816 17.9963C10.9896 17.9963 12.9564 17.3325 14.5298 16.0787L18.0947 19.6927C18.3159 19.914 18.5864 20.0123 18.8814 20.0123C19.1764 20.0123 19.4468 19.8894 19.6681 19.6927C20.1106 19.2747 20.1106 18.5618 19.6681 18.1192ZM15.7837 8.99816C15.7837 10.8175 15.0707 12.5138 13.7923 13.7923C12.5138 15.0707 10.7929 15.7837 8.99816 15.7837C7.20344 15.7837 5.48248 15.0707 4.20406 13.7923C2.92563 12.5138 2.21266 10.7929 2.21266 8.99816C2.21266 7.17886 2.92563 5.48248 4.20406 4.20406C5.48248 2.92563 7.20344 2.21266 8.99816 2.21266C10.8175 2.21266 12.5138 2.92563 13.7923 4.20406C15.0707 5.48248 15.7837 7.17886 15.7837 8.99816Z" fill="#757575"></path>
									</g>
									<defs>
										<clipPath id="clip0_2_54-538021">
											<rect width="20" height="21" fill="white"></rect>
										</clipPath>
									</defs>
								</svg>
								<input onChange={searchRecentChatList} className="border-none h-11 appearance-none fs-18 rubik-400 w-full py-2 pl-10 text-gray-600 leading-tight shadow-none focus:shadow-none focus:border-none active:shadow-none outline-offset-0 focus:ring-0 focus:border-0 border-0" id="username" type="text" placeholder="Search" />
							</div>

							<RecentChats
								openChatbox={openChatbox}
								selectedEmployeeId={selectedEmployeeId}
								setAllGroupMessages={setAllGroupMessages}
								setSelectedEmployeeId={setSelectedEmployeeId}
								setMyInputMessages={setMyInputMessages}
								setChatAppenedMessages={setChatAppenedMessages}
								setChatInput={setChatInput}
								employeesOnlineStatus={employeesOnlineStatus}
								allGroupMessages={allGroupMessages}
								chatInput={chatInput}
								myInputMessages={myInputMessages}
								chatAppenedMessages={chatAppenedMessages}
								openOverlay={openOverlay}
								closeOverlay={closeOverlay}
								chatLoading={chatLoading}
								recentChatLoading={recentChatLoading}
								playMessageSound={playMessageSound}
							/>
						</div>

						<div className="hidden rounded-lg bg-gray-50 dark:bg-gray-800" id="settingss" role="tabpanel" aria-labelledby="settingss-tab">
							<div className="inline-block sticky w-full top-16 z-10 ot_searchBox">
								<svg width="20" height="21" className="absolute left-3 top-3" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clipPath="url(#clip0_2_54-538021)">
										<path d="M19.6681 18.1192L16.0787 14.5544C18.8814 10.965 18.5864 5.85126 15.3657 2.63061C13.6693 0.934235 11.4075 0 8.99816 0C6.58881 0 4.32698 0.934235 2.63061 2.63061C0.934235 4.32698 0 6.58881 0 8.99816C0 11.4075 0.934235 13.6693 2.63061 15.3657C4.32698 17.0621 6.58881 17.9963 8.99816 17.9963C10.9896 17.9963 12.9564 17.3325 14.5298 16.0787L18.0947 19.6927C18.3159 19.914 18.5864 20.0123 18.8814 20.0123C19.1764 20.0123 19.4468 19.8894 19.6681 19.6927C20.1106 19.2747 20.1106 18.5618 19.6681 18.1192ZM15.7837 8.99816C15.7837 10.8175 15.0707 12.5138 13.7923 13.7923C12.5138 15.0707 10.7929 15.7837 8.99816 15.7837C7.20344 15.7837 5.48248 15.0707 4.20406 13.7923C2.92563 12.5138 2.21266 10.7929 2.21266 8.99816C2.21266 7.17886 2.92563 5.48248 4.20406 4.20406C5.48248 2.92563 7.20344 2.21266 8.99816 2.21266C10.8175 2.21266 12.5138 2.92563 13.7923 4.20406C15.0707 5.48248 15.7837 7.17886 15.7837 8.99816Z" fill="#757575"></path>
									</g>
									<defs>
										<clipPath id="clip0_2_54-538021">
											<rect width="20" height="21" fill="white"></rect>
										</clipPath>
									</defs>
								</svg>
								<input onChange={searchContactList} className="border-0 h-11 appearance-none border_Color fs-18 rubik-400 w-full py-2 pl-10 text-gray-600 leading-tight shadow-sm focus:ring-0 focus:border-0 border-0" id="username" type="text" placeholder="Search" />
							</div>
							<ContactList
								openChatbox={openChatbox}
								selectedEmployeeId={selectedEmployeeId}
								setAllGroupMessages={setAllGroupMessages}
								setSelectedEmployeeId={setSelectedEmployeeId}
								setMyInputMessages={setMyInputMessages}
								setChatAppenedMessages={setChatAppenedMessages}
								setChatInput={setChatInput}
								employeesOnlineStatus={employeesOnlineStatus}
								allGroupMessages={allGroupMessages}
								chatInput={chatInput}
								myInputMessages={myInputMessages}
								chatAppenedMessages={chatAppenedMessages}
								openOverlay={openOverlay}
								closeOverlay={closeOverlay}
								chatLoading={chatLoading}
							/>
						</div>
					</div>

					{/* --------------------------------------------------------------------Create a Chat Group Section Start----------------------------------------------- */}

					<CreateChatGroup />

					{/* --------------------------------------------------------------------Create a Chat Group End ----------------------------------------------- */}

				</div>
			</section>

			<div id="overlay" style={{ zIndex: '9999999999' }}>
				<span id="close-btn" onClick={closeOverlay}>&times;</span>
				<img src={overlayImage} className="rounded-full" alt="Large Image" />
			</div>

			<button onClick={openChat} className="z-50 fixed bottom-4 right-4 blueBg-color text-white p-3 rounded-full shadow-lg lg:hidden">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6 14H14V12H6V14ZM6 11H18V9H6V11ZM6 8H18V6H6V8ZM2 22V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H6L2 22ZM5.15 16H20V4H4V17.125L5.15 16Z" fill="#FFFFFF" />
				</svg>
			</button>

			<div className={`z-30 w-full left-0 bottom-0 md:w-96 md:left-4 sm:w-96 sm:left-4 lg:bottom-0 lg:left-4 bg-white border-l border-t border-r fixed  ${isChatOpen ? 'show' : 'hidden'} border-gray-300 w-96 h-96 p-4 scroll-box chatbox__display`} id='chatScreen'>

				<div className="relative">
					<div className="bg-light sticky top-0 px-0 z-10">

						<ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
							<li className="me-2 w-6/12" role="presentation">
								<button className="w-full inline-block p-4 border-b-2 rounded-t-lg" id="recent-tab" data-tabs-target="#recent" type="button" role="tab" aria-controls="recent" aria-selected="false">
									Recent Chat
								</button>
							</li>
							<li className="me-2 w-6/12" role="presentation">
								<button className="w-full inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
									id="contact-tab" data-tabs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
									Contact
								</button>
							</li>
						</ul>

						<button onClick={closeChat} className="absolute top-0 right-0 z-20 mt-0 text-white p-0 rounded-full">
							<div className="absolute top-2 right-2 blueBg-color mr-auto h-5 w-5 shadow-md flex items-center justify-center rounded-full">
								<svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#FFFFFF"></path>
								</svg>
							</div>
						</button>

					</div>

					<div className="scroll-box js-chatbox-display">
						<div id="default-tab-content">

							<div className="hidden rounded-lg bg-gray-50 dark:bg-gray-800" id="recent" role="tabpanel" aria-labelledby="recent-tab">
								<div className="inline-block sticky w-full top-16 z-10 ot_searchBox">
									<svg width="20" height="21" className="absolute left-3 top-3" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0_2_54-538021)">
											<path d="M19.6681 18.1192L16.0787 14.5544C18.8814 10.965 18.5864 5.85126 15.3657 2.63061C13.6693 0.934235 11.4075 0 8.99816 0C6.58881 0 4.32698 0.934235 2.63061 2.63061C0.934235 4.32698 0 6.58881 0 8.99816C0 11.4075 0.934235 13.6693 2.63061 15.3657C4.32698 17.0621 6.58881 17.9963 8.99816 17.9963C10.9896 17.9963 12.9564 17.3325 14.5298 16.0787L18.0947 19.6927C18.3159 19.914 18.5864 20.0123 18.8814 20.0123C19.1764 20.0123 19.4468 19.8894 19.6681 19.6927C20.1106 19.2747 20.1106 18.5618 19.6681 18.1192ZM15.7837 8.99816C15.7837 10.8175 15.0707 12.5138 13.7923 13.7923C12.5138 15.0707 10.7929 15.7837 8.99816 15.7837C7.20344 15.7837 5.48248 15.0707 4.20406 13.7923C2.92563 12.5138 2.21266 10.7929 2.21266 8.99816C2.21266 7.17886 2.92563 5.48248 4.20406 4.20406C5.48248 2.92563 7.20344 2.21266 8.99816 2.21266C10.8175 2.21266 12.5138 2.92563 13.7923 4.20406C15.0707 5.48248 15.7837 7.17886 15.7837 8.99816Z" fill="#757575"></path>
										</g>
										<defs>
											<clipPath id="clip0_2_54-538021">
												<rect width="20" height="21" fill="white"></rect>
											</clipPath>
										</defs>
									</svg> <input onChange={searchRecentChatList} className="focus:ring-0 focus:border-0 border-0 border-0 h-11 appearance-none border_Color fs-18 rubik-400 w-full py-2 pl-10 text-gray-600 leading-tight shadow-sm" id="username" type="text" placeholder="Search" />
								</div>

								<ul className="my-3">
									<ResponsiveRecentChatsView openChatbox={openChatbox} recentChatLoading={recentChatLoading} />
								</ul>
							</div>

							<div className="hidden rounded-lg bg-gray-50 dark:bg-gray-800" id="contact" role="tabpanel" aria-labelledby="contact-tab">
								<div className="inline-block sticky w-full top-16 z-10 ot_searchBox">
									<svg width="20" height="21" className="absolute left-3 top-3" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0_2_54-538021)">
											<path d="M19.6681 18.1192L16.0787 14.5544C18.8814 10.965 18.5864 5.85126 15.3657 2.63061C13.6693 0.934235 11.4075 0 8.99816 0C6.58881 0 4.32698 0.934235 2.63061 2.63061C0.934235 4.32698 0 6.58881 0 8.99816C0 11.4075 0.934235 13.6693 2.63061 15.3657C4.32698 17.0621 6.58881 17.9963 8.99816 17.9963C10.9896 17.9963 12.9564 17.3325 14.5298 16.0787L18.0947 19.6927C18.3159 19.914 18.5864 20.0123 18.8814 20.0123C19.1764 20.0123 19.4468 19.8894 19.6681 19.6927C20.1106 19.2747 20.1106 18.5618 19.6681 18.1192ZM15.7837 8.99816C15.7837 10.8175 15.0707 12.5138 13.7923 13.7923C12.5138 15.0707 10.7929 15.7837 8.99816 15.7837C7.20344 15.7837 5.48248 15.0707 4.20406 13.7923C2.92563 12.5138 2.21266 10.7929 2.21266 8.99816C2.21266 7.17886 2.92563 5.48248 4.20406 4.20406C5.48248 2.92563 7.20344 2.21266 8.99816 2.21266C10.8175 2.21266 12.5138 2.92563 13.7923 4.20406C15.0707 5.48248 15.7837 7.17886 15.7837 8.99816Z" fill="#757575"></path>
										</g>
										<defs>
											<clipPath id="clip0_2_54-538021">
												<rect width="20" height="21" fill="white"></rect>
											</clipPath>
										</defs>
									</svg> <input onChange={searchContactList} className="border-0 h-11 focus:ring-0 focus:border-0 border-0 appearance-none  fs-18 rubik-400 w-full py-2 pl-10 text-gray-600 leading-tight shadow-sm" id="username" type="text" placeholder="Search" />
								</div>

								<ul className="my-3">
									<ResponsiveContactList openChatbox={openChatbox} chatLoading={chatLoading} />
								</ul>
							</div>

						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ChatBox