import {
	PHARMACY_ADD_DISPENSE_DATA_REQUEST,
	PHARMACY_ADD_DISPENSE_DATA_SUCCESS,
	PHARMACY_ADD_DISPENSE_DATA_FAILURE
} from '../../../constants';

import { authToken } from '../../../../storage/authToken';
import { persistor } from '../../../../services/store/store'
import { fetchAllDispenseList, fetchMemo } from './fetchAllDispenseListData';

import { fetchAllDeliveryDriversList } from './deliveryDeriversLists';
import { fetchAllDispenseArchivedDelveries } from './archivedDelveriesLists';
import { PreviewPdf } from '../../../../utils/helper/helper';
import { searchByRxNumberArchivedlistData } from './searchByRxNumberArchivedList';

import Axios from '../../../../networking/intraceptor'
import axios from 'axios';
import Cookies from 'js-cookie';

import * as path from '../../../../networking/urlEndPoints'
import * as routeNames from '../../../../routes/routeName'

export const pharmacyAddDispenseDataRequest = (types) => ({
	type: PHARMACY_ADD_DISPENSE_DATA_REQUEST,
	types: types
});

export const pharmacyAddDispenseDataSuccess = (data, types) => ({
	type: PHARMACY_ADD_DISPENSE_DATA_SUCCESS,
	payload: data,
	types: types
});

export const pharmacyAddDispenseDataFailure = (error, types) => ({
	type: PHARMACY_ADD_DISPENSE_DATA_FAILURE,
	payload: error,
	types: types
});

export const addDispenseData = (url, requestedData, setShowModal, type, navigate) => {
	return async (dispatch) => {
		dispatch(pharmacyAddDispenseDataRequest());
		try {
			const response = await Axios.post(`${url}`, requestedData)

			if (response?.status === 200) {
				dispatch(pharmacyAddDispenseDataSuccess(response?.data, type));

				if (typeof setShowModal === "function" && type === 'pharmacy_add_new_delivery_deriver') {
					setShowModal(prevState => ({
						...prevState,
						showToast: true,
						compoundModal: false,
						addDriverModal: false
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false,
						}));
					}, 2000);
					document.body.style.overflow = 'auto'
					dispatch(fetchAllDispenseList(path.pharmacy_driver_list, 'pharmacy_driver_list'))
					dispatch(fetchAllDeliveryDriversList(path.pharmacy_driver_list))
				}

				if (typeof navigate === 'function') {
					if (type === 'add-new-custom-delivery' && requestedData?.recurrence === 1) {
						navigate(routeNames.pharmacy_recurrence_delivery)
					} else {
						navigate(routeNames.pharmacy_delivery)
					}
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAddDispenseDataFailure(error?.response, type));
			}
		}
	}
}

export const addDriverData = (url, formDatas, setShowModal, type, selectedFile) => {
	return async (dispatch) => {
		dispatch(pharmacyAddDispenseDataRequest(type));

		try {
			const formData = new FormData();
			if (selectedFile) {
				formData.append('driver_photo', selectedFile);
			}

			if (formDatas.phone) {
				formData.append('phone', formDatas.phone)
			}

			if (formDatas.password) {
				formData.append('password', formDatas.password)
			}

			formData.append('name', formDatas.name);
			formData.append('email', formDatas.email);
			formData.append('passcode', formDatas.passcode);

			const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${url}`, formData, {
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${authToken()}`,
					'Content-Type': 'multipart/form-data',
				},
			})

			if (response?.status === 200) {
				dispatch(pharmacyAddDispenseDataSuccess(response?.data, type));

				if (typeof setShowModal === "function" && (type === 'pharmacy-add-driver' || type === 'pharmacy-update-driver')) {
					setShowModal(prevState => ({
						...prevState,
						showToast: true,
						addDriverModal: false,
						editDriver: false,
						compoundModal: false,
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false,
						}));
					}, 2000);
					document.body.style.overflow = 'auto'

					dispatch(fetchAllDispenseList(path.pharmacy_driver_list, 'pharmacy_driver_list'))
					dispatch(fetchAllDeliveryDriversList(path.pharmacy_driver_list))
				}
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response?.status === 401) {
				Cookies.remove('authToken')
				persistor.purge()
				window.location.href = '/'
			}
			else {
				dispatch(pharmacyAddDispenseDataFailure(error?.response, type));
			}
		}
	}
}

export const moveToNextDateDispneseList = (url, requestedData, setShowModal, type) => {
	return async (dispatch) => {
		dispatch(pharmacyAddDispenseDataRequest());
		try {
			const response = await Axios.post(`${url}`, requestedData)

			if (response?.status === 200) {
				dispatch(pharmacyAddDispenseDataSuccess(response?.data, type));

				if (typeof setShowModal === "function" && type === 'pharmacy-move-to-next-date') {
					setShowModal(prevState => ({
						...prevState,
						showToast: true,
						openMoveToNextDateModal: false
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false,
						}));
					}, 2000)
					document.body.style.overflow = 'auto'
					dispatch(fetchAllDispenseList(path.pharmacy_daily_dispense_list, 'pharmacy-daily-dispense-list'))
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAddDispenseDataFailure(error?.response, type));
			}
		}
	}
}

export const pharmacyUpdatedDailyDispenselogStatus = (patient_id, status, setShowModal, type, reasonNote) => {
	return async (dispatch) => {
		dispatch(pharmacyAddDispenseDataRequest());

		const requestedData = {
			log_id: patient_id,
			status: status
		}

		if (status === 'Missed') {
			requestedData.note = reasonNote
		}

		try {
			const response = await Axios.post(`${path.pharmacy_dispensedelivery_update_status}`, requestedData)

			if (response?.status === 200) {
				dispatch(pharmacyAddDispenseDataSuccess(response?.data, type));

				if (typeof setShowModal === "function" && type === 'pharmacy-update-log-status') {
					setShowModal(prevState => ({
						...prevState,
						showToast: true,
						reasonModalVisible: false
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false,
						}));
					}, 2000)
					document.body.style.overflow = 'auto'
					dispatch(fetchAllDispenseList(path.pharmacy_daily_dispense_list, 'pharmacy-daily-dispense-list'))
				}
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAddDispenseDataFailure(error?.response, type));
			}
		}
	}
}

export const pharmacyPatientArchievedSetpinDelivery = (patient_id, pin, type, formData, pageNumber, setShowModal) => {
	return async (dispatch) => {
		dispatch(pharmacyAddDispenseDataRequest(type));
		const requestedData = {
			patient_id: patient_id,
			pin: pin
		}
		try {
			const response = await Axios.post(`${path.patient_archieved_setpin_delivery}`, requestedData)

			if (response?.status === 200) {
				dispatch(pharmacyAddDispenseDataSuccess(response?.data, type))
				if (type === 'set_pin' && typeof setShowModal === 'function') {
					dispatch(fetchAllDispenseArchivedDelveries(formData, 'pharmacy-archived-deliveries', pageNumber))

					setShowModal(prevState => ({
						...prevState,
						showToast: true,
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false,
						}));
					}, 2000);
				}
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAddDispenseDataFailure(error?.response, type));
				if (type === 'set_pin' && typeof setShowModal === 'function') {
					setShowModal(prevState => ({
						...prevState,
						errorToast: true,
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							errorToast: false,
						}));
					}, 2000);
				}
			}
		}
	}
}

export const pharmacyPatientArchievedAddNoteDelivery = (patient_id, note, type, formData, pageNumber, setShowModal, activeTab) => {
	return async (dispatch) => {
		dispatch(pharmacyAddDispenseDataRequest(type));
		const requestedData = {
			patient_id: patient_id,
			notes: note
		}
		try {
			const response = await Axios.post(`${path.patient_archieved_patient_updatenotes}`, requestedData)

			if (response?.status === 200) {
				dispatch(pharmacyAddDispenseDataSuccess(response?.data, type))
				if (type === 'add_note' && typeof setShowModal === 'function') {
					if (activeTab === 'searchrxsheet') {
						dispatch(searchByRxNumberArchivedlistData(formData, 'rx_list'))
					} else {
						dispatch(fetchAllDispenseArchivedDelveries(formData, 'pharmacy-archived-deliveries', pageNumber))
					}
					setShowModal(prevState => ({
						...prevState,
						showToast: true,
						editNoteModalVisible: false
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							showToast: false,
						}));
					}, 2000);
					document.body.style.overflow = 'auto'
				}
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAddDispenseDataFailure(error?.response, type));
				if (type === 'add_note' && typeof setShowModal === 'function') {
					setShowModal(prevState => ({
						...prevState,
						errorToast: true,
						editNoteModalVisible: false
					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							errorToast: false,
						}));
					}, 2000);
				}
			}
		}
	}
}

export const pharmacyPatientPrescriptionViewPdf = (url, type, setShowModal) => {
	return async (dispatch) => {
		dispatch(pharmacyAddDispenseDataRequest(type));
		try {
			const response = await Axios.get(`${url}`)

			if (response?.status === 200) {
				dispatch(pharmacyAddDispenseDataSuccess(response?.data, type))
				if (type === 'view_sheet') {
					PreviewPdf(response?.data?.data)
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {

				if (type === 'view_sheet' && typeof setShowModal === 'function') {
					setShowModal(prevState => ({
						...prevState,
						pdfError: true,

					}));
					setTimeout(() => {
						setShowModal(prevState => ({
							...prevState,
							pdfError: false,
						}));
					}, 2000);
				}

				dispatch(pharmacyAddDispenseDataFailure(error?.response, type));
			}
		}
	}
}

export const pharmacyLabelsPrint = (Id, checklabel, type) => {
	return async (dispatch) => {
		dispatch(pharmacyAddDispenseDataRequest(type));
		try {
			const requestedData = {
				labelid: Id,
				checklabel: checklabel
			}
			const response = await Axios.post(`${path.print_deliveries_labelsheet}`, requestedData)

			if (response?.status === 200) {
				dispatch(pharmacyAddDispenseDataSuccess(response?.data, type))
				if (type === 'label_sheet') {
					PreviewPdf(response?.data?.data)
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAddDispenseDataFailure(error?.response, type));
			}
		}
	}
}



export const counsellingFetchImages = (type, requestedData, setOverlayImage, setShowToast) => {
	return async (dispatch) => {
		dispatch(pharmacyAddDispenseDataRequest());
		try {
			const response = await Axios.post(`${path.consent_showimage}`, requestedData)

			if (response?.status === 200) {
				dispatch(pharmacyAddDispenseDataSuccess(response?.data, type));
				document.getElementById('overlay').style.display = 'flex';

				if (typeof setOverlayImage === 'function') {
					const imageUrl = `${response?.data?.data}`;
					setOverlayImage(imageUrl)
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				if (typeof setShowToast === 'function') {
					setShowToast(true)
					setTimeout(() => {
						setShowToast(false)
					}, 2000);
				}
				dispatch(pharmacyAddDispenseDataFailure(error?.response, type));
			}
		}
	}
}

export const SyncMemoData = (type, setShowToast, pageNumber) => {
	return async (dispatch) => {
		dispatch(pharmacyAddDispenseDataRequest(type));
		try {
			const response = await Axios.get(`${'pending-memo-sync'}`)

			if (response?.status === 200) {
				dispatch(pharmacyAddDispenseDataSuccess(response?.data, type));
				const params = {
					page: pageNumber,
				}

				dispatch(fetchMemo("memo_list", params));

				if (typeof setShowToast === 'function') {
					setShowToast((prevState) => ({ prevState, showToast: true }))
					setTimeout(() => {
						setShowToast((prevState) => ({ prevState, showToast: false }))
					}, 2000);
				}

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				if (typeof setShowToast === 'function') {
					setShowToast((prevState) => ({ prevState, errorToast: true }))
					setTimeout(() => {
						setShowToast((prevState) => ({ prevState, errorToast: false }))
					}, 2000);
				}
				dispatch(pharmacyAddDispenseDataFailure(error?.response, type));
			}
		}
	}
}


export const pharmacyConsentSubmissionViewPdf = (url, type) => {
	return async (dispatch) => {
		dispatch(pharmacyAddDispenseDataRequest(type));
		try {
			const response = await Axios.get(`${url}`)

			if (response?.status === 200) {
				dispatch(pharmacyAddDispenseDataSuccess(response?.data, type))

				// if (type === 'concent_pdf_view') {
					PreviewPdf(response?.data?.data)
				// }

			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAddDispenseDataFailure(error?.response, type));
			}
		}
	}
}



