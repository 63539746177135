import React, {
	useCallback, useEffect,
	useRef,
	useState
} from 'react'

import { ProfileCameraIcon, } from '../../assets/svg/icons'
import { useCustomSelectors } from '../../services/selectors/allSelectors'
import { ProfilePicLoader } from './loadingView'

import { debounce } from 'lodash'
import { onHandleProfilePicChanger } from '../../assets/js/custome'

import * as constants from '../../constants/workspace'
import * as Hooks from '../../hooks'
import * as routeNames from '../../routes/routeName'
import * as pharmacyTeamNotes from '../../services/actions/pharmacy/other'
import * as useEffectHelpers from '../../utils/helper/useEffectsHelper'

const TabsSubMenuList = ({ data }) => {

	const navigate = Hooks.useCustomNavigate()
	const location = Hooks.useCustomLocation()
	const { adminModule } = useCustomSelectors()

	return (<>
		<ul className="pt-2 pb-4">
			{data?.map((value, index) => {
				return (<React.Fragment key={index}>
					{value.name === 'WINRX SETTINGS' && adminModule === 0 ?
						null
						:
						<li className="mb-2 mr-2 grid" onClick={() => { return navigate(value.route) }}>
							<a className={`${location.pathname === value.route ? "active" : ""}  py-2 rounded-md w-full btnnn`}>
								{value.name.toUpperCase()}
							</a>
						</li>}
				</React.Fragment>
				)
			})}
		</ul>
	</>)
}

const Sidebar = () => {

	const navigate = Hooks.useCustomNavigate()
	const dispatch = Hooks.useCustomDispatch()

	const location = Hooks.useCustomLocation()
	const fileInputRef = useRef(null);
	const imageRef = useRef(null)

	const [showNote, setShowNote] = Hooks.useCustomState(true)
	const [profilePicLoader, setProfilePicLoader] = Hooks.useCustomState(false)

	const [addTeamNoteData, setAddTeamNoteData] = useState('')
	const [loading, setLoading] = useState(false)

	const { profileData, pharmacyId,
		teamNotesMessageResponse, pharmacyLoginUser } = useCustomSelectors()

	const teamNotesMessage = teamNotesMessageResponse?.data?.data;

	const debouncedDispatch = useCallback(
		debounce((value) => {
			dispatch(pharmacyTeamNotes.pharmacyAddTeamNotes(value));
		}, 10), [dispatch]
	);

	const handalDashboardDataChange = (e) => {
		const value = e.target.value;
		setAddTeamNoteData(value);
	};

	useEffectHelpers.useAblySubScribeTeamNotes(pharmacyId, dispatch, setLoading, profileData)

	useEffect(() => {
		if (teamNotesMessage) {
			setAddTeamNoteData(teamNotesMessage)
		} else if (teamNotesMessage === null) {
			setAddTeamNoteData('')
		}
	}, [teamNotesMessage])

	const getStatusFromPathname = (pathname) => {
		const searchParams = new URLSearchParams(pathname.split('?')[1]);
		return searchParams.get('status');
	};

	const currentStatus = getStatusFromPathname(location.search);

	const HandleUpdateNote = () => {
		setLoading(false)
		debouncedDispatch(addTeamNoteData);
	}

	return (
		<>
			<div className="col-span-12 hidden lg:block lg:col-span-3 lg:self-start lg:sticky md:col-span-12 md:hidden md:relative overflow-auto relative scroll-box sm:col-span-12 sm:hidden sm:relative top-40">

				<div className="relative  h-auto sm:h-auto md:h-auto lg:h-auto">
					<div className="relative flex items-center">
						{profileData &&
							<>
								{profilePicLoader ?
									<div style={{ position: 'absolute', top: '23px', left: '25px' }}>
										<ProfilePicLoader />
									</div>
									: null}


								<img src={profileData?.thumbnail} className=" w-16 h-16" alt="" ref={imageRef} />
							</>}

						<input type="file" accept="image/*" onChange={(e) => onHandleProfilePicChanger(e, setProfilePicLoader, imageRef, dispatch)} id="fileInput" ref={fileInputRef} className="hidden" />

						<div className="ot_takePictureBgSidebar rounded-lg rounded-t-none" onClick={() => fileInputRef.current.click()}>
							<ProfileCameraIcon />
						</div>

						<span className="bottom-0 absolute left-14 profile-activity-indicator  w-3.5 h-3.5 bg-green-500 border-2 border-white dark:border-gray-800 rounded-full"></span>

						<div className="">
							<h3 className="rubik-400 fs-22 pl-2 -top-4" style={{ fontWeight: '300' }}>{profileData?.name?.toUpperCase()}</h3>
							{profileData?.type && <p className="rubik-500 fs-16 text-gray-500 pl-2" style={{ fontWeight: '300' }}>{profileData?.type?.charAt(0)?.toUpperCase() + profileData?.type?.slice(1)}</p>}
						</div>
					</div>

					<div className='sidebarListActive mt-4'>
						{(location.pathname === routeNames.HOME_ROUTE || location.pathname === routeNames.DASHBOARD_ROUTE) || (location.pathname === routeNames.APPRECIATIONS_ROUTE) ||
							(location.pathname === routeNames.TIMESHEET_ROUTE) || (location.pathname === routeNames.NEWREQUEST_ROUTE) ||
							(location.pathname === routeNames.ALLREQUEST_ROUTE) || (location.pathname === routeNames.pharmacy_search_module) ||
							(location.pathname === routeNames.ALLEMPLOYEES_ROUTE) || (location.pathname === routeNames.EDIT_TIME_OFFR_REQUEST_ROUTE)
							|| (location.pathname === routeNames.MY_PROFILE) || (location.pathname === routeNames.pharmacy_all_notifications) ||
							(location.pathname === routeNames.pharmacy_file_manager) || (location.pathname === routeNames.pharmacy_reminder_notes) ||
							(location.pathname.startsWith(routeNames.EDIT_TIME_OFFR_REQUEST_ROUTE) || (location.pathname === routeNames.pharmacy_tasks_list))
							?
							<ul className="pt-2 pb-4">

								{constants?.DashboardItems?.map((value, index) => {
									return (<React.Fragment key={index}>

										{value.name === 'TEAM NOTES' ?
											<li className="py-2 px-0 grid">
												<button onClick={() => setShowNote(!showNote)} className="px-3 py-2 hover:bg-gray-400 hover:text-white flex items-center justify-between w-full py-2 px-3  rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-gray-400 dark:hover:text-white dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent">TEAM NOTES <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
													<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
												</svg></button>
												{
													showNote &&

													<div className="container pt-2">
														<div className="relative rounded-md">
															<textarea
																value={addTeamNoteData}
																onChange={handalDashboardDataChange}
																style={{ background: 'lightyellow', height: '300px', paddingBottom: '50px' }}
																rows="14"
																// disabled={teamNotesMessageResponse?.loading ? true : false}
																className="rubik-400 fs-16 resize-none block p-2.5 w-full text-sm text-gray-900 bg-white rounded-md border border-gray-300 focus:ring-blue-500 
            													focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
																placeholder="">
															</textarea>
															{teamNotesMessageResponse?.loading && loading ? null :
																<button
																	onClick={HandleUpdateNote}
																	type="button"
																	style={{ height: '30px' }}
																	className="absolute bottom-2 left-2 flex items-center justify-center text-white bg-red-500 border border-gray-300 focus:outline-none hover:bg-red-400 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800">
																	Update
																</button>}
														</div>
													</div>


												}
											</li> : <li className="py-2 px-0  grid" onClick={() => { return navigate(value.route) }}>
												<a className={`${location.pathname === value.route ? "active" : ""}  py-2 rounded-md w-full btnnn`}>
													{value.name.toUpperCase()}
												</a>
											</li>}

									</React.Fragment>
									)
								})}
							</ul> :

							(location.pathname === routeNames.SETTINGS_ROUTE) || (location.pathname === routeNames.pharmacy_front_profile) ||
								(location.pathname === routeNames.pharmacy_fax_log) || (location.pathname === routeNames.pharmacy_break_parroll_settings)
								|| (location.pathname === routeNames.pharmacy_settings)
								?
								<TabsSubMenuList data={constants?.SettingItems} />
								:
								location.pathname === routeNames.ADD_PATIENTS || location.pathname === routeNames.ALL_PATIENTS
									|| location.pathname === routeNames.pharmacy_patient_route || location.pathname === routeNames.pharmacy_add_patient_route
									?
									<ul className="pt-2 pb-4">
										{constants.PateintData.map((value, index) => {
											const isActive = value.route === routeNames.ADD_PATIENTS
												? location.pathname === routeNames.ADD_PATIENTS :
												value.route === routeNames.pharmacy_patient_route ? location.pathname === routeNames.pharmacy_patient_route
													: currentStatus && currentStatus === getStatusFromPathname(value.route);

											return (
												<React.Fragment key={index}>
													<li className="py-2 px-0 grid" onClick={() => navigate(value.route)}>
														<a className={`py-2 rounded-md w-full btnnn ${isActive ? 'active' : ''}`}>
															{value.name.toUpperCase()}
														</a>
													</li>
												</React.Fragment>
											);
										})}

									</ul> :

									location.pathname === routeNames.PHARMACY_INFORMATION_UPDATE_FORM ||
										location.pathname === routeNames.PHARMACY_PRESCRIPTION_TRASNSFER_FORM ||
										location.pathname === routeNames.pharmacy_Emergency_Supply_OAT_medications_form ||
										location.pathname === routeNames.pharmacy_medication_form ||
										location.pathname === routeNames.missing_Rx_Requirements ||
										location.pathname === routeNames.pharmacy_emergency_supply_continuity_form ||
										location.pathname === routeNames.patient_agreement_for_take_home_dosing ||
										location.pathname === routeNames.pharmacy_Plan_G_Form ||
										location.pathname === routeNames.pharmacy_Plan_Z_Form ||
										location.pathname === routeNames.pharmacy_doctor_communication_form ||
										location.pathname === routeNames.pharmacy_Pharmacist_Prescriber_Communication ||
										location.pathname === routeNames.pharmacy_original_prescription_form ||
										location.pathname === routeNames.pharmacy_doctor_note_form ||
										location.pathname === routeNames.pharmacy_tb_skin_test_logs ||
										location.pathname === routeNames.pharmacy_tb_skin_test_mantoux_form
										?
										<TabsSubMenuList data={constants?.FormsData} />
										:
										location.pathname === routeNames.pharmacy_compounds ||
											location.pathname === routeNames.pharmacy_compound_agreement_form ||
											location.pathname === routeNames.pharmacy_controlled_order_request ||
											location.pathname === routeNames.pharmacy_order_compound_form ||
											location.pathname === routeNames?.pharmacy_order_compounding_form ||
											location.pathname.startsWith(routeNames.pharmacy_compound_price_list) ?

											<ul className="pt-2 pb-4">
												{constants?.compoundItems?.map((value, index) => {
													if (value.name === 'PRICE LIST' && pharmacyLoginUser?.pharmacy_compound_price_list === value.PermissionStatus) {
														return (
															<li className="mb-2 mr-2 grid" key={index} onClick={() => navigate(value.route)}>
																<a className={`${location.pathname === value.route ? "active" : ""} py-2 rounded-md w-full btnnn`}>
																	{value.name.toUpperCase()}
																</a>
															</li>
														);
													} else if (value.name !== 'PRICE LIST') {
														return (
															<li className="mb-2 mr-2 grid" key={index} onClick={() => navigate(value.route)}>
																<a className={`${location.pathname === value.route ? "active" : ""} py-2 rounded-md w-full btnnn`}>
																	{value.name.toUpperCase()}
																</a>
															</li>
														);
													} else {
														return null;
													}
												})}
											</ul>
											:
											location.pathname === routeNames.pharmacy_create_manual_delivery ||
												location.pathname === routeNames.pharmacy_route_delivery ||
												location.pathname === routeNames.pharmacy_driver ||
												location.pathname === routeNames.pharmacy_deliveries_supervisor ||
												location.pathname === routeNames.pharmacy_delivery ||
												location.pathname === routeNames.pharmacy_missed_deliveries ||
												location.pathname === routeNames.pharmacy_archived_deliveries ||
												location.pathname === routeNames.pharmacy_delivery_lables ||
												location.pathname === routeNames.pharmacy_patient_counselling ||
												location.pathname === routeNames.pharmacy_consent_form_logs ||
												location.pathname === routeNames.pharmacy_drugs_billing ||
												location.pathname === routeNames.pharmacy_oat_prescriptions ||
												location.pathname === routeNames.pharmacy_memo ||
												location.pathname === routeNames.upload_prescription ||
												location.pathname === routeNames.pharmacy_ongoing_prescritiptions ||
												location.pathname === routeNames.pharmacy_returned_patches ||
												location.pathname === routeNames.pharmacy_reversed_deliveries ||
												location.pathname === routeNames.pharmacy_expiring_rx ?

												<TabsSubMenuList data={constants?.DispenseItems} />
												:

												location.pathname === routeNames.pharmacy_patient_blister_pack ||
													location.pathname === routeNames.pharmacy_blister_patients ||
													location.pathname === routeNames.pharmacy_blister_packs ||
													location.pathname === routeNames.pharmacy_blister_daily_packs ||
													location.pathname === routeNames.pharmacy_blister_pack_on_shelf
													?
													<TabsSubMenuList data={constants?.BlistersItems} />

													: location.pathname === routeNames.pharmacy_patient_vials_pack ||
														location.pathname === routeNames.pharmacy_add_vials_patient ||
														location.pathname === routeNames.pharmacy_vials_packs ||
														location.pathname === routeNames.pharmacy_dailyvials_packs
														?
														<TabsSubMenuList data={constants?.AddVialsItems} />
														:
														location.pathname === routeNames.pharmacy_add_medication_patients ||
															location.pathname === routeNames.pharmacy_speciality_pack ||
															location.pathname === routeNames.pharmacy_speciality_medication_packs ||
															location.pathname === routeNames.pharmacy_dailymedication_packs
															?
															<TabsSubMenuList data={constants?.MedicationItems} />

															: location.pathname === routeNames.pharmacy_generate_invoice ||
																location.pathname === routeNames.pharmacy_previous_invoices
																?
																<TabsSubMenuList data={constants?.InvoiceItems} />
																:
																location.pathname === routeNames.ADD_ARV ||
																	location.pathname === routeNames.ARV_LIST
																	?
																	<TabsSubMenuList data={constants?.ARVItems} />

																	: location.pathname === routeNames.pharmacy_daily_revenue ||
																		location.pathname === routeNames.pharmacy_cash_change ||
																		location.pathname === routeNames.pharmacy_deposit_info ||
																		location.pathname === routeNames.pharmacy_hand_cash
																		?
																		<TabsSubMenuList data={constants?.RevenueItems} />

																		: location.pathname === routeNames.create_new_fda_form ||
																			location.pathname === routeNames.all_generated_fda_form_list ||
																			location.pathname === routeNames.pharmacy_expiring_frequent_dispense ||
																			location.pathname === routeNames.pharmacy_expired_frequent_dispense
																			?
																			<TabsSubMenuList data={constants?.FdaFormsItems} />

																			: location.pathname === routeNames.pharmacy_inventory_list ||
																				location.pathname === routeNames.pharmacy_total_reversed
																				?
																				<TabsSubMenuList data={constants?.InventoryItems} />
																				:
																				location.pathname === routeNames.pharmacy_add_travel_pack ||
																					location.pathname === routeNames.pharmacy_add_travel_patient ||
																					location.pathname === routeNames.pharmacy_travel_logs ||
																					location.pathname === routeNames.pharmacy_billing_due ||
																					location.pathname === routeNames.pharmacy_travel_returning_patients
																					?
																					<TabsSubMenuList data={constants?.AddTravelItems} />
																					:
																					location.pathname === routeNames.pharmacy_order_transfer ||
																						location.pathname === routeNames.pharmacy_order_new ||
																						location.pathname === routeNames.pharmacy_app_user_list ||
																						location.pathname === routeNames.pharmacy_app_announcements ||
																						location.pathname === routeNames.pharmacy_app_notification_list ||
																						location.pathname.startsWith(routeNames.pharmacy_view_order_details)
																						?
																						<TabsSubMenuList data={constants?.AppItems} />
																						:
																						location.pathname === routeNames.pharmacy_timeoff ||
																							location.pathname === routeNames.pharmacy_create_new_task ||
																							location.pathname === routeNames.pharmacy_tasks ||
																							location.pathname === routeNames.pharmacy_certificate_template ||
																							location.pathname === routeNames.pharmacy_team_attendance ||
																							location.pathname === routeNames.pharmacy_check_out_in ||
																							location.pathname === routeNames.pharmacy_assign_certificate ||
																							location.pathname === routeNames.pharmacy_department ||
																							location.pathname === routeNames.pharmacy_user ||
																							location.pathname === routeNames.pharmacy_team_avalibility
																							?
																							<TabsSubMenuList data={constants?.TeamItems} />
																							: null
						}
					</div>
				</div>
			</div>

		</>
	)
}

export default Sidebar;