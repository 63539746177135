import React, { useEffect, useState } from "react";

import {
	Footer,
	Sidebar
} from "../../components/common";

import {
	EmailOutlined,
	FaxOutlined,
	MapOutlined,
	Phone,
} from "@mui/icons-material";

import UpdatePharmacyOpenHourFrontProfile, {
	UpdatePharmacyFrontProfile,
	UpdatePharmacyLogo,
	AddMultilePhotosForPharmacy,
	UpdatePharmacyContactFrontProfile,
	UpdatePharmacyServiceFrontProfile
} from "../../models/admin/pharmacyProfile";

import { useCustomSelectors } from "../../services/selectors/allSelectors";
import { EditIcons, NewUserIcon } from "../../assets/svg/icons";

import { handleShowModal } from "../../assets/js/custome";
import { ErrorToastMessage, SuccessToastMessage } from "../../components/loaders/toastMessage";
import { useAdminSelectors } from "../../services/selectors/adminModuleSelectors";
import * as pharmacy from '../../services/actions/pharmacy/other';
import ChatBox from "../../components/chat/chatbox";
import { useDispatch } from "react-redux";
import * as Hooks from '../../hooks'


const PharmacyFrontProfile = () => {
    const dispatch = useDispatch()
	const { pharmacyLoginData, adminModule } = useCustomSelectors();
	const { addTeamDataResponse } = useAdminSelectors()
	const moduleData = Hooks.useCustomSelector(state => state?.pharmacySearch)
	const [showModal, setShowModal] = useState({ pharmacyLogo: false, updateProfile: false, addMultiplePhotos: false , updateContactProfile: false ,UpdatePharmacyOpenHourFrontProfile:false , UpdatePharmacyServiceFrontProfile:false})
	const [showToast, setShowToast] = useState({ successToast: false, errorToast: false })
	const [formData, setFormData] = useState({ pharmacyName: '', aboutPharmacy: '' })
    const contactData = moduleData?.data?.pharmacy
	const hourData = moduleData?.data?.working_hours
	const serviceData = moduleData?.data?.services
	const AllServices = [
		{
			"id": 39,
			"name": "Addiction Treatment"
		},
		{
			"id": 42,
			"name": "Asthma / COPD Management and training"
		},
		{
			"id": 3,
			"name": "Flu Shot Services"
		},
		{
			"id": 1,
			"name": "Free Prescription Delivery"
		},
		{
			"id": 38,
			"name": "Full line of vitamins"
		}
	]

	console.log(moduleData,"sdhjsdgjs")


	useEffect(() => {
			dispatch(pharmacy.pharmacySearchModule("pharmacies",pharmacyLoginData?.id))
	}, [])

	return (
		<>
			<div className="BodyBgColor">
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						<Sidebar />
						<div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content">
								<div className="container mt-2">
									<div className=" border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="mb-4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6">
											<div className="flex flex-col items-center justify-center gap-2 p-2 shadow rounded-md border">

												<div className='flex items-center'>
													<img className="mx-auto mb-2 object-cover rounded-t-lg h-auto w-20 md:rounded-none md:rounded-s-lg"
														src={pharmacyLoginData?.logo}
														alt="logo" />

													{/* {adminModule === 1 &&
														<div className='pt-14' onClick={() => handleShowModal(setShowModal, showModal, 'pharmacyLogo')}>
															<EditIcons />
														</div>} */}
												</div>

												<div className="flex flex-col justify-between leading-normal">
													<h5 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
														{pharmacyLoginData?.name}
													</h5>
												</div>


												<div className="flex gap-2 justify-center mt-2">
													<button
														type="button"
														style={{ background: "mediumseagreen" }}
														className="fs-12 flex justify-center items-center w-24 h-6 bg-blue-700 hover:bg-blue-800  close_btn rounded-md px-4 py-1.5">
														Verified
													</button>

													<button type="button"
														style={{ background: "goldenrod" }}
														className="fs-12 flex justify-center items-center w-24 h-6 bg-blue-700 hover:bg-blue-800  close_btn rounded-md px-4 py-1.5">
														Premium
													</button>

													{/* {adminModule === 1 &&
														<>
															<button type="button" onClick={() => handleShowModal(setShowModal, showModal, 'addMultiplePhotos')}
																style={{ background: "#997878" }}
																className="fs-12 flex justify-center items-center w-24 h-6 bg-blue-700 hover:bg-blue-800  close_btn rounded-md px-4 py-1.5">
																Add Photos
															</button>

															<div className='pt-0' onClick={() => {
																return handleShowModal(setShowModal, showModal, 'updateProfile'),
																	setFormData({ ...formData, pharmacyName: pharmacyLoginData?.name, aboutPharmacy: pharmacyLoginData?.about_us })
															}}>
																<EditIcons />
															</div>
														</>} */}

												</div>
											</div>
											<div className="inline-block p-3 shadow rounded-md border">
												<div className="flex items-center justify-between mb-2">
													<h5 className="text-2xl font-bold tracking-tight text-gray-900 pb-2 dark:text-white">
														Contact Details
													</h5>
													<div onClick={() => {
														handleShowModal(setShowModal, showModal, 'updateContactProfile');
														setFormData({
															...formData,
															pharmacyName: pharmacyLoginData?.name,
															aboutPharmacy: pharmacyLoginData?.about_us
														});
													}}>
														<EditIcons />
													</div>
												</div>

												{[{ title: 'Manager', description: <>{contactData?.manager_name}</>, icon: <NewUserIcon /> },
												{ title: 'Owner', description: <>{contactData?.owner_name ? contactData?.owner_name : 'Not Specified'}</>, icon: <NewUserIcon /> },
												{ title: 'Location', description: <>{contactData?.address}</>, icon: <MapOutlined style={{ color: "#3A52BB" }} /> },
												{ title: 'Email', description: <>{contactData?.email}</>, icon: <EmailOutlined style={{ color: "#3A52BB" }} /> },
												{ title: 'Phone', description: <>{contactData?.phone}</>, icon: <Phone style={{ color: "#3A52BB" }} /> },
												{ title: 'Fax', description: <>{contactData?.fax}</>, icon: <FaxOutlined style={{ color: "#3A52BB" }} /> }]?.map((value, index) => {

													return (<React.Fragment key={index}>
														<div className="flex flex-wrap p-2 items-center hover:bg-gray-100 rounded-lg shadow-sm mb-2">
															<div className="relative flex-0 mr-3" style={{ width: 25 }}>
																{value.icon}
															</div>

															<div className="relative flex-0">
																<h5 className="font-medium">{value.title}</h5>
															</div>

															<div className="relative flex-1 text-right">
																<p>{value?.description}</p>
															</div>
														</div>
													</React.Fragment>)
												})}

											</div>
										</div>

										<div className="shadow rounded-md border p-2 mb-4">
											<h5 className="text-gray-500 dark:text-white">
												{pharmacyLoginData?.about_us}
											</h5>
										</div>

										<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
											{pharmacyLoginData?.open_hours && (
												<div className="shadow rounded-md border">
													<div className="border">
													<div className="flex items-center justify-between mb-2 p-2 pb-0">
													<h5 className="text-2xl font-bold tracking-tight text-gray-900 pb-2 dark:text-white">
													Open Hours
													</h5>
													<div onClick={() => {
														handleShowModal(setShowModal, showModal, 'UpdatePharmacyOpenHourFrontProfile');
														setFormData({
															...formData,
															pharmacyName: pharmacyLoginData?.name,
															aboutPharmacy: pharmacyLoginData?.about_us
														});
													}}>
														<EditIcons />
													</div>
												</div>
														{/* <h5 className="text-2xl px-3 py-2 font-bold tracking-tight text-gray-900 dark:text-white">
															Open Hours
														</h5> */}
														{hourData?.map((data, index) => (
															<div key={index} className="grid grid-cols-2 border-b py-2 px-3 text-gray-500 hover:bg-gray-100">
																<h5 className="fs-16 rubik-400">{data?.day}</h5>
																<h5 className="fs-16 rubik-400">
																	{data?.start} - {data?.end}
																</h5>
															</div>
														))}
													</div>
												</div>
											)}

											{pharmacyLoginData?.services && (
												<div className="shadow rounded-md border">
													<div className="border">
													<div className="flex items-center justify-between mb-2 p-2 pb-0">
														<h5 className="mb-2 text-2xl px-3 py-2 font-bold tracking-tight text-gray-900 dark:text-white">
															Services
														</h5>

														<div onClick={() => {
														handleShowModal(setShowModal, showModal, 'UpdatePharmacyServiceFrontProfile');
														setFormData({
															...formData,
															pharmacyName: pharmacyLoginData?.name,
															aboutPharmacy: pharmacyLoginData?.about_us
														});
													}}>
														<EditIcons />
													</div></div>

														{AllServices?.map(
															(value, index) => {
																return (<React.Fragment key={index}>
																	<div className="flex items-center mb-3 px-3 text-gray-500">
																		<input disabled
																			checked={serviceData?.some(selected => selected.name == value?.name)}
																			type="checkbox"
																			value=""
																			className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 
																			dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
																		<label
																			htmlFor="disabled-checked-checkbox"
																			className="ml-2 text-sm font-medium dark:text-gray-500 rubik-400 fs-16">
																			{value?.name}
																		</label>
																	</div>
																</React.Fragment>
																);
															}
														)}
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{showModal.pharmacyLogo &&
				<UpdatePharmacyLogo setShowModal={setShowModal}
					showModal={showModal}
					setShowToast={setShowToast} />}

			{showModal.addMultiplePhotos &&
				<AddMultilePhotosForPharmacy setShowModal={setShowModal}
					showModal={showModal}
					setShowToast={setShowToast} />}

			{showModal.updateProfile &&
				<UpdatePharmacyFrontProfile setShowModal={setShowModal}
					data={formData}
					showModal={showModal}
					setShowToast={setShowToast} />}

			{showModal.updateContactProfile &&
				<UpdatePharmacyContactFrontProfile setShowModal={setShowModal}
					data={formData}
					contactData={contactData}
					showModal={showModal}
					setShowToast={setShowToast} pharmacyLoginId={pharmacyLoginData?.id}/>}
	
			{showModal.UpdatePharmacyOpenHourFrontProfile &&
				<UpdatePharmacyOpenHourFrontProfile setShowModal={setShowModal}
					data={formData}
					hourData={hourData}
					showModal={showModal}
					setShowToast={setShowToast} pharmacyLoginId={pharmacyLoginData?.id} />}

{showModal.UpdatePharmacyServiceFrontProfile &&
				<UpdatePharmacyServiceFrontProfile setShowModal={setShowModal}
					data={formData}
					serviceData={serviceData}
					showModal={showModal}
					setShowToast={setShowToast} pharmacyLoginId={pharmacyLoginData?.id} />}

			{showToast.successToast && addTeamDataResponse.type === 'update_logo' && <SuccessToastMessage status={'Logo updated successfully'} />}
			{showToast.errorToast && addTeamDataResponse.type === 'update_logo' && <ErrorToastMessage status={'Oops! got an issue while updating logo please try to update again.'} />}

			{showToast.successToast && addTeamDataResponse.type === 'update_profile' && <SuccessToastMessage status={'Profile Updated Successfully'} />}
			{showToast.errorToast && addTeamDataResponse.type === 'update_profile' && <ErrorToastMessage status={'Oops! got an issue while updating profile details. please try to update again.'} />}

			{showToast.successToast && addTeamDataResponse.type === 'add_pharmacy_photos' && <SuccessToastMessage status={'Images added Successfully'} />}

			<ChatBox />
			<Footer />
		</>
	);
};

export default PharmacyFrontProfile;
