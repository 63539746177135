import React from 'react'
import { useSelector } from 'react-redux'

import {
	patientProvinceList
} from '../../constants/rootReduceName';


export const useCustomPatientSelectors = () => {

	const patientListResponse = useSelector(state => state.patientsList)
	const patientListData = patientListResponse?.data?.data
	const provinceList = useSelector(state => state[patientProvinceList])

	const updatePatientProfileResponse = useSelector(state => state.updatePatientsProfile)
	const patientDataResponse = useSelector(state => state?.patientData)
	const winrxPatientDataResponse = useSelector(state => state?.patientData)
	const addPatientResponse = useSelector(state => state.addPatients)
	const patientData = patientDataResponse?.data?.data

	const savePatientsToClinicResponse = useSelector(state => state?.linkPatientsToClinic)
	const getAllClinicsResponse = useSelector(state => state.getAllClinics)
	const getClinicsData = getAllClinicsResponse?.data?.data

	const patientListNotesResponse = useSelector(state => state.patientNotesList)
	const savePatientListResponse = useSelector(state => state.savePatientNote)
	const patientListNotesData = patientListNotesResponse?.data

	const doctorsListResponse = useSelector(state => state?.doctorsList)
	const updateDoctorResponse = useSelector(state => state?.oatFormPref)
	const dctList = doctorsListResponse?.data?.data
	const doctorList = doctorsListResponse?.data?.data

	const updateClinicResponse = useSelector(state => state?.oatFormPref)
	const activePatientListResponse = useSelector(state => state.activePatients)
	const activePatientList = activePatientListResponse?.data?.data

	const updateNotificationsClinicsResponse = useSelector(state => state.updateNotificationsClinics)
	const patientCoverageListResponse = useSelector(state => state.coverageList)
	const coverageList = patientCoverageListResponse?.data?.data
	const addARVResponse = useSelector(state => state.addARVState)
	const deleteARVResponse = useSelector(state => state.deleteARVState)
	const uploadPresImageResponse  = useSelector(state => state.uploadPresState)
	const deleteAppResponse  = useSelector(state => state.deleteAppState)
	return {
		patientListResponse,
		patientListData,
		provinceList,
		updatePatientProfileResponse,
		patientDataResponse,
		addPatientResponse,
		patientData,
		savePatientsToClinicResponse,
		getAllClinicsResponse,
		getClinicsData,
		patientListNotesResponse,
		savePatientListResponse,
		patientListNotesData,
		doctorsListResponse,
		updateDoctorResponse,
		dctList,
		updateClinicResponse,
		doctorList,
		winrxPatientDataResponse,
		activePatientList,
		activePatientListResponse,
		updateNotificationsClinicsResponse,
		coverageList,
		patientCoverageListResponse,
		addARVResponse,
		deleteARVResponse,
		uploadPresImageResponse,
		deleteAppResponse
	}
}
