import React, { useEffect, useState } from "react";

import {
    ReUploadPrescriptionImage,
    ViewUploadPrescriptionImage
} from "../../../models/pharmacy/prescriotionPopUp";

import { useDispenseSelectors } from "../../../services/selectors/dispenseSelectors";
import { currentDate } from '../../../utils/constants/formInitialStates';
import { fetchUploadPrescription } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'

import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";
import { TextInput } from "../../../components/pharmacy/dispense/dispense";
import { initalShowToastMessage } from "../../../utils/constants/formInitialStates";
import { SuccessToastMessage } from "../../../components/loaders/toastMessage";

import { EyeIcon } from "../../../assets/svg/icons";
import { BlisterDropDown } from '../../../components/pharmacy/form/form'

// import * as routeNames from "../../../routes/routeName";
import * as Hooks from "../../../hooks";
import * as Common from "../../../components/common";
import * as Modals from "../../../assets/js/custome";
import * as Dispense from '../../../components/pharmacy/dispense/dispense'

import ChatBox from "../../../components/chat/chatbox";

const prescriptionTableHeading = [
    { text: "ROUTE", classNames: ["p-3"] },
    { text: "PATIENT", classNames: ["p-3 no-wrap"] },
    { text: "RX", classNames: ["p-3"] },
    { text: "DRUG", classNames: ["p-3"] },
    { text: "DRUG SIG", classNames: ["p-3"] },
    { text: "PRESCRIPTION", classNames: ["p-3"] }
]

const TableRowData = ({ value, isReminder = false, className1 = "p-3", align = "" }) => {
    return (
        <td scope="row" className={className1}>
            <div className="flex " >
                <p className={align}>{value}</p>
                {isReminder && (
                    <div className="w-[32px] cursor-pointer h-[32px]" style={{ width: "15px" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 
                        58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 
                        0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" /></svg>
                    </div>
                )}
            </div>
        </td>
    );
};

const UploadPrescription = ({ activeTab }) => {

    const location = Hooks.useCustomLocation();
    const navigate = Hooks.useCustomNavigate();
    const dispatch = Hooks.useCustomDispatch();

    const { dispenseMemo } = useDispenseSelectors();
    const prescriptionListResponse = dispenseMemo
    const prescriptionList = prescriptionListResponse?.data?.data;
    const routeListForDropdown = prescriptionListResponse?.data?.data?.routes

    const [formData, setFormData] = useState({ search: "", type: "", date: currentDate });
    const [imageLogID, setimageLogID] = useState("")

    const searchParams = new URLSearchParams();

    const initialPage = parseInt(new URLSearchParams(location.search).get("page")) || 1;

    const [reset, setReset] = useState(false);
    const [showModal, setShowModal] = React.useState({ MemoExpiryUpdateModal: false });
    const [pageNumber, setPageNumber] = useState(initialPage);

    // const [showToast, setShowToast] = useState(initalShowToastMessage);
    const [arvID, setarvID] = useState(null);

    function settingIDToShowImage(id) {
        setarvID([id, Date.now()])
    }

    useEffect(() => {
        setPageNumber(initialPage);
    }, [initialPage]);

    useEffect(() => {
        const params = {
            page: pageNumber,
            no_of_records: 10,
            date: formData.date
        };
        dispatch(fetchUploadPrescription("prescrition_lists", params));
        return () => { };
    }, []);

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
            no_of_records: 10,
        };
        searchParams.append("active", activeTab);

        if (formData.route) params.route = formData.route;
        if (formData.search) params.search = formData.search;
        if (formData.date) params.search_date = formData.date;

        if (value !== undefined) {
            searchParams.append("page", value);
            setPageNumber(value);
        }
        dispatch(fetchUploadPrescription("prescrition_lists", params));
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);
    };

    const handleResetFilteredData = () => {
        const searchParams = new URLSearchParams();
        setReset(true)
        setFormData({ ...formData, search: '', route: '', date: '' })
        setTimeout(() => {
            setReset(false)
        }, 500);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        dispatch(fetchUploadPrescription("prescrition_lists", searchParams));
        navigate(newUrl);
    }

    return (
        <>
            <div className="container">
                <div className="flex flex-wrap lg:flex-nowrap gap-3">
                    <div className="col-span-12 flex">
                        <div className="flex gap-2">
                            <div className='w-34'>
                                <TextInput
                                    label="Search"
                                    name="search"
                                    formData={formData}
                                    setFormData={setFormData}
                                    height="42px"
                                />
                            </div>


                            <div className='w-34 ml-2'>
                                <Dispense.StartDate formData={formData}
                                    setFormData={setFormData} />
                            </div>
                            <div className='flex-0 ml-1 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '104px' }}>
                                <BlisterDropDown formData={formData}
                                    setFormData={setFormData}
                                    name="route"
                                    value={formData.route}
                                    filterList={routeListForDropdown}
                                    reset={reset}
                                    placeholder='Route' />
                            </div>
                            <button
                                type="button"
                                style={{ backgroundColor: "#3B82F6", width: "100px" }}
                                onClick={handlePagination}
                                className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
                                                            border border-gray-300 			focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                Filter
                            </button>

                            <button onClick={handleResetFilteredData} type="button" className="   h-full  py-4 items-center justify-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                Reset
                            </button>

                        </div>
                    </div>
                </div>

                <div>
                    {prescriptionListResponse?.loading && prescriptionListResponse?.type === "prescrition_lists" ? (

                        <div className="flex justify-center items-center mt-60 mb-80">
                            <Common.LoadingView />
                        </div>)

                        :
                        prescriptionListResponse?.error !== null && prescriptionListResponse?.type === "prescrition_lists" ? (
                            <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                <h1 className="text-4xl font-bold text-red-600 mb-2">
                                    Oops!
                                </h1>
                                <p className="text-lg text-gray-800 mb-2">
                                    Something went wrong while fetching the data.
                                </p>
                                <button
                                    onClick={() => window.location.reload()}
                                    className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500"
                                >
                                    Please Try Again.
                                </button>
                            </div>)

                            : prescriptionList?.data?.length === 0 && prescriptionListResponse?.type === "prescrition_lists" ? (
                                <div className="bg-white rounded-md">
                                    <p className="text-gray-500">
                                        No Record available
                                    </p>
                                </div>)

                                : prescriptionListResponse?.type === "prescrition_lists" ? (
                                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                        <div className="shadow-md border rounded-lg  relative overflow-x-auto">
                                            <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                <thead
                                                    className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900"
                                                    style={{
                                                        borderBottom: "1px solid lightgray",
                                                        background: "#f6f4f4",
                                                    }}
                                                >
                                                    <tr>
                                                        {prescriptionTableHeading?.map((header, index) => (
                                                            <th
                                                                key={index}
                                                                scope="col"
                                                                className={header?.classNames?.join(" ")}
                                                            >
                                                                <p className="rubik-500 fs-14">
                                                                    {header?.text}
                                                                </p>
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {prescriptionList.data?.map((value, idx) => (
                                                        <React.Fragment key={idx}>
                                                            <tr style={{ borderBottom: `${"1px solid lightgray"}` }}>

                                                                <TableRowData value={value?.route} />
                                                                <TableRowData value={value?.patient_name} />
                                                                <TableRowData value={value?.rxnumber} />
                                                                <TableRowData value={value?.drug} />
                                                                <TableRowData value={value?.rxsig} />
                                                                <td scope="row" style={{ float: "right" }} className="p-3 flex gap-2 btnnn">

                                                                    {value.prescription === "Re Upload" ? (
                                                                        <div className="flex items-center">

                                                                            <div
                                                                                onClick={() => {
                                                                                    settingIDToShowImage(value.uptriplicate_id);
                                                                                    Modals.handleShowModal(
                                                                                        setShowModal,
                                                                                        showModal,
                                                                                        "ViewUploadPrescriptionModal"
                                                                                    );
                                                                                }}
                                                                                className="fs-12 rubik-400 text-gray-400"
                                                                            >
                                                                                <EyeIcon className="text-gray-500 rounded-md bg-gray-200 p-1" />
                                                                            </div>
                                                                            <button
                                                                                onClick={() => {
                                                                                    setimageLogID(value.id);
                                                                                    Modals.handleShowModal(
                                                                                        setShowModal,
                                                                                        showModal,
                                                                                        "ReUploadPrescriptionModal"
                                                                                    );
                                                                                }}
                                                                                type="button"
                                                                                style={{ width: "80px" }}
                                                                                className="ml-2 bg-red-500 sm:text-center md:text-start lg:text-start rubik-400 fs-12 py-1 text-white rounded-lg text-sm  flex justify-center items-center"
                                                                            >
                                                                                Re Upload
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <div >
                                                                            <button
                                                                                onClick={() => {
                                                                                    setimageLogID(value.id);
                                                                                    Modals.handleShowModal(
                                                                                        setShowModal,
                                                                                        showModal,
                                                                                        "ReUploadPrescriptionModal"
                                                                                    );
                                                                                }}
                                                                                type="button"
                                                                                style={{ width: "80px", background: 'orange' }}
                                                                                className="ml-2 sm:text-center md:text-start lg:text-start rubik-400 fs-12 text-white rounded-lg text-sm  py-1 flex justify-center items-center"
                                                                            >
                                                                                Upload
                                                                            </button>
                                                                        </div>
                                                                    )}


                                                                </td>


                                                            </tr>
                                                        </React.Fragment>
                                                    ))}
                                                </tbody>
                                            </table>

                                            {prescriptionListResponse?.error !== null ? null : (
                                                <>
                                                    {prescriptionList.data?.length !== 0 && (
                                                        <>
                                                            {prescriptionList.pagination?.last_page !== 1 && (
                                                                <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                    <nav aria-label="Page navigation example">
                                                                        <ul className="inline-flex -space-x-px text-base h-10">
                                                                            <CustomPagination
                                                                                count={
                                                                                    prescriptionList.pagination?.last_page
                                                                                }
                                                                                page={pageNumber}
                                                                                onChange={(event, value) => {
                                                                                    handlePagination(event, value);
                                                                                }}
                                                                            />
                                                                        </ul>
                                                                    </nav>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}

                                        </div>
                                    </div>
                                ) : null}
                </div>
            </div>

            <ChatBox />
            <ViewUploadPrescriptionImage
                showModal={showModal}
                arvID={arvID}
                onClose={() =>
                    Modals.handleHideModal(setShowModal, showModal, "ViewUploadPrescriptionModal")
                }
                setShowModal={setShowModal}
                formData={formData}
                pageNumber={pageNumber}
            />


            <ReUploadPrescriptionImage
                showModal={showModal}
                arvID={imageLogID}
                onClose={() =>
                    Modals.handleHideModal(setShowModal, showModal, "ReUploadPrescriptionModal")
                }
                setShowModal={setShowModal}
                formData={formData}
                pageNumber={pageNumber}
                setImgSrcs=""
            />

            {/* {showToast.showToast && <SuccessToastMessage status={'Updated Successfully'} />} */}

        </>
    );
}

export default UploadPrescription;