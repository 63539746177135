import React, { useState, useEffect } from 'react'

import { ModalCloseIcon } from '../../assets/svg/icons'
import { handleHideModal } from '../../assets/js/custome'

import { useBlisterSelectors } from '../../services/selectors/blisterSelectors';
import { Date } from '../../components/pharmacy/form/form';
import { AddTravelRecordsData, UpdateTravelRecordsData } from '../../services/actions/pharmacy/travel/addTravelData';

import * as Hooks from '../../hooks'
import * as routeName from '../../routes/routeName'

import { currentDate } from '../../utils/constants/formInitialStates';

const initalData = {
    note: '',
    travel_date: currentDate,
    next_bill_date: '',
    return_date: '',
    travel_duration: '',
    billed_first_day: '',
    next_bill_qty: ''
}

const AddTravelRecords = ({ setShowModal, showModal, patientProfileData, setShowToast, travelLogsData,params }) => {

    const { AddBlisterPackResponse } = useBlisterSelectors()
    const dispatch = Hooks.useCustomDispatch()
    const location = Hooks.useCustomLocation()

    const updateTravelLogs = location.pathname === routeName.pharmacy_travel_logs

    const [formData, setFormData] = useState(initalData)

    const [errorMessages, setErrorMessages] = useState({
        note: '',
        travel_date: '',
        next_bill_date: '',
        return_date: '',
        travel_duration: '',
        billed_first_day: '',
        next_bill_qty: ''
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => {
            const updatedData = { ...prevFormData, [name]: value };

            if (name === 'travel_duration' || name === 'billed_first_day') {
                const travelDuration = parseInt(updatedData.travel_duration);
                const billedFirstDay = parseInt(updatedData.billed_first_day);

                if (!isNaN(travelDuration) && !isNaN(billedFirstDay) && travelDuration >= billedFirstDay) {
                    const result = travelDuration - billedFirstDay;
                    updatedData.next_bill_qty = result;
                } else {
                    updatedData.next_bill_qty = 0;
                }
            }

            if (name === 'billed_first_day' || name === 'travel_date') {
                const { travel_date, billed_first_day } = updatedData;
                if (travel_date && billed_first_day) {
                    const travelDate = new window.Date(travel_date);
                    const nextBillDate = new window.Date(travelDate);
                    nextBillDate.setDate(travelDate.getDate() + parseInt(billed_first_day));

                    updatedData.next_bill_date = nextBillDate.toLocaleDateString("en-CA", {
                        timeZone: "America/Vancouver",
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    }).replace(/\//g, "-");
                }
            }

            if (name === 'travel_duration' || name === 'travel_date') {
                const { travel_date, travel_duration } = updatedData;
                if (travel_date && travel_duration) {
                    const travelDate = new window.Date(travel_date);
                    const nextBillDate = new window.Date(travelDate);
                    nextBillDate.setDate(travelDate.getDate() + parseInt(travel_duration));

                    updatedData.return_date = nextBillDate.toLocaleDateString("en-CA", {
                        timeZone: "America/Vancouver",
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    }).replace(/\//g, "-");
                }
            }

            return updatedData;
        });
    };


    const inputFeilds = [
        { label: "TRAVEL DURATION", key: "travel_duration", error: errorMessages.travel_duration },
        { label: "BILLED FIRST DAY", key: "billed_first_day", error: errorMessages.billed_first_day },
        { label: "NEXT BILL QTY", key: "next_bill_qty", error: errorMessages.next_bill_qty }
    ]

    const handleSubmitPackDetails = () => {
        const specificValidations = {
            travel_date: 'Travel Date is required',
            travel_duration: 'Travel Duration is required',
            billed_first_day: 'Billed First Day is required',
            next_bill_date: 'Next Bill Date is required',
            next_bill_qty: 'Next Bill Qty is required',
            return_date: 'Return Date is required',
        }

        const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

        const newErrorMessages = Object.fromEntries(
            Object.entries(specificValidations).map(([field, message]) => [
                field,
                validateField(field, formData[field], message),
            ])
        );

        setErrorMessages({
            ...errorMessages,
            ...newErrorMessages,
        });

        const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

        if (allDataPresent) {

            if (updateTravelLogs && travelLogsData?.patient_id) {
                const requestData = {
                    travel_date: formData.travel_date,
                    travel_duration: formData.travel_duration,
                    billed_first_day: formData.billed_first_day,
                    next_bill_date: formData.next_bill_date,
                    next_bill_qty: formData.next_bill_qty,
                    return_date: formData.return_date,
                    note: formData.note,
                }
                dispatch(UpdateTravelRecordsData('update_travel_records', requestData, setShowModal, setShowToast,travelLogsData.id,params))
            } else {

                const requestData = {
                    patient_id: patientProfileData.id,
                    travel_date: formData.travel_date,
                    travel_duration: formData.travel_duration,
                    billed_first_day: formData.billed_first_day,
                    next_bill_date: formData.next_bill_date,
                    next_bill_qty: formData.next_bill_qty,
                    return_date: formData.return_date,
                    note: formData.note,
                }

                dispatch(AddTravelRecordsData('add_travel_record', requestData, setShowModal, setShowToast))
            }
        }
    };

    useEffect(() => {
        if (travelLogsData && updateTravelLogs) {
            setFormData({
                ...formData,
                travel_date: travelLogsData?.travel_date,
                travel_duration: travelLogsData?.travel_duration,
                billed_first_day: travelLogsData?.billed_first_day,
                next_bill_qty: travelLogsData?.next_bill_qty,
                next_bill_date: travelLogsData?.next_bill_date,
                return_date: travelLogsData?.return_date,
                note: travelLogsData?.note,
            })
        }
    }, [travelLogsData])

    return (
        <React.Fragment>
            <div className="overflow-y-auto overflow-x-hidden fixed top-0 left-0 right-0 bottom-0 z-50 flex  items-center 
							justify-center w-full bg-black bg-opacity-50 dark:bg-opacity-100 max-h-full">
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                {updateTravelLogs ? `Update Travel Record For - ${travelLogsData?.patient.name}` : `Add Travel Record For - ${patientProfileData?.name}`}
                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'addBlisterPackModal')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="pl-2 pb-2 pr-2 md:p-5">
                            <form>
                                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 mb-2">
                                    <div className='relative'>
                                        <Date formData={formData}
                                            setFormData={setFormData}
                                            handleFormDataChange={handleInputChange}
                                            label='TRAVEL DATE'
                                            name='travel_date'
                                            dValue={formData.travel_date}
                                        />

                                        {errorMessages.travel_date && (
                                            <p className="text-xs text-red-600 dark:text-red-400">
                                                {errorMessages.travel_date}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
                                    {inputFeilds?.map((field, idx) => (
                                        <div className="relative mb-2" key={idx}>
                                            <input
                                                type="text"
                                                id={field.key}
                                                name={field.key}
                                                value={formData[field.key]}
                                                onChange={handleInputChange}
                                                className="block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white"
                                                placeholder=""
                                                autoComplete='false'
                                                onKeyPress={(e) => {
                                                    if (isNaN(String.fromCharCode(e.charCode))) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                            <label
                                                htmlFor={field.key}
                                                className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                                {field.error ? '' : field.label}
                                            </label>

                                            {field.error && (
                                                <p className="text-xs text-red-600 dark:text-red-400">
                                                    {field.error}
                                                </p>
                                            )}

                                        </div>
                                    ))}
                                </div>

                                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 mb-2">
                                    <div className='relative'>
                                        <Date formData={formData} setFormData={setFormData} label='NEXT BILL DATE' name='next_bill_date' dValue={formData.next_bill_date} />
                                        {errorMessages.next_bill_date && (
                                            <p className="text-xs text-red-600 dark:text-red-400">
                                                {errorMessages.next_bill_date}
                                            </p>
                                        )}
                                    </div>
                                    <div className='relative'>
                                        <Date formData={formData} setFormData={setFormData} label='RETURN DATE' name='return_date' dValue={formData.return_date} />
                                        {errorMessages.return_date && (
                                            <p className="text-xs text-red-600 dark:text-red-400">
                                                {errorMessages.return_date}
                                            </p>
                                        )}

                                    </div>
                                </div>

                                <div className="sm:mb-4 md:mb-6 lg:mb-6 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6">

                                    <div className="relative" >
                                        <textarea
                                            type="text"
                                            id={"note"}
                                            name={"note"}
                                            value={formData.note}
                                            onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                                            className="block pl-4 px-2.5 pb-2.5 pt w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white"
                                            placeholder=""
                                        />
                                        <label
                                            htmlFor={'note'}
                                            className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                        >
                                            NOTE
                                        </label>
                                    </div>

                                </div>


                                <div className="z-30 flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 sticky bottom-0 bg-light">
                                    <div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-7 mx-auto">
                                        <div className="col-span-12">
                                            <button type="button" onClick={handleSubmitPackDetails}
                                                className={`rubik-500 flex justify-center items-center text-white blueBg-color   w-full text-sm px-5 py-2.5 text-center`}>
                                                {AddBlisterPackResponse?.loading ? 'Processing...' : updateTravelLogs ? 'Update' : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default AddTravelRecords