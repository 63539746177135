import React, { } from 'react'

import { currentDate } from '../../../utils/constants/formInitialStates'
import { fetchFentanylPatchesList } from '../../../services/actions/pharmacy/dispense/labelList'

import { useDispenseSelectors } from '../../../services/selectors/dispenseSelectors'
import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'

import { BlisterDropDown } from '../../../components/pharmacy/form/form'

import { pharmacyPreviewInvoicePdf } from '../../../services/actions/pharmacy/forms/previewPdf'
import { useFormsSelectors } from '../../../services/selectors/formsSelector'
import { SendFaxLog } from '../../../models/dispense/returnPatchesSendFax'

import * as Common from '../../../components/common'
import * as Dispense from '../../../components/pharmacy/dispense/dispense'
import * as Hooks from '../../../hooks'
import * as path from '../../../networking/urlEndPoints'
// import * as images from '../../../assets/images'

import ChatBox from '../../../components/chat/chatbox'

const returnPatchesHeadingList = [
    { text: "ROUTE", classNames: ['p-3'] },
    { text: "PATIENT", classNames: ['p-3'] },
    { text: "RX", classNames: ['p-3'] },
    { text: "DRUG", classNames: ['p-3'] },
    { text: "DRUG SIG", classNames: ['p-3'] },
    { text: "STATUS", classNames: ['p-3'] },
    { text: "", classNames: ['p-3'] },
];

const TableRowData = ({ value, className1 = 'p-3', className2 = 'rubik-400 fs-14' }) => {
    return (<td scope="row" className={className1}>
        <p className={className2}>{value}</p>
    </td>)
}

const ReturnedFentanylPatches = () => {

    const location = Hooks.useCustomLocation()
    const dispatch = Hooks.useCustomDispatch()
    const navigate = Hooks.useCustomNavigate()

    const [formData, setFormData] = React.useState({ patchstatus: 'all', search: '', date: currentDate });

    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;
    const search = new URLSearchParams(location.search).get('search')
    const searchDate = new URLSearchParams(location.search).get('date') || formData.date

    const [pageNumber, setPageNumber] = React.useState(initialPage)
    const [patchID, setPatchID] = React.useState(null)

    const { dispenseLabelListsResponse } = useDispenseSelectors()
    const { previewPdfResponse } = useFormsSelectors()

    const returnPatchesList = dispenseLabelListsResponse?.data?.data

    const patchstatus = formData.patchstatus === 'Is Returned' ? 'default' : formData.patchstatus === 'Yes' ? 'yes' : formData.patchstatus === 'No' ? 'no' : 'default'
    const filterList = ['Is Returned', 'Yes', 'No']

    React.useEffect(() => {
        setPageNumber(initialPage)
        setFormData({ ...formData, date: searchDate })
    }, [initialPage, searchDate])

    React.useEffect(() => {
        const params = {
            search_date: searchDate,
            search: search,
            page: pageNumber,
        }
        dispatch(fetchFentanylPatchesList(params, 'return-fentanyl-patches-list'))
        return () => { }
    }, [])

    const handleFilterData = () => {
        const params = {
            search_date: formData?.date,
            search: formData.search,
            patchstatus: patchstatus
        }
        const searchParams = new URLSearchParams()
        searchParams.append('date', formData?.date)
        if (formData?.search) searchParams.append('search', formData?.search)

        dispatch(fetchFentanylPatchesList(params, 'return-fentanyl-patches-list'))
        const newUrl = `${location.pathname}?${searchParams.toString()}`
        navigate(newUrl);
    }

    const handlePagination = (event, value) => {
        const searchParams = new URLSearchParams()
        if (value !== undefined) {
            const params = {
                search_date: formData?.date,
                page: value,
                patchstatus: patchstatus
            }
            searchParams.append('page', value);
            setPageNumber(value)
            dispatch(fetchFentanylPatchesList(params, 'return-fentanyl-patches-list'))
            const newUrl = `${location.pathname}?${searchParams.toString()}`
            navigate(newUrl);
        }
    }

    const handleReset = () => {
        const searchParams = new URLSearchParams();
        searchParams.delete('page');
        searchParams.delete('search');
        searchParams.delete('date');
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);
        setFormData({ ...formData, search: '', patchstatus: 'all', date: currentDate })
        const params = {
            search_date: currentDate,
            page: 1,
            patchstatus: 'all'
        }
        dispatch(fetchFentanylPatchesList(params, 'return-fentanyl-patches-list'))
    }

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        {/* <Common.Sidebar /> */}
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
													sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-4">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

                                        <div className="flex flex-wrap lg:flex-nowrap gap-3">
                                            <div className='flex-0 lg:w-3/12 md:w-4/12 sm:w-6/12 w-full'>
                                                <Dispense.TextInput label='Search' name='search' formData={formData}
                                                    setFormData={setFormData} />
                                            </div>

                                            <div className='flex-0 lg:w-3/12 md:w-4/12 sm:w-6/12 w-full'>
                                                <Dispense.StartDate formData={formData}
                                                    setFormData={setFormData} />
                                            </div>

                                            <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 w-full'>
                                                <BlisterDropDown formData={formData}
                                                    setFormData={setFormData}
                                                    name="patchstatus"
                                                    value={formData.patchstatus}
                                                    filterList={filterList}
                                                    placeholder='All' />
                                            </div>

                                            <div className='flex-0 lg:w-1/12 md:w-2/12 sm:w-4/12 w-full'>
                                                <button style={{ backgroundColor: '#3282F6' }} type="button" onClick={handleFilterData}
                                                    className="flex text-white ml-auto w-full h-full pl-6 py-4 items-center justify-center border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 
														dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                                    Filter
                                                </button>
                                            </div>

                                            <div className='flex-0 lg:w-1/12 md:w-4/12 sm:w-6/12 w-full'>
                                                <button type="button" style={{ backgroundColor: 'White' }} onClick={handleReset}
                                                    className="flex  w-full h-full items-center justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 
                                                    py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                                    Reset
                                                </button>
                                            </div>
                                        </div>

                                        {dispenseLabelListsResponse?.loading && dispenseLabelListsResponse?.type === 'return-fentanyl-patches-list' ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            dispenseLabelListsResponse?.error !== null && dispenseLabelListsResponse?.type === 'return-fentanyl-patches-list' ?
                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4  bg-red-600 text-white rounded hover:bg-red-700 
																	focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div> :
                                                returnPatchesList?.data?.length === 0 ?
                                                    <div className="bg-white pt-4 rounded-md">
                                                        <p className="text-gray-500">No Record Available.</p>
                                                    </div>
                                                    :
                                                    dispenseLabelListsResponse?.type === 'return-fentanyl-patches-list' ?
                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                            <div className="shadow-md border rounded-lg relative overflow-x-auto">
                                                                <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                    <thead className="text-xs text-gray-900 uppercase dark:bg-gray-700 dark:text-gray-400" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {returnPatchesHeadingList?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-12">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            returnPatchesList?.data?.map((value, idx) => (
                                                                                <React.Fragment key={idx}>
                                                                                    <tr style={{ borderBottom: `${'1px solid lightgray'}` }}>
                                                                                        <TableRowData value={value?.route} />
                                                                                        <TableRowData value={value?.patient} />
                                                                                        <TableRowData value={value?.rxnumber} />
                                                                                        <TableRowData value={value?.drug} className1='p-3 w-56' />
                                                                                        <TableRowData value={value.sign} className1='p-3 w-96' />
                                                                                        <TableRowData value={value?.status} />

                                                                                        {value.status === 'No' &&
                                                                                            <td scope="row" className={''}>
                                                                                                <div className='flex gap-2'>
                                                                                                    <p onClick={() => { return setPatchID(value.id), dispatch(pharmacyPreviewInvoicePdf(`${path.returned_fentanyl_pdf}/${value.id}`)) }}
                                                                                                        className={'bg-red-500 btnnn text-white p-1 fs-12 rounded-md'}>
                                                                                                        {previewPdfResponse?.loading && patchID === value.id ? 'loading...' : 'View PDF'}
                                                                                                    </p>
                                                                                                    <p className={'btnnn bg-green-500 text-white p-1 fs-12 rounded-md'} onClick={() => SendFaxLog(value.id, `Dr. ${value.doctor_first_name} ${value.doctor_last_name} at fax number ${value.drfax}`, dispatch)}>Send Fax</p>
                                                                                                </div>
                                                                                            </td>
                                                                                        }
                                                                                    </tr>
                                                                                </React.Fragment>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>

                                                                {dispenseLabelListsResponse?.error !== null ? null : <>
                                                                    {returnPatchesList?.data?.length !== 0 && <>
                                                                        {returnPatchesList?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center 
																			md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={returnPatchesList?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={(event, value) => {
                                                                                                handlePagination(event, value)
                                                                                            }}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}

                                                            </div>
                                                        </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />

        </>

    )
}

export default ReturnedFentanylPatches