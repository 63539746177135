import React, { useEffect, useState } from 'react'
import { Sidebar } from '../../../components/common'

import { useCustomDispatch, useCustomLocation, useCustomNavigate } from '../../../hooks'
import { LoadingView } from '../../../components/common'

import { authToken } from '../../../storage/authToken'
import { ErrorMessages } from '../../../components/error/alertMessages'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'
import { useCustomLoadingStates } from '../../../hooks/customStates'
import { pharmacy_assign_certificate_employee, pharmacy_create_certificate } from "../../../routes/routeName";
import { assign_certificate_pdf } from '../../../networking/urlEndPoints'
import { fetchCertificateTemplates, fethEmployeeAssignedCertificateList } from '../../../services/actions/admin/team/fetchTeamsList'
import { useAdminSelectors } from '../../../services/selectors/adminModuleSelectors'
import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'
import { Loader } from "../../../components/common/loadingView";

import * as Common from '../../../components/common'
import ChatBox from '../../../components/chat/chatbox'
import { previewAndDownloadCertificate } from '../../../services/actions/pharmacy/forms/previewPdf'

export const employeeListTableHeading = [
    { text: "EMPLOYEE", classNames: ['p-3'] },
    { text: "Certificate Title", classNames: ['p-3 pl-96'] },
    { text: "ACTION", classNames: ['p-3 '] },
];

export const TableRowData = ({ value, className1 = 'p-3' }) => {
    return (<td scope="row" className={className1}>
        <p>{value}</p>
    </td>)
}

const EmployeCertificateTemplates = () => {

    const token = authToken()

    const dispatch = useCustomDispatch()
    const location = useCustomLocation()
    const navigate = useCustomNavigate()
    const startDateRef = React.useRef()
    const endDateRef = React.useRef()
    const [loadingStates, setLoadingStates] = useState({});
    const { TeamListResponse } = useAdminSelectors()
    const { adminModule } = useCustomSelectors()

    const certificateTemplatesRequestList = TeamListResponse?.data?.data

    const { apiCallMade, setApiCallMade } = useCustomLoadingStates()

    const searchParams = new URLSearchParams();
    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)


    useEffect(() => {
        if (!apiCallMade && token && adminModule === 1) {
            const params = {
                page: pageNumber,
                no_of_records: 10,
            }
            dispatch(fethEmployeeAssignedCertificateList('fetch_employee_certificate_templates', params))
            setApiCallMade(true)
        }
    }, [])


    const handleSaveInvoicePdf = async (id) => {
        setLoadingStates((prevState) => ({ ...prevState, [id]: true }));
        let path = `${assign_certificate_pdf}?certificate_id=${id}`;
        await dispatch(previewAndDownloadCertificate(path));
        setLoadingStates((prevState) => ({ ...prevState, [id]: false }));
    }

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
            no_of_records: 10,
        };

        if (value) {
            setPageNumber(value);
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl);
        }

        dispatch(fethEmployeeAssignedCertificateList('fetch_employee_certificate_templates', params))
    }




    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content" id="allRequest">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="w-full mb-5">
                                            <div className="ml-auto">
                                                <button style={{ width: "180px" }} onClick={() => navigate(pharmacy_assign_certificate_employee)} type="button" className="rubik-500 ml-auto rounded-md flex fs-14  h-8 text-white blueBg-color  focus:ring-4 
 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-1.5 text-cen3ter
 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 justify-center items-center">
                                                    <span className="flex items-center">Assign Certificate</span>
                                                </button>
                                            </div>
                                        </div>

                                        {
                                            TeamListResponse?.loading && TeamListResponse?.type === 'fetch_employee_certificate_templates' ?
                                                <div className="flex justify-center items-center mt-60 mb-80">
                                                    <LoadingView />
                                                </div>
                                                :

                                                TeamListResponse?.error !== null && TeamListResponse?.type === 'fetch_employee_certificate_templates' ?
                                                    <ErrorMessages />


                                                    : certificateTemplatesRequestList?.data?.length === 0 && TeamListResponse?.type === 'fetch_employee_certificate_templates' ?
                                                        <p className='text-gray-500'>No Record Found.</p>

                                                        : TeamListResponse?.type === 'fetch_employee_certificate_templates' ?

                                                            <div className="relative overflow-x-auto border rounded-lg">
                                                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 whitespace-nowrap">
                                                                    <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {employeeListTableHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            certificateTemplatesRequestList?.data?.map((value, index) => {

                                                                                return (<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                                                                                    <TableRowData value={value?.employee} />

                                                                                    <TableRowData value={value?.certificate_title} className1='pl-96' />


                                                                                    <td scope="row" className="p-3  flex gap-0 pl-3 items-center r" >  <button
                                                                                        style={{ height: "20px", width: '110px' }}
                                                                                        type="button"
                                                                                        onClick={() => handleSaveInvoicePdf(value.id)}
                                                                                        className="p-2 sm:text-center md:text-start 
												 			                                lg:text-start rubik-400 fs-10 text-white rounded-md text-sm px-1 bg-red-500 py-1 flex justify-center items-center"
                                                                                    >
                                                                                        {loadingStates[value.id] ? <Loader /> : 'Download Certificate'}
                                                                                    </button></td>

                                                                                </tr>)
                                                                            })}
                                                                    </tbody>
                                                                </table>

                                                                {TeamListResponse?.error !== null ? null : <>
                                                                    {certificateTemplatesRequestList?.data?.length !== 0 && <>
                                                                        {certificateTemplatesRequestList?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={certificateTemplatesRequestList?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={handlePagination}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}
                                                            </div>
                                                            : null
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ChatBox />
        </>
    )
}

export default EmployeCertificateTemplates