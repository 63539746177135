import { updateNotificationsClinics } from "../../services/actions/patients/pharmacyUpdateNotificationClinicForm";

import * as path from '../../networking/urlEndPoints';
import * as forms from '../../services/actions/pharmacy/forms';
import { pharmacyDefaultSetting, pharmacyDeliverySetting } from "../../services/actions/admin/team/addTeamData";

const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

const commonFormsValidations = {
	phn: 'PHN is required',
	dob: 'DOB is required',
	pharmacyManager: 'Pharmacy Manager is required',
};

export const handlePharmacyRxTransferForm = (
	formData,
	setErrorMessages,
	errorMessages,
	dispatch,
	type,
	showToast,
	setShowToast
) => {

	const specificValidations = {
		name: formData.fetchType === 'winrx' ? 'Name is required' : '',
		patientName: formData.fetchType === 'local' ? 'Patient is required' : '',
		toPharmacy: 'Pharmacy is required',
		toPharmacyPhone: 'Pharmacy Phone is required',
		toPharmacyFax: 'Pharmacy Fax is required',
		requested_Prescription: 'Requested Prescription is required',
	};

	const allValidations = { ...commonFormsValidations, ...specificValidations };

	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	if (allDataPresent && type === 'PDF') {
		dispatch(forms.pharmacyViewRxTransferPdf(formData));
	}

	if (allDataPresent && type === 'FAX') {
		const requestedData = {
			date: formData.date,
			date_of_transfer: formData.transferDate,
			pharmacyname: formData.pharmacyName,
			pharmacymanager: formData.pharmacyManager,
			pharmacyuserdesignation: formData.desgination,
			requestedprescription: formData.requested_Prescription,
			topharmacy: formData.toPharmacy.value,
			topharmacyphone: formData.toPharmacyPhone,
			topharmacyfaxnumber: formData.toPharmacyFax,
		}
		if (formData.fetchType === 'winrx') {
			requestedData.fetchtype = formData.fetchType
			requestedData.winrxpatientphn = formData.phn
			requestedData.winrxpatientname = formData.name
			requestedData.winrxpatientdob = formData.dob
		}

		if (formData.fetchType === 'local') {
			requestedData.fetchtype = formData.fetchType
			requestedData.patient = formData.patientName.value
			requestedData.patientphn = formData.phn
			requestedData.patientdob = formData.dob
		}
		dispatch(forms.pharmacySendFax(path.pharmacy_rx_transfer_fax_form, requestedData, showToast, setShowToast))
	}
};


export const handlePharmacyInformationUpdateForm = (formData, setErrorMessages, errorMessages, dispatch, type, showToast, setShowToast) => {
	const specificValidations = {
		name: formData.fetchType === 'winrx' ? 'Name is required' : '',
		patientName: formData.fetchType === 'local' ? 'Patient is required' : '',
		clinicName: 'Clinic is required',
		clinicFaxNumber: 'Clinic Fax is required',
		pharmacyFax: 'Pharmacy Fax is required',
		pharmacyPhone: 'Pharmacy Phone is required',
		pharmacyEmail: 'Pharmacy Email is required',
		doctorName: 'Doctor Name is required'
	}
	const allValidations = { ...commonFormsValidations, ...specificValidations };
	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);
	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});
	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');
	if (allDataPresent && type === 'PDF') {
		dispatch(updateNotificationsClinics(formData))
	}
	if (allDataPresent && type === 'FAX') {
		dispatch(forms.pharmacyUpdateNoficationSRFaxForm(formData, showToast, setShowToast))
	}
}

export const handleDrNotificationForEmergencySupplyForm = (
	formData,
	setErrorMessages,
	errorMessages,
	dispatch,
	type,
	selectComponents,
	showToast,
	setShowToast) => {
	const specificValidations = {
		name: formData.fetchType === 'winrx' ? 'Name is required' : '',
		patientName: formData.fetchType === 'local' ? 'Patient is required' : '',
		clinicName: 'Clinic is required',
		clinicFaxNumber: 'Clinic Fax is required',
		doctorName: 'Doctor Name is required'
	}
	const allValidations = { ...commonFormsValidations, ...specificValidations }

	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	)

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	})

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

	if (allDataPresent && selectComponents) {
		const medicationData = selectComponents?.map((value) => ({
			medicine_name: value.selectedOptions ? value.selectedOptions.label : null,
			medicine_other_name: value.inputValue ? value.inputValue : null
		}))

		const requestedData = {
			date: formData.date,
			end_date: formData.transferDate,
			pharmacyname: formData.pharmacyName,
			pharmacymanager: formData.pharmacyManager,
			pharmacyuserdesignation: formData.desgination,
			clinic: formData.clinicName.value,
			doctor: formData.doctorName.value,
			clinicfaxnumber: formData.clinicFaxNumber,
			medication: medicationData
		}
		if (formData.fetchType === 'winrx') {
			requestedData.fetchtype = formData.fetchType
			requestedData.winrxpatientphn = formData.phn
			requestedData.winrxpatientname = formData.name
			requestedData.winrxpatientdob = formData.dob
		}

		if (formData.fetchType === 'local') {
			requestedData.fetchtype = formData.fetchType
			requestedData.patient = formData.patientName.value
			requestedData.patientphn = formData.phn
			requestedData.patientdob = formData.dob
		}

		if (type === 'PDF') {
			dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_dr_notification_emergency_supply, requestedData))
		}
		if (type === 'FAX') {
			dispatch(forms.pharmacySendFax(path.pharmacy_dr_notification_emergency_fax_form, requestedData, showToast, setShowToast))
		}
	}

}


export const handleAntiViralRequestForm = (
	formData,
	setErrorMessages,
	errorMessages,
	dispatch,
	type,
	showToast,
	setShowToast) => {
	const specificValidations = {
		name: formData.fetchType === 'winrx' ? 'Name is required' : '',
		patientName: formData.fetchType === 'local' ? 'Patient is required' : '',
		clinicName: 'Clinic is required',
		clinicFaxNumber: 'Clinic Fax is required',
		doctorName: 'Doctor Name is required',
		medicationLeftFor: 'Medication left for is required',
		medicationName: 'Medication name is required',
		numberOfDaysSupplyRequested: 'Days are required'
	}
	const allValidations = { ...commonFormsValidations, ...specificValidations }

	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	)

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	})

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

	if (allDataPresent) {

		const requestedData = {
			date: formData.date,
			pharmacyname: formData.pharmacyName,
			pharmacymanager: formData.pharmacyManager,
			pharmacyuserdesignation: formData.desgination,
			clinic: formData.clinicName.value,
			doctor: formData.doctorName.value,
			clinicfaxnumber: formData.clinicFaxNumber,
			medicationname: formData.medicationName,
			numberofdayssupplyrequested: formData.numberOfDaysSupplyRequested,
			numberofdays: formData.medicationLeftFor
		}
		if (formData.fetchType === 'winrx') {
			requestedData.fetchtype = formData.fetchType
			requestedData.winrxpatientphn = formData.phn
			requestedData.winrxpatientname = formData.name
			requestedData.winrxpatientdob = formData.dob
		}

		if (formData.fetchType === 'local') {
			requestedData.fetchtype = formData.fetchType
			requestedData.patient = formData.patientName.value
			requestedData.patientphn = formData.phn
			requestedData.patientdob = formData.dob
		}

		if (type === 'PDF') {
			dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_anti_viral_request_form, requestedData))
		}
		if (type === 'FAX') {
			dispatch(forms.pharmacySendFax(path.pharmacy_anti_viral_srfax_form, requestedData, showToast, setShowToast))
		}
	}
}

export const handleMissingRxRequirementsForm = (
	formData,
	setErrorMessages,
	errorMessages,
	dispatch,
	mixingRxFormCheckboxValues,
	type,
	showToast,
	setShowToast) => {
	const specificValidations = {
		name: formData.fetchType === 'winrx' ? 'Name is required' : '',
		patientName: formData.fetchType === 'local' ? 'Patient is required' : '',
		toPharmacy: 'Pharmacy is required',
		toPharmacyPhone: 'Pharmacy Phone is required',
		toPharmacyFax: 'Pharmacy Fax is required',
		toPharmacyAddress: 'Address is required',
		toPharmacyManager: 'Manager is required'
	}
	const allValidations = { ...commonFormsValidations, ...specificValidations }

	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	)

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	})

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

	if (allDataPresent) {

		const requestedData = {
			date: formData.date,
			pharmacyaddress: formData.toPharmacyAddress,
			topharmacyfaxnumber: formData.toPharmacyFax,
			topharmacyphone: formData.toPharmacyPhone,
			topharmacy: formData.toPharmacy.value,
			pharmacymanager: formData.toPharmacyManager
		}
		if (formData.fetchType === 'winrx') {
			requestedData.fetchtype = formData.fetchType
			requestedData.winrxpatientphn = formData.phn
			requestedData.winrxpatientname = formData.name
			requestedData.winrxpatientdob = formData.dob
		}

		if (formData.fetchType === 'local') {
			requestedData.fetchtype = formData.fetchType
			requestedData.patient = formData.patientName.value
			requestedData.patientphn = formData.phn
			requestedData.patientdob = formData.dob
		}

		if (mixingRxFormCheckboxValues) {
			const checkboxKeys = [
				'Date',
				'dose_interval',
				'drug_name_strength',
				'frequency',
				'interval_between_refills',
				'max_daily_dose',
				'patient_name',
				'printedname',
				'quantity',
				'quantity_to_dispense',
				'uniquesignature',
			];

			checkboxKeys.forEach((key) => {
				if (mixingRxFormCheckboxValues[key]) {
					requestedData[key] = key;
				}
			});
		}

		if (type === 'PDF') {
			dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_missing_rx_form, requestedData))
		}
		if (type === 'FAX') {
			dispatch(forms.pharmacySendFax(path.pharmacy_missing_rx_srfax_form, requestedData, showToast, setShowToast))
		}
	}
}

export const handleEmergencySupplyForContinuityofCareForm = (
	formData,
	setErrorMessages,
	errorMessages,
	dispatch,
	type,) => {
	const specificValidations = {
		patientName: 'Patient name is required',
	}
	const newErrorMessages = Object.fromEntries(
		Object.entries(specificValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message)]))

	setErrorMessages({ ...errorMessages, ...newErrorMessages })

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '')

	if (allDataPresent) {
	
		const requestedData = {
			...(formData.add_other ? { add_other: 1 } : {}),
			nn_code: formData.nn_code,
			left_with: formData.left_with,
			refill_with: formData.refill_with,
			dosage_for: formData.dosage_for,
			dosage_interval: formData.dosage_interval,
			patient: formData.patientName.value,
			other: formData.textForFaxValue,

			rational: Object.entries(formData)
				.filter(([key, value]) => key.startsWith('rational') && value !== false)
				.map(([key]) => {
					const splitKey = key.split('[');
					return parseInt(splitKey?.length > 1 ? splitKey[1].split(']')[0] : null);
				}),
			followup: Object.entries(formData)
				.filter(([key, value]) => key.startsWith('followup') && value !== false)
				.map(([key]) => {
					const splitKey = key.split('[');
					return parseInt(splitKey?.length > 1 ? splitKey[1].split(']')[0] : null);
				})
		};

		if (type === 'PDF') {
			dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_emergency_supply_continuity_form, requestedData))
		}
	}
}

export const handlePharmacy_PlanG_PlanZ_Form = (formData, setErrorMessages, errorMessages, dispatch, type, formType, showToast, setShowToast) => {
	const specificValidations = {
		name: formData.fetchType === 'winrx' ? 'Name is required' : '',
		patientName: formData.fetchType === 'local' ? 'Patient is required' : '',
		clinicName: 'Clinic is required',
		clinicFaxNumber: 'Clinic Fax is required',
		doctorName: 'Doctor Name is required',
		phn: 'PHN is required',
		dob: 'DOB is required',
	};
	const allValidations = { ...specificValidations };
	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);
	setErrorMessages({ ...errorMessages, ...newErrorMessages, });
	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	const requestedData = {
		date: formData.date,
		clinic: formData.clinicName.value,
		doctor: formData.doctorName.value,
		clinicfaxnumber: formData.clinicFaxNumber,
	};

	if (formData.fetchType === 'winrx') {
		Object.assign(requestedData, {
			fetchtype: formData.fetchType,
			winrxpatientphn: formData.phn,
			winrxpatientname: formData.name,
			winrxpatientdob: formData.dob,
			winrxpatientphone: formData.winrxPatientPhone,
			winrxpatientaddress: formData.winrxPatientAddress,
			winrxpatientcity: formData.winrxPatientCity,
			winrxpatientprovince: formData.winrxPatientProvince,
			winrxpatientpostcode: formData.winrxPatientPostcode,
		});
	}

	if (formData.fetchType === 'local') {
		Object.assign(requestedData, {
			fetchtype: formData.fetchType,
			patient: formData.patientName.value,
			patientphn: formData.phn,
			patientdob: formData.dob,
		});
	}

	if (allDataPresent) {
		Object.assign(requestedData, {
			profession: formData.planGFormProfession,
			rational: formData.planGFormRational,
		});
		if (type === 'PDF' && formType === 'PLAN-G-FORM') {
			dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_plan_g_form, requestedData));
		}
		if (type === 'FAX' && formType === 'PLAN-G-FORM') {
			dispatch(forms.pharmacySendFax(path.pharmacy_plan_g_srfax_form, requestedData, showToast, setShowToast))
		}
	}

	if (allDataPresent) {
		Object.assign(requestedData, {
			applicable_option: formData.planZFormApplicationOption,
			rational: formData.planZFormRational,
		});
		if (type === 'PDF' && formType === 'PLAN-Z-FORM') {
			dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_plan_z_form, requestedData));
		}
		if (type === 'FAX' && formType === 'PLAN-Z-FORM') {
			dispatch(forms.pharmacySendFax(path.pharmacy_plan_z_srfax_form, requestedData, showToast, setShowToast));
		}
	}

}

export const handleDoctorCommunicationForm = (formData, setErrorMessages,
	errorMessages,
	dispatch, type, showToast,
	setShowToast) => {
	const specificValidations = {
		subjectOfFax: formData.subjectOfFax ? '' : 'Subject of fax is required',
		pharmacyManager: formData.pharmacyManager ? '' : 'Pharmacy Manager is required'
	}

	if (formData.doctorCommnicationForm === 'With Patient') {
		if (formData.fetchType === 'winrx') {
			specificValidations.phn = formData.phn ? '' : 'PHN is required';
			specificValidations.name = formData.name ? '' : 'Name is required';
			specificValidations.dob = formData.dob ? '' : 'DOB is required';
		} else if (formData.fetchType === 'local') {
			specificValidations.phn = formData.phn ? '' : 'PHN is required';
			specificValidations.patientName = formData.patientName ? '' : 'Name is required';
			specificValidations.dob = formData.dob ? '' : 'Dob is required';
		}
	}

	if (formData.doctorCommnicationFormType === 'clinic') {
		specificValidations.clinicName = formData.clinicName ? '' : 'Clinic is required';
		specificValidations.doctorName = formData.doctorName ? '' : 'Doctor is required';
		specificValidations.clinicFaxNumber = formData.clinicFaxNumber ? '' : 'Clinic Fax is required';
	}

	if (formData.doctorCommnicationFormType === 'pharmacy') {
		specificValidations.toPharmacy = formData.toPharmacy ? '' : 'Pharmacy is required';
		specificValidations.doctorName = formData.doctorName ? '' : 'Doctor is required';
		specificValidations.toPharmacyFax = formData.toPharmacyFax ? '' : 'Pharmacy Fax is required';
	}

	if (formData.doctorCommnicationFormType === 'custom') {
		specificValidations.customClinicName = formData.customClinicName ? '' : 'Clinic is required';
		specificValidations.customDoctor = formData.customDoctor ? '' : 'Doctor is required';
		specificValidations.customToFaxNumber = formData.customToFaxNumber ? '' : 'Fax is required';
	}

	const allValidations = { ...specificValidations }
	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);
	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});
	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	const requestedData = {
		date: formData.date,
		fetchclinic: formData.doctorCommnicationFormType,
		fax_subject: formData.subjectOfFax,
		fax_text: formData.textForFaxValue,
		pharmacyname: formData.pharmacyName,
		pharmacymanager: formData.pharmacyManager,
		pharmacyuserdesignation: formData.desgination
	}

	if (formData.fetchType === 'winrx' && formData.doctorCommnicationForm === 'With Patient') {
		Object.assign(requestedData, {
			fetchtype: formData.fetchType,
			winrxpatientphn: formData.phn,
			winrxpatientname: formData.name,
			winrxpatientdob: formData.dob,
		})
	}

	if (formData.fetchType === 'local' && formData.doctorCommnicationForm === 'With Patient') {
		Object.assign(requestedData, {
			fetchtype: formData.fetchType,
			patient: formData.patientName.value,
			patientphn: formData.phn,
			patientdob: formData.dob,
		})
	}

	if (formData.doctorCommnicationFormType === 'clinic') {
		Object.assign(requestedData, {
			clinic: formData.clinicName.value,
			doctor: formData.doctorName.value,
			clinicfaxnumber: formData.clinicFaxNumber,
		})
	}

	if (formData.doctorCommnicationFormType === 'pharmacy') {
		Object.assign(requestedData, {
			topharmacy: formData.toPharmacy.value,
			doctor: formData.doctorName.value,
			topharmacyfaxnumber: formData.toPharmacyFax,
		})
	}

	if (formData.doctorCommnicationFormType === 'custom') {
		Object.assign(requestedData, {
			custom_doctor: formData.customDoctor,
			custom_name: formData.customClinicName,
			tofaxnumber: formData.customToFaxNumber,
		})
	}

	if (allDataPresent && formData.doctorCommnicationForm === 'With Patient' && type === 'PDF') {
		dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_doctor_communication_letter_head, requestedData));
	}

	if (allDataPresent && formData.doctorCommnicationForm === 'Without Patient' && type === 'PDF') {
		dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_doctor_comm_withoutout_form, requestedData));
	}

	if (allDataPresent && formData.doctorCommnicationForm === 'With Patient' && type === 'FAX') {
		dispatch(forms.pharmacySendFax(path.pharmacy_doctor_communication_srfax, requestedData, showToast, setShowToast));
	}

	if (allDataPresent && formData.doctorCommnicationForm === 'Without Patient' && type === 'FAX') {
		dispatch(forms.pharmacySendFax(path.pharmacy_doctor_comm_withoutout_srfax, requestedData, showToast, setShowToast))
	}
}

export const handlePharmacistCommunicationForm = (formData, setErrorMessages,
	errorMessages,
	dispatch, type, showToast,
	setShowToast, canvaValue) => {

	const specificValidations = {
		name: formData.fetchType === 'winrx' ? 'Name is required' : '',
		patientName: formData.fetchType === 'local' ? 'Patient is required' : '',
		dob: 'DOB is required',
		phn: 'PHN is required',
		clinicName: 'Clinic is required',
		doctorName: 'Doctor is required',
		clinicFaxNumber: 'Clinic Fax is required',
		pharmacyManager: 'Pharmacy Manager is required',
		pharmacyFax: 'Pharmacy Fax is required',
		prescriptionFolio: 'Folio is required',
		prescriptionCPID: 'CPID is required',
		descriptionAuthorized: 'Description is required'
	}

	const allValidations = { ...specificValidations }
	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);
	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	const requestedData = {
		date: formData.date,
		clinic: formData.clinicName.value,
		doctor: formData.doctorName.value,
		clinicfaxnumber: formData.clinicFaxNumber,
		returnForm: formData.returnForm,
		prescribing_date: formData.prescribingDate,
		descriptionauthorized: formData.descriptionAuthorized,
		prescriptionfolio: formData.prescriptionFolio,
		toprescribernumber: formData.prescriptionCPID,
		clipboard_image: canvaValue,
		pharmacyname: formData.pharmacyName,
		pharmacymanager: formData.pharmacyManager,
		pharmacyfax: formData.pharmacyFax,
		pharmacyuserdesignation: formData.desgination,
		patientrecords: formData.patientrecords,
		methadone_dose_date: formData.methadoneDoseDate,
		daily_dose_date: formData.dailyDoseDate,
		consumed_dose: formData.consumedDose,
		total_dose: formData.totalDose,
	}

	if (formData.prescribingDateRange === 'range_date') {
		Object.assign(requestedData, {
			despense_type: formData.prescribingDateRange,
			range_from_date: formData.prescribingStartDate,
			range_to_date: formData.prescribingEndDate,
		})
	}

	if (formData.prescribingDateRange === 'single_date') {
		Object.assign(requestedData, {
			despense_type: formData.prescribingDateRange,
			despensing_date: formData.prescribingStartDate,
		})
	}

	if (formData.fetchType === 'winrx') {
		Object.assign(requestedData, {
			fetchtype: formData.fetchType,
			winrxpatientphn: formData.phn,
			winrxpatientname: formData.name,
			winrxpatientdob: formData.dob,
		})
	}

	if (formData.fetchType === 'local') {
		Object.assign(requestedData, {
			fetchtype: formData.fetchType,
			patient: formData.patientName.value,
			patientphn: formData.phn,
			patientdob: formData.dob,
		})
	}

	if (allDataPresent && type === 'PDF') {
		dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_pharmacist_prescriber_communication_form, requestedData));
	}

	if (allDataPresent && type === 'FAX') {
		dispatch(forms.pharmacySendFax(path.pharmacy_pharmacist_prescriber_communication_srfax_form, requestedData, showToast, setShowToast))
	}
}


export const handlePharmacyRequestCopyofOriginalRxforOAT_DDForm = (
	formData,
	setErrorMessages,
	errorMessages,
	dispatch,
	type,
	showToast,
	setShowToast
) => {

	const specificValidations = {
		name: formData.fetchType === 'winrx' ? 'Name is required' : '',
		patientName: formData.fetchType === 'local' ? 'Patient is required' : '',
		toPharmacy: 'Pharmacy is required',
		toPharmacyManager: 'Pharmacy Manager is required',
		toPharmacyFax: 'Pharmacy Fax is required',
		requested_Prescription: 'Requested Prescription is required',
	};

	const allValidations = { ...commonFormsValidations, ...specificValidations };

	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	if (allDataPresent) {

		const requestedData = {
			date: formData.date,
			date_of_transfer: formData.transferDate,
			pharmacyname: formData.pharmacyName,
			pharmacymanager: formData.pharmacyManager,
			pharmacyuserdesignation: formData.desgination,
			requestedprescription: formData.requested_Prescription,
			topharmacy: formData.toPharmacy.value,
			topharmacymanager: formData.toPharmacyManager,
			topharmacyfaxnumber: formData.toPharmacyFax,
		}
		if (formData.fetchType === 'winrx') {
			requestedData.fetchtype = formData.fetchType
			requestedData.winrxpatientphn = formData.phn
			requestedData.winrxpatientname = formData.name
			requestedData.winrxpatientdob = formData.dob
		}

		if (formData.fetchType === 'local') {
			requestedData.fetchtype = formData.fetchType
			requestedData.patient = formData.patientName.value
			requestedData.patientphn = formData.phn
			requestedData.patientdob = formData.dob
		}

		if (type === 'PDF') {
			dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_request_original_rx_oat_form, requestedData));
		}
		if (type === 'FAX') {
			dispatch(forms.pharmacySendFax(path.pharmacy_request_original_fax_form, requestedData, showToast, setShowToast))
		}
	}
};

export const handleARVForm = (
	formData,
	setErrorMessages,
	errorMessages,
	dispatch,
	showToast,
	setShowToast,
	addNewDrugs,
	isValid
) => {
	const specificValidations = {
		name: formData.fetchType === "winrx" ? "Name is required" : "",
		dob: "DOB is required",
		patientName: formData.fetchType === "local" ? "Patient is required" : "",
		phn: "PHN is required",
		date: "Date is required",

	};

	let newErrorMessages = [];
	let allDataPresent = false;
	const allValidations = { ...specificValidations };
	const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

	newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		]))

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});


	const formattedDrugsData = addNewDrugs.map((obj) => {
		const drugFields = {};
		obj.addedDrugs.forEach((item) => {
			const key = item.name;
			const value = item.value;
			if (value !== "") {
				drugFields[key] = value;
			}
		});
		return drugFields;
	});

	allDataPresent = Object.values(newErrorMessages).every(
		(message) => message === ""
	);

	if (allDataPresent && isValid) {
		dispatch(
			forms.ARVAddPatientForm(
				formData,
				showToast,
				setShowToast,
				formattedDrugsData
			))
	}
};


export const handleAddDoctorNoteValidation = (formData, setErrorMessages,
	errorMessages,
	dispatch) => {

	const specificValidations = {

		pharmacy_manager: 'Pharmacy manager is required',
		desgination: 'Desgination is required',
		name: 'required'
	}

	const allValidations = { ...specificValidations }
	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);
	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});
	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	const requestedData = {
		date: formData.date,
		fax_subject: formData.subject,
		fax_text: formData.textForFaxValue,
		pharmacyname: formData.pharmacyName,
		pharmacymanager: formData.pharmacy_manager,
		pharmacyuserdesignation: formData.desgination
	}

	if (allDataPresent) {
		dispatch(forms.pharmacyPreviewFormPdf(path.pharmacy_doctor_form, requestedData));
	}
}

export const onhandleTBSkinTestFOrmValidate = (formData, errorMessages, setErrorMessages, dispatch) => {
	const specificValidations = {
		name: formData.fetchType === 'winrx' ? 'Name is required' : '',
		patientName: formData.fetchType === 'local' ? 'Patient is required' : '',
		phn: 'PHN is required',
		date: 'Date is required',
		dob: 'DOB is required',
		// test_information_date: 'Date is required',
		// site: 'Site is required',
		// lot_number: 'Lot Number is required',
		// expiration_date: 'Expiration date is required',
		// expiration_date: 'Expiration date is required',
		// test_result_date: 'Date is required',
		// induration: 'Induration is required',
		// ppd_mantoux_test_result: 'PPD (MANTOUX) TEST RESULT is required',
		// interpreted_by: 'Interpreted by is required',
		// date_of_interpretation: 'Date of Interpretation is required',
		// follow_up: 'Follow up  is required',
	}
	const allValidations = { ...specificValidations };

	const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);


	setErrorMessages({ ...errorMessages, ...newErrorMessages, });

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	const requestedData = {
		date: formData.date,
		test_information_date: formData.test_information_date,
		site: formData.site,
		lot_number: formData.lot_number,
		expiration_date: formData.expiration_date,
		administered_by: formData.administered_by,

		test_result_date: formData.test_result_date,
		induration: formData.induration,
		ppd_mantoux_test_result: formData.ppd_mantoux_test_result,

		interpreted_by: formData.interpreted_by,
		date_of_interpretation: formData.date_of_interpretation,
		follow_up: formData.follow_up,
		comments: formData.comments,
		patientphone: formData.phone,
		// patient_consent: formData.patient_consent
	};

	if (formData.fetchType === 'winrx') {
		Object.assign(requestedData, {
			fetchtype: formData.fetchType,
			winrxpatientphn: formData.phn,
			winrxpatientname: formData.name,
			winrxpatientdob: formData.dob,
			winrxpatientphone: formData.winrxPatientPhone,
			winrxpatientaddress: formData.winrxPatientAddress,
			winrxpatientcity: formData.winrxPatientCity,
			winrxpatientprovince: formData.winrxPatientProvince,
			winrxpatientpostcode: formData.winrxPatientPostcode,
		});
	}

	if (formData.fetchType === 'local') {
		Object.assign(requestedData, {
			fetchtype: formData.fetchType,
			patient: formData?.patientName?.value,
			patientphn: formData.phn,
			patientdob: formData.dob,
		});
	}

	if (allDataPresent) {
		dispatch(forms.pharmacyPreviewFormPdf('pharmacy-skin-test-form', requestedData))
	}
}


// export const handleAddDeliveryFormValidation = (formData, setErrorMessages,
// 	errorMessages,
// 	dispatch,  setFormVisible) => {

// 	const specificValidations = {

// 		begin_time: 'Start time is required',
// 		end_time: 'End time is required',
// 		date: 'required'
// 	}

// 	const allValidations = { ...specificValidations }
// 	const newErrorMessages = Object.fromEntries(
// 		Object.entries(allValidations).map(([field, message]) => [
// 			field,
// 			validateField(field, formData[field], message),
// 		])
// 	);
// 	setErrorMessages({
// 		...errorMessages,
// 		...newErrorMessages,
// 	});
// 	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

// 	const requestedData = {
// 		initial_date: formData.date,
// 		begin_time: formData.begin_time,
// 		end_time: formData.end_time,
// 	}

// 	if (allDataPresent) {
// 		dispatch(pharmacyDeliverySetting('update-delivery-time-setting', requestedData ,  setFormVisible));
// 	}
// }

export const handleAddDeliveryFormValidation = (formData, setErrorMessages,
	errorMessages,
	dispatch, setFormVisible) => {

	const isTimeGapSufficient = (startTime, endTime) => {
		let [startHour, startMinute] = startTime.split(':').map(Number);
		let [endHour, endMinute] = endTime.split(':').map(Number);

		let startTotalMinutes = startHour * 60 + startMinute;
		let endTotalMinutes = endHour * 60 + endMinute;

		let timeDifference = endTotalMinutes - startTotalMinutes;

		if (timeDifference < 0) {
			timeDifference += 24 * 60
		}

		return timeDifference >= 6 * 60;
	};


	const specificValidations = {
		begin_time: 'Start time is required',
		end_time: 'End time is required',
		date: 'Date is required'
	};

	let newErrorMessages = {};

	for (const field in specificValidations) {
		if (!formData[field]) {
			newErrorMessages[field] = specificValidations[field];
		}
	}


	if (formData.begin_time && formData.end_time) {
		if (!isTimeGapSufficient(formData.begin_time, formData.end_time)) {
			newErrorMessages['time_gap'] = 'The start and end times must have 6-hour gap.';
		}
	}

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	const requestedData = {
		initial_date: formData.date,
		begin_time: formData.begin_time,
		end_time: formData.end_time,
	};

	if (allDataPresent) {
		dispatch(pharmacyDeliverySetting('update-delivery-time-setting', requestedData, setFormVisible, setErrorMessages));
	}
};


export const handleDefaultPharmacistFormValidation = (formData, setErrorMessages,
	errorMessages,
	dispatch, setFormVisible) => {

	const specificValidations = {
		pharmacist_id: 'Pharmacist is required',
		date: 'Start Date is required',
	};

	const allValidations = { ...specificValidations }
	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);
	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});
	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	const requestedData = {
		start_date: formData.date,
		pharmacist_id: formData.pharmacist_id,
	};

	if (allDataPresent) {
		dispatch(pharmacyDefaultSetting('update-delivery-time-setting', requestedData, setFormVisible, setErrorMessages));
	}
};
