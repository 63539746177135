import React, {
    useEffect,
    useState,
} from "react";

import {
    useCustomDispatch,
    useCustomLocation,
    useCustomNavigate
} from "../../../hooks";

import { FetchPharmacyDepositInfo } from "../../../services/actions/pharmacy/revenue/revenueList";

import { useRevenueSelector } from "../../../services/selectors/revenueSelectors";
import { handleShowModal } from "../../../assets/js/custome";

import { AddDepositModal } from "../../../models/revenue/depositInfo";
import { DeleteIcon, EyeIcon } from "../../../assets/svg/icons";

import { DeleteDailyRevenueModal } from "../../../models/revenue/dailyRevenue";
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";

import ChatBox from "../../../components/chat/chatbox";
import * as Common from "../../../components/common";

const revenueListHeading = [
    { text: "Number", classNames: ['p-3'] },
    { text: "Deposit Date", classNames: ['p-3'] },
    { text: "Action", classNames: ['p-3'] },
];

const TableRowData = ({ value, className1 = 'p-3 w-96', }) => {
    return (<td scope="row" className={className1}>
        <p>{value}</p>
    </td>)
}

const DepositInformation = () => {

    const dispatch = useCustomDispatch()
    const location = useCustomLocation()
    const navigate = useCustomNavigate()

    const { RevenueListResponse } = useRevenueSelector();

    const [showModal, setShowModal] = useState({ addDepositModal: false, deleteRevenueList: false })
    const [isEdit, setIsEdit] = useState(false)
    const [depositListData, setDepositListData] = useState(null)

    const depositInfoList = RevenueListResponse?.data?.data

    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)

    useEffect(() => {
        const params = {
            page: pageNumber
        }

        dispatch(FetchPharmacyDepositInfo('deposit_list_info', params))
        return () => { }
    }, [])

    const handlePagination = (event, value) => {

        if (value) {
            const params = {
                page: value
            }

            setPageNumber(value);
            const searchParams = new URLSearchParams();
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            dispatch(FetchPharmacyDepositInfo('deposit_list_info', params))
            navigate(newUrl);
        }
    };

    return (
        <>
            <div className="BodyBgColor">
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center 
                                                    text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

                                        <button onClick={() => { return setIsEdit(false), setDepositListData(null), handleShowModal(setShowModal, showModal, 'addDepositModal') }} type="button" style={{ width: '170px', height: '26px', backgroundColor: '#3B82F6' }}
                                            className="flex w-full items-center justify-center text-white bg-red-500
																border border-gray-300 hover:bg-red-300  font-medium rounded-lg text-sm px-5 py-0.5  dark:bg-gray-800">
                                            Add New Deposit
                                        </button>

                                        {RevenueListResponse?.loading && RevenueListResponse?.type === 'deposit_list_info' ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            RevenueListResponse?.error !== null && RevenueListResponse?.type === 'deposit_list_info' ?

                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div>
                                                :

                                                depositInfoList?.data?.length === 0 && RevenueListResponse?.type === 'deposit_list_info' ?

                                                    <div className="bg-white rounded-md mt-4">
                                                        <p className="text-gray-500">No Records are available.</p>
                                                    </div>

                                                    : RevenueListResponse?.type === 'deposit_list_info' ?

                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                            <div className="shadow-md border rounded-lg relative">
                                                                <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                    <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {revenueListHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            depositInfoList?.data?.map((value, idx) => {
                                                                                return (<React.Fragment key={idx}>
                                                                                    <tr style={{ borderBottom: '1px solid lightgray' }}>
                                                                                        <TableRowData value={`${idx + 1}`} />
                                                                                        <TableRowData value={value.deposit_date} />

                                                                                        <td scope="row" className={'p-3 flex gap-2 btnnn'}>
                                                                                            <p className='fs-12 rubik-400 text-gray-400 ' 
                                                                                            onClick={() => { return setDepositListData(value), setIsEdit(true), handleShowModal(setShowModal, showModal, 'addDepositModal') }}>
                                                                                                <EyeIcon className = "text-black"/>
                                                                                                </p>

                                                                                            <div onClick={() => { return setDepositListData(value), setIsEdit(true), handleShowModal(setShowModal, showModal, 'deleteRevenueList') }}>
                                                                                                <DeleteIcon color="red"/>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </React.Fragment>)
                                                                            })}
                                                                    </tbody>
                                                                </table>

                                                                {RevenueListResponse?.error !== null ? null : <>
                                                                    {depositInfoList?.data?.length !== 0 && <>
                                                                        {depositInfoList?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={depositInfoList?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={handlePagination}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}

                                                            </div>

                                                        </div> : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal.addDepositModal &&
                <AddDepositModal setShowModal={setShowModal}
                    showModal={showModal}
                    isEdit={isEdit}
                    depositListData={depositListData}
                />}


            {showModal.deleteRevenueList &&
                <DeleteDailyRevenueModal setShowModal={setShowModal}
                    showModal={showModal}
                    pageNumber={pageNumber}
                    isEdit={isEdit}
                    logData={depositListData}
                    text={'Are you sure you want to delete?'}
                />
            }

            <ChatBox />
            <Common.Footer />
        </>
    );
};

export default DepositInformation;
