import {
	PHARMACY_PATIENT_PRESCRIPTION_DETAILS_REQUEST,
	PHARMACY_PATIENT_PRESCRIPTION_DETAILS_SUCCESS,
	PHARMACY_PATIENT_PRESCRIPTION_DETAILS_FAILURE
} from '../../../constants';

import Axios from '../../../../networking/intraceptor'
import * as path from '../../../../networking/urlEndPoints'

export const patientPrescriptionDetailsRequest = () => ({
	type: PHARMACY_PATIENT_PRESCRIPTION_DETAILS_REQUEST
});

export const patientPrescriptionDetailsSuccess = (data) => ({
	type: PHARMACY_PATIENT_PRESCRIPTION_DETAILS_SUCCESS,
	payload: data,
});

export const patientPrescriptionDetailsFailure = (error) => ({
	type: PHARMACY_PATIENT_PRESCRIPTION_DETAILS_FAILURE,
	payload: error
})

export const fetchPatientPrescriptionDetail = (patient_id, formData) => {
	return async (dispatch) => {
		dispatch(patientPrescriptionDetailsRequest());
		const params = {
			from_date: formData.date,
			patient_id: patient_id
		}
		try {

			const response = await Axios.get(`${path.pharmacy_patient_prescriptions_details}`, { params })

			if (response?.status === 200) {
				dispatch(patientPrescriptionDetailsSuccess(response?.data));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(patientPrescriptionDetailsFailure(error?.response));
			}
		}
	}
}

