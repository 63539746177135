import React, { useCallback } from "react";
import { debounce } from "lodash";
import { UpdateBlisterPack } from "../../../services/actions/pharmacy/blisters/addBlisterPackData";
import { useCustomDispatch } from "../../../hooks";

export const UpdaTeValues = ({ id, type, data, idx, setShowToast, setShowModal, pageNumber }) => {

    const dispatch = useCustomDispatch()

    const handleInputChange = (e, id) => {
        const { value } = e.target;
        const requestData = {
            pack_id: id,
            [type]: value
        }
        debounceUpdateBlisterDailyPack('update_blister_pack', requestData);
    }

    const debounceUpdateBlisterDailyPack = useCallback(debounce((fetchType, requestData) => {
        dispatch(UpdateBlisterPack(fetchType, requestData, setShowModal, setShowToast, pageNumber))
    }, 500), [])

    return (<td scope="row" className="p-3">
        <input type="text" id={`${idx}`} autoComplete="off"
            onChange={(e) => handleInputChange(e, id)}
            name={type}
            style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
            defaultValue={data}
            className="block w-16 text-sm text-gray-900 border-200-gray  bg-transparent appearance-none dark:text-white dark:border-gray-600 
            dark:focus:border-blue-500 
            focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
            placeholder=""
        />
    </td>)
}

export const UpdateTextAreaValue = ({ id, type, data, idx, setShowToast, setShowModal, pageNumber }) => {

    const dispatch = useCustomDispatch()

    const handleInputChange = (e, id, type) => {
        const { value } = e.target;
        const requestData = {
            pack_id: id,
            [type]: value
        }
        debounceUpdateBlisterDailyPack(requestData);
    }

    const debounceUpdateBlisterDailyPack = useCallback(debounce((fetchType, requestData) => {
        dispatch(UpdateBlisterPack(fetchType, requestData, setShowModal, setShowToast, pageNumber))
    }, 500), [])

    return (<td scope="row" className="p-3">
        <textarea type="text" id={`${idx}`} autoComplete="off"
            onChange={(e) => handleInputChange(e, id, type)}
            style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
            defaultValue={data}
            name={type}
            className="block w-16 text-sm text-gray-900 border-200-gray  bg-transparent appearance-none dark:text-white dark:border-gray-600 
            dark:focus:border-blue-500 
            focus:ring-0 focus:border-gray-500 peer cursor-text bg-white"
            placeholder=""
        />
    </td>)
}