import React from 'react';
import { useCustomNavigate } from '../../hooks';
import * as routesNames from '../../routes/routeName';
import Cookies from 'js-cookie';

export const AddShortCutKeys = () => {

	const navigate = useCustomNavigate();

	React.useEffect(() => {
		const keyRoutes = {
			d: routesNames.DASHBOARD_ROUTE,
			m: routesNames.NEWREQUEST_ROUTE,
			r: routesNames.APPRECIATIONS_ROUTE,
			s: routesNames.SETTINGS_ROUTE,
			p: routesNames.ALL_PATIENTS,
			f: routesNames.PHARMACY_INFORMATION_UPDATE_FORM,
			a: routesNames.ADD_PATIENTS,
			x: routesNames.PHARMACY_PRESCRIPTION_TRASNSFER_FORM,
			n: routesNames.pharmacy_Emergency_Supply_OAT_medications_form,
			v: routesNames.pharmacy_medication_form,
			g: routesNames.pharmacy_Plan_G_Form,
			z: routesNames.pharmacy_Plan_Z_Form,
			c: routesNames.pharmacy_order_compounding_form,
		}

		const handleKeyPress = (e) => {
			const key = e?.key?.toLowerCase();
			const isInputFocused = ['input', 'textarea', 'select'].includes(document.activeElement.tagName.toLowerCase());
			const isQuillEditorFocused = document.activeElement.classList.contains('ql-editor');

			if (isInputFocused || isQuillEditorFocused) {
				return;
			}

			const isCtrlPressed = e.ctrlKey || e.metaKey || e.shiftKey || e.altKey

			if (!isCtrlPressed && keyRoutes[key]) {
				if ([routesNames.ALL_PATIENTS, routesNames.ADD_PATIENTS].includes(keyRoutes[key])) {

					Cookies.set('work_tab', 0);
					navigate(keyRoutes[key]);

				} else if ([routesNames.PHARMACY_INFORMATION_UPDATE_FORM, routesNames.pharmacy_Plan_Z_Form,
				routesNames.pharmacy_Plan_G_Form, routesNames.pharmacy_medication_form, routesNames.pharmacy_Emergency_Supply_OAT_medications_form,
				routesNames.PHARMACY_PRESCRIPTION_TRASNSFER_FORM].includes(keyRoutes[key])) {

					Cookies.set('work_tab', 1);
					navigate(keyRoutes[key]);

				} else if ([routesNames.pharmacy_order_compounding_form].includes(keyRoutes[key])) {

					Cookies.set('work_tab', 5);
					navigate(keyRoutes[key]);

				} else {
					Cookies.remove('work_tab');
					navigate(keyRoutes[key]);
				}
			}
		}

		document.addEventListener('keydown', handleKeyPress);
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [navigate]);

	return null;
};
