export const RxNumberRowHeading = [
	{ text: 'Date', classNames: ['p-3', 'px-2', 'w-28'], pStyle: 'rubik-500 fs-14' },
	{ text: 'ROUTE', classNames: ['p-3', 'px-2', 'w-16'], pStyle: 'rubik-500 fs-14' },
	{ text: 'Name', classNames: ['p-3', 'px-2', 'w-40'], pStyle: 'rubik-500 fs-14' },
	{ text: 'PHN', classNames: ['p-3', 'px-2', 'w-24'], pStyle: 'rubik-500 fs-14' },
	// { text: 'Note', classNames: ['p-3', 'px-2'], pStyle: 'rubik-500 fs-14' },
	// { text: 'Assign', classNames: ['p-3', 'px-2'], pStyle: 'rubik-500 fs-14' },
	// { text: 'GEO', classNames: ['p-3', 'px-2'], pStyle: 'rubik-500 fs-14' },
	{ text: 'PHONE', classNames: ['p-3', 'px-2'], pStyle: 'rubik-500 fs-14' },

	{ text: 'Sheet', classNames: ['p-3', 'px-2', 'rounded-e-lg', 'w-32'], pStyle: 'rubik-500 fs-14 text-right' },
];

export const RxNumberRowSubRowsHeadings = [
	{ text: 'RX', classNames: ['p-3'] },
	{ text: 'NARC', classNames: ['p-3'] },
	{ text: 'DATE', classNames: ['p-3'] },
	{ text: 'DIN', classNames: ['p-3'] },
	{ text: 'PROCESSING', classNames: ['p-3'] },
	{ text: 'WIT', classNames: ['p-3'] },
	{ text: 'CARRY', classNames: ['p-3'] },
	{ text: 'DRUG', classNames: ['p-3', 'w-36'] },
	{ text: 'SIG', classNames: ['p-3'] },
	{ text: 'PROCESSING DATE TIME', classNames: ['p-3'] },
	{ text: 'STATUS', classNames: ['p-3 text-start', 'rounded-e-lg '] },
];


export const archivedRowHeadings = [
	{ text: 'Name', classNames: ['p-3', 'px-2', 'w-40'], pStyle: 'rubik-500 fs-14' },
	{ text: 'ROUTE', classNames: ['p-3', 'px-2', 'w-16'], pStyle: 'rubik-500 fs-14' },
	{ text: 'PHN', classNames: ['p-3', 'px-2', 'w-24'], pStyle: 'rubik-500 fs-14' },
	{ text: 'PHONE', classNames: ['p-3', 'px-2', 'w-36'], pStyle: 'rubik-500 fs-14' },
	{ text: 'Note', classNames: ['p-3', 'px-2'], pStyle: 'rubik-500 fs-14' },
	// { text: 'Assign', classNames: ['p-3', 'px-2'], pStyle: 'rubik-500 fs-14' },
	{ text: 'Comment', classNames: ['p-3', 'px-2'], pStyle: 'rubik-500 fs-14' },
	// { text: 'GEO', classNames: ['p-3', 'px-2'], pStyle: 'rubik-500 fs-14' },
	{ text: 'Status', classNames: ['p-3', 'px-2', 'rounded-e-lg', 'w-32'], pStyle: 'rubik-500 fs-14 text-right' },
];

export const archivedSubRowsHeadings = [
	{ text: 'RX', classNames: ['p-3'] },
	{ text: 'DIN', classNames: ['p-3'] },
	{ text: 'PROCESSING', classNames: ['p-3'] },
	{ text: 'WIT', classNames: ['p-3'] },
	{ text: 'CARRY', classNames: ['p-3'] },
	{ text: 'DRUG', classNames: ['p-3', 'w-36'] },
	{ text: 'SIG', classNames: ['p-3'] },
	{ text: 'DATE', classNames: ['p-3', 'w-32'] },
	{ text: 'SHEET', classNames: ['p-3 text-right', 'rounded-e-lg '] },
];

export const LableRowHeader = [
	{ text: "Name", classNames: ['p-3'] },
	{ text: "ROUTE", classNames: ['p-3'] },
	{ text: "PHN", classNames: ['p-3'] },
	{ text: "RX", classNames: ['p-3'] },
	{ text: "DRUG", classNames: ['p-3'] },
	{ text: "RX DURATION", classNames: ['p-3', 'w-full'] },
	{ text: "SCHEDULED", classNames: ['p-3'] },
	{ text: "ACTION", classNames: ['p-3', 'text-right', 'rounded-e-lg'] },
];

export const ExpiringRxHeader = [
	{ text: "ROUTE", classNames: ['p-3'] },
	{ text: "PATIENT NAME", classNames: ['p-3'] },
	{ text: "PHN", classNames: ['p-3'] },
	{ text: "PHONE", classNames: ['p-3'] },
	{ text: "RX COUNT", classNames: ['p-3'] },
];

export const counsentSubmissionHeader = [
	{ text: "DATE", classNames: ['p-3'] },
	{ text: "PHN", classNames: ['p-3'] },
	{ text: "PATIENT NAME", classNames: ['p-3'] },
	{ text: "DATE OF BIRTH", classNames: ['p-3'] },
	{ text: "PROFILE IMAGE", classNames: ['p-3'] },
	{ text: "DOCUMENT", classNames: ['p-3'] },
	{ text: "PDF", classNames: ['p-3'] },
];

export const todayBillngTableHeading = [
	{ text: "DATE", classNames: ['p-3'] },
	{ text: "NAME", classNames: ['p-3'] },
	{ text: "ROUTE", classNames: ['p-3'] },
	{ text: "PHN", classNames: ['p-3'] },
	{ text: "RX", classNames: ['p-3'] },
	{ text: "DRUG", classNames: ['p-3'] },
	{ text: "CURRENT DOSE", classNames: ['p-3'] },
	{ text: "PREVIOUS DOSE", classNames: ['p-3'] },
];

export const counsenTransferHeader = [
	{ text: "PHN", classNames: ['p-3'] },
	{ text: "PATIENT NAME", classNames: ['p-3'] },
	{ text: "DATE OF BIRTH", classNames: ['p-3'] },
	{ text: "DATE TAKEN", classNames: ['p-3'] },
	{ text: "DATE ORIGIN", classNames: ['p-3'] },
	{ text: "PDF", classNames: ['p-3'] },
];