import React, { useState } from 'react'

import {
	pharmacyPatientArchievedSetpinDelivery,
	pharmacyPatientPrescriptionViewPdf
} from '../../../services/actions/pharmacy/dispense/addDispenseData'

import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'
import { fetchAllDispenseArchivedDelveries } from '../../../services/actions/pharmacy/dispense/archivedDelveriesLists'
import { fetchPatientPrescriptionDetail } from '../../../services/actions/pharmacy/dispense/pharmacyPatientPrescriptionsDetails'
import { useDispenseSelectors } from '../../../services/selectors/dispenseSelectors'

import { initalArchivedDelveriesData } from '../../../utils/constants/formInitialStates'
import { EditNoteModal, GetDelDif } from '../../../models/dispense/dailyDispenseModals'

import { pharmacySearchRxNumberListFailure, pharmacySearchRxNumberListSuccess, searchByRxNumberArchivedlistData } from '../../../services/actions/pharmacy/dispense/searchByRxNumberArchivedList'
import { syncTodaysDelveries } from '../../../services/actions/pharmacy/dispense/syncToday\'sDelveries'
import { pharmacyPatientUpdateWitnessDose } from '../../../services/actions/pharmacy/dispense/updateRxWitnessDose'

import ChatBox from '../../../components/chat/chatbox'

import * as Common from '../../../components/common'
import * as Dispense from '../../../components/pharmacy/dispense/dispense'
import * as routeNames from '../../../routes/routeName'
import * as Hooks from '../../../hooks'

import * as Icons from '../../../assets/svg/icons'
import * as constant from '../../../constants/dispense'
import * as Modal from '../../../assets/js/custome'
import * as Toast from '../../../components/loaders/toastMessage'


const PrescriptionListValues = ({ value, style = {} }) => {
	return (<th scope="col" className="p-3" style={style}>
		<p className="rubik-400 fs-14 text-gray-700 font-medium">{value}</p>
	</th>)
}

const ArchivedDispense = () => {

	const location = Hooks.useCustomLocation()
	const navigate = Hooks.useCustomNavigate()
	const dispatch = Hooks.useCustomDispatch()

	const [formData, setFormData] = useState(initalArchivedDelveriesData)
	const [loading, setLoading] = useState(false)

	const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;
	const deliveryStatus = new URLSearchParams(location.search).get('status')
	const from_date = new URLSearchParams(location.search).get('from_date')
	const route = new URLSearchParams(location.search).get('route')

	const { archivedDelveriesListResponse,
		addDispenseDataResponse,
		patientPrescriptionDetailsResponse,
		searchByRxNumberArchivedListResponse,
		syncTodaysDelveriesResponse,
		pharmacyPatientUpdateRxWitnessDoseResponse
	} = useDispenseSelectors()

	const prescriptionDetailList = patientPrescriptionDetailsResponse?.data?.data?.data?.[0]
	const archivedDelveriesList = archivedDelveriesListResponse?.data
	const searchByRxNumberArchivedList = searchByRxNumberArchivedListResponse?.data?.data

	const [showModal, setShowModal] = useState({
		editNoteModalVisible: false,
		GetDelDif: false,
		editDispenseListModalVisible: false,
		showToast: false,
		errorToast: false,
		pdfError: false,
		witError: false,
		witSuccess: false
	})

	const [pageNumber, setPageNumber] = useState(initialPage)
	const [selectedRow, setSelectedRow] = useState(null)
	const [selectedRowID, setSelectedRowID] = useState(null)

	const [patientData, setPatientData] = useState(null)
	const [rowIndex, setRowIndex] = useState(null)

	const [activeTab, setActiveTab] = useState('deliveries');
	const [rxLoading, setRxLoading] = useState(false)

	React.useEffect(() => {
		setFormData({
			...formData,
			deliveryStatus: deliveryStatus,
			date: from_date ? from_date : formData?.date,
			patientRoutes: route
		})
		return () => { }
	}, [])

	React.useEffect(() => {
		setPageNumber(initialPage)
	}, [initialPage])

	React.useEffect(() => {
		dispatch(fetchAllDispenseArchivedDelveries(formData, 'pharmacy-archived-deliveries', pageNumber, from_date))
		return () => { }
	}, [])


	const handleFilterData = (event, value) => {
		setPatientData(null)
		setRowIndex(null)
		const searchParams = new URLSearchParams()

		searchParams.append('active', activeTab)
		searchParams.append('from_date', formData.date)

		if (value !== undefined) {
			searchParams.append('page', value);
			setPageNumber(value)
		}

		if (value === 1) {
			dispatch(fetchAllDispenseArchivedDelveries(formData, 'pharmacy-archived-deliveries'))
		} else {
			dispatch(fetchAllDispenseArchivedDelveries(formData, 'pharmacy-archived-deliveries', value))
		}

		const newUrl = `${routeNames.pharmacy_archived_deliveries}?${searchParams.toString()}`;
		navigate(newUrl);
	}

	const handleResetFilteredData = () => {
		const searchParams = new URLSearchParams();
		setPatientData(null)
		setRowIndex(null)
		setFormData({ ...formData, searchPatients: '', deliveryStatus: null, patientRoutes: null })
		searchParams.delete('from_date');
		searchParams.delete('status');
		searchParams.delete('route');
		searchParams.delete('page');
		const newUrl = `${routeNames.pharmacy_archived_deliveries}?${searchParams.toString()}`;
		navigate(newUrl);
	}

	const handleFilterRxNumber = () => {
		if (formData.rxnumber) {
			setLoading(false)
			setRxLoading(true)
			dispatch(searchByRxNumberArchivedlistData(formData, 'rx_list'))
		}
	}

	React.useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const activeParam = params.get('active');

		if (activeParam === 'searchrxsheet') {
			setActiveTab('searchrxsheet');
		}
	}, []);

	const handleTabChange = (tab) => {
		setRxLoading(true)
		setActiveTab(tab);
		navigate(`${routeNames.pharmacy_archived_deliveries}?active=${tab}`);
		setFormData({ ...formData, rxnumber: '' })
		dispatch(pharmacySearchRxNumberListSuccess(null))
		dispatch(pharmacySearchRxNumberListFailure(null))
		setRowIndex(null)
	};

	const handleDownLoadRxSheet = (number) => {
		setLoading(false);
		setRxLoading(true)
		setActiveTab(`searchrxsheet`)
		navigate(`${routeNames.pharmacy_archived_deliveries}?active=${`searchrxsheet`}`)
		setFormData({ ...formData, rxnumber: number })
		// setRowIndex(null)
		dispatch(pharmacySearchRxNumberListSuccess(null))
		// dispatch(pharmacySearchRxNumberListFailure(null))
		const data = {
			rxnumber: number
		}
		dispatch(searchByRxNumberArchivedlistData(data, 'rx_list'))
	}

	return (

		<React.Fragment>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">

					<div className="grid grid-cols-12 gap-4">
						{/* <Common.Sidebar /> */}
						<div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content">
								<div className="grid grid-cols-12 gap-4 w-full">
									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
													sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<Common.BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="mb-4 border-b border-gray-200 dark:border-gray-700 flex">
											<ul className="flex flex-wrap -mb-px text-sm font-medium text-center">

												<li className="me-2" role="presentation">
													<button className={`inline-block border-b-1 rounded-t-sm p-4 ${activeTab === 'deliveries' ? 'bg-gray-100' : ''}`}
														id="filter-row-tab" onClick={() => handleTabChange('deliveries')}
														type="button" role="tab" aria-controls="filter-row"
														aria-selected={activeTab === 'deliveries' ? 'true' : 'false'}>
														Deliveries
													</button>
												</li>

												<li className="me-2" role="presentation">
													<button className={`inline-block p-4 border-b-1 rounded-t-sm hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'searchrxsheet' ? 'bg-gray-100' : ''}`}
														id="export-row-tab" onClick={() => {
															return handleTabChange('searchrxsheet'),
																dispatch(pharmacySearchRxNumberListSuccess(null),
																	dispatch(pharmacySearchRxNumberListFailure(null))),
																setRowIndex(null)
														}}
														type="button" role="tab" aria-controls="export-row"
														aria-selected={activeTab === 'searchrxsheet' ? 'true' : 'false'}>
														Rx Sheet
													</button>
												</li>
											</ul>
											{activeTab === 'searchrxsheet' ? null :
												<div className='ml-auto flex justify-center items-center w-94'>
													<button onClick={() => !syncTodaysDelveriesResponse?.loading && dispatch(syncTodaysDelveries('sync_todays_delveries', setShowModal))} className=' bg-blue-500 text-white text-xs font-medium px-1.5 py-0.5 rounded dark:bg-gray-700 
 														dark:text-blue-400 fs-10 border border-blue-500 flex items-center justify-center  w-full text-center' style={{ height: '28px', width: '150px' }}>
														{syncTodaysDelveriesResponse?.loading && syncTodaysDelveriesResponse?.type === 'sync_todays_delveries' && <><Common.Loader /> &nbsp; </>}Sync Today's Deliveries
													</button>
												</div>}
										</div>

										<div id="filter-tab-content">

											{/* ---------------------------------------------Deliveries Sheet----------------------------------------------------------  */}

											<div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'deliveries' ? '' : 'hidden'}`} id="filter-row" role="tabpanel" aria-labelledby="filter-row-tab">
												{activeTab === 'deliveries' && <>
													<div className="flex flex-wrap gap-y-3">

														<div className='w-64'>
															<Dispense.TextInput label='Search' name='searchPatients' formData={formData}
																setFormData={setFormData} />
														</div>

														<div className='w-64 ml-2'>
															<Dispense.StartDate formData={formData}
																setFormData={setFormData} />
														</div>

														<div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full ml-2' style={{ width: '200px' }}>
															<Dispense.StatusList formData={formData} deliveryStatus={deliveryStatus}
																setFormData={setFormData} />
														</div>

														<div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full ml-2' style={{ width: '200px' }}>
															<Dispense.RouteList formData={formData}
																setFormData={setFormData} />
														</div>
														<div className='flex-0 lg:w-1/12 md:w-2/12 sm:w-4/12 w-full ml-2'>
															<button style={{ backgroundColor: '#3B82F6', color: 'white' }} onClick={handleFilterData} type="button"
																className="flex text-white ml-auto w-full h-full pl-6 py-4 items-center justify-center text-gray-900 bg-white border border-gray-300 
															focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
																Filter
															</button>
														</div>

														<div className='flex-0 lg:w-1/12 md:w-2/12 sm:w-4/12 w-full ml-2'>
															<button onClick={handleResetFilteredData} type="button" className="flex ml-auto w-full h-full  py-4 items-center justify-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
																Reset
															</button>
														</div>

													</div>

													<React.Fragment>
														{(archivedDelveriesListResponse?.loading && archivedDelveriesListResponse?.type === 'pharmacy-archived-deliveries' ||
															addDispenseDataResponse?.loading && addDispenseDataResponse?.type === 'set_pin') ?
															<div className="flex justify-center items-center mt-60 mb-80">
																<Common.LoadingView />
															</div> :
															archivedDelveriesListResponse?.error !== null && archivedDelveriesListResponse?.type === 'pharmacy-archived-deliveries' ?
																<div className="flex flex-col items-center justify-center mt-60 mb-80">
																	<h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
																	<p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
																	<button onClick={() => window.location.reload()}
																		className="px-4  bg-red-600 text-white rounded hover:bg-red-700 
																	focus:outline-none focus:ring-2 focus:ring-red-500">
																		Please Try Again.
																	</button>

																</div> :
																archivedDelveriesList?.data?.length === 0 ?
																	<div className="bg-white pt-4 rounded-md">
																		<p className="text-gray-500">No Record Available.</p>
																	</div>
																	:
																	<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
																		<div className="shadow-md border rounded-lg relative overflow-x-auto">
																			<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
																				<thead className="text-xs text-gray-900 uppercase blueBg-color dark:bg-gray-700 dark:text-gray-400"
																					style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}
																				>
																					<tr>
																						{constant.archivedRowHeadings?.map((column, index) => (
																							<th key={index} scope="col" className={column.classNames.join(' ')}>
																								{column.text && <p className={column.pStyle} >{column.text}</p>}
																							</th>
																						))}
																					</tr>
																				</thead>

																				<tbody>
																					{
																						archivedDelveriesList?.data?.map((value, idx) => (
																							<React.Fragment key={idx}>
																								<tr className='border-b border-gray-50'>

																									<th
																										scope="col"
																										className="p-3 btnnn"
																										style={{ whiteSpace: 'nowrap' }}
																										onClick={(e) => {
																											if (rowIndex === idx) {
																												setRowIndex(null);
																												setPatientData(null);
																											} else {
																												setLoading(true);
																												setPatientData(value);
																												setRowIndex(idx);
																												dispatch(fetchPatientPrescriptionDetail(value.patient_id, formData));
																											}
																										}}
																									>
																										<div className='flex'>
																											<img src={value.profile_image} className='w-6 h-6 rounded-md' alt={`${idx}-img`} />

																											<div className="rubik-400 fs-14 flex items-center ml-2" >
																												<span className='underline decoration-blue-500'>{value.name}</span>

																												{value.show_flag &&
																													<Icons.FlagIcon color='red' />}

																												{/* <a className='btnnn' >
																													{idx === rowIndex ?
																														<Icons.ClosedEyeIcon style={{ marginRight: 10 }} />
																														:
																														<Icons.EyeIcon style={{ marginRight: 10 }} />
																													}
																												</a> */}

																											</div>
																										</div>
																									</th>

																									<th scope="col" className="p-3 ">

																										{
																											value.pinindlist === 1 ?
																												<span className='btnnn flex'
																													onClick={() => dispatch(pharmacyPatientArchievedSetpinDelivery(value.patient_id, 1, 'set_pin', formData, pageNumber, setShowModal))}>
																													<Icons.PinIcon /><span className="rubik-400 fs-14 ml-1">{value.route}</span>
																												</span>
																												:
																												<span className='btnnn flex'
																													onClick={() => dispatch(pharmacyPatientArchievedSetpinDelivery(value.patient_id, 0, 'set_pin', formData, pageNumber, setShowModal))}>
																													<Icons.PinVIcon color='rgb(15, 111, 201)' /><span className="rubik-400 fs-14 ml-1">{value.route}</span>
																												</span>
																										}

																									</th>



																									<th scope="col" className="p-3">
																										<p className="rubik-400 fs-14">{value.phn}</p>
																									</th>

																									<th scope="col" className="p-3">
																										<p className="rubik-400 fs-14">{value.phone}</p>
																									</th>

																									<th scope="col" className="p-3" onClick={() => { return setPatientData(value), setFormData({ ...formData, addNote: value.note }) }}>
																										<p className="rubik-400 fs-14 flex">
																											{value.note ? <>{value.note} <br /></> : null}
																											<span onClick={() => {
																												setSelectedRow(value);
																												Modal.handleShowModal(setShowModal, showModal, 'editNoteModalVisible')
																											}} className="text-blue-800 btnnn fs-12 rubik-400">
																												{value.note ? <span className='ml-2 flex items-center justify-center'><Icons.Edit color='#1E40AF' /></span> : 'Add Note'}
																											</span></p>
																									</th>

																									<th scope="col" className="p-3">
																										<p className="rubik-400 fs-14">{value.comment}</p>
																									</th>

																									<th scope="col" className="p-3 text-center rounded-e-lg ">
																										<div className='flex gap-1 items-center'>
																											{value.status === 'pending' ?
																												<div className=' flex items-end justify-end'>
																													<p className="rubik-400 fs-12 text-white bg-yellow-500 p-1 w-6 h-6 flex justify-center items-center rounded-full" style={{ color: '' }}>P</p></div> :
																												value.status === 'missed' ?
																													<div className=' flex items-end justify-end'>
																														<p className="rubik-400 fs-12 text-white bg-red-500 p-1 w-6 h-6 flex justify-center items-center rounded-full">M</p></div> :
																													value.status === 'delivered' ?
																														<>
																															<div className='flex items-end justify-end'>
																																<p className="rubik-400 fs-12 text-white bg-green-500 p-1 w-6 h-6 flex justify-center items-center rounded-full">D</p></div>
																														</> :
																														value.status === 'today_missed' ?
																															<div className=' flex items-end justify-end'>
																																<p className="rubik-400 fs-12 text-white bg-red-500 p-1 w-6 h-6 flex justify-center items-center rounded-full">T</p></div>
																															: value.status === 'final_missed' ?
																																<div className=' flex items-end justify-end'>
																																	<p className="rubik-400 fs-12 text-white bg-red-500 p-1 w-6 h-6 flex justify-center items-center rounded-full">F</p></div>
																																: value.status === 'returned' ?
																																	<div className=' flex items-end justify-end'>
																																		<p className="rubik-400 fs-12 text-white bg-red-500 p-1 w-6 h-6 flex justify-center items-center rounded-full">R</p></div>
																																	: null}

																											<div className='flex justify-end flex-nowrap'>
																											</div>
																											<div className='flex gap-2'>
																												{value.subtract_count !== "" && (
																													<div onClick={() => {
																														setSelectedRowID(value.patient_id);
																														Modal.handleShowModal(setShowModal, showModal, 'GetDelDif')
																													}} className="flex items-end justify-end">
																														<p className="rubik-400 fs-12 text-white bg-red-700 p-1 w-6 h-6 flex justify-center items-center rounded-full">
																															{value.subtract_count}
																														</p>
																													</div>
																												)}

																												{value.add_count !== "" && (
																													<div onClick={() => {
																														setSelectedRowID(value.patient_id);
																														Modal.handleShowModal(setShowModal, showModal, 'GetDelDif')
																													}} className="flex items-end justify-end">
																														<p className="rubik-400 fs-12 text-white bg-green-400 p-1 w-6 h-6 flex justify-center items-center rounded-full">
																															{value.add_count}
																														</p>
																													</div>
																												)}
																											</div>
																										</div>
																									</th>

																								</tr>

																								<tr className={`bg-gray-200`}>
																									<td scope='row' className='p-0' colSpan={11}>
																										{(patientPrescriptionDetailsResponse?.loading && idx === rowIndex && loading) ?
																											<div className="flex justify-center items-center p-5">
																												<Common.ProfilePicLoader />
																											</div> :
																											patientPrescriptionDetailsResponse?.data?.data?.data?.length === 0 && idx === rowIndex ? null :

																												patientPrescriptionDetailsResponse?.data !== null && idx === rowIndex ?
																													<div className="relative overflow-x-auto">
																														<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto">
																															<thead className="text-xs text-black-800 uppercase" style={{ background: "#6b7cc6", color: 'white', border: '0.1px solid gray' }}>
																																<tr style={{ padding: '0px' }}>
																																	{constant.archivedSubRowsHeadings?.map((column, index) => (
																																		<th key={index} scope="col" className={column?.classNames?.join(' ')}>
																																			<p className="rubik-500 fs-14">{column.text}</p>
																																		</th>
																																	))}

																																</tr>
																															</thead>

																															<tbody>
																																{
																																	prescriptionDetailList?.prescriptions?.map((row, idx) => (
																																		<tr key={idx} style={{ border: '0.1px solid gray', backgroundColor: row?.color }}>

																																			<PrescriptionListValues value={row.rx_number} />
																																			<PrescriptionListValues value={row.din} />
																																			<PrescriptionListValues value={row.processing} />

																																			<th scope="col" className="p-3">
																																				<p className="rubik-400 fs-14">
																																					<input type='text'
																																						defaultValue={row.wit}

																																						onKeyPress={(e) => {
																																							const char = String.fromCharCode(e.charCode);
																																							const value = e.target.value;
																																							if (!/[0-9.]/.test(char) || (char === '.' && value.includes('.'))) {
																																								e.preventDefault();
																																							}
																																						}}
																																						onChange={(e) => {
																																							return setLoading(false), setTimeout(() => {
																																								e.target.value && dispatch(pharmacyPatientUpdateWitnessDose('update_wit_dose', setShowModal, row.rx_number, row.id, e.target.value, value.patient_id, formData))
																																							}, 200)
																																						}}

																																						style={{ border: 'none', padding: '0px', backgroundColor: row?.color }}
																																						className='block pl-2 pt-4 w-full text-sm  bg-transparent border-1 
																																					border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
																																					cursor-text bg-white text-gray-700 font-sm'/>
																																				</p>
																																			</th>

																																			<PrescriptionListValues value={row.carry} className='' />
																																			<PrescriptionListValues value={row.drug} />
																																			{/* <PrescriptionListValues value={row.sig} /> */}

																																			<th scope="col" className="p-3">
																																				<p className="rubik-400 fs-14 text-gray-700">
																																					{row.sig}
																																				</p>
																																				{row.note &&
																																					<p className='rubik-400 p-1  fs-10 rounded-lg' style={{ fontStyle: 'italic', color: 'black', background: '#e8dede' }}>{row.note}</p>}
																																			</th>

																																			<th scope="col" className="p-3" >
																																				<div className="rubik-400 fs-14 flex text-gray-700 font-medium">{row.date}

																																					{value.status === 'pending' ?
																																						<p className="rubik-400 fs-12 text-white bg-yellow-500 p-1 w-4 h-4 flex justify-center items-center rounded-sm ml-1" style={{ color: '' }}>P</p> :
																																						value.status === 'missed' ?
																																							<p className=' flex items-end justify-end'>
																																								<p className="rubik-400 fs-12 text-white bg-red-500 p-1 w-4 h-4 flex justify-center items-center rounded-sm ml-1">M</p></p> :
																																							value.status === 'delivered' ?
																																								<>
																																									<p className='flex items-end justify-end'>
																																										<p className="rubik-400 fs-12 text-white bg-green-500 p-1 w-4 h-4 flex justify-center items-center rounded-sm ml-1">D</p></p>
																																								</> :
																																								value.status === 'today_missed' ?
																																									<p className=' flex items-end justify-end'>
																																										<p className="rubik-400 fs-12 text-white bg-red-500 p-1 w-4 h-4 flex justify-center items-center rounded-sm ml-1">T</p></p>
																																									: value.status === 'today_missed' ?
																																										<p className=' flex items-end justify-end'>
																																											<p className="rubik-400 fs-12 text-white bg-red-500 p-1 w-4 h-4 flex justify-center items-center rounded-sm ml-1">T</p></p>
																																										: null}

																																					{row.re_reasion && <p className='rubik-400 fs-14 flex justify-center items-center'>({row.re_reasion})</p>}

																																				</div>
																																			</th>

																																			<th scope="col" className="p-3 btnnn rounded-e-lg "
																																				// onClick={() => { return dispatch(pharmacyPatientPrescriptionViewPdf(row.sheet, 'view_sheet', setShowModal)), setSubRowIndex(idx) }}
																																				onClick={() => { handleDownLoadRxSheet(row.rx_number) }}
																																			>
																																				<p className="rubik-400 fs-14 text-gray-700 font-medium">
																																					{/* {addDispenseDataResponse?.loading && idx === subRowIndex &&
																																						addDispenseDataResponse?.type === 'view_sheet' ?
																																						<div className='flex items-center justify-center '><Common.ProfilePicLoader /></div> : 'View'} */}
																																					View
																																				</p>
																																			</th>
																																		</tr>
																																	))
																																}
																															</tbody>
																														</table>
																													</div> : null}
																									</td>
																								</tr>
																							</React.Fragment>
																						))
																					}
																				</tbody>
																			</table>


																			{archivedDelveriesListResponse?.error !== null ? null : <>
																				{archivedDelveriesList?.data?.length !== 0 && <>
																					{archivedDelveriesList?.pagination?.last_page !== 1 &&
																						<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
																							<nav aria-label="Page navigation example">
																								<ul className="inline-flex -space-x-px text-base h-10">
																									<CustomPagination
																										count={archivedDelveriesList?.pagination?.last_page}
																										page={pageNumber}
																										onChange={(event, value) => {
																											handleFilterData(event, value)
																										}}
																									/>
																								</ul>
																							</nav>
																						</div>
																					}
																				</>}
																			</>}
																		</div>
																	</div>}
													</React.Fragment>
												</>}
											</div>
											{/* ---------------------------------------------Search By RX Number Start----------------------------------------------------------  */}








											{/* ---------------------------------------------Search By RX Number Start----------------------------------------------------------  */}
											<div className={`rounded-lg dark:bg-gray-800 ${activeTab === 'searchrxsheet' ? '' : 'hidden'}`} id="filter-row" role="tabpanel" aria-labelledby="filter-row-tab">
												{activeTab === 'searchrxsheet' && <>
													<div className="flex flex-wrap items-center justify-between md:w-full lg:w-4/12">
														<div className="flex-grow  md:w-auto">

															<div className="relative w-full">
																<input type="text" id={'rxnumber'} autoComplete="off"
																	onChange={(e) => setFormData({ ...formData, rxnumber: e.target.value })}
																	name={'rxnumber'}
																	style={{ height: '42px' }}
																	value={formData.rxnumber}
																	className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
																	dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
																	bg-white `}
																	placeholder=""
																	onKeyPress={(e) => {
																		if (
																			isNaN(String.fromCharCode(e.charCode))) {
																			e.preventDefault();
																		}
																	}}
																/>
																<label htmlFor={'rxnumber'}
																	className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
																		origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
																		peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
																		peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
																	RX Number
																</label>
															</div>
														</div>

														<button onClick={handleFilterRxNumber}
															style={{ background: '#3B82F6', color: 'white' }}
															type="button"
															className="flex-shrink-0 py-2.5 text-sm text-gray-900 bg-white border border-gray-300 
																focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium 
																rounded-lg dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 
																dark:hover:border-gray-600 dark:focus:ring-gray-700 w-24 ml-2">
															Filter
														</button>
													</div>

													{searchByRxNumberArchivedListResponse?.error !== null
														&& searchByRxNumberArchivedListResponse?.error?.data?.error === 'Rx number not found.'
														&& searchByRxNumberArchivedListResponse?.type === 'export_pdf' ?
														searchByRxNumberArchivedListResponse?.error?.data?.error :
														searchByRxNumberArchivedListResponse?.error !== null && searchByRxNumberArchivedListResponse?.type === 'export_pdf' ?
															'Something went wrong while generating the PDF. Please try again' : null
													}

													<React.Fragment>
														{searchByRxNumberArchivedListResponse?.loading && searchByRxNumberArchivedListResponse?.type === 'rx_list' && rxLoading ?
															<div className="flex justify-center items-center mt-60 mb-80">
																<Common.LoadingView />
															</div> :
															searchByRxNumberArchivedListResponse?.error !== null
																&& searchByRxNumberArchivedListResponse?.error?.data?.error === 'Rx number not found.' && searchByRxNumberArchivedListResponse?.type === 'rx_list' ?
																searchByRxNumberArchivedListResponse?.error?.data?.error
																: searchByRxNumberArchivedListResponse?.error !== null && searchByRxNumberArchivedListResponse?.error?.data === 'Invalid RX' && searchByRxNumberArchivedListResponse?.type === 'rx_list' ?
																	<h1 className="text-lg font-medium text-red-600 mt-2">Invalid RX.</h1>
																	:
																	searchByRxNumberArchivedListResponse?.error !== null && searchByRxNumberArchivedListResponse?.type === 'rx_list' ?
																		<div className="flex flex-col items-center justify-center mt-60 mb-80">
																			<h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
																			<p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
																			<button onClick={() => window.location.reload()}
																				className="px-4  bg-red-600 text-white rounded hover:bg-red-700 
                            														   focus:outline-none focus:ring-2 focus:ring-red-500">
																				Please Try Again.
																			</button>
																		</div> :

																		searchByRxNumberArchivedListResponse?.data !== null && searchByRxNumberArchivedListResponse?.type === 'rx_list' ?
																			<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
																				<div className="shadow-md border rounded-lg relative overflow-x-auto">
																					<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto">
																						<thead className="text-xs text-white uppercase blueBg-color dark:bg-gray-700 dark:text-gray-400">
																							<tr>
																								{constant.RxNumberRowHeading?.map((column, index) => (
																									<th key={index} scope="col" className={column.classNames.join(' ')}>
																										{column.text && <p className={column.pStyle} >{column.text}</p>}
																									</th>
																								))}
																							</tr>
																						</thead>

																						<tbody>
																							<React.Fragment>
																								<tr style={{ borderBottom: `1px solid lightgray` }}>

																									<th scope="col" className="p-2 no-wrap w-56">
																										<p className="rubik-400">{searchByRxNumberArchivedList?.date}</p>
																									</th>

																									<th scope="col" className="p-2">
																										<p className="rubik-400 fs-14">{searchByRxNumberArchivedList?.route}</p>
																									</th>

																									<th scope="col" className="p-2">
																										<p className="rubik-400 fs-14">{searchByRxNumberArchivedList?.name}</p>
																									</th>

																									<th scope="col" className="p-2">
																										<p className="rubik-400 fs-14">{searchByRxNumberArchivedList?.phn}</p>
																									</th>

																									<th scope="col" className="p-2">
																										<p className="rubik-400 fs-14">{searchByRxNumberArchivedList?.phone}</p>
																									</th>

																									<th scope="col" className="p-2 text-right rounded-e-lg btnnn">
																										<p className="rubik-400 fs-14 flex items-end justify-end"
																											onClick={() => dispatch(pharmacyPatientPrescriptionViewPdf(searchByRxNumberArchivedList?.sheet, 'view_sheet', setShowModal))}>
																											{addDispenseDataResponse?.loading && addDispenseDataResponse?.type === 'view_sheet' ? <Common.ProfilePicLoader /> : 'View'}
																										</p>
																									</th>
																								</tr>

																								<tr className={`bg-gray-200`}>
																									<td scope='row' className='p-0' colSpan={11}>
																										<div className="relative overflow-x-auto">
																											<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto">

																												<thead className="text-xs text-black-800 uppercase" style={{ background: 'rgb(107, 124, 198)', color: 'white', border: '0.1px solid gray' }}>
																													<tr style={{ padding: '0px' }}>
																														{constant.RxNumberRowSubRowsHeadings?.map((column, index) => (
																															<th key={index} scope="col" className={column?.classNames?.join(' ')}>
																																<p className="rubik-500 fs-14">{column.text}</p>
																															</th>
																														))}

																													</tr>
																												</thead>

																												<tbody>
																													{
																														searchByRxNumberArchivedList?.rx_drug_detail?.map((value, idx) => (
																															<tr key={idx} style={{ border: '0.1px solid gray' }}>

																																<PrescriptionListValues value={value.rx} />
																																<PrescriptionListValues value={value.narc} />
																																<PrescriptionListValues value={value.date} style={{ whiteSpace: 'nowrap' }} />
																																<PrescriptionListValues value={value.din} />
																																<PrescriptionListValues value={value.proceessing} style={{ whiteSpace: 'nowrap' }} />

																																<th scope="col" className="p-3">
																																	<p className="rubik-400 fs-14">

																																		<input type='text'
																																			defaultValue={value.wit}
																																			onKeyPress={(e) => {
																																				const char = String.fromCharCode(e.charCode);
																																				const value = e.target.value;
																																				if (!/[0-9.]/.test(char) || (char === '.' && value.includes('.'))) {
																																					e.preventDefault();
																																				}
																																			}}

																																			onChange={(e) => {
																																				return setRxLoading(false), setTimeout(() => {
																																					e.target.value &&
																																						dispatch(pharmacyPatientUpdateWitnessDose('update_wit_dose', setShowModal, value.rx, value.logid, e.target.value, value.patient_id, formData, 'rx_sheet_update_wit_dose'))
																																				}, 200)
																																			}}

																																			style={{ border: 'none', padding: '0px', backgroundColor: value?.color }}
																																			className='block pl-2 pt-4 w-full text-sm  bg-transparent border-1 
																																					border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
																																					cursor-text text-gray-700 font-sm'/>
																																	</p>
																																</th>

																																<PrescriptionListValues value={value.carry} className='' />
																																<PrescriptionListValues value={value.drug} className='' />
																																<PrescriptionListValues value={value.sig} />

																																<th scope="col" className="p-3 btnnn rounded-e-lg flex">
																																	<p className="rubik-400 fs-14">{value.proceessing_date_time}</p>
																																	{value.dflag && <p className='-pl-2'><Icons.FlagIcon color='red' /></p>}
																																</th>
																																<th scope="col" className="p-3 btnnn rounded-e-lg">
																																	{value.status === 'pending' ?
																																		<p className="rubik-400 fs-14 text-yellow-500" style={{ color: '' }}>Pending</p> :
																																		value.status === 'missed' ?
																																			<p className="rubik-400 fs-14 text-red-500">Missed</p> :
																																			value.status === 'delivered' ?
																																				<p className="rubik-400 fs-14 text-green-500">Delivered</p> :

																																				value.status === 'returned' ?
																																					<p className="rubik-400 fs-14 text-red-500">Returned</p> :
																																					null}
																																</th>

																															</tr>
																														))
																													}
																												</tbody>
																											</table>
																										</div>
																									</td>
																								</tr>
																							</React.Fragment>

																						</tbody>
																					</table>
																				</div>
																			</div> : null}
													</React.Fragment>
												</>}
											</div>
											{/* ---------------------------------------------Search By RX Number Start----------------------------------------------------------  */}

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ChatBox />
			<Common.Footer />

			{
				showModal?.showToast && addDispenseDataResponse?.type === 'set_pin' &&
				<Toast.SuccessToastMessage status='Updated Successfully' />
			}

			{
				showModal?.showToast && syncTodaysDelveriesResponse?.type === 'sync_todays_delveries' &&
				<Toast.SuccessToastMessage status='Updated Successfully' />
			}

			{
				showModal?.errorToast && addDispenseDataResponse?.type === 'set_pin' &&
				<Toast.ErrorToast status='Got an issue while Updating.please try again!' />
			}

			{
				showModal?.showToast && addDispenseDataResponse?.type === 'add_note' &&
				<Toast.SuccessToastMessage status='Note added Successfully' />
			}

			{
				showModal?.errorToast && addDispenseDataResponse?.type === 'add_note' &&
				<Toast.ErrorToast status='Got an issue while adding notes.please try to add again!' />
			}

			{
				showModal?.pdfError && addDispenseDataResponse?.type === 'view_sheet' &&
				<Toast.ErrorToast status='Got an issue while Genrating PDF. please try again' />
			}

			{
				showModal?.witError && pharmacyPatientUpdateRxWitnessDoseResponse?.type === 'update_wit_dose' &&
				<Toast.ErrorToast status='Got an issue while updating. please try again' />
			}


			{
				showModal?.witSuccess && pharmacyPatientUpdateRxWitnessDoseResponse?.type === 'update_wit_dose' &&
				<Toast.SuccessToastMessage status='Updated Successfully.' />
			}

			<EditNoteModal patientData={patientData} activeTab={activeTab} noteValue={selectedRow?.NOTE || ""} formData={formData} setFormData={setFormData} pageNumber={pageNumber}
				showModal={showModal} onClose={() => Modal.handleHideModal(setShowModal, showModal, 'editNoteModalVisible')} setShowModal={setShowModal} />

			{showModal.GetDelDif && (
				<GetDelDif
					formData={formData}
					dataid={selectedRowID}
					showModal={showModal}
					onClose={() => setShowModal(prevState => ({ ...prevState, GetDelDif: false }))}
					setShowModal={setShowModal}
				/>
			)}
		</React.Fragment >
	)
}

export default ArchivedDispense