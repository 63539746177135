import React, { useEffect, useState } from "react";
import { useDispenseSelectors } from "../../../services/selectors/dispenseSelectors";
import { currentDate } from '../../../utils/constants/formInitialStates';
import { fetchReverseDeliveries } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'
import { CustomPagination } from "../../../components/pharmacy/dashboard/pagination";
import * as Dispense from '../../../components/pharmacy/dispense/dispense'

import * as Icons from '../../../assets/svg/icons'
import {
	DropdownSelect,
	TextInput,
} from "../../../components/pharmacy/dispense/dispense";
import { initalShowToastMessage } from "../../../utils/constants/formInitialStates";
import * as Common from "../../../components/common";
import * as Hooks from "../../../hooks";
import * as routeNames from "../../../routes/routeName";

import ChatBox from "../../../components/chat/chatbox";
import { ReUploadPrescriptionImage, ViewUploadPrescriptionImage, ViewUploadRePrescriptionImage } from "../../../models/pharmacy/prescriotionPopUp";

const memoListHeading = [
	{ text: "ROUTE", classNames: ["p-3"] },
	{ text: "NAME", classNames: ["p-3 no-wrap"] },
	{ text: "PHN", classNames: ["p-3"] },
	{ text: "PHONE", classNames: ["p-3"] },
	{ text: "STATUS", classNames: ["p-3"] },

]


const TableRowData = ({ value, isReminder = false, className1 = "p-3", align = "" }) => {
	return (
		<td scope="row" className={className1}>
			<div className="flex " >
				<p className={align}>{value}</p>
				{isReminder && (
					<div className="w-[32px] cursor-pointer h-[32px]" style={{ width: "15px" }}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 
                        58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 
                        0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" /></svg>
					</div>
				)}
			</div>
		</td>
	);
};


const PharmacyReversedDelveries = () => {

	const location = Hooks.useCustomLocation();
	const navigate = Hooks.useCustomNavigate();
	const dispatch = Hooks.useCustomDispatch();

	const { dispenseMemo } = useDispenseSelectors();
	const memoList = dispenseMemo?.data?.data;
	const [formData, setFormData] = useState({ search: "", type: "", date: currentDate, patientRoutes: '', status: "" });

	const searchParams = new URLSearchParams();

	const initialPage = parseInt(new URLSearchParams(location.search).get("page")) || 1;
	// const from_date = new URLSearchParams(location.search).get('from_date') || formData?.startDate
	// const to_date = new URLSearchParams(location.search).get('to_date') || formData?.endDate

	const [reset, setReset] = useState(false);
	const [pageNumber, setPageNumber] = useState(initialPage);


	

	const [expandedRows, setExpandedRows] = useState([]);

	const handleRowClick = (idx) => {
		setExpandedRows((prevExpandedRows) =>
			prevExpandedRows.includes(idx) ?
				prevExpandedRows.filter((rowIdx) => rowIdx !== idx) :
				[...prevExpandedRows, idx]
		);
	};
	// React.useEffect(() => {
	//     setFormData({
	//         ...formData,
	//         date: from_date,
	//         route: to_date
	//     })
	//     return () => { }
	// }, [from_date, to_date])

	useEffect(() => {
		setPageNumber(initialPage);
	}, [initialPage]);

	useEffect(() => {
		const params = {
			page: pageNumber,
			no_of_records: 10,
			date: formData.date
		};
		dispatch(fetchReverseDeliveries("ists_ru_rp_re", params));
		return () => { };
	}, []);


	const handlePagination = (event, value) => {
		setExpandedRows([])
		const params = {
			page: value || pageNumber,
			no_of_records: 10,
		};

		if (formData.patientRoutes) params.route = formData.patientRoutes.label;
		if (formData.search) params.search = formData.search;
		if (formData.date) params.search_date = formData.date;
		if (formData.status != "") params.status = formData.status;

		if (value !== undefined) {
			searchParams.append("page", value);
			setPageNumber(value);
		}

		dispatch(fetchReverseDeliveries("ists_ru_rp_re", params));
		const newUrl = `${location.pathname}?${searchParams.toString()}`;
		navigate(newUrl);
	};

	const handleResetFilteredData = () => {
		const searchParams = new URLSearchParams();
		setReset(true)
		setFormData({ ...formData, search: '', patientRoutes: '', date: currentDate, status: '', })
		setTimeout(() => {
			setReset(false)
		}, 500);
		const newUrl = `${routeNames.pharmacy_reversed_deliveries}?${searchParams.toString()}`;
		dispatch(fetchReverseDeliveries("ists_ru_rp_re", searchParams));
		navigate(newUrl);
	}
	const isRowExpanded = (idx) => expandedRows.includes(idx);

	return (
		<>
			<div className="BodyBgColor">
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="grid grid-cols-12 gap-4">
						{/* <Common.Sidebar /> */}
						<div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content">
								<div className="grid grid-cols-12 gap-4 w-full">
									<div
										className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left"
									>
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<Common.BreadCrumb />
										</h2>
									</div>
								</div>
								<div className="container mt-2">
									<div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="col-span-12 flex">
											<div className="flex gap-2">
												<div className='w-34'>
													<TextInput
														label="Search"
														name="search"
														formData={formData}
														setFormData={setFormData}
														height="42px"
													/>
												</div>


												<div className='w-34 ml-2'>
													<Dispense.StartDate formData={formData}
														setFormData={setFormData} />
												</div>
												<div className='md:flex-1 lg:w-4/12 md:w-4/12 sm:w-6/12 sm:pr-3 md:pr-0 w-full'>
													<Dispense.RouteList formData={formData}
														setFormData={setFormData} />
												</div>
												<div className='w-34 '>
													<DropdownSelect
														label="Select Status"
														height="44px"
														name="status"
														formData={formData}
														setFormData={setFormData}
														options={[
															{ value: "", label: "Select Status" },
															{ value: "RR", label: "RR" },
															{ value: "RE", label: "RE" },
															{ value: "RU", label: "RU" },
														]}
													/>
												</div>
												<button
													type="button"
													style={{ backgroundColor: "#3B82F6", width: "100px" }}
													onClick={handlePagination}
													className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
                                                            border border-gray-300 			focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
													Filter
												</button>

												<button onClick={handleResetFilteredData} type="button" className="   h-full  py-4 items-center justify-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
													Reset
												</button>

											</div>
										</div>

										{dispenseMemo?.loading &&
											dispenseMemo?.type === "ists_ru_rp_re" ? (
											<div className="flex justify-center items-center mt-60 mb-80">
												<Common.LoadingView />
											</div>
										) : dispenseMemo?.error !== null &&
											dispenseMemo?.type === "ists_ru_rp_re" ? (
											<div className="flex flex-col items-center justify-center mt-60 mb-80">
												<h1 className="text-4xl font-bold text-red-600 mb-2">
													Oops!
												</h1>
												<p className="text-lg text-gray-800 mb-2">
													Something went wrong while fetching the data.
												</p>
												<button
													onClick={() => window.location.reload()}
													className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500"
												>
													Please Try Again.
												</button>
											</div>
										) : memoList?.record?.length === 0 &&
											dispenseMemo?.type === "ists_ru_rp_re" ? (
											<div className="bg-white rounded-md">
												<p className="text-gray-500">
													No Record available
												</p>
											</div>
										) : dispenseMemo?.type === "ists_ru_rp_re" ? (
											<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
												<div className="shadow-md border rounded-lg  relative overflow-x-auto">
													<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
														<thead
															className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900"
															style={{
																borderBottom: "1px solid lightgray",
																background: "#f6f4f4",
															}}
														>
															<tr>
																{memoListHeading?.map((header, index) => (
																	<th
																		key={index}
																		scope="col"
																		className={header?.classNames?.join(" ")}
																	>
																		<p className="rubik-500 fs-14">
																			{header?.text}
																		</p>
																	</th>
																))}
															</tr>
														</thead>

														<tbody>
															{memoList.record?.map((value, idx) => (
																<React.Fragment key={idx}>
																	<tr
																		style={{ borderBottom: "1px solid lightgray", cursor: "pointer" }}
																		onClick={() => handleRowClick(idx)}
																	>
																		<td scope="row" >
																			<div className="flex p-1">
																				{isRowExpanded(idx) ? (
																					<Icons.ClosedEyeIcon style={{ marginRight: 10 }} />
																				) : (
																					<Icons.EyeIcon style={{ marginRight: 10 }} />
																				)}
																				<div>{value.route}</div>
																			</div>

																		</td>

																		<TableRowData value={`${value.last_name} ${value.first_name}`} />
																		<TableRowData value={value?.phn} />
																		<TableRowData value={value?.phone} />
																		<td scope="col" className="p-3 text-center rounded-e-lg float-left">
																			<div className="flex items-end justify-end">
																				<p className="rubik-400 fs-12 text-white bg-red-500 p-1 w-6 h-6 flex justify-center items-center rounded-full">
																					{value?.status}
																				</p>
																			</div>
																			<div className="flex justify-end flex-nowrap"></div>
																		</td>
																	</tr>
																	{expandedRows.includes(idx) && (
																		<tr>
																			<td colSpan="5">
																				<table className="table w-full p-1" style={{ backgroundColor: "" }}>
																					<thead>
																						<tr style={{ fontSize: "12px" }}>
																							<th className="p-1" scope="row">RX</th>
																							<th className="p-1" scope="row"> DIN</th>
																							<th className="p-1" scope="row">PROC QTY</th>
																							<th className="p-1" scope="row">WIT</th>
																							<th className="p-1" scope="row">CARRY</th>
																							<th className="p-1" scope="row">DRUG</th>
																							<th className="p-1" scope="row">SIG</th>
																							<th className="p-1" scope="row">STATUS</th>
																						</tr>
																					</thead>
																					<tbody>
																						{value.windata.map((windataItem, windataIdx) => (
																							<tr className="p-1" key={windataIdx} style={{ backgroundColor: windataItem.color }}>
																								<td className="p-1" scope="row">{windataItem?.rxnumber}</td>
																								<td className="p-1" scope="row">{windataItem?.din}</td>
																								<td className="p-1" scope="row">{windataItem?.refill_reqty}</td>

																								<td className="p-1" scope="row">{windataItem.witness}</td>
																								<td className="p-1" scope="row">{windataItem?.carry}</td>
																								<td className="p-1" scope="row">{windataItem?.drug}</td>
																								<td className="p-1" scope="row">{windataItem?.rxsig}</td>
																								<td className="p-1" scope="row">
																									<p className="rubik-400 fs-12 text-white bg-red-500 p-1 w-6 h-6 flex justify-center items-center rounded-full">
																										{windataItem?.re_reasion}
																									</p>

																								</td>
																							</tr>
																						))}
																					</tbody>
																				</table>
																			</td>
																		</tr>
																	)}

																</React.Fragment>
															))}
														</tbody>
													</table>

													{dispenseMemo?.error !== null ? null : (
														<>
															{memoList.record?.length !== 0 && (
																<>
																	{memoList.pagination?.last_page !== 1 && (
																		<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
																			<nav aria-label="Page navigation example">
																				<ul className="inline-flex -space-x-px text-base h-10">
																					<CustomPagination
																						count={
																							memoList.pagination?.last_page
																						}
																						page={pageNumber}
																						onChange={(event, value) => {
																							handlePagination(event, value);
																						}}
																					/>
																				</ul>
																			</nav>
																		</div>
																	)}
																</>
															)}
														</>
													)}

												</div>
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ChatBox />


		</>
	);
}

export default PharmacyReversedDelveries;