import React, {
    useEffect,
    useState
} from 'react'

import {
    pharmacyPreviewInvoicePdf,
    previewPdfFailure
} from '../../../services/actions/pharmacy/forms/previewPdf'

import { fetchPreviousInvoiceList } from '../../../services/actions/pharmacy/blisters/patientBlisterPackList'
import { useBlisterSelectors } from '../../../services/selectors/blisterSelectors'
import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'

import { useFormsSelectors } from '../../../services/selectors/formsSelector'
import { Errors } from '../../../components/error/alertMessages'

import ChatBox from '../../../components/chat/chatbox'

import * as Common from '../../../components/common'
import * as Hooks from '../../../hooks'

const invoiceListHeading = [
    { text: "INVOICE NUMBER", classNames: ['p-3'] },
    { text: "CUSTOMER NAME", classNames: ['p-3'] },
    { text: "PHN", classNames: ['p-3'] },
    { text: "PHONE", classNames: ['p-3'] },
    { text: "DATE", classNames: ['p-3'] },
    { text: "TOTAL", classNames: ['p-3'] },
    { text: "", classNames: ['p-3'] },
];

export const TableRowData = ({ value, className1 = 'p-3', }) => {
    return (<td scope="row" className={className1}>
        <p>{value}</p>
    </td>)
}

const PreviousInvoicesList = () => {

    const dispatch = Hooks.useCustomDispatch()
    const location = Hooks.useCustomLocation()
    const navigate = Hooks.useCustomNavigate()

    const { patientBlisterPackListResponse } = useBlisterSelectors()
    const { previewPdfResponse } = useFormsSelectors()

    const previousInvoiceList = patientBlisterPackListResponse?.data?.data

    const searchParams = new URLSearchParams();

    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;
    const [pageNumber, setPageNumber] = useState(initialPage)
    const [invoiceID, setInvoiceId] = useState(null)

    useEffect(() => {
        if (initialPage) {
            setPageNumber(initialPage)
        }
    }, [initialPage])

    useEffect(() => {
        const params = {
            page: pageNumber,
            no_of_records: 10
        }
        dispatch(fetchPreviousInvoiceList('previous_invoice_list', params))
        return () => { }
    }, [])

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
            no_of_records: 10
        };

        if (value) {
            setPageNumber(value);
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl);
        }

        dispatch(fetchPreviousInvoiceList('previous_invoice_list', params))
    };

    useEffect(() => {
        dispatch(previewPdfFailure(null))
    }, [])

    return (
        <React.Fragment>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        <Common.Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        {previewPdfResponse?.error !== null &&
                                            <div className='ml-auto w-54'>
                                                <Errors error={'Oops Got an issue while fetching PDF Details. Please try again.'} />
                                            </div>}

                                        {patientBlisterPackListResponse?.loading && patientBlisterPackListResponse?.type === 'previous_invoice_list' ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            patientBlisterPackListResponse?.error !== null && patientBlisterPackListResponse?.type === 'previous_invoice_list' ?
                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div> :
                                                previousInvoiceList?.data?.length === 0 && patientBlisterPackListResponse?.type === 'previous_invoice_list' ?
                                                    <div className="bg-white rounded-md">
                                                        <p className="text-gray-500">No record available..</p>
                                                    </div>
                                                    : patientBlisterPackListResponse?.type === 'previous_invoice_list' ?
                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2">
                                                            <div className="shadow-md border rounded-lg  relative">
                                                                <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                    <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {invoiceListHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            previousInvoiceList?.data?.map((value, idx) => (
                                                                                <React.Fragment key={idx}>
                                                                                    <tr style={{ borderBottom: `${'1px solid lightgray'}` }}>

                                                                                        <TableRowData value={value.invoice_number} />
                                                                                        <TableRowData value={value.customer_name} />

                                                                                        <TableRowData value={value.phn} />

                                                                                        <TableRowData value={value.phone} />
                                                                                        <TableRowData value={value.date} />
                                                                                        <TableRowData value={value.total} />

                                                                                        <td scope="row" className={'p-3'} onClick={() => { return setInvoiceId(idx), dispatch(pharmacyPreviewInvoicePdf(value?.view)) }}>
                                                                                            <p className='bg-blue-500 flex btnnn p-1 justify-center items-center fs-12 rounded-md text-white'>
                                                                                                {idx === invoiceID && previewPdfResponse?.loading ?
                                                                                                    <Common.Loader />
                                                                                                    : 'View PDF'}

                                                                                            </p>
                                                                                        </td>

                                                                                    </tr>
                                                                                </React.Fragment>)

                                                                            )}
                                                                    </tbody>
                                                                </table>


                                                                {patientBlisterPackListResponse?.error !== null ? null : <>
                                                                    {previousInvoiceList?.data?.length !== 0 && <>
                                                                        {previousInvoiceList?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={previousInvoiceList?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={handlePagination}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}
                                                            </div>

                                                        </div> : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />

            <Common.Footer />
        </React.Fragment>
    )
}

export default PreviousInvoicesList