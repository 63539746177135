import {
    ADD_TEAM_DATA_REQUEST,
    ADD_TEAM_DATA_SUCCESS,
    ADD_TEAM_DATA_FAILURE
} from "../constants"

import {
    assign_certificate_employee,
    pharmacy_save_certifiate,
    pharmacy_task,
    puser_attendance_sheet, update_certificate_template,
    update_shift_users,
    delete_shift_user,
    update_break_setting,
    update_payroll_setting,
    pharmacy_team_users,
    pharmacy_user_departments,
    pharmacy_delete_department,
    pharmacy_update_department,
    pharmacy_user_department,
    on_boarding_user_profile_update
} from "../../../../networking/urlEndPoints"

import {
    fetchAllTaskLists,
    fetchDefaultpharmacistSettingsList,
    fetchPharmacyDeliverySettingsList,
    fetchPharmacyDepartmentAssigns,
    fetchPharmacyTeamScheduleList,
    fetchPharmacyTeamTimeSheet,
    fetchPharmacyTeamsList
} from "./fetchTeamsList"

import { pharmacy_assign_certificate } from "../../../../routes/routeName"

import { PreviewPdf } from "../../../../utils/helper/helper"
import { authToken } from "../../../../storage/authToken"
import { persistor } from "../../../store/store"
import * as pharmacy from '../../../../services/actions/pharmacy/other';
import Axios from "../../../../networking/intraceptor"
import axios from "axios"
import Cookies from "js-cookie"

export const addTeamDataRequest = (fetchType) => ({
    type: ADD_TEAM_DATA_REQUEST,
    fetchType: fetchType
})

export const addTeamDataSuccess = (data, fetchType) => ({
    type: ADD_TEAM_DATA_SUCCESS,
    payload: data,
    fetchType: fetchType
})

export const addTeamDataFailure = (error, fetchType) => ({
    type: ADD_TEAM_DATA_FAILURE,
    payload: error,
    fetchType: fetchType
})

// ----------------------------------------------------Create Task Section Start----------------------------------

export const handleAddNewTask = (type, reqData, setShowToast) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))
        try {
            const response = await Axios.post(`${pharmacy_task}`, reqData)
            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))
                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }

            }
        }
    }
}


export const handleUpdateNewTasks = (type, reqData, setShowToast, id, setShowModal, paginationNumber) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))
        try {
            const response = await Axios.put(`${pharmacy_task}/${id}`, reqData)
            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }

                setShowModal((prevstat) => ({ ...prevstat, editTask: false }))
                document.body.style.overflow = 'auto'

                const params = {
                    page: paginationNumber
                }

                dispatch(fetchAllTaskLists('task_list', params))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }

            }
        }
    }
}

export const handleDeleteTasksList = (type, setShowToast, id, setShowModal, paginationNumber) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))
        try {
            const response = await Axios.delete(`${pharmacy_task}/${id}`)
            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }

                setShowModal((prevstat) => ({ ...prevstat, deleteTask: false }))
                document.body.style.overflow = 'auto'

                const params = {
                    page: paginationNumber
                }

                dispatch(fetchAllTaskLists('task_list', params))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }

            }
        }
    }
}

export const HandleUpdateTaskStatus = (type, setShowToast, id, newStatus) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        try {
            const reData = {
                status: newStatus
            }

            const response = await Axios.patch(`${pharmacy_task}/${id}/status`, reData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }

            }
        }
    }
}

export const handleAddComments = (type, setShowToast, reData, id, setCommentsData, commentsEndRef) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        try {
            const response = await Axios.post(`${pharmacy_task}/${id}/conversations`, reData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))
                setTimeout(() => {
                    commentsEndRef.current?.scrollIntoView({ behavior: 'smooth', block: "end" });
                }, 100)
                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }

                setCommentsData((prevstat) => ({ ...prevstat, reply: '' }))

                // dispatch(fetchTaskCommentsList('comments_list', id))

            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }

            }
        }
    }
}

// ----------------------------------------------------Create Task Section End----------------------------------

export const handleAddCertificateAsTemplate = (type, setShowToast, json, template_name) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        const jsonDateToBeSent = {
            canva_json_data: json,
            certificate_title: template_name
        }
        try {
            const response = await Axios.post(`${pharmacy_save_certifiate}`, jsonDateToBeSent)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, showToast: false }))
                    }, 2000);
                }
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 3000);
                }

            }
        }
    }
}


export const assignCertificateToEmployee = (type, setShowToast, requestData, navigate) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        try {
            const response = await Axios.post(`${assign_certificate_employee}`, requestData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, showToast: false }))
                        navigate(pharmacy_assign_certificate);
                    }, 2000);
                }
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 3000);
                }

            }
        }
    }
}




// ----------------------------------------------------Time Sheet Section Start----------------------------------

export const handleExportTimeSheetPdf = (type, params) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        try {
            const response = await Axios.get(`${puser_attendance_sheet}`, { params })

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))
                PreviewPdf(response?.data?.data)
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))
            }
        }
    }
}

export const handleAddTeamWorkTimeShift = (type, requestData, formdata, setShowToast, setShowModal, setErrorMessages) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        setErrorMessages((prevstat) => ({
            ...prevstat,
            breakOutTime: '',
            breakInTime: '',
            checkOutTime: '',
            checkInTime: ''
        }))

        try {
            const response = await Axios.post(`add-shift-users`, requestData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))
                const { startDate, endDate } = formdata

                const params = {
                    from_date: startDate,
                    to_date: endDate,
                }
                dispatch(fetchPharmacyTeamTimeSheet('team_management_sheet', params))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }
                setShowModal((prevstat) => ({ ...prevstat, addWorkShift: false }))
                document.body.style.overflow = 'auto'

                setErrorMessages((prevstat) => ({ ...prevstat, breakOutTime: '' }))

            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))
                const err = error?.response?.data?.errors
                if (err) {
                    setErrorMessages((prevstat) => ({
                        ...prevstat,
                        breakOutTime: err?.breakouttime,
                        breakInTime: err?.breakintime,
                        checkOutTime: err?.checkouttime,
                        checkInTime: err?.checkintime
                    }))
                } else
                    if (typeof setShowToast === 'function') {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                        setTimeout(() => {
                            setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                        }, 2000);
                    }
            }
        }
    }
}

export const handleUpdateCertificateAsTemplate = (type, setShowToast, json, template_name, id) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        const jsonDateToBeSent = {
            template_id: id,
            canva_json_data: json,
            certificate_title: template_name
        }
        try {
            const response = await Axios.post(`${update_certificate_template}`, jsonDateToBeSent)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, showToast: false }))
                    }, 2000);
                }
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 3000);
                }

            }
        }
    }
}

export const handleUpdateTeamTimeShift = (type, requestData, formdata, setShowToast, setShowModal, setErrorMessages) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        setErrorMessages((prevstat) => ({
            ...prevstat,
            breakOutTime: '',
            breakInTime: '',
            checkOutTime: '',
            checkInTime: ''
        }))

        try {
            const response = await Axios.post(update_shift_users, requestData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))
                const { startDate, endDate } = formdata

                const params = {
                    from_date: startDate,
                    to_date: endDate,
                }
                dispatch(fetchPharmacyTeamTimeSheet('team_management_sheet', params))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }
                setShowModal((prevstat) => ({ ...prevstat, addWorkShift: false }))
                document.body.style.overflow = 'auto'

                setErrorMessages((prevstat) => ({ ...prevstat, breakOutTime: '' }))

            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))
                const err = error?.response?.data?.errors
                if (err) {
                    setErrorMessages((prevstat) => ({
                        ...prevstat,
                        breakOutTime: err?.breakouttime || err?.breakout_time,
                        breakInTime: err?.breakintime || err?.breakin_time,
                        checkOutTime: err?.checkouttime || err?.checkout_time,
                        checkInTime: err?.checkintime || err?.checkin_time
                    }))
                } else
                    if (typeof setShowToast === 'function') {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                        setTimeout(() => {
                            setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                        }, 2000);
                    }
            }
        }
    }
}

export const HandleDeleteTeamTimeShift = (type, setShowToast, setShowModal, params, reqData) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))
        try {
            const response = await Axios.post(`${delete_shift_user}`, reqData)
            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }

                setShowModal((prevstat) => ({ ...prevstat, deleteTask: false }))
                document.body.style.overflow = 'auto'

                dispatch(fetchPharmacyTeamTimeSheet('team_management_sheet', params))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }

            }
        }
    }
}

export const updatePayrollSetting = (type, requestData, formdata, setShowToast, setShowModal, setErrorMessages) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        setErrorMessages((prevstat) => ({
            ...prevstat,
            break_time: '',
            breakin_diff_hours: '',
            breakin_diff_minutes: ''
        }))

        try {
            const response = await Axios.post(`${update_payroll_setting}`, requestData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))
                setShowToast(response?.data?.message)
            } else {
                throw new Error(response)
            }

        } catch (error) {
            if (error?.response) {
                const err = error?.response?.data?.errors
                if (err) {
                    setErrorMessages((prevstat) => ({
                        ...prevstat,
                        break_time: '',
                        breakin_diff_hours: '',
                        breakin_diff_minutes: ''
                    }))
                }
            }
        }
    }
}

export const updateBreakSetting = (type, requestData, formdata, setShowToast, setShowModal, setErrorMessages) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        setErrorMessages((prevstat) => ({
            ...prevstat,
            break_time: '',
            breakin_diff_hours: '',
            breakin_diff_minutes: ''
        }))

        try {
            const response = await Axios.post(`${update_break_setting}`, requestData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))
                setShowToast(response?.data?.message)
            } else {
                throw new Error(response)
            }

        } catch (error) {
            if (error?.response) {
                const err = error?.response?.data?.errors
                if (err) {
                    setErrorMessages((prevstat) => ({
                        ...prevstat,
                        break_time: '',
                        breakin_diff_hours: '',
                        breakin_diff_minutes: ''
                    }))
                }
            }
        }
    }
}

export const updatePharmacyTimeSetting = (type, requestData, formdata, setShowToast, setShowModal, setErrorMessages) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        setErrorMessages((prevstat) => ({
            ...prevstat,
            break_time: '',
            breakin_diff_hours: '',
            breakin_diff_minutes: ''
        }))

        try {
            const response = await Axios.post('enforcement-schedule-setting', requestData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))
                setShowToast(response?.data?.message)
            } else {
                throw new Error(response)
            }

        } catch (error) {
            if (error?.response) {
                const err = error?.response?.data?.errors
                if (err) {
                    setErrorMessages((prevstat) => ({
                        ...prevstat,
                        break_time: '',
                        breakin_diff_hours: '',
                        breakin_diff_minutes: ''
                    }))
                }
            }
        }
    }
}
// ----------------------------------------------------Time Sheet Section End----------------------------------


// -------------------------------------------------ManageMyTeam Section----------------------------------------------------

export const handleAddNewPharmacyUser = (type, setShowModal, setShowToast, reqData, setErrorMessages, selectedFile) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))

        try {

            const formData = new FormData();
            const departMentsIds = reqData?.department_name?.map((value) => value.value)

            formData.append('name', reqData.name);
            formData.append('email', reqData.email);
            formData.append('user_name', reqData.username);
            formData.append('encrypted_passcode', reqData.passcode);

            departMentsIds.forEach((id, index) => {
                formData.append(`departments[${index}]`, id);
            });

            if (selectedFile) formData.append('photo', selectedFile);
            if (reqData.pharmacist) formData.append('is_pharmacist', reqData.pharmacist ? 1 : '');
            if (reqData.pharmacy_portal) formData.append('pharmacy_portal', reqData.pharmacy_portal ? 1 : '');
            if (reqData.employee_portal) formData.append('employee_portal', reqData.employee_portal ? 1 : '');

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${pharmacy_team_users}`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken()}`,
                    'Content-Type': 'multipart/form-data',
                }
            })

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }
                dispatch(fetchPharmacyTeamsList('manage_users_list'))

                setShowModal((prevstat) => ({ ...prevstat, add_new_user: false }))
                document.body.style.overflow = 'auto'

            } else {
                throw new Error(response);
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                Cookies.remove('authToken')
                persistor.purge()
                window.location.href = '/'
            }
            else {
                dispatch(addTeamDataFailure(error?.response, type))
                if (error?.response?.data?.data?.error) {
                    const { user_name, email, encrypted_passcode } = error?.response?.data?.data?.error
                    setErrorMessages((prevStat) => ({ ...prevStat, username: user_name || '', email: email || '', passcode: encrypted_passcode || '' }))
                } else {
                    if (typeof setShowToast === 'function') {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                        setTimeout(() => {
                            setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                        }, 2000);
                    }
                }
            }
        }
    };
};

export const handleUpdateUserPasscode = (type, setShowModal, setShowToast, reqData, setErrorMessage) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        try {
            const response = await Axios.post(`${pharmacy_team_users}/${'updateAttendencePassword'}`, reqData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))

                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);

                }

                setShowModal((prevstat) => ({ ...prevstat, update_passcode: false }))
                document.body.style.overflow = 'auto'

            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                setErrorMessage((prevStat) => ({ ...prevStat, error: error?.response?.data?.data?.error?.passcode }))
                // if (typeof setShowToast === 'function') {
                //     setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                //     setTimeout(() => {
                //         setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                //     }, 2000);
                // }
            }
        }
    }
}

export const handleUpdateUserPassword = (type, setShowModal, setShowToast, reqData, setErrorMessage) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        try {
            const response = await Axios.post(`${pharmacy_team_users}/${'updatePassword'}`, reqData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))

                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);

                }

                setShowModal((prevstat) => ({ ...prevstat, update_password: false }))
                document.body.style.overflow = 'auto'

            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                setErrorMessage((prevStat) => ({ ...prevStat, error: error?.response?.data?.data?.error?.passcode }))
                // if (typeof setShowToast === 'function') {
                //     setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                //     setTimeout(() => {
                //         setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                //     }, 2000);
                // }
            }
        }
    }
}

export const handleSendOnBoardingRequest = (type, setShowModal, setShowToast, reqData) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        try {
            const response = await Axios.post(`${pharmacy_team_users}/${'sendOnboardingRequest'}`, reqData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))

                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);

                }

                setShowModal((prevstat) => ({ ...prevstat, boarding: false }))
                document.body.style.overflow = 'auto'

            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))
            }
        }
    }
}

export const handleUpdatePharmacyUser = (type, setShowModal, setShowToast, reqData, setErrorMessages, selectedFile, user_id, pageNumber) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))

        try {

            const formData = new FormData();
            const departMentsIds = reqData?.department_name?.map((value) => value.value)

            formData.append('name', reqData.name);
            formData.append('email', reqData.email);
            formData.append('user_name', reqData.username);
            formData.append('encrypted_passcode', reqData.passcode);
            formData.append('puser_id', user_id);

            departMentsIds.forEach((id, index) => {
                formData.append(`departments[${index}]`, id);
            });

            if (selectedFile) formData.append('photo', selectedFile);
            if (reqData.pharmacist) formData.append('is_pharmacist', reqData.pharmacist ? 1 : '');
            if (reqData.pharmacy_portal) formData.append('pharmacy_portal', reqData.pharmacy_portal ? 1 : '');
            if (reqData.employee_portal) formData.append('employee_portal', reqData.employee_portal ? 1 : '');
            if (reqData.admin_module) formData.append('admin_module', reqData.admin_module ? 1 : '');

            formData.append('active', reqData.status ? 1 : 0);

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${pharmacy_team_users}/updatePharmacyUser`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken()}`,
                    'Content-Type': 'multipart/form-data',
                }
            })

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }
                const params = {
                    page: pageNumber
                }
                dispatch(fetchPharmacyTeamsList('manage_users_list', params))

                setShowModal((prevstat) => ({ ...prevstat, add_new_user: false }))
                document.body.style.overflow = 'auto'

            } else {
                throw new Error(response);
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                Cookies.remove('authToken')
                persistor.purge()
                window.location.href = '/'
            }
            else {
                dispatch(addTeamDataFailure(error?.response, type))
                if (error?.response?.data?.data?.error) {
                    const { user_name, email, encrypted_passcode } = error?.response?.data?.data?.error
                    setErrorMessages((prevStat) => ({ ...prevStat, username: user_name || '', email: email || '', passcode: encrypted_passcode || '' }))
                } else {
                    if (typeof setShowToast === 'function') {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                        setTimeout(() => {
                            setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                        }, 2000);
                    }
                }
            }
        }
    };
}

// ---------------------------------------------WinrX Setting -----------------------------------------

export const handleUpdateWinrXSettings = (type, setShowToast, reqData) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        try {
            const response = await Axios.post(`pharmacy-update-setting`, reqData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000)
                }
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))
            }
        }
    }
}

export const createNewPharmacyTeamDepartment = (type, setShowToast, requestData, setShowAddDepartment, setNewDepartmentName, setNewDepartmentError, setisLoaded, SetDeptError) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        try {
            const response = await Axios.post(`${pharmacy_user_departments}`, requestData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, showToast: false }))

                    }, 2000);
                    setShowAddDepartment(false);
                    setNewDepartmentName('');
                    setNewDepartmentError('');
                    dispatch(fetchPharmacyDepartmentAssigns('fetch_pharmacy_department_assign_work', '', setisLoaded))
                }
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (error?.response?.data?.data?.error?.department_name) {
                    const deptErrorMessages = error.response.data.data.error.department_name.join(', ');
                    SetDeptError(deptErrorMessages);
                }


                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        SetDeptError("Something went wrong. Please try again later.")
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 3000);
                }

            }
        }
    }
}

export const handleDeleteDepartment = (type, setShowToast, setShowModal, id, setisLoaded) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))

        try {
            const response = await Axios.delete(`${pharmacy_user_departments}/${id}`)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }

                setShowModal((prevstat) => ({ ...prevstat, deleteDept: false }))
                document.body.style.overflow = 'auto'
                dispatch(fetchPharmacyDepartmentAssigns('fetch_pharmacy_department_assign_work', '', setisLoaded))

            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }
            }
        }
    }
}

export const addUpdateSettingIndepartments = (type, setShowToast, dataTobeSent, setShowAddDepartment, setNewDepartmentName, setNewDepartmentError) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        try {
            const formData = new FormData();
            Object.keys(dataTobeSent).forEach(key => {
                formData.append(key, dataTobeSent[key]);
            });

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${pharmacy_update_department}`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken()}`,
                    'Content-Type': 'multipart/form-data',
                }
            })

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, showToast: false }))

                    }, 2000);
                    setShowAddDepartment(false);
                    setNewDepartmentName('');
                    setNewDepartmentError('');
                    //dispatch(fetchPharmacyDepartmentAssigns('fetch_pharmacy_department_assign_work'))
                }
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 3000);
                }

            }
        }
    }
}


export const addUpdateDepartmentUsers = (type, setShowToast, dataTobeSent, setShowAddDepartment, setNewDepartmentName, setNewDepartmentError) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        try {
            const formData = new FormData();
            Object.keys(dataTobeSent).forEach(key => {
                formData.append(key, dataTobeSent[key]);
            });

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${pharmacy_user_department}`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken()}`,
                    'Content-Type': 'multipart/form-data',
                }
            })

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, showToast: false }))

                    }, 2000);
                    setShowAddDepartment(false);
                    setNewDepartmentName('');
                    setNewDepartmentError('');
                    //dispatch(fetchPharmacyDepartmentAssigns('fetch_pharmacy_department_assign_work'))
                }
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 3000);
                }

            }
        }
    }
}

export const pharmacyDeliverySetting = (type, requestData, setFormVisible, setErrorMessages) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        setErrorMessages({
            begin_time: '',
            end_time: '',
            date: '',
            initial_date: '',
            time_gap: ''
        })
        try {
            const response = await Axios.post(`update-delivery-time-setting`, requestData)

            if (response?.status === 200 || 201) {
                setFormVisible(null)
                setErrorMessages({
                    begin_time: '',
                    end_time: '',
                    date: '',
                    initial_date: '',
                    time_gap: ''
                })
                dispatch(addTeamDataSuccess(response?.data, type))
                dispatch(fetchPharmacyDeliverySettingsList('setting_delivery_time'))
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                const { initial_date, end_time } = error?.response?.data?.data?.error
                setErrorMessages((prevStat) => ({ ...prevStat, initial_date: initial_date, end_time_error: end_time }))
                dispatch(addTeamDataFailure(error?.response, type))

            }
        }
    }
}

export const pharmacyDefaultSetting = (type, requestData, setFormVisible, setErrorMessages) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        setErrorMessages({
            start_date: '',
            pharmacist_id: ''
        })
        try {
            const response = await Axios.post(`update-pharmacist-setting`, requestData)

            if (response?.status === 200 || 201) {
                setFormVisible(null)
                setErrorMessages({
                    start_date: '',
                    pharmacist_id: ''
                })
                dispatch(addTeamDataSuccess(response?.data, type))
                dispatch(fetchDefaultpharmacistSettingsList('default_pharmacist_settings'))
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                const { start_date } = error?.response?.data?.errors
                setErrorMessages((prevStat) => ({ ...prevStat, start_date: start_date }))
                dispatch(addTeamDataFailure(error?.response, type))
            }
        }
    }
}

// -------------------------------------Team Schedule Api --------------------

export const HandleADDShift = (type, setShowToast, setShowModal, reqData, startDate, endDate) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))

        try {
            const response = await Axios.post(`pharmacy-team-scedule`, reqData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }

                setShowModal((prevstat) => ({ ...prevstat, addShift: false, addemployeeShift: false }))
                document.body.style.overflow = 'auto'

                const data = {
                    start_date: startDate,
                    end_date: endDate,
                }

                if (startDate && endDate) dispatch(fetchPharmacyTeamScheduleList('team_schedule_list', data))

            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }
            }
        }
    }
}



export const updateTemProfile = (type, setShowToast, requestData, id) => {
    return async (dispatch) => {

        dispatch(addTeamDataRequest(type))

        try {
            const formData = new FormData();
            Object.keys(requestData).forEach(key => {
                formData.append(key, requestData[key]);
            });
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${on_boarding_user_profile_update}/${id}`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken()}`,
                    'Content-Type': 'multipart/form-data',
                }
            })


            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, showToast: false }))
                    }, 2000);
                }
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 3000);
                }

            }
        }
    }
}

export const HandleEDITShift = (type, setShowToast, setShowModal, params, id, startDate, endDate) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))

        try {
            const response = await Axios.put(`pharmacy-team-scedule/${id}`, params)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }

                setShowModal((prevstat) => ({ ...prevstat, editShift: false }))
                document.body.style.overflow = 'auto'

                const data = {
                    start_date: startDate,
                    end_date: endDate,
                }
                dispatch(fetchPharmacyTeamScheduleList('team_schedule_list', data))

            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }
            }
        }
    }
}

export const handleDeleteTeamScheduleShift = (type, setShowToast, setShowModal, id, startDate, endDate) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))

        try {
            const response = await Axios.delete(`pharmacy-team-scedule/${id}`)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }

                const params = {
                    start_date: startDate,
                    end_date: endDate,
                }

                dispatch(fetchPharmacyTeamScheduleList('team_schedule_list', params))

                setShowModal((prevstat) => ({ ...prevstat, deleteDept: false }))
                document.body.style.overflow = 'auto'

            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }
            }
        }
    }
}

export const HandleAddPublishShift = (type, setShowToast, setShowModal, reqData, startDate, endDate) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))

        try {
            const response = await Axios.post(`pharmacy-team-scedule/publishSchedule`, reqData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }

                const params = {
                    start_date: startDate,
                    end_date: endDate,
                }

                dispatch(fetchPharmacyTeamScheduleList('team_schedule_list', params))

                setShowModal((prevstat) => ({ ...prevstat, addPublish: false }))
                document.body.style.overflow = 'auto'

            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }
            }
        }
    }
}

// -------------------------------------------Pharmacy Front Profile---------------------------------------


export const handleUpdatePharmacyFrontProfileLogo = (type, setShowModal, setShowToast, logo, url) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))
        try {
            const formData = new FormData();
            if (url === 'pharmacy-gallery-images') {

                for (const logos of logo) {
                    formData.append('gallery', logos)
                }

            } else {
                formData.append('logo', logo)
            }
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${url ? 'pharmacy-gallery-images' : 'pharmacy-update-logo'}`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken()}`,
                    'Content-Type': 'multipart/form-data',
                }
            })

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))
                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }
                setShowModal((prevstat) => ({ ...prevstat, pharmacyLogo: false, addMultiplePhotos: false }))
                document.body.style.overflow = 'auto'

            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }
            }
        }
    }
}

export const HandleUpdatePharmacyProfile = (type, setShowModal, setShowToast, reqData,pharmacyLoginId) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))
        try {
            const response = await Axios.post(`pharmacy-update-profile`, reqData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))
                dispatch(pharmacy.pharmacySearchModule("pharmacies",pharmacyLoginId))
                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }
                setShowModal((prevstat) => ({ ...prevstat, updateProfile: false , updateContactProfile: false}))
                document.body.style.overflow = 'auto'
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }
            }
        }
    }
}


export const HandleUpdatePharmacyHour = (type, setShowModal, setShowToast, formData,pharmacyLoginId) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))
        try {
            const response = await Axios.post(`pharmacy-update-hours`, formData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))
                dispatch(pharmacy.pharmacySearchModule("pharmacies",pharmacyLoginId))
                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }
                setShowModal((prevstat) => ({ ...prevstat, updateProfile: false , UpdatePharmacyOpenHourFrontProfile: false}))
                document.body.style.overflow = 'auto'
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }
            }
        }
    }
}

export const HandleUpdatePharmacyServiceProfile = (type, setShowModal, setShowToast, formData,pharmacyLoginId) => {
    return async (dispatch) => {
        dispatch(addTeamDataRequest(type))
        try {
            const response = await Axios.post(`services-update-pharmacy`, formData)

            if (response?.status === 200) {
                dispatch(addTeamDataSuccess(response?.data, type))
                dispatch(pharmacy.pharmacySearchModule("pharmacies",pharmacyLoginId))
                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
                    }, 2000);
                }
                setShowModal((prevstat) => ({ ...prevstat, updateProfile: false , UpdatePharmacyServiceFrontProfile: false}))
                document.body.style.overflow = 'auto'
            } else {
                throw new Error(response)
            }

        } catch (error) {

            if (error?.response) {
                dispatch(addTeamDataFailure(error?.response, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }
            }
        }
    }
}