import React, { useState } from 'react'

import { currentDate } from '../../../utils/constants/formInitialStates'
import { fetchUploadedTriplicatesList } from '../../../services/actions/pharmacy/dispense/labelList'

import { useDispenseSelectors } from '../../../services/selectors/dispenseSelectors'
import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'

import { ViewUploadPrescriptionImage } from '../../../models/pharmacy/prescriotionPopUp'
import { EyeIcon } from '../../../assets/svg/icons'
import { BlisterDropDown } from '../../../components/pharmacy/form/form'
import { ErrorToastMessage } from '../../../components/loaders/toastMessage'

import * as Common from '../../../components/common'
import * as Dispense from '../../../components/pharmacy/dispense/dispense'
import * as Hooks from '../../../hooks'
import * as Modals from "../../../assets/js/custome";
import * as path from '../../../networking/urlEndPoints'

// import ChatBox from '../../../components/chat/chatbox'
import Axios from '../../../networking/intraceptor'

const triplicatedTableHeadingList = [
    { text: "ROUTE", classNames: ['p-3'] },
    { text: "PATIENT", classNames: ['p-3'] },
    { text: "RX", classNames: ['p-3'] },
    { text: "DRUG", classNames: ['p-3'] },
    { text: "DRUG SIG", classNames: ['p-3'] },
    { text: "PRESCRIPTION", classNames: ['p-3'] },
    { text: "PATIENT SIGN", classNames: ['p-3'] },
];

const TableRowData = ({ value, className1 = 'p-3', className2 = 'rubik-400 fs-14' }) => {
    return (<td scope="row" className={className1}>
        <p className={className2}>{value}</p>
    </td>)
}

const handleDownLoadPatientSign = async (id, setLoading, name, setShowModal) => {
    try {
        setLoading(true)
        const response = await Axios.post(`${path.show_upload_image}`, {
            triplicateId: id,
            type: 'sign',
        });
        setLoading(false)

        let base64Data = response?.data?.data;

        if (!base64Data) {
            throw new Error("No base64 data found in response.");
        }
        if (base64Data.startsWith('data:image')) {
            base64Data = base64Data.split(',')[1];
        }
        base64Data = base64Data.replace(/[^A-Za-z0-9+/=]/g, '');
        while (base64Data.length % 4 !== 0) {
            base64Data += '=';
        }

        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${name}-sign.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        setLoading(false)
        setShowModal((prevStat) => ({ ...prevStat, errorToast: true }))
        setTimeout(() => {
            setShowModal((prevStat) => ({ ...prevStat, errorToast: false }))
        }, 2000);
    }
};


const UploadedTriplicates = () => {

    const location = Hooks.useCustomLocation()
    const dispatch = Hooks.useCustomDispatch()
    const navigate = Hooks.useCustomNavigate()

    const [formData, setFormData] = React.useState({ uploadtype: 'all', search: '', date: currentDate });

    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;
    const search = new URLSearchParams(location.search).get('search')

    const [pageNumber, setPageNumber] = React.useState(initialPage)
    const [triplicateID, setTriplicateID] = useState(null);

    const [showModal, setShowModal] = React.useState({ ViewUploadPrescriptionModal: false, errorToast: false });
    const [loading, setLoading] = useState(false)

    const { dispenseLabelListsResponse } = useDispenseSelectors()
    const expiringRxList = dispenseLabelListsResponse?.data?.data

    const uploadType = formData.uploadtype === 'All Uploads' ? 'all' : formData.uploadtype === 'Signed' ? 'signed' : formData.uploadtype === 'Un Signed' ? 'unsigned' : 'all'
    const filterList = ['All Uploads', 'Signed', 'Un Signed']

    React.useEffect(() => {
        setPageNumber(initialPage)
    }, [initialPage])

    React.useEffect(() => {
        if (formData?.date) {
            const params = {
                search_date: formData?.date,
                search: search,
                page: pageNumber,
            }
            dispatch(fetchUploadedTriplicatesList(params, 'uploaded-triplicates-list'))
        }
        return () => { }

    }, [])

    const handleFilterData = () => {
        const params = {
            search_date: formData?.date,
            search: formData.search,
            uploadtype: uploadType
        }
        const searchParams = new URLSearchParams()
        searchParams.append('date', formData?.date)
        if (formData?.search) searchParams.append('search', formData?.search)

        dispatch(fetchUploadedTriplicatesList(params, 'uploaded-triplicates-list'))
        const newUrl = `${location.pathname}?${searchParams.toString()}`
        navigate(newUrl);
    }

    const handlePagination = (event, value) => {
        const searchParams = new URLSearchParams()
        if (value !== undefined) {
            const params = {
                search_date: formData?.date,
                page: value,
                uploadtype: uploadType
            }
            searchParams.append('page', value);
            setPageNumber(value)
            dispatch(fetchUploadedTriplicatesList(params, 'uploaded-triplicates-list'))
            const newUrl = `${location.pathname}?${searchParams.toString()}`
            navigate(newUrl);
        }
    }

    const handleReset = () => {
        const searchParams = new URLSearchParams();
        searchParams.delete('page');
        searchParams.delete('search');
        searchParams.delete('date');
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);
        setFormData({ ...formData, search: '', uploadtype: 'all', date: currentDate })
        const params = {
            search_date: currentDate,
            page: 1,
            uploadtype: 'all'
        }
        dispatch(fetchUploadedTriplicatesList(params, 'uploaded-triplicates-list'))
    }

    return (
        <>

            <div className="container ">

                <div className="flex flex-wrap lg:flex-nowrap gap-3">
                    <div className='flex-0 lg:w-3/12 md:w-4/12 sm:w-6/12 w-full'>
                        <Dispense.TextInput label='Search' name='search' formData={formData}
                            setFormData={setFormData} />
                    </div>

                    <div className='flex-0 lg:w-3/12 md:w-4/12 sm:w-6/12 w-full'>
                        <Dispense.StartDate formData={formData}
                            setFormData={setFormData} />
                    </div>

                    <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 w-full'>
                        <BlisterDropDown formData={formData}
                            setFormData={setFormData}
                            name="uploadtype"
                            value={formData.uploadtype}
                            filterList={filterList}
                            placeholder='All' />
                    </div>

                    <div className='flex-0 lg:w-1/12 md:w-2/12 sm:w-4/12 w-full'>
                        <button style={{ backgroundColor: '#3282F6' }} type="button" onClick={handleFilterData}
                            className="flex text-white ml-auto w-full h-full pl-6 py-4 items-center justify-center border border-gray-300 	
														focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 
														dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Filter
                        </button>
                    </div>

                    <div className='flex-0 lg:w-1/12 md:w-4/12 sm:w-6/12 w-full'>
                        <button type="button" style={{ backgroundColor: 'White' }} onClick={handleReset}
                            className="flex  w-full h-full items-center justify-center text-gray-500 bg-white 
							border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 
                            py-2.5 me-2 dark:bg-gray-800 
						    dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Reset
                        </button>
                    </div>
                </div>

                {dispenseLabelListsResponse?.loading && dispenseLabelListsResponse?.type === 'uploaded-triplicates-list' ?
                    <div className="flex justify-center items-center mt-60 mb-80">
                        <Common.LoadingView />
                    </div> :
                    dispenseLabelListsResponse?.error !== null && dispenseLabelListsResponse?.type === 'uploaded-triplicates-list' ?
                        <div className="flex flex-col items-center justify-center mt-60 mb-80">
                            <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                            <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                            <button onClick={() => window.location.reload()}
                                className="px-4  bg-red-600 text-white rounded hover:bg-red-700 
																	focus:outline-none focus:ring-2 focus:ring-red-500">
                                Please Try Again.
                            </button>
                        </div> :
                        expiringRxList?.data?.length === 0 ?
                            <div className="bg-white pt-4 rounded-md">
                                <p className="text-gray-500">No Record Available.</p>
                            </div>
                            :
                            dispenseLabelListsResponse?.type === 'uploaded-triplicates-list' ?
                                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                    <div className="shadow-md border rounded-lg relative overflow-x-auto">
                                        <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                            <thead className="text-xs text-gray-900 uppercase dark:bg-gray-700 dark:text-gray-400" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                <tr>
                                                    {triplicatedTableHeadingList?.map((header, index) => (
                                                        <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                            <p className="rubik-500 fs-12">{header?.text}</p>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    expiringRxList?.data?.map((value, idx) => (
                                                        <React.Fragment key={idx}>
                                                            <tr style={{ borderBottom: `${'1px solid lightgray'}` }}>
                                                                <TableRowData value={value?.route} />
                                                                <TableRowData value={value?.patient} />
                                                                <TableRowData value={value?.rx} />
                                                                <TableRowData value={value?.drug} className1='p-3 w-56' />
                                                                <TableRowData value={value?.drug_sig} className1='p-3 w-96' />

                                                                <td scope="row" className={'p-3 '}>
                                                                    <div className='rounded-md flex items-center justify-center  btnnn'
                                                                        onClick={() => {
                                                                            setTriplicateID(value.id);
                                                                            Modals.handleShowModal(
                                                                                setShowModal,
                                                                                showModal,
                                                                                "ViewUploadPrescriptionModal"
                                                                            );
                                                                        }}
                                                                    >
                                                                        <EyeIcon className="bg-gray-100 p-1 rounded-md  text-gray-600" />
                                                                    </div>
                                                                </td>

                                                                <td scope="row" className={'p-3 btnnn'}>
                                                                    {value?.patient_sign === 'Awaiting' ?
                                                                        <p className='p-1 text-white rounded-md w-16 fs-12 bg-red-500 text-center' >{value?.patient_sign}</p>
                                                                        :
                                                                        <p className='p-1 text-white rounded-md w-16 fs-12 text-center' style={{ background: 'orange' }}
                                                                            onClick={() => { return handleDownLoadPatientSign(value.id, setLoading, value?.patient, setShowModal), setTriplicateID(value.id) }}                                                                                            >
                                                                            {loading && value.id === triplicateID ? 'loading...' : value?.patient_sign}
                                                                        </p>}
                                                                </td>
                                                            </tr>

                                                        </React.Fragment>
                                                    ))
                                                }
                                            </tbody>
                                        </table>

                                        {dispenseLabelListsResponse?.error !== null ? null : <>
                                            {expiringRxList?.data?.length !== 0 && <>
                                                {expiringRxList?.pagination?.last_page !== 1 &&
                                                    <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center 
																			md:justify-center justify-center mt-5">
                                                        <nav aria-label="Page navigation example">
                                                            <ul className="inline-flex -space-x-px text-base h-10">
                                                                <CustomPagination
                                                                    count={expiringRxList?.pagination?.last_page}
                                                                    page={pageNumber}
                                                                    onChange={(event, value) => {
                                                                        handlePagination(event, value)
                                                                    }}
                                                                />
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                }
                                            </>}
                                        </>}

                                    </div>
                                </div> : null
                }
            </div>


            {showModal.errorToast &&
                <ErrorToastMessage status='Oops! Got an issue while downloading sign.please try again' />}

            <ViewUploadPrescriptionImage
                showModal={showModal}
                arvID={triplicateID}
                onClose={() =>
                    Modals.handleHideModal(setShowModal, showModal, "ViewUploadPrescriptionModal")
                }
                setShowModal={setShowModal}
                formData={formData}
                pageNumber={pageNumber}
            />
        </>

    )
}

export default UploadedTriplicates