import {
    MEMO_REMINDER_UPDATE_REQUEST,
    MEMO_REMINDER_UPDATE_SUCCESS,
    MEMO_REMINDER_UPDATE_FAILURE
} from "../../../constants"
import { handleHideModal } from "../../../../assets/js/custome";

import { fetchMemo } from '../../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'

import { update_reminder } from "../../../../networking/urlEndPoints"
import {
    showToastMessage,
    hideToastMessage
} from "../../../../assets/js/custome"

import Axios from "../../../../networking/intraceptor"

export const reminderUpdateRequest = (types) => ({
    type: MEMO_REMINDER_UPDATE_REQUEST,
    types: types
})

export const reminderUpdateSuccess = (data, types) => ({
    type: MEMO_REMINDER_UPDATE_SUCCESS,
    payload: data,
    types: types
})

export const reminderUpdateFailure = (error, types) => ({
    type: MEMO_REMINDER_UPDATE_FAILURE,
    payload: error,
    types: types
})

export const updateExpiry = (type, params, showToast, setShowToast, searchParam, setShowModal, showModal) => {

    return async (dispatch) => {

        dispatch(reminderUpdateRequest());
        try {
            const response = await Axios.post(`${update_reminder}`, params)
            if (response?.status === 200) {
                showToastMessage(showToast, setShowToast)
                // dispatch(fetchMemo("memo_list", searchParam));
                handleHideModal(setShowModal, showModal, "MemoExpiryUpdateModal")
                dispatch(reminderUpdateSuccess(response?.data, type))
                setTimeout(() => {
                    hideToastMessage(showToast, setShowToast)
                }, 2000)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(reminderUpdateFailure(error?.response, type))
            }
        }
    }
}


