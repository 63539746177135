import React, { useEffect, useState } from "react";

import {
    Document,
    Packer,
    Paragraph,
    Table, TableRow,
    TableCell,
    TextRun,
    AlignmentType,
    WidthType
} from 'docx';

import {
    BreadCrumb,
    LoadingView,
    Sidebar
} from "../../../components/common";

import {
    ErrorToastMessage,
    SuccessToastMessage
} from "../../../components/loaders/toastMessage";

import {
    DangerIcon,
    DeleteIcon,
    EditIcons,
    UserSLashIcon
} from "../../../assets/svg/icons";

import { DeleteList } from "../../../models/admin/editTaskList";
import { fetchPharmacyTeamTimeSheet } from "../../../services/actions/admin/team/fetchTeamsList";
import { useAdminSelectors } from "../../../services/selectors/adminModuleSelectors";
import { ErrorMessages } from "../../../components/error/alertMessages";

import { useCustomSelectors } from "../../../services/selectors/allSelectors";
import { AssignTaskSelect } from "./createNewtask";

import { handleShowModal } from "../../../assets/js/custome";
import { handleExportTimeSheetPdf } from "../../../services/actions/admin/team/addTeamData";

import { AddTeamWorkShift } from "../../../models/admin/teamTimeSheet";
import { ArchivedDelveriesDateRangePicker } from "../../../components/pharmacy/form/dateRangePicker";

import { currentDate } from "../../../utils/constants/formInitialStates";
import { timesheetDates } from '../../../constants/rootReduceName';

import ChatBox from "../../../components/chat/chatbox";
import Button from "../../../components/common/button";

import * as Hooks from '../../../hooks';
import * as useEffectHelper from '../../../utils/helper/useEffectsHelper'

const generateWordDocument = (data, pharmacyLoginData, start_date, end_date) => {
    const doc = new Document({
        sections: [{
            properties: {},
            children: [
                new Paragraph({
                    children: [
                        new TextRun({ text: pharmacyLoginData?.name, bold: true, size: 24 }),
                    ],
                    alignment: AlignmentType.LEFT,
                }),
                new Paragraph({
                    children: [
                        new TextRun({ text: "TIME SHEET", bold: true, size: 24 }),
                    ],
                    alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                    children: [
                        new TextRun({ text: pharmacyLoginData?.address }),
                    ],
                }),
                new Paragraph({
                    children: [
                        new TextRun({ text: pharmacyLoginData?.city + ',' + pharmacyLoginData?.zip_code }),
                    ],
                }),
                new Paragraph({ text: "" }),
                new Paragraph({
                    children: [
                        new TextRun({ text: `Pay Period: ${start_date} - ${end_date}` }),
                    ],
                }),
                new Paragraph({
                    children: [
                        new TextRun({ text: `Approved by: ${pharmacyLoginData?.name}` }),
                    ],
                }),
                new Paragraph({ text: "" }),
                new Paragraph({
                    children: [
                        new TextRun({ text: "Notes:" }),
                    ],
                }),
                new Paragraph({ text: "" }),
                new Table({
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    },
                    rows: [
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [new Paragraph("EMPLOYEE")],
                                    width: { size: 50, type: WidthType.PERCENTAGE },
                                    margins: {
                                        top: 100, bottom: 100, left: 100, right: 100
                                    },
                                }),
                                new TableCell({
                                    children: [new Paragraph("REGULAR HOURS")],
                                    width: { size: 50, type: WidthType.PERCENTAGE },
                                    margins: {
                                        top: 100, bottom: 100, left: 100, right: 100
                                    },
                                }),
                            ],
                        }),
                        ...data?.map(employee =>
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph(employee.name)],
                                        margins: {
                                            top: 100, bottom: 100, left: 100, right: 100
                                        },
                                    }),
                                    new TableCell({
                                        children: [new Paragraph(employee.w_hour)],
                                        margins: {
                                            top: 100, bottom: 100, left: 100, right: 100
                                        },
                                    }),
                                ],
                            })
                        ),
                    ],
                }),
            ],
        }],
    });

    Packer.toBlob(doc).then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'TimeSheet.docx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }).catch(error => {

    });
};


const endDate = (() => {
    const endDate = new Date(currentDate);
    endDate.setDate(endDate.getDate() + 5);
    return endDate.toISOString().split("T")[0];
})()

const TeamTimeSheet = () => {

    const searchParams = new URLSearchParams();

    const dispatch = Hooks.useCustomDispatch();
    const location = Hooks.useCustomLocation();
    const navigate = Hooks.useCustomNavigate();

    const startDateRef = React.useRef()
    const endDateRef = React.useRef()

    const { TeamListResponse, addTeamDataResponse } = useAdminSelectors()
    const { profileData, chatUserList, pharmacyLoginData } = useCustomSelectors()
    const dates = Hooks.useCustomSelector(state => state[timesheetDates]?.timesheetDates)

    const employeeList = chatUserList?.data?.data
    const timeSheetList = TeamListResponse?.data?.data

    const [formData, setFormData] = useState({
        taskStatus: '', assignTask: '',
        startDate: dates?.start_date,
        endDate: endDate
    })

    const [errorMessage, setErrorMessages] = useState({ title: '', descripition: '', assignTask: '' })

    const [showModal, setShowModal] = useState({ addWorkShift: false, deleteTask: false })
    const [logData, setLogData] = useState(null)
    const [showToast, setShowToast] = useState({ successToast: '', errorToast: '' })

    const [apiCallMade, setApiCallMade] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [teamlistData, setTeamListData] = useState(null)

    useEffect(() => {
        if (!apiCallMade) {
            const params = {
                from_date: formData.startDate,
                to_date: endDate
            }
            dispatch(fetchPharmacyTeamTimeSheet('team_management_sheet', params))
            setApiCallMade(true)
        }
    }, [])

    const handleFilter = () => {
        const { startDate, endDate, assignTask } = formData;

        if (startDate && endDate) {
            const params = {
                from_date: startDate,
                to_date: endDate,
                ...(assignTask && { team_member: assignTask })
            };
            const type = assignTask ? 'team_logs' : 'team_management_sheet';
            dispatch(fetchPharmacyTeamTimeSheet(type, params));
        }
    };


    const handleReset = () => {
        const { startDate, endDate } = formData
        if (startDate && endDate) {
            const params = {
                from_date: startDate,
                to_date: endDate,
            }
            dispatch(fetchPharmacyTeamTimeSheet('team_management_sheet', params))
        }
        searchParams.delete('page');
        setFormData({ ...formData, startDate: startDate, endDate: endDate, assignTask: '' })
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);
    }

    const ExportPdf = () => {
        const { startDate, endDate } = formData
        if (startDate && endDate) {
            const params = {
                from_date: startDate,
                to_date: endDate,
                exportsheet: true
            }
            dispatch(handleExportTimeSheetPdf('export_pdf', params))
        }
    }

    useEffectHelper.useArchivedDelveriesDateRangePickerEffect(formData, setFormData, startDateRef, endDateRef)

    const ExportWord = () => {
        if (timeSheetList) {
            generateWordDocument(timeSheetList?.attendance, pharmacyLoginData, formData.startDate, formData.endDate);
        }
    };

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">

                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">


                                        <div className="flex flex-wrap lg:flex-nowrap gap-3">

                                            <div className='flex flex-0 lg:w-4/12 md:w-6/12 sm:w-8/12 w-full' style={{ gridTemplateColumns: '2fr 2fr 7px 2fr 2fr', height: '30px' }}>
                                                <ArchivedDelveriesDateRangePicker
                                                    startDateRef={startDateRef}
                                                    endDateRef={endDateRef}
                                                    formData={formData}
                                                    setFormData={setFormData} />
                                            </div>

                                            <div className='flex flex-0 lg:w-2/12 md:w-6/12 sm:w-8/12 w-full'>
                                                <AssignTaskSelect
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                    employeeList={employeeList}
                                                    profileData={profileData}
                                                    errorMessage={errorMessage}
                                                    placeholder="All Employees"
                                                    padding='pb-2.5 pt-2.5'
                                                />
                                            </div>

                                            <div className='' style={{ width: '60px' }}>
                                                <Button color="white" text="Filter" handleFilter={handleFilter} />
                                            </div>

                                            <div className="ml-2" style={{ width: '60px' }}>
                                                <Button color="gray" text="Reset" bgColor="white" handleFilter={handleReset} />
                                            </div>

                                            {!formData.assignTask &&
                                                <div className="ml-2">
                                                    <Button color="white" text="Export Word" width='127px' bgColor="#519351" handleFilter={ExportWord} />
                                                </div>}

                                            <div className={`${formData.assignTask && 'ml-2'}`}>
                                                <Button color="white"
                                                    text={`${addTeamDataResponse?.loading && addTeamDataResponse?.type === 'export_pdf' ? 'Processing...' : 'Export PDF'}`}
                                                    width="122px"
                                                    bgColor="#ff2d2d"
                                                    handleFilter={ExportPdf} />
                                            </div>

                                        </div>

                                        {
                                            TeamListResponse?.loading && (TeamListResponse?.type === 'team_management_sheet' || TeamListResponse?.type === 'team_logs') ?
                                                <div className="flex justify-center items-center mt-60 mb-80">
                                                    <LoadingView />
                                                </div>
                                                :

                                                TeamListResponse?.error !== null && (TeamListResponse?.type === 'team_management_sheet' || TeamListResponse?.type === 'team_logs') ?
                                                    <div className="mb-60">
                                                        <ErrorMessages />
                                                    </div>
                                                    :

                                                    TeamListResponse?.type === 'team_logs' ?
                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mt-2">
                                                            <div className="container mx-auto shadow-lg overflow-x-auto">
                                                                <table className="min-w-full bg-white border">
                                                                    <thead>
                                                                        <tr className="bg-blue-500 text-white">
                                                                            <th className="py-2 px-4 text-left fs-14 rubik-500"> Date</th>
                                                                            <th className="py-2 px-4 text-left fs-14 rubik-500">Time Card</th>
                                                                            <th className="py-2 px-4 text-left fs-14 rubik-500">Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {timeSheetList?.data?.map((value, idx,) => (
                                                                            <React.Fragment key={idx}>
                                                                                <tr className="" style={{ border: '1px solid lightgray' }}>
                                                                                    <td className="py-2 px-4 text-gray-700 text-sm" style={{ border: '1px solid lightgray' }}>
                                                                                        <p>{value.checkin_date}</p>
                                                                                        <p>{value.day}</p>
                                                                                    </td>
                                                                                    <td className="py-2 px-4 text-gray-700 text-sm" style={{ border: '1px solid lightgray' }}>
                                                                                        {value?.time_card?.map((data, index) => {
                                                                                            return (<React.Fragment key={index}>
                                                                                                <p>{data.checkin_time} - {data.checkout_time} ({data.duration})</p>
                                                                                            </React.Fragment>)
                                                                                        })}
                                                                                    </td>
                                                                                    <td className="py-2 px-4 text-gray-700 text-sm">
                                                                                        <p>{value.total_duration}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        :
                                                        TeamListResponse?.type === 'team_management_sheet' ?

                                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mt-2">
                                                                <div className="container mx-auto shadow-lg overflow-x-auto">
                                                                    <table className="min-w-full bg-white border">
                                                                        <thead>
                                                                            <tr className="bg-blue-500 text-white">
                                                                                <th className="py-2 px-4 text-left fs-14 rubik-500"> Employee Name and Day</th>
                                                                                <th className="py-2 px-4 text-left fs-14 rubik-500">Clock in-out</th>
                                                                                <th className="py-2 px-4 text-left fs-14 rubik-500">Hours</th>
                                                                                <th className="py-2 px-4 text-left fs-14 rubik-500">Break in-out</th>
                                                                                <th className="py-2 px-4 text-left fs-14 rubik-500">Hours</th>
                                                                                <th className="py-2 px-4 text-left fs-14 rubik-500">Working Hours</th>
                                                                                <th className="py-2 px-4 text-left fs-14 rubik-500"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {timeSheetList?.attendance?.map((employee, idx,) => (
                                                                                <React.Fragment key={idx}>

                                                                                    <tr key={employee.id} style={{ border: '2px solid lightgray' }}>
                                                                                        <td className={`py-2 px-4 flex items-center ${idx % 2 === 0 ? 'text-blue-800' : 'text-red-800'}`}>
                                                                                            <span className="font-bold text-gray-700">{employee.name.charAt(0).toUpperCase() + employee.name.slice(1)}</span>
                                                                                            {employee?.department?.map((value, index) => {
                                                                                                return (<button key={index} className="bg-gray-100 text-gray py-0.5 fs-10 px-1 rounded hover:bg-orange-700 ml-1">
                                                                                                    {value}
                                                                                                </button>)
                                                                                            })}
                                                                                        </td>
                                                                                        <td className={`py-2 px-4 text-gray-700 text-sm rubik-500 ${idx % 2 === 0 ? 'text-blue-800' : 'text-red-800'}`}></td>
                                                                                        <td className={`py-2 px-4 text-gray-700 text-sm rubik-500 ${idx % 2 === 0 ? 'text-blue-800' : 'text-red-800'}`}>{employee.clock_hours}</td>
                                                                                        <td className={`py-2 px-4 text-gray-700 text-sm rubik-500 ${idx % 2 === 0 ? 'text-blue-800' : 'text-red-800'}`}>{employee.breakInOut}</td>
                                                                                        <td className={`py-2 px-4 text-gray-700 text-sm rubik-500 ${idx % 2 === 0 ? 'text-blue-800' : 'text-red-800'}`}>{employee.break_hours}</td>
                                                                                        <td className={`py-2 px-4 text-gray-700 text-sm rubik-500 ${idx % 2 === 0 ? 'text-blue-800' : 'text-red-800'}`}>{employee.w_hour}</td>
                                                                                        <td className={`py-2 px-4 text-gray-700 text-sm rubik-500 ${idx % 2 === 0 ? 'text-blue-800' : 'text-red-800'}`}>
                                                                                            <button
                                                                                                onClick={() => {
                                                                                                    setLogData(employee);
                                                                                                    setTeamListData(null);
                                                                                                    setIsEdit(false);
                                                                                                    handleShowModal(setShowModal, showModal, 'addWorkShift');
                                                                                                }}
                                                                                                className="bg-green-500 text-white py-1 px-2 rounded hover:bg-green-700 ml-2"
                                                                                            >
                                                                                                + Add Work Shift
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>

                                                                                    {employee?.checkInOutFormatted?.map((value, index, array) => (
                                                                                        <tr key={index} className="border-b" style={{ borderTop: 'none', fontWeight: '400', borderLeft: '2px solid lightgray', borderRight: '2px solid lightgray', borderBottom: index === array.length - 1 && '2px solid lightgray' }}>
                                                                                            <td className="py-2 px-4 text-gray-700 fs-12 flex">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                                                                    <path fillRule="evenodd" d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z" clipRule="evenodd" />
                                                                                                </svg>
                                                                                                &nbsp; &nbsp;
                                                                                                {value?.checkdate}</td>

                                                                                            <td className="py-2 px-4 text-gray-700 fs-12">
                                                                                                <p className="flex">
                                                                                                    {value?.checkin_time ? value?.checkin_time : <UserSLashIcon />}
                                                                                                    &nbsp; to &nbsp;
                                                                                                    {value?.checkout_time ? value?.checkout_time : <UserSLashIcon />}
                                                                                                </p>
                                                                                            </td>

                                                                                            {!value?.total_checkin_out_time ?
                                                                                                <td className="py-2 px-4 text-gray-700 fs-12">
                                                                                                    <DangerIcon />
                                                                                                </td> :
                                                                                                <td className="py-2 px-4 text-gray-700 fs-12">
                                                                                                    {value?.total_checkin_out_time}
                                                                                                </td>}


                                                                                            <td className="py-2 px-4 text-gray-700 fs-12">
                                                                                                <p className="flex">
                                                                                                    {value?.breakin_time ? value?.breakin_time : <UserSLashIcon />}
                                                                                                    &nbsp; to &nbsp;
                                                                                                    {value?.breakout_time ? value?.breakout_time : <UserSLashIcon />}
                                                                                                </p>
                                                                                            </td>

                                                                                            {value?.total_breakin_out_time === 'triangle' ?
                                                                                                <td className="py-2 px-4 text-gray-700 fs-12">
                                                                                                    <DangerIcon />
                                                                                                </td> :
                                                                                                <td className="py-2 px-4 text-gray-700 fs-12">
                                                                                                    {value?.total_breakin_out_time}
                                                                                                </td>}

                                                                                            <td className="py-2 px-4 text-gray-700 fs-12">{value?.total_working_hours ? value?.total_working_hours : <DangerIcon />}</td>

                                                                                            <td className="py-2 px-4 text-gray-700 text-sm rubik-500">
                                                                                                <div className='flex justify-center items-center'>
                                                                                                    <button onClick={() => { return setLogData(value), setTeamListData(employee), setIsEdit(true), handleShowModal(setShowModal, showModal, 'addWorkShift') }} className="ml-2 pt-1">
                                                                                                        <EditIcons color='orange' />
                                                                                                    </button>
                                                                                                    <button onClick={() => { return setLogData(value), setTeamListData(employee), handleShowModal(setShowModal, showModal, 'deleteTask') }} className="ml-2">
                                                                                                        <DeleteIcon color='blue' />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}

                                                                                    <tr className=" h-12" style={{ border: 'none', boxShadow: 'none' }}>
                                                                                        <td className="py-2 px-4 text-gray-700 text-sm"></td>
                                                                                        <td className="py-2 px-4 text-gray-700 text-sm"></td>
                                                                                        <td className="py-2 px-4 text-gray-700 text-sm"></td>
                                                                                        <td className="py-2 px-4 text-gray-700 text-sm"></td>
                                                                                        <td className="py-2 px-4 text-gray-700 text-sm"></td>
                                                                                        <td className="py-2 px-4 text-gray-700 text-sm"></td>
                                                                                    </tr>
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

            {showModal.addWorkShift &&
                <AddTeamWorkShift showModal={showModal}
                    setShowModal={setShowModal}
                    setShowToast={setShowToast}
                    logData={logData}
                    formdata={formData}
                    isEdit={isEdit}
                    teamlistData={teamlistData}
                />}

            {showModal.deleteTask &&
                <DeleteList logData={logData}
                    setShowModal={setShowModal}
                    text={"Are you sure ? You won't be able to revert this!"}
                    setShowToast={setShowToast}
                    formData={formData}
                    teamlistData={teamlistData}
                />}

            {showToast.successToast && addTeamDataResponse.type === 'add_team_work_shift' && <SuccessToastMessage status={'Added Successfully'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'add_team_work_shift' && <ErrorToastMessage status={'Got an issue while adding shift please try to again later.'} />}

            {showToast.successToast && addTeamDataResponse.type === 'update_team_time_shift' && <SuccessToastMessage status={'Updated Successfully'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'update_team_time_shift' && <ErrorToastMessage status={'Got an issue while adding shift please try to again later.'} />}


            {showToast.successToast && addTeamDataResponse.type === 'delete_task' && <SuccessToastMessage status={'Deleted Successfully'} />}
            {showToast.errorToast && addTeamDataResponse.type === 'delete_task' && <ErrorToastMessage status={'Got an issue while Delete shift please try to again later.'} />}

            <ChatBox />

        </>
    )
}

export default TeamTimeSheet