import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './services/store/store'

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			{/* <React.StrictMode> */}
				<BrowserRouter>
					<App />
				</BrowserRouter>
			{/* </React.StrictMode> */}
		</PersistGate>
	</Provider>
)

// --------------When make build open comment function in app and useractivity file
