import { useRef } from 'react';
import { updateCoverPicture } from "../../services/actions/auth/updateUserProfileCoverPictureActions";

import Cookies from "js-cookie";
import { updateProfilePicture } from '../../services/actions/auth/updateUserProfileActions';

export function openChatbox() {
	const chatbox = document.getElementById('fixedChatbox');
	chatbox.style.display = 'block';
}

export async function openChatboxOne(id) {
	const chatbox = document.getElementById('fixedChatboxOne');
	chatbox.style.display = 'block';
	if (typeof id === 'function') {
		await closeChatbox(id)
	}
}

export function closeChatboxOne() {
	const chatbox = document.getElementById('fixedChatboxOne');
	chatbox.style.display = 'none';
}

export function closeChatbox(setSelectedEmployeeId) {
	// const chatbox = document.getElementById('fixedChatbox');
	// chatbox.style.display = 'none';
	Cookies.remove('initated')
	if (typeof setSelectedEmployeeId === 'function') {
		setSelectedEmployeeId(null)
	}
}

export function changeImage() {
	document.getElementById('fileInput').click();
}

export function fileSelect(id, setGroupData) {
	var element = document.getElementById(id);
	var previewWrapper = document.getElementById(`${id}Preview`);
	var previewElement = null;
	if (element) {
		element.click();
		element.addEventListener("change", function (event) {
			var files = element.files;
			if (files.length) {
				var file = files[0];
				var reader = new FileReader();
				reader.onload = function (e) {
					if (!previewElement) {
						// debugger;
						previewElement = document.createElement('img');
						previewElement.src = e.target.result;
						previewElement.classList.add('w-full')
						previewElement.classList.add('h-full')
						previewWrapper.innerHTML = previewElement.outerHTML;
					}
				};
				reader.readAsDataURL(file);
				setGroupData(prevData => ({ ...prevData, groupIcon: file }));
			}

		}, false);
	}
}

export function AddCoverImage() {
	document.getElementById('fileInputss').click();
}

export const handleCoverImage = (e, dispatch) => {
	const file = e.target.files[0];
	if (file) {
		const reader = new FileReader();

		reader.onload = async (event) => {
			await dispatch(updateCoverPicture(file))

		};
		reader.readAsDataURL(file);
	}
}

export const handleImageChange = (e, imageRef, setSelectedFile) => {
	const file = e.target.files[0];
	if (file) {
		setSelectedFile(file);
		const reader = new FileReader();

		reader.onload = (event) => {
			imageRef.current.src = event.target.result;
		};
		reader.readAsDataURL(file);
	}
};


export function formatDateToCustomFormat(inputDate) {
	const date = new Date(inputDate);
	const day = date.getDate();
	const month = date.toLocaleString('en-US', { month: 'long' });

	return `${day} ${month}`;
}

//----------------------------------Time Sheet Starting Date Value Section End-----------------------------

//---------------------------------------Settings Page ----------------------------------
export const onPlaceSelected111 = (place, setCity, setProvince, setFormData, setAddress1, setAddress2, setZipCode) => {
	if (place && place.place_id) {
		const request = {
			placeId: place.place_id,
			fields: ['address_components', 'geometry', 'formatted_address', 'name']
		};
		const service = new window.google.maps.places.PlacesService(document.createElement('div'));
		service.getDetails(request, (placeResult, status) => {
			if (status === window.google.maps.places.PlacesServiceStatus.OK) {
				const addressComponents = placeResult.address_components;
				let houseNumber, streetName, city, state, province, country, postcode

				for (let i = 0; i < addressComponents.length; i++) {
					const component = addressComponents[i];

					if (component.types.includes('route')) {
						streetName = component.long_name;
					} else if (component.types.includes('locality')) {
						city = component.long_name;
						setCity(city)
					} else if (component.types.includes('administrative_area_level_1')) {
						state = component.long_name;
						setProvince(state)
					} else if (component.types.includes('postal_code')) {
						postcode = component.long_name;
						setZipCode(postcode)

					} else if (component.types.includes('street_number')) {
						houseNumber = component.long_name;
					}
				}
				setAddress2('')

				if (houseNumber) {
					setAddress1(`${houseNumber} ${streetName}`);
				} else {
					setAddress1(`${streetName}`);
				}
			}
		});
	}
};

export const useInputHandlers = () => {
	const inputRefs = {
		name: useRef(null),
		dob: useRef(null),
		email: useRef(null),
		phone: useRef(null),
		address: useRef(null),
		address_2: useRef(null),
		city: useRef(null),
		province: useRef(null),
		zipcode: useRef(null),
		sinNumber: useRef(null),
	};

	const handleInputFocus = (refName) => {
		inputRefs[refName].current.style.color = 'black';
	};

	const handleInputBlur = (refName) => {
		inputRefs[refName].current.style.color = '#757575';
	};

	return {
		inputRefs,
		handleInputFocus,
		handleInputBlur,
	};
};

//---------------------------------------Settings Page ----------------------------------

export const playMessageSound = () => {
	const audio = document.getElementById('messageSound');
	if (audio) {
		audio.play()
			.catch(error => {
				console.error('Failed to play audio:', error);
			});
	}
}

export function closeOverlay() {
	document.getElementById('overlay').style.display = 'none';
}


export const handleFileSelect = () => {
	const fileInput = document.getElementById('chatFileInput');
	if (fileInput) {
		fileInput.click();
	}
};

export const hanlePdfSelect = () => {
	const fileInput = document.getElementById('chatPdfInput');
	if (fileInput) {
		fileInput.click();
	}
}


export const onhandleImageChange = (event, setMediaFile, mediaFile) => {
	const selectedFile = event.target.files[0];

	if (selectedFile) {
		const fileSizeLimit = 5 * 1024 * 1024;

		if (selectedFile.size > fileSizeLimit) {
			window.alert('File size exceeds the limit of 5MB. Please choose a smaller file.');
		} else {
			setMediaFile([...mediaFile, selectedFile]);
		}
	}
};

export const onhandlePdfFileChange = (event, setMediaPdf, mediaPdf) => {
	const selectedFile = event.target.files[0];

	if (selectedFile) {
		const fileSizeLimit = 5 * 1024 * 1024;

		if (selectedFile.size > fileSizeLimit) {
			window.alert('File size exceeds the limit of 5MB. Please choose a smaller file.');
		} else {
			setMediaPdf([...mediaPdf, selectedFile]);
		}
	}
};


export const onPlaceSetPateintAddresses = async (place, setPatientAddress, pateintAddress) => {

	if (place && place.place_id) {
		const request = {
			placeId: place.place_id,
			fields: ['address_components', 'geometry', 'formatted_address', 'name']
		};

		const service = await new window.google.maps.places.PlacesService(document.createElement('div'));
		service.getDetails(request, (placeResult, status) => {

			if (status === window.google.maps.places.PlacesServiceStatus.OK) {

				const { lat, lng } = placeResult.geometry.location;
				const latitude = lat();
				const longitude = lng();

				const addressComponents = placeResult.address_components;
				let route, streetNumber, subpremise
				let updatedPatientFormData = { ...pateintAddress };

				updatedPatientFormData.mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
				updatedPatientFormData.fullAddress = placeResult.formatted_address

				updatedPatientFormData.lat = latitude
				updatedPatientFormData.long = longitude

				for (let i = 0; i < addressComponents.length; i++) {
					const component = addressComponents[i];

					if (component.types.includes('route')) {
						route = component.long_name;
					} else if (component.types.includes('locality')) {
						updatedPatientFormData.city = component.long_name;
					} else if (component.types.includes('administrative_area_level_1')) {
						updatedPatientFormData.province = component.short_name;
					} else if (component.types.includes('postal_code')) {

						updatedPatientFormData.postalCode = component.long_name;
					} else if (component.types.includes('street_number')) {
						streetNumber = component.long_name;
					} else if (component.types.includes('country')) {
						updatedPatientFormData.country = component.long_name;
					} else if (component.types.includes('subpremise')) {
						subpremise = component.long_name;
					}
				}

				if (streetNumber && route && subpremise) {
					updatedPatientFormData.address = `${streetNumber} ${route} #${subpremise}`;
				}
				else if (streetNumber && route) {
					updatedPatientFormData.address = `${streetNumber} ${route}`;
				} else if (route) {
					updatedPatientFormData.address = route;
				} else if (streetNumber) {
					updatedPatientFormData.address = streetNumber;
				}

				setPatientAddress({ ...updatedPatientFormData });
			}
		});
	}
};

export const openDeleteModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		deleteModal: true
	})
}

export const hideDeleteModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		deleteModal: false
	})
}

export const openLinkClinicModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		linkClinkModal: true
	})
}

export const hideLinkClinicModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		linkClinkModal: false
	})
}

export const openStatusUpdateModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		statusUpdateModal: true
	})
}

export const hideStatusUpdateModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		statusUpdateModal: false
	})
}

export const openPatientNoteModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		patientNoteModal: true,
	})
}

export const hidePatientNoteModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		patientNoteModal: false,
	})
}

export const showDoctorListModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		doctorListModal: true,
	})
	document.body.style.overflow = 'hidden';
}

export const hideDoctorListModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		doctorListModal: false,
	})
	document.body.style.overflow = 'auto';
}

export const showClinictModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		updateClinicModal: true,
	})
	document.body.style.overflow = 'hidden';
}

export const hideClinictModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		updateClinicModal: false,
	})
	document.body.style.overflow = 'auto';
}


export const showAddDoctorModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		addDoctorModal: true,
	})
	document.body.style.overflow = 'hidden';
}

export const hideAddDoctorModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		addDoctorModal: false,
	})
	document.body.style.overflow = 'auto';
}

export const showAddClinictModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		addClinicModal: true,
	})
	document.body.style.overflow = 'hidden';
}

export const hideAddClinicModal = (setShowModal, showModal) => {
	setShowModal({
		...showModal,
		addClinicModal: false,
	})
	document.body.style.overflow = 'auto'
}

export const showToastMessage = (showToast, setShowToast) => {
	setShowToast({
		...showToast,
		showToast: true
	})
}

export const hideToastMessage = (showToast, setShowToast) => {
	setShowToast({
		...showToast,
		showToast: false
	})
}


export const handleShowModal = (setShowModal, showModal, key) => {
	setShowModal({
		...showModal,
		[key]: true,
	})
	document.body.style.overflow = 'hidden';
}

export const handleHideModal = (setShowModal, showModal, key) => {
	setShowModal({
		...showModal,
		[key]: false,
	})
	document.body.style.overflow = 'auto'
}

export const onHandleProfilePicChanger = async (e, setProfilePicLoader, imageRef, dispatch) => {
	setProfilePicLoader(true)
	const file = e.target.files[0];
	if (file) {
		const reader = new FileReader();

		reader.onload = (event) => {
			if (imageRef.current) {
				imageRef.current.src = event.target.result;
			}
		};
		reader.readAsDataURL(file);
		await dispatch(updateProfilePicture(file, setProfilePicLoader))
	}
}
