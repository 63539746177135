import React, { useEffect, useRef, useState } from "react";

import {
	showAddClinictModal,
	showAddDoctorModal,
} from "../../../assets/js/custome";

import {
	initialAddPateintDOBPicker,
	initialAddPatientFromDatePicker,
	initialPharmacyDatePicker,
	initialPharmacyPreviousDatePicker,
	initialPharmacyTodaysDate,
} from "../../../assets/js/datepicker";

import { AddClinics, AddDoctors } from "../../../models/patients";

import { useCustomPatientSelectors } from "../../../services/selectors/patientSelectors";

import { useCustomDispatch } from "../../../hooks";
import { fetchAllClinics } from "../../../services/actions/pharmacy/clinic/getClinics";

import { fetchPatientData } from "../../../services/actions/patients/patientDataPhnAction";
import { FetchAllDoctorsList } from "../../../services/actions/pharmacy/doctors/doctorsList";
import { Loader } from "../../common/loadingView";

import { fetchAllActivePatientList } from "../../../services/actions/patients/patientActiveLists";
import { initalModals } from "../../../utils/constants/formInitialStates";

import { fetchAllPharmacyLists } from "../../../services/actions/pharmacy/forms/pharmacyList";
import { useFormsSelectors } from "../../../services/selectors/formsSelector";

import Select from "react-select";
import * as style from '../../../style'

export const Date = ({ formData, setFormData, handleFormDataChange, name = "date", dValue = formData?.date, label = 'Date', height = "auto" }) => {
	const DateRef = useRef();

	useEffect(() => {
		const startDOBPickerInstance = initialPharmacyDatePicker(
			DateRef,
			setFormData,
			formData,
			name
		);
		return () => {
			if (startDOBPickerInstance) {
				startDOBPickerInstance.destroy();
			}
		};
	}, [DateRef, formData]);

	return (
		<div className="relative z-20">
			<input
				type="text"
				id="text1"
				ref={DateRef}
				style={{ height: height }}
				name="date"
				value={dValue}
				onChange={handleFormDataChange}
				className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
				placeholder=" "
			/>
			<label
				htmlFor="text1"
				className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
			>
				{label}
			</label>
		</div>
	);
};

export const PreviousDatePicker = ({ formData, setFormData, handleFormDataChange, name = "date", dValue = formData?.date, label = 'Date', height = "auto" }) => {
	const DateRef = useRef();

	useEffect(() => {
		const startDOBPickerInstance = initialPharmacyPreviousDatePicker(
			DateRef,
			setFormData,
			formData,
			name
		);
		return () => {
			if (startDOBPickerInstance) {
				startDOBPickerInstance.destroy();
			}
		};
	}, [DateRef, formData]);

	return (
		<div className="relative z-20">
			<input
				type="text"
				id="text1"
				ref={DateRef}
				style={{ height: height }}
				name="date"
				value={dValue}
				onChange={handleFormDataChange}
				className="pl-4 block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
				placeholder=" "
			/>
			<label
				htmlFor="text1"
				className="ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
			>
				{label}
			</label>
		</div>
	);
};

export const FormWinRxPatients = ({ handleFormDataChange, formData }) => {
	return (
		<>
			<li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
				<div className="flex items-center pl-3">
					<input
						id="horizontal-list-radio-license"
						name="fetchType"
						onChange={handleFormDataChange}
						defaultChecked
						value="winrx"
						type="radio"
						className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
					/>
					<label
						htmlFor="horizontal-list-radio-license"
						className="cursor-pointer w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400"
					>
						WinRx
					</label>
				</div>
			</li>
			<li className="w-full">
				<div className="flex items-center pl-3">
					<input
						id="horizontal-list-radio-id"
						type="radio"
						name="fetchType"
						onChange={handleFormDataChange}
						value="local"
						className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
					/>
					<label
						htmlFor="horizontal-list-radio-id"
						className="cursor-pointer w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 fs-16 rubik-400"
					>
						Local
					</label>
				</div>
			</li>
		</>
	);
};

export const PatientPhn = ({
	handleFormDataChange,
	formData,
	errorMessages,
	setErrorMessages,
	setFormData,
	text = 'PHN'
}) => {
	const dispatch = useCustomDispatch();
	const phnRef = useRef();
	const { winrxPatientDataResponse, patientData } = useCustomPatientSelectors();

	useEffect(() => {
		if (winrxPatientDataResponse?.error !== null) {
			setErrorMessages({
				...errorMessages,
				phn: winrxPatientDataResponse?.error?.data?.message,
			});
		}
		if (winrxPatientDataResponse?.loading) {
			setFormData({
				...formData,
				name: "",
				dob: "",
			});
		}
		if (winrxPatientDataResponse?.data !== null) {
			setFormData({
				...formData,
				name: `${patientData?.GIVEN} ${patientData?.SURNAME}`,
				dob: `${patientData?.BIRTHDATE}`,
				winrxPatientPhone: `${patientData?.AREA}${patientData?.PHONE}`,
				winrxPatientAddress: `${patientData?.ADDR1} ${patientData?.ADDR2} ${patientData?.ADDR3}`,
				winrxPatientCity: patientData?.CITY,
				winrxPatientProvince: patientData?.PROV,
				winrxPatientPostcode: patientData?.PC,
				winrxPatientFirstName: patientData?.GIVEN,
				winrxPatientLastName: patientData?.SURNAME
			});

			setErrorMessages({
				...errorMessages,
				name: "",
				dob: "",
			});
		}
	}, [winrxPatientDataResponse]);

	const handleFetchWinRxData = async () => {
		if (!formData.phn) {
			setErrorMessages({
				...errorMessages,
				phn: "PHN is required",
			});
		} else {
			await dispatch(fetchPatientData(formData.phn));
		}
	};
	useEffect(() => {
		if (formData?.fetchType === "winrx") {
			phnRef.current.focus();
		}
	}, [formData.fetchType]);

	return (
		<>
			<div className="relative">
				<input
					autoComplete="off"
					type="text"
					id="phn"
					ref={phnRef}
					onChange={handleFormDataChange}
					name="phn"
					value={formData.phn}
					onKeyPress={(e) => {
						if (
							isNaN(String.fromCharCode(e.charCode)) ||
							e.target.value.length >= 10
						) {
							e.preventDefault();
						}
					}}
					className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
					dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
					bg-white ${errorMessages.phn && "border-red-600"
						}`}
					placeholder=" "
				/>
				<label
					htmlFor="phn"
					className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 ml-4 rubik-400 fs-16"
				>
					{!errorMessages.phn && text}
				</label>
				{formData.fetchType === "winrx" ? (
					<div onClick={handleFetchWinRxData}>
						<button
							type="button"
							className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg mt-1 text-sm px-2 py-0 me-1 mb-0 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 absolute right-2 top-2.5 rubik-400 fs-12"
						>
							{winrxPatientDataResponse?.loading ? <Loader /> : "Search"}
						</button>
					</div>
				) : null}
				{errorMessages.phn === "PHN could not be located in WinRx." ? (
					<div className="relative">
						{errorMessages.phn && (
							<p className="text-xs text-red-600 dark:text-red-400">
								{errorMessages.phn}
							</p>
						)}
					</div>
				) : (
					<>
						{errorMessages.phn && (
							<p className="text-xs text-red-600 dark:text-red-400">
								{errorMessages.phn}
							</p>
						)}
					</>
				)}
			</div>
		</>
	);
};

export const PatientList = ({
	errorMessages,
	formData,
	setFormData,
	setErrorMessages,
	zIndex
}) => {
	const dispatch = useCustomDispatch();
	const patientListRef = useRef();

	const { activePatientList, activePatientListResponse } =
		useCustomPatientSelectors();

	const [activeList, setActiveList] = useState([]);

	useEffect(() => {
		const list = activePatientList || [];
		const formattedClinics = list?.map((value) => ({
			label: `${value?.first_name} ${value?.last_name} (${value?.phone == null ? "" : value?.phone
				})`,
			value: value?.id,
			phn: value?.phn,
			dob: value?.dob,
			phone: value?.phone,
			p_address: value.address
		}));
		setActiveList(formattedClinics);
	}, [activePatientList]);

	useEffect(() => {
		if (activePatientListResponse?.loading) {
			setErrorMessages({
				...errorMessages,
				patientName: "",
			});
		} else if (activePatientListResponse?.error !== null) {
			setErrorMessages({
				...errorMessages,
				patientName:
					"Getting an issue while fetching request please try to reload the page or try again",
			});
		} else {
			setErrorMessages({
				...errorMessages,
				patientName: "",
			});
		}
	}, [activePatientListResponse]);

	useEffect(() => {
		if (formData?.fetchType === "local") {
			patientListRef?.current?.focus();
			handleGetActivePatientList();
		}
	}, [formData?.fetchType]);

	const handleGetActivePatientList = () => {
		if (activePatientListResponse?.data === null) {
			dispatch(fetchAllActivePatientList());
		}
	};

	return (
		<div onClick={handleGetActivePatientList}>
			<Select
				ref={patientListRef}
				isMulti={false}
				onChange={(selectedValue) => {
					setFormData(
						{
							...formData,
							patientName: selectedValue,
							phn: selectedValue?.phn,
							dob: selectedValue?.dob,
							phone: selectedValue?.phone,
							p_address: selectedValue?.p_address
						},
						setErrorMessages({ ...errorMessages, patientName: "" })
					);
				}}
				value={formData?.patientName}
				options={activeList}
				classNamePrefix="react-select"
				styles={{
					control: (provided) => ({
						...provided,
						height: 46,
						paddingLeft: "13px",
						color: "#6B7280",
						borderRadius: "8px",
						position: "relative",
						border: errorMessages?.patientName
							? "1px solid red"
							: "1px solid lightgrey",
						// zIndex: 999999999999999,
					}),
					menuList: (provided) => ({
						...provided,
						maxHeight: "200px",
						overflowY: "auto",
					}),
					menu: (provided) => ({
						...provided,
						maxHeight: "200px",
						overflowY: "hidden",
					}),
				}}
				placeholder={`${errorMessages?.patientName ? "" : "Select Patient"}`}
				isSearchable={true}
				noOptionsMessage={() =>
					activePatientListResponse?.loading ? "Loading..." : "No Options"
				}
				className={`basic-multi-select ${zIndex}`}
			/>

			{errorMessages?.patientName && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages?.patientName}
				</p>
			)}
		</div>
	);
};

export const PatientListRow = ({
	errorMessages,
	formData,
	setFormData,
	setErrorMessages,
	id,
}) => {
	const dispatch = useCustomDispatch();
	const [apiCallMade, setApiCallMade] = useState(false);
	const patientListRef = useRef();

	const { activePatientList, activePatientListResponse } =
		useCustomPatientSelectors();

	const [activeList, setActiveList] = useState([]);

	useEffect(() => {
		const list = activePatientList || [];
		const formattedClinics = list?.map((value) => ({
			label: `${value.first_name} ${value.last_name} (${value.phone == null ? "" : value.phone
				})`,
			value: value.id,
			phn: value.phn,
			dob: value.dob,
		}));

		setActiveList(formattedClinics);
	}, [activePatientList]);

	useEffect(() => {
		if (activePatientListResponse?.loading) {
			setErrorMessages({
				...errorMessages,
				patientName: "",
			});
		} else if (activePatientListResponse?.error !== null) {
			setErrorMessages({
				...errorMessages,
				patientName:
					"Getting an issue while fetching request please try to reload the page or try again",
			});
		} else {
			setErrorMessages({
				...errorMessages,
				patientName: "",
			});
		}
	}, [activePatientListResponse]);

	useEffect(() => {
		if (formData?.fetchType === "local") {
			patientListRef?.current?.focus();
			handleGetActivePatientList();
		}
	}, [formData?.fetchType]);

	const handleGetActivePatientList = () => {
		if (activePatientListResponse?.data === null) {
			dispatch(fetchAllActivePatientList());
		}
	};

	return (
		<div onClick={handleGetActivePatientList}>
			<Select
				ref={patientListRef}
				isMulti={false}
				onChange={(selectedValue) => {
					setFormData(
						{
							...formData,
							patientName: [...formData.patientName || [], { [id]: selectedValue }],
							phn: [...formData?.phn || [], { [id]: selectedValue.phn }],
							dob: [...formData?.dob || [], { [id]: selectedValue.dob }],
						},
						setErrorMessages({ ...errorMessages, patientName: "" })
					);
				}}
				value={formData?.patientName[id]}
				options={activeList}
				classNamePrefix="react-select"
				styles={{
					control: (provided) => ({
						...provided,
						height: 46,
						paddingLeft: "13px",
						color: "#6B7280",
						borderRadius: "8px",
						position: "relative",
						border: errorMessages.patientName
							? "1px solid red"
							: "1px solid lightgrey",
						zIndex: 999999999999999,
					}),
					menuList: (provided) => ({
						...provided,
						maxHeight: "200px",
						overflowY: "auto",
					}),
					menu: (provided) => ({
						...provided,
						maxHeight: "200px",
						overflowY: "hidden",
					}),
				}}
				placeholder={`${errorMessages?.patientName ? "" : "Select Patient"}`}
				isSearchable={true}
				noOptionsMessage={() =>
					activePatientListResponse?.loading ? "Loading..." : "No Options"
				}
				className="basic-multi-select z-20"
			/>

			{errorMessages?.patientName && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages?.patientName}
				</p>
			)}
		</div>
	);
};

export const PatientName = ({
	handleFormDataChange,
	formData,
	errorMessages,
	setFormData,
	setErrorMessages,
	text = 'Patient Name',
	zIndex = ''
}) => {
	return (
		<div className="relative">
			{formData.fetchType === "winrx" ? (
				<>
					<input
						autoComplete="off"
						type="text"
						id="name"
						onChange={handleFormDataChange}
						name="name"
						value={formData.name}
						className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.name && "border-red-600"
							}`}
						placeholder=""
					/>
					<label
						htmlFor="name"
						className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
					>
						{!errorMessages.name && text}
					</label>
					{errorMessages.name && (
						<p className="text-xs text-red-600 dark:text-red-400">
							{errorMessages.name}
						</p>
					)}
				</>
			) : (
				<PatientList
					errorMessages={errorMessages}
					formData={formData}
					setFormData={setFormData}
					setErrorMessages={setErrorMessages}
					zIndex={zIndex}
				/>
			)}
		</div>
	);
};

export const PatientDob = ({
	handleFormDataChange,
	formData,
	errorMessages,
	setFormData,
	setErrorMessages,
}) => {
	const DateRef = useRef();
	const { patientData } = useCustomPatientSelectors();
	useEffect(() => {
		const startDOBPickerInstance = initialAddPateintDOBPicker(
			DateRef,
			setFormData,
			formData
		);

		return () => {
			if (startDOBPickerInstance) {
				startDOBPickerInstance.destroy();
			}
		};
	}, [DateRef, patientData, formData]);

	useEffect(() => {
		if (DateRef.current.value) {
			setErrorMessages({
				...errorMessages,
				dob: "",
			});
		}
	}, [DateRef, formData]);

	return (
		<div className="relative">
			<input
				ref={DateRef}
				type="text"
				id="dob"
				onChange={handleFormDataChange}
				name="dob"
				value={formData.dob}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.dob && "border-red-600"
					}`}
				placeholder=""
			/>
			<label
				htmlFor="dob"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.dob && "DOB"}
			</label>
			{errorMessages.dob && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.dob}
				</p>
			)}
		</div>
	);
};

export const ClinicName = ({
	formData,
	errorMessages,
	setFormData,
	setErrorMessages,
}) => {
	const { getClinicsData, getAllClinicsResponse } = useCustomPatientSelectors();
	const [clinicList, setClinicList] = useState([]);

	const dispatch = useCustomDispatch();

	useEffect(() => {
		const clinics = getClinicsData || [];
		const formattedClinics = clinics?.map((clinic) => ({
			label: `${clinic.name} (${clinic.fax})`,
			value: clinic.id,
			fax: clinic.fax,
		}));

		setClinicList(formattedClinics);
	}, [getClinicsData]);

	const handleFetchCliniList = () => {
		if (getAllClinicsResponse?.data === null) dispatch(fetchAllClinics());
	};

	useEffect(() => {
		if (getAllClinicsResponse?.loading) {
			setErrorMessages({
				...errorMessages,
				clinicName: "",
			});
		} else if (getAllClinicsResponse.error !== null) {
			setErrorMessages({
				...errorMessages,
				clinicName:
					"Getting an issue while fetching request please try to reload the page or try again",
			});
		} else {
			setErrorMessages({
				...errorMessages,
				clinicName: "",
			});
		}
	}, [getAllClinicsResponse]);

	return (
		<>
			<div
				onClick={handleFetchCliniList}
				className={`${!errorMessages.clinicName &&
					clinicList?.length !== 0 &&
					"select-wrapper"
					}`}
			>
				<Select
					isMulti={false}
					onChange={(selectedValue) => {
						setFormData(
							{
								...formData,
								clinicName: selectedValue,
								clinicFaxNumber: selectedValue.fax,
							},
							setErrorMessages({
								...errorMessages,
								clinicName: "",
								clinicFaxNumber: "",
							})
						);
					}}
					value={formData?.clinicName}
					options={clinicList}
					classNamePrefix="react-select"
					styles={{
						control: (provided) => ({
							...provided,
							height: 46,
							paddingLeft: "13px",
							color: "#6B7280",
							borderRadius: "8px",
							position: "relative",
							top: "17.4px",
							border: errorMessages.clinicName
								? "1px solid red"
								: "1px solid lightgrey",
						}),

						menuList: (provided) => ({
							...provided,
							maxHeight: "200px",
							overflowY: "auto",
						}),
						menu: (provided) => ({
							...provided,
							marginTop: "20px",
							maxHeight: "200px",
							overflowY: "hidden",
						}),
					}}
					isSearchable={true}
					noOptionsMessage={() =>
						getAllClinicsResponse?.loading ? "Loading..." : "No Options"
					}
					className="basic-multi-select"
					placeholder={`${errorMessages.clinicName ? "" : "Select Clinic"}`}
				/>

				{errorMessages.clinicName && (
					<p className="mt-4 text-xs text-red-600 dark:text-red-400">
						{errorMessages.clinicName}
					</p>
				)}
				{/* {!errorMessages.clinicName && clinicList?.length !== 0 && (
					<label htmlFor="react-select-2-input">Clinic</label>
				)} */}
			</div>
		</>
	);
};

export const DoctorList = ({
	formData,
	errorMessages,
	setFormData,
	setErrorMessages,
	placeholder = "Select Doctor"
}) => {
	const { doctorList, doctorsListResponse } = useCustomPatientSelectors();
	const [doctorLists, setDoctorLists] = useState([]);
	const dispatch = useCustomDispatch();

	useEffect(() => {
		const list = doctorList || [];
		const formattedClinics = list?.map((value) => ({
			label: `${value.last_name} ${value.first_name} ${value.cps_id}`,
			value: value.id,
			cps_id: value.cps_id,
		}));

		setDoctorLists(formattedClinics);
	}, [doctorList]);

	const handleFetchDoctorList = () => {
		if (doctorsListResponse?.data === null) dispatch(FetchAllDoctorsList());
	};

	useEffect(() => {
		if (doctorsListResponse?.loading) {
			setErrorMessages({
				...errorMessages,
				doctorName: "",
			});
		} else if (doctorsListResponse?.error !== null) {
			setErrorMessages({
				...errorMessages,
				doctorName:
					"Getting an issue while fetching request please try to reload the page or try again",
			});
		} else {
			setErrorMessages({
				...errorMessages,
				doctorName: "",
			});
		}
	}, [doctorsListResponse]);

	return (
		<div onClick={handleFetchDoctorList}>
			<Select
				isMulti={false}
				onChange={(selectedValue) => {
					setFormData(
						{
							...formData,
							doctorName: selectedValue,
							prescriptionCPID: selectedValue.cps_id,
						},
						setErrorMessages({ ...errorMessages, doctorName: "", prescriptionCPID: '' })
					);
				}}
				value={formData?.doctorName}
				options={doctorLists}
				classNamePrefix="react-select"
				isSearchable={true}
				noOptionsMessage={() =>
					doctorsListResponse?.loading ? "Loading..." : "No Options"
				}
				className="basic-multi-select"
				placeholder={`${errorMessages.doctorName ? "" : placeholder}`}
				styles={{
					control: (provided) => ({
						...provided,
						height: 46,
						paddingLeft: "13px",
						color: "#6B7280",
						borderRadius: "8px",
						position: "relative",
						top: "17.4px",
						border: errorMessages.doctorName
							? "1px solid red"
							: "1px solid lightgrey",
					}),

					menuList: (provided) => ({
						...provided,
						maxHeight: "200px",
						overflowY: "auto",
					}),
					menu: (provided) => ({
						...provided,
						marginTop: "20px",
						maxHeight: "200px",
						overflowY: "hidden",
					}),
				}}
			/>
			{errorMessages.doctorName && (
				<p className="mt-4 text-xs text-red-600 dark:text-red-400">
					{errorMessages.doctorName}
				</p>
			)}
		</div>
	);
};

export const ClinicFaxNumber = ({
	handleFormDataChange,
	formData,
	errorMessages,
}) => {
	return (
		<div className="relative mt-4">
			<input
				autoComplete="off"
				type="text"
				id="clinicFaxNumber"
				onChange={handleFormDataChange}
				name="clinicFaxNumber"
				value={formData.clinicFaxNumber}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.clinicFaxNumber && "border-red-600"
					}`}
				placeholder=""
				onKeyPress={(e) => {
					if (
						isNaN(String.fromCharCode(e.charCode)) ||
						e.target.value.length >= 10
					) {
						e.preventDefault();
					}
				}}
			/>
			<label
				htmlFor="clinicFaxNumber"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.clinicFaxNumber && "Clinic Fax Number"}
			</label>
			{errorMessages.clinicFaxNumber && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.clinicFaxNumber}
				</p>
			)}
		</div>
	);
};

export const LoginPharmacyName = ({
	handleFormDataChange,
	formData,
	errorMessages,
}) => {
	return (
		<div className="relative mt-4">
			<input
				type="text"
				id="pharmacyName"
				onChange={handleFormDataChange}
				name="pharmacyName"
				value={formData.pharmacyName}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.pharmacyName && "border-red-600"
					}`}
				style={{ background: "#F3F4F6" }}
				disabled
				placeholder=""
			/>
			<label
				style={{ background: "#F3F4F6" }}
				htmlFor="pharmacyName"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.pharmacyName && "Pharmacy Name"}
			</label>
			{errorMessages.pharmacyName && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.pharmacyName}
				</p>
			)}
		</div>
	);
};

export const LoginPharmacyManger = ({
	handleFormDataChange,
	formData,
	errorMessages,
}) => {
	return (
		<div className="relative mt-4">
			<input
				type="text"
				id="pharmacyManager"
				onChange={handleFormDataChange}
				name="pharmacyManager"
				value={formData.pharmacyManager}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.pharmacyManager && "border-red-600"
					}`}
				placeholder=""
			/>
			<label
				htmlFor="pharmacyManager"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.pharmacyManager && "Pharmacy Manager"}
			</label>
			{errorMessages.pharmacyManager && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.pharmacyManager}
				</p>
			)}
		</div>
	);
};

export const LoginPharmacyPhone = ({
	handleFormDataChange,
	formData,
	errorMessages,
}) => {
	return (
		<div className="relative mt-4">
			<input
				type="text"
				id="pharmacyPhone"
				onChange={handleFormDataChange}
				name="pharmacyPhone"
				value={formData.pharmacyPhone}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.pharmacyPhone && "border-red-600"
					}`}
				placeholder=""
				onKeyPress={(e) => {
					if (
						isNaN(String.fromCharCode(e.charCode)) ||
						e.target.value.length >= 10
					) {
						e.preventDefault();
					}
				}}
			/>
			<label
				htmlFor="pharmacyPhone"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.pharmacyPhone && "Pharmacy Phone"}
			</label>
			{errorMessages.pharmacyPhone && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.pharmacyPhone}
				</p>
			)}
		</div>
	);
};

export const LoginPharmacyEmail = ({
	handleFormDataChange,
	formData,
	errorMessages,
}) => {
	return (
		<div className="relative mt-4">
			<input
				type="text"
				id="pharmacyEmail"
				onChange={handleFormDataChange}
				name="pharmacyEmail"
				value={formData.pharmacyEmail}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.pharmacyEmail && "border-red-600"
					}`}
				placeholder=""
			/>
			<label
				htmlFor="pharmacyEmail"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.pharmacyEmail && "Pharmacy Email"}
			</label>
			{errorMessages.pharmacyEmail && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.pharmacyEmail}
				</p>
			)}
		</div>
	);
};

export const Designation = ({
	handleFormDataChange,
	formData,
	errorMessages,
}) => {
	return (
		<div className="relative mt-4">
			<input
				type="text"
				id="desgination"
				onChange={handleFormDataChange}
				name="desgination"
				value={formData.desgination}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white `}
				placeholder=""
			/>
			<label
				htmlFor="desgination"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				Desgination
			</label>
		</div>
	);
};

export const LoginPharmacyFax = ({
	handleFormDataChange,
	formData,
	errorMessages,
}) => {
	return (
		<div className="relative mt-4">
			<input
				type="text"
				id="pharmacyFax"
				onChange={handleFormDataChange}
				name="pharmacyFax"
				value={formData.pharmacyFax}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.pharmacyFax && "border-red-600"
					}`}
				placeholder=""
				onKeyPress={(e) => {
					if (
						isNaN(String.fromCharCode(e.charCode)) ||
						e.target.value.length >= 10
					) {
						e.preventDefault();
					}
				}}
			/>
			<label
				htmlFor="pharmacyFax"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.pharmacyFax && "Pharmacy Fax"}
			</label>
			{errorMessages.pharmacyFax && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.pharmacyFax}
				</p>
			)}
		</div>
	);
};

export const AddDoctor = ({ text = 'Add Doctor' }) => {
	const [showModal, setShowModal] = useState(initalModals);
	return (
		<>
			<button
				type="button"
				style={{ padding: "2px 7px" }}
				onClick={() => showAddDoctorModal(setShowModal, showModal)}
				className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br shadow-md shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-sm px-1  text-xs text-center absolute mt-6"
			>
				{text}
			</button>

			{showModal.addDoctorModal && (
				<AddDoctors showModal={showModal} setShowModal={setShowModal} />
			)}
		</>
	);
};

export const AddClinic = () => {
	const [showModal, setShowModal] = useState(initalModals);

	return (
		<>
			<button
				type="button"
				style={{ padding: "2px 7px" }}
				onClick={() => showAddClinictModal(setShowModal, showModal)}
				className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br shadow-md shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-sm px-1  text-xs text-center absolute mt-6"
			>
				Add Clinic
			</button>

			{showModal.addClinicModal && (
				<AddClinics showModal={showModal} setShowModal={setShowModal} />
			)}
		</>
	);
};

export const ToPharmacy = ({
	formData,
	errorMessages,
	setFormData,
	setErrorMessages,
}) => {
	const { pharmaciesListResponse } = useFormsSelectors();
	const pharmacyLists = pharmaciesListResponse?.data?.data;
	const [pharmaciesList, setPharmaciesList] = useState([]);

	const dispatch = useCustomDispatch();

	const handleFetchCliniList = () => {
		if (pharmaciesListResponse?.data === null)
			dispatch(fetchAllPharmacyLists());
	};

	useEffect(() => {
		if (pharmaciesListResponse?.loading) {
			setErrorMessages({
				...errorMessages,
				toPharmacy: "",
			});
		} else if (pharmaciesListResponse?.error !== null) {
			setErrorMessages({
				...errorMessages,
				toPharmacy:
					"Getting an issue while fetching request please try to reload the page or try again",
			});
		} else {
			setErrorMessages({
				...errorMessages,
				toPharmacy: "",
			});
		}
	}, [pharmaciesListResponse]);

	useEffect(() => {
		if (!pharmaciesListResponse?.error && pharmaciesListResponse?.data) {
			const formattedLists = pharmacyLists?.map((value) => ({
				label: `${value.name}`,
				value: value?.id,
				pharmacyFax: value?.fax,
				pharmacyPhone: value?.phone,
				pharmacyAddress: value?.address,
				pharmacyManager: value?.manager_name,
			}));
			setPharmaciesList(formattedLists);
		}
	}, [pharmaciesListResponse]);
	return (
		<>
			<div onClick={handleFetchCliniList}
				className={`${!errorMessages?.toPharmacy && formData?.toPharmacy && "select-wrapper z-10"}`}>
				<Select
					isMulti={false}
					onChange={(selectedValue) => {
						setFormData(
							{
								...formData,
								toPharmacy: selectedValue,
								toPharmacyFax: selectedValue?.pharmacyFax,
								toPharmacyPhone: selectedValue?.pharmacyPhone,
								toPharmacyAddress: selectedValue?.pharmacyAddress,
								toPharmacyManager: selectedValue?.pharmacyManager,
							},
							setErrorMessages({
								...errorMessages,
								toPharmacy: "",
								toPharmacyPhone: "",
								toPharmacyFax: "",
								toPharmacyManager: "",
								toPharmacyAddress: ""
							})
						);
					}}
					value={formData?.toPharmacy}
					options={pharmaciesList}
					classNamePrefix="react-select"
					styles={{
						control: (provided) => ({
							...provided,
							height: 46,
							paddingLeft: "13px",
							color: "#6B7280",
							borderRadius: "8px",
							position: "relative",
							top: "17.4px",
							border: errorMessages?.toPharmacy
								? "1px solid red"
								: "1px solid lightgrey",
						}),
						menuList: (provided) => ({
							...provided,
							maxHeight: "300px",
							overflowY: "auto",
						}),
						menu: (provided) => ({
							...provided,
							marginTop: "20px",
							maxHeight: "200px",
							overflowY: "hidden",
						}),
					}}
					isSearchable={true}
					noOptionsMessage={() =>
						pharmaciesListResponse?.loading ? "Loading..." : "No Options"
					}
					className="basic-multi-select"
					placeholder={`${errorMessages.toPharmacy ? "" : "To Pharmacy"}`}
				/>

				{errorMessages?.toPharmacy && (
					<p className="mt-4 text-xs text-red-600 dark:text-red-400">
						{errorMessages?.toPharmacy}
					</p>
				)}
				{!errorMessages?.toPharmacy && formData?.toPharmacy && (
					<label htmlFor="react-select-2-input">To Pharmacy</label>
				)}
			</div>
		</>
	);
};

export const ToPharmacyPhone = ({
	handleFormDataChange,
	formData,
	errorMessages,
}) => {
	return (
		<div className="relative mt-4">
			<input
				type="text"
				id="toPharmacyPhone"
				onChange={handleFormDataChange}
				name="toPharmacyPhone"
				value={formData.toPharmacyPhone}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.toPharmacyPhone && "border-red-600"
					}`}
				placeholder=""
				onKeyPress={(e) => {
					if (
						isNaN(String.fromCharCode(e.charCode)) ||
						e.target.value.length >= 10
					) {
						e.preventDefault();
					}
				}}
			/>
			<label
				htmlFor="toPharmacyPhone"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.toPharmacyPhone && "Pharmacy Phone"}
			</label>
			{errorMessages.toPharmacyPhone && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.toPharmacyPhone}
				</p>
			)}
		</div>
	);
};

export const ToPharmacyFaxNumber = ({
	handleFormDataChange,
	formData,
	errorMessages,
}) => {
	return (
		<div className="relative mt-4">
			<input
				type="text"
				id="toPharmacyFax"
				onChange={handleFormDataChange}
				name="toPharmacyFax"
				value={formData.toPharmacyFax}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.toPharmacyFax && "border-red-600"
					}`}
				placeholder=""
				onKeyPress={(e) => {
					if (
						isNaN(String.fromCharCode(e.charCode)) ||
						e.target.value.length >= 10
					) {
						e.preventDefault();
					}
				}}
			/>
			<label
				htmlFor="toPharmacyFax"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.toPharmacyFax && "Pharmacy Fax"}
			</label>
			{errorMessages.toPharmacyFax && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.toPharmacyFax}
				</p>
			)}
		</div>
	);
};

export const ToPharmacyManager = ({
	handleFormDataChange,
	formData,
	errorMessages,
}) => {
	return (
		<div className="relative mt-4">
			<input
				type="text"
				id="toPharmacyManager"
				onChange={handleFormDataChange}
				name="toPharmacyManager"
				value={formData.toPharmacyManager}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.toPharmacyManager && "border-red-600"
					}`}
				placeholder=""
				onKeyPress={(e) => {
					if (
						isNaN(String.fromCharCode(e.charCode)) ||
						e.target.value.length >= 10
					) {
						e.preventDefault();
					}
				}}
			/>
			<label
				htmlFor="toPharmacyManager"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.toPharmacyManager && "Pharmacy Manager"}
			</label>
			{errorMessages.toPharmacyManager && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.toPharmacyManager}
				</p>
			)}
		</div>
	);
};

export const LoginPharmacyProvider = ({
	handleFormDataChange,
	formData,
	errorMessages,
}) => {
	return (
		<div className="relative mt-4">
			<input
				type="text"
				id="provider"
				onChange={handleFormDataChange}
				name="provider"
				value={formData.provider}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.provider && "border-red-600"
					}`}
				placeholder=""
			/>
			<label
				htmlFor="provider"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.provider && "Provider"}
			</label>
			{errorMessages.provider && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.provider}
				</p>
			)}
		</div>
	);
};

export const BlisterDropDown = ({
	formData,
	setFormData,
	value,
	name,
	placeholder = 'Select',
	filterList,
	reset
}) => {

	const [options, setOptions] = useState([]);

	useEffect(() => {
		const list = filterList || [];
		const formattedData = list?.map((value, index) => {
			const values = value.split(',');
			return values.map((val, idx) => ({ label: val?.trim(), value: val?.trim(), key: `${val}+${index}` }))
		}).flat();
		setOptions(formattedData);

		return () => { }
	}, [filterList])

	const [selectedOption, setSelectedOption] = useState(null);

	const handleChange = (selectedValue) => {
		setSelectedOption(selectedValue);
		setFormData({
			...formData,
			[name]: selectedValue.label
		});
	};

	useEffect(() => {
		if (reset) {
			setSelectedOption(null);
		}
	}, [reset]);

	return (<Select
		isMulti={false}
		onChange={handleChange}
		value={selectedOption}
		options={options}
		classNamePrefix="react-select"
		isSearchable={false}
		className="basic-multi-select"
		placeholder={placeholder}
		styles={style.RouteStyle}
	/>
	);
};



export const PatientPhone = ({
	handleFormDataChange,
	formData,
	errorMessages,
	label='Customer Phone'
}) => {
	return (
		<div className="relative">
			<input
				type="text"
				id="phone"
				onChange={handleFormDataChange}
				name="phone"
				value={formData.phone}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.phone && "border-red-600"
					}`}
				placeholder=""
				onKeyPress={(e) => {
					if (
						isNaN(String.fromCharCode(e.charCode)) ||
						e.target.value.length >= 10
					) {
						e.preventDefault();
					}
				}}
			/>
			<label
				htmlFor="phone"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.phone && label}
			</label>
			{errorMessages.phone && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.phone}
				</p>
			)}
		</div>
	);
};

export const DateWithValidation = ({
	handleFormDataChange,
	formData = formData.datevalid,
	errorMessages,
	setFormData,
	setErrorMessages,
	label = "Reminder",
	border = '1px solid lightgray',
	setShowModal, showModal, memoID, expiryDate, searchParam,
	setShowToast,
	showToast
}) => {

	const DateRefForValid = useRef();
	const dispatch = useCustomDispatch()

	useEffect(() => {
		const startDOBPickerInstance = initialPharmacyTodaysDate(
			DateRefForValid,
			setFormData,
			formData, 'isvaliddate',
			dispatch,
			setShowModal, showModal, memoID, searchParam,
			setShowToast,
			showToast
		);
		return () => {
			if (startDOBPickerInstance) {
				startDOBPickerInstance.destroy();
			}
		};
	}, [DateRefForValid, formData]);

	useEffect(() => {
		if (DateRefForValid?.current?.value) {
			setErrorMessages({
				...errorMessages,
				datevalid: "",
			});
		}

	}, [DateRefForValid, formData]);

	return (
		<div className="relative">
			<input
				ref={DateRefForValid}
				type="text"
				style={{ border: border }}
				id="datevalid"
				onChange={handleFormDataChange}
				name="datevalid"
				defaultValue={formData}
				className={`block ${label ? 'pl-4' : '-ml-2'} px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 
				appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
				cursor-text bg-white ${errorMessages.datevalid && "border-red-600"
					}`}
				placeholder=""
			/>
			<label
				htmlFor="datevalid"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.datevalid && label}
			</label>
			{errorMessages.datevalid && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.datevalid}
				</p>
			)}

		</div>
	);
};


export const FDAFormDoctorList = ({
	formData,
	errorMessages,
	setFormData,
	setErrorMessages,
	placeholder = "Select Doctor",
}) => {
	const { doctorList, doctorsListResponse } = useCustomPatientSelectors();
	const [doctorLists, setDoctorLists] = useState([]);
	const dispatch = useCustomDispatch();

	useEffect(() => {
		const list = doctorList || [];
		const formattedClinics = list?.map((value) => ({
			label: `${value.last_name} ${value.first_name} ${value.cps_id}`,
			value: value.id,
			cps_id: value.cps_id,
		}));

		setDoctorLists(formattedClinics);
	}, [doctorList]);

	const handleFetchDoctorList = () => {
		if (doctorsListResponse?.data === null) dispatch(FetchAllDoctorsList());
	};

	useEffect(() => {
		if (doctorsListResponse?.loading) {
			setErrorMessages({
				...errorMessages,
				doctor: "",
			});
		} else if (doctorsListResponse?.error !== null) {
			setErrorMessages({
				...errorMessages,
				doctor:
					"Getting an issue while fetching request please try to reload the page or try again",
			});
		} else {
			setErrorMessages({
				...errorMessages,
				doctor: "",
			});
		}
	}, [doctorsListResponse]);

	return (
		<div onClick={handleFetchDoctorList}>
			<Select
				isMulti={false}
				onChange={(selectedValue) => {
					setFormData(
						{
							...formData,
							doctor: selectedValue,
							// prescriptionCPID: selectedValue.cps_id,
						},
						setErrorMessages({ ...errorMessages, doctor: "" })
					);
				}}
				value={formData?.doctor}
				options={doctorLists}
				classNamePrefix="react-select"
				isSearchable={true}
				noOptionsMessage={() =>
					doctorsListResponse?.loading ? "Loading..." : "No Options"
				}
				className="basic-multi-select"
				placeholder={`${errorMessages.doctor ? "" : placeholder}`}
				styles={{
					control: (provided) => ({
						...provided,
						height: 46,
						paddingLeft: "13px",
						color: "#6B7280",
						borderRadius: "8px",
						position: "relative",
						top: "17.4px",
						border: errorMessages.doctor
							? "1px solid red"
							: "1px solid lightgrey",
					}),

					menuList: (provided) => ({
						...provided,
						maxHeight: "200px",
						overflowY: "auto",
					}),
					menu: (provided) => ({
						...provided,
						marginTop: "20px",
						maxHeight: "200px",
						overflowY: "hidden",
					}),
				}}
			/>
			{errorMessages.doctor && (
				<p className="mt-4 text-xs text-red-600 dark:text-red-400">
					{errorMessages.doctor}
				</p>
			)}
		</div>
	);
};


export const PatientFromDate = ({
	handleFormDataChange,
	formData,
	errorMessages,
	setFormData,
	setErrorMessages,
}) => {
	const DateRef = useRef();
	const { patientData } = useCustomPatientSelectors();
	useEffect(() => {
		const startDOBPickerInstance = initialAddPatientFromDatePicker(
			DateRef,
			setFormData,
			formData
		);

		return () => {
			if (startDOBPickerInstance) {
				startDOBPickerInstance.destroy();
			}
		};
	}, [DateRef, patientData, formData]);

	useEffect(() => {
		if (DateRef.current.value) {
			setErrorMessages({
				...errorMessages,
				fromdate: "",
			});
		}
	}, [DateRef, formData]);

	return (
		<div className="relative">
			<input
				ref={DateRef}
				type="text"
				id="fromdate"
				onChange={handleFormDataChange}
				name="fromdate"
				value={formData.fromdate}
				className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text bg-white ${errorMessages.dob && "border-red-600"
					}`}
				placeholder=""
			/>
			<label
				htmlFor="fromdate"
				className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-6  origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
			>
				{!errorMessages.fromdate && "From Date"}
			</label>
			{errorMessages.fromdate && (
				<p className="text-xs text-red-600 dark:text-red-400">
					{errorMessages.fromdate}
				</p>
			)}
		</div>
	);
};
