import {
    ADD_BLISTER_PACK_REQUEST,
    ADD_BLISTER_PACK_SUCCESS,
    ADD_BLISTER_PACK_FAILURE
} from "../../../constants"

import {
    add_blister_pack, update_blister_pack,
    delete_blister_pack, print_dispense_blister,
    patient_checkrefills,
    add_medrx_notes,
    add_billing,
    print_label_dispense_blister,
    update_blister_log,
    add_vials_pack,
    update_vials_pack,
    update_vials_packlogs,
    add_sMedication_pack,
    update_sMedication_pack,
    update_sMedication_log
} from "../../../../networking/urlEndPoints"

import { fetchBlisterDailyPackList, fetchBlisterPacksList, fetchBlisterPacksOnShelfList, fetchMEdicationDailyPackList, fetchVialsDailyPackList } from "./patientBlisterPackList"
import { PreviewPdf } from "../../../../utils/helper/helper"

import Axios from "../../../../networking/intraceptor"
import * as routeName from '../../../../routes/routeName'
import * as path from '../../../../networking/urlEndPoints'

// -----------------------Add request action ------------------------
export const addPatientBlisterPackRequest = (types) => ({
    type: ADD_BLISTER_PACK_REQUEST,
    types: types
})

// -----------------------Success action ------------------------

export const addPatientBlisterPackSuccess = (data, types) => ({
    type: ADD_BLISTER_PACK_SUCCESS,
    payload: data,
    types: types
})

// -----------------------Failure action ------------------------

export const addPatientBlisterPackFailure = (error, types) => ({
    type: ADD_BLISTER_PACK_FAILURE,
    payload: error,
    types: types
})

// ------------------------Add Blister Pack----------------------------

export const AddBlisterPack = (type, formdata, id, setShowModal, setShowToast) => {

    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))
        try {
            const requestData = {
                patient_id: id,
                recurrence_day_type: formdata.recurrence_day_type,
                recurrence_day: formdata.recurrence_day_type === 'custom' ? formdata.r_days : formdata.recurrence_day,
                rx_need_to_bill: formdata.rx_need_to_bill,
                deductibles: formdata.deductibles,
                note: formdata.note,
            }

            const response = await Axios.post(`${add_blister_pack}`, requestData)
            if (response?.status === 200) {

                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, addBlisterPackModal: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}


// ------------------------Update Blister Pack----------------------------

export const UpdateBlisterPack = (type, requestData, setShowModal, setShowToast, paginationNumber) => {

    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))
        try {
            const response = await Axios.post(`${update_blister_pack}`, requestData)
            if (response?.status === 200) {

                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, updateBlisterlist: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                const params = {
                    page: paginationNumber,
                    no_of_records: 10
                }

                dispatch(fetchBlisterPacksList('blister_packs_list', params))


            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}

// ------------------------Delete Blister Pack------------------------------

export const DeleteBlisterPack = (type, formdata, id, setShowModal, setShowToast, paginationNumber, location, urlEndPoint) => {

    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))
        try {

            const endpoint = urlEndPoint ? `${urlEndPoint}/${id}` : `${delete_blister_pack}/${id}`;
            const response = await Axios.post(endpoint)

            if (response?.status === 200) {

                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, deleteBlisterModal: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                const params = {
                    page: paginationNumber,
                    no_of_records: 10
                }

                if (location.pathname === routeName.pharmacy_blister_pack_on_shelf) {
                    dispatch(fetchBlisterPacksOnShelfList('blister_packs_on_shelf_list', params))
                } else if (location.pathname === routeName.pharmacy_vials_packs) {
                    dispatch(fetchBlisterPacksList('Vials_pack_list', params, path.vials_patient_pack))
                } else if (location.pathname === routeName.pharmacy_speciality_medication_packs) {
                    dispatch(fetchBlisterPacksList('medication_pack_list', params, path.sMedication_pack_list))
                } else {
                    dispatch(fetchBlisterPacksList('blister_packs_list', params))
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}

// --------------------------------Print All Medicine Data in Blister Pack page list while opening the patient medication modal -------------------------------
export const PrintAllDispenseData = (type, patient_id, dispense) => {
    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))

        try {
            const uploadData = {
                patient_id: patient_id,
                rxlabeltoprint: dispense
            }
            const response = await Axios.post(`${print_dispense_blister}`, uploadData)

            if (response?.status === 200) {
                PreviewPdf(response?.data?.data)
                dispatch(addPatientBlisterPackSuccess(response?.data, type))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}

// --------------------------------Update the  Refills In Patient Medication Detail-------------------------------

export const PatientCheckRefills = (type, is_checked, c_type, patient_id, rxnum, setShowToast) => {
    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))

        try {
            const uploadData = {
                is_checked: is_checked,
                ctype: c_type,
                patient_id: patient_id,
                rxnum: rxnum
            }
            const response = await Axios.post(`${patient_checkrefills}`, uploadData)

            if (response?.status === 200) {

                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, showToast: false }))
                    }, 2000);
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}

// --------------------------------Add patient note-------------------------------

export const AddPatientNote = (type, requestData, setShowModal, setShowToast) => {
    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))

        try {
            const response = await Axios.post(`${add_medrx_notes}`, requestData)

            if (response?.status === 200) {

                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowModal((prevstat) => ({ ...prevstat, addNoteModal: false }))

                    setShowToast((prevstat) => ({ ...prevstat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, showToast: false }))
                    }, 2000);
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}

// --------------------------------Update patient note-------------------------------

export const AddBillingNote = (type, requestData, setShowModal, setShowToast) => {
    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))

        try {
            const response = await Axios.post(`${add_billing}`, requestData)

            if (response?.status === 200) {

                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowToast === 'function') {
                    setShowModal((prevstat) => ({ ...prevstat, patientNoteModal: false }))

                    setShowToast((prevstat) => ({ ...prevstat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, showToast: false }))
                    }, 2000);
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}

// --------------------------------Print label Data-------------------------------

export const PrintLabelsData = (type, uploadData) => {
    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))

        try {

            const response = await Axios.post(`${print_label_dispense_blister}`, uploadData)

            if (response?.status === 200) {
                PreviewPdf(response?.data?.data)
                dispatch(addPatientBlisterPackSuccess(response?.data, type))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}


// ----------------------------------Update Daily Blister Pack---------------------------

// export const UpdateBlisterDailyPack = (type, formdata, id, setShowModal, setShowToast, paginationNumber) => {

//     return async (dispatch) => {
//         dispatch(addPatientBlisterPackRequest(type))
//         try {
//             let requestData
//             if (type === 'update_blister_daily_pack') {
//                 requestData = {
//                     pack_id: id,
//                     blister_route: formdata.route,
//                     facility: formdata.facility,
//                     deductible: formdata.deductible,
//                     rx_need_to_bill: formdata.no_of_rx_need_to_bill,
//                     note: formdata.note,
//                     prescription_billed: formdata.prescription_billed
//                 }
//             } else {
//                 requestData = {
//                     allbilled: formdata,
//                     pack_id: id,
//                 }
//             }

//             const response = await Axios.post(`${update_blister_log}`, requestData)

//             if (response?.status === 200) {
//                 dispatch(addPatientBlisterPackSuccess(response?.data, type))

//                 if (typeof setShowModal === 'function') {
//                     setShowModal((prevStat) => ({ ...prevStat, updateBlisterlist: false }))
//                     document.body.style.overflow = 'auto'
//                 }

//                 if (typeof setShowToast === 'function') {
//                     setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
//                     setTimeout(() => {
//                         setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
//                     }, 2000);
//                 }

//                 const params = {
//                     page: paginationNumber,
//                     no_of_records: 10
//                 }
//                 // dispatch(fetchBlisterDailyPackList('blister_daily_pack_list', params))

//             } else {
//                 throw new Error(response)
//             }
//         } catch (error) {
//             if (error?.response) {

//                 if (typeof setShowToast === 'function') {
//                     setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
//                     setTimeout(() => {
//                         setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
//                     }, 2000);
//                 }

//                 dispatch(addPatientBlisterPackFailure(error?.response, type))
//             }
//         }
//     }
// }

export const UpdateBlisterDailyPack = (type, requestData, setShowModal, setShowToast, paginationNumber) => {

    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))
        try {
            const response = await Axios.post(`${update_blister_log}`, requestData)

            if (response?.status === 200) {
                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, updateBlisterlist: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                const params = {
                    page: paginationNumber,
                    no_of_records: 10
                }
                // dispatch(fetchBlisterDailyPackList('blister_daily_pack_list', params))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}

export const UpdateBlisterPAckOnShelf = (type, formdata, id, setShowModal, setShowToast, paginationNumber) => {

    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))
        try {
            let requestData = {
                pack_id: id,
                blister_route: formdata.route,
                facility: formdata.facility,
                no_of_blister_pack: formdata.no_of_blister_pack,
                note: formdata.note,
            }
            const response = await Axios.post(`${update_blister_pack}`, requestData)

            if (response?.status === 200) {
                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, updateBlisterlist: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                const params = {
                    page: paginationNumber,
                    no_of_records: 10
                }
                if (type === 'update_blister_packs_on_shelf') {
                    dispatch(fetchBlisterPacksOnShelfList('blister_packs_on_shelf_list', params))
                }
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}

export const BulkUpdateNOB = (type, formdata, checkblister, setShowModal, setShowToast, paginationNumber) => {

    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))
        try {
            let requestData = {
                checkblister: checkblister,
                value: formdata.value
            }

            const response = await Axios.post(`${path.patient_assign_bshelfpacks}`, requestData)

            if (response?.status === 200) {
                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, updateBlisterlist: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                // const params = {
                //     page: paginationNumber,
                //     no_of_records: 10,
                //     ...formdata
                // }

                const params = {
                    page: paginationNumber,
                    no_of_records: 10
                };

                if (formdata.route) params.route = formdata.route;
                if (formdata.search) params.search = formdata.search;
                if (formdata.day) params.rec_day = formdata.day.match(/\d+/)[0];
                if (formdata.weekday) params.rec_weekday = formdata.weekday;
                if (formdata.facility) params.facility = formdata.facility;
                if (formdata.startDate) params.from_date = formdata.startDate;
                if (formdata.endDate) params.to_date = formdata.endDate;
                if (formdata.shelfType) params.no_of_blister_pack = formdata.shelfType;

                if (type === 'update_blister_packs_on_shelf') {
                    dispatch(fetchBlisterPacksOnShelfList('blister_packs_on_shelf_list', params))
                } else {
                    dispatch(fetchBlisterPacksOnShelfList('blister_packs_on_shelf_list', params))
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}
















// ----------------------------------------------------Vials Section Start ---------------------------------------

export const AddPatientVialsPack = (type, formdata, id, setShowModal, setShowToast) => {

    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))
        try {
            const requestData = {
                patient_id: id,
                recurrence_day_type: formdata.recurrence_day_type,
                recurrence_day: formdata.recurrence_day_type === 'custom' ? formdata.r_days : formdata.recurrence_day,
                rx_need_to_bill: formdata.rx_need_to_bill,
                deductibles: formdata.deductibles,
                note: formdata.note,
            }

            const response = await Axios.post(`${add_vials_pack}`, requestData)
            if (response?.status === 200) {

                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, addBlisterPackModal: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}

export const UpdateVialsPack = (type, formdata, id, setShowModal, setShowToast, paginationNumber) => {

    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))
        try {
            const requestData = {
                pack_id: id,
                vials_route: formdata.route,
                facility: formdata.facility,
                deductible: formdata.deductible,
                rx_need_to_bill: formdata.no_of_rx_need_to_bill,
                note: formdata.note,
                date: formdata.processingDate
            }

            const response = await Axios.post(`${update_vials_pack}`, requestData)

            if (response?.status === 200) {

                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, updateBlisterlist: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                const params = {
                    page: paginationNumber,
                    no_of_records: 10
                }

                dispatch(fetchBlisterPacksList('Vials_pack_list', params, path.vials_patient_pack))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}


export const UpdateDailyVailsPack = (type, formdata, id, setShowModal, setShowToast, paginationNumber) => {

    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))
        try {
            let requestData
            if (type === 'update_daily_vials_pack') {
                requestData = {
                    pack_id: id,
                    vials_route: formdata.route,
                    facility: formdata.facility,
                    deductible: formdata.deductible,
                    rx_need_to_bill: formdata.no_of_rx_need_to_bill,
                    note: formdata.note,
                    prescription_billed: formdata.prescription_billed
                }
            } else {
                requestData = {
                    allbilled: formdata,
                    pack_id: id,
                }
            }

            const response = await Axios.post(`${update_vials_packlogs}`, requestData)

            if (response?.status === 200) {
                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, updateBlisterlist: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                const params = {
                    page: paginationNumber,
                    no_of_records: 10
                }

                if (type === 'update_daily_vials_pack') dispatch(fetchVialsDailyPackList('vials_daily_pack_list', params))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}


// ----------------------------------------------------Add Medication Start ---------------------------------------


export const AddPatientMedicationPack = (type, formdata, id, setShowModal, setShowToast) => {

    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))
        try {
            const requestData = {
                patient_id: id,
                recurrence_day_type: formdata.recurrence_day_type,
                recurrence_day: formdata.recurrence_day_type === 'custom' ? formdata.r_days : formdata.recurrence_day,
                rx_need_to_bill: formdata.rx_need_to_bill,
                deductibles: formdata.deductibles,
                note: formdata.note,
            }

            const response = await Axios.post(`${add_sMedication_pack}`, requestData)
            if (response?.status === 200) {

                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, addBlisterPackModal: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}


export const UpdateMedicationPack = (type, formdata, id, setShowModal, setShowToast, paginationNumber) => {

    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))
        try {
            const requestData = {
                pack_id: id,
                medication_route: formdata.route,
                facility: formdata.facility,
                deductible: formdata.deductible,
                rx_need_to_bill: formdata.no_of_rx_need_to_bill,
                note: formdata.note,
                date: formdata.processingDate
            }

            const response = await Axios.post(`${update_sMedication_pack}`, requestData)

            if (response?.status === 200) {

                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, updateBlisterlist: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                const params = {
                    page: paginationNumber,
                    no_of_records: 10
                }

                dispatch(fetchBlisterPacksList('medication_pack_list', params, path.sMedication_pack_list))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}

export const UpdateDailyMedicationPack = (type, formdata, id, setShowModal, setShowToast, paginationNumber) => {

    return async (dispatch) => {
        dispatch(addPatientBlisterPackRequest(type))
        try {
            let requestData
            if (type === 'update_daily_medication_pack') {
                requestData = {
                    pack_id: id,
                    medication_route: formdata.route,
                    facility: formdata.facility,
                    deductible: formdata.deductible,
                    rx_need_to_bill: formdata.no_of_rx_need_to_bill,
                    note: formdata.note,
                    prescription_billed: formdata.prescription_billed
                }
            } else {
                requestData = {
                    allbilled: formdata,
                    pack_id: id,
                }
            }

            const response = await Axios.post(`${update_sMedication_log}`, requestData)

            if (response?.status === 200) {
                dispatch(addPatientBlisterPackSuccess(response?.data, type))

                if (typeof setShowModal === 'function') {
                    setShowModal((prevStat) => ({ ...prevStat, updateBlisterlist: false }))
                    document.body.style.overflow = 'auto'
                }

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, showToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, showToast: false }))
                    }, 2000);
                }

                const params = {
                    page: paginationNumber,
                    no_of_records: 10
                }

                if (type === 'update_daily_medication_pack') dispatch(fetchMEdicationDailyPackList('medication_daily_pack_list', params))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {

                if (typeof setShowToast === 'function') {
                    setShowToast((prevStat) => ({ ...prevStat, errorToast: true }))
                    setTimeout(() => {
                        setShowToast((prevStat) => ({ ...prevStat, errorToast: false }))
                    }, 2000);
                }

                dispatch(addPatientBlisterPackFailure(error?.response, type))
            }
        }
    }
}
