import {
    PHARMACY_RX_NUMBER_ARCHIVED_LIST_REQUEST,
    PHARMACY_RX_NUMBER_ARCHIVED_LIST_SUCCESS,
    PHARMACY_RX_NUMBER_ARCHIVED_LIST_FAILURE,
} from '../../../constants';
import { pharmacy_rxnumber_archieved_list } from '../../../../networking/urlEndPoints';

import Axios from '../../../../networking/intraceptor'
import { PreviewPdf } from '../../../../utils/helper/helper';

export const pharmacySearchRxNumberListRequest = (type) => ({
    type: PHARMACY_RX_NUMBER_ARCHIVED_LIST_REQUEST,
    types:type
});

export const pharmacySearchRxNumberListSuccess = (data,type) => ({
    type: PHARMACY_RX_NUMBER_ARCHIVED_LIST_SUCCESS,
    payload: data,
    types:type
});

export const pharmacySearchRxNumberListFailure = (error,type) => ({
    type: PHARMACY_RX_NUMBER_ARCHIVED_LIST_FAILURE,
    payload: error,
    types:type
});

export const searchByRxNumberArchivedlistData = (formData,type) => {
    return async (dispatch) => {
        dispatch(pharmacySearchRxNumberListRequest(type));
        try {
            const params = {
                rxnumber: formData?.rxnumber
            }
            const response = await Axios.get(`${pharmacy_rxnumber_archieved_list}`, { params })
            if (response?.status === 200) {
                dispatch(pharmacySearchRxNumberListSuccess(response?.data,type));
            } else {
                throw new Error(response);
            }
        } catch (error) {
            if (error?.response) {
                dispatch(pharmacySearchRxNumberListFailure(error?.response?.data,type));
            }
        }
    }
}

export const searchByRxNumberExportPdfData = (formData,type) => {
    return async (dispatch) => {
        dispatch(pharmacySearchRxNumberListRequest(type));
        try {
            const params = {
                rxnumber: formData?.rxnumber,
                exportsheet: true
            }
            const response = await Axios.get(`${pharmacy_rxnumber_archieved_list}`, { params })
            if (response?.status === 200) {
                dispatch(pharmacySearchRxNumberListSuccess(response?.data,type));
                PreviewPdf(response?.data?.data)
            } else {
                throw new Error(response);
            }
        } catch (error) {
            if (error?.response) {
                dispatch(pharmacySearchRxNumberListFailure(error?.response?.data,type));
            }
        }
    }
}

