import {
    LIST_ARV_DATA_REQUEST,
    LIST_ARV_DATA_FAILURE,
    LIST_ARV_DATA_SUCCESS
} from "../../../constants"

import { get_arv_lists, on_boarding_user_profile, patient_rxdelivered_diffrence, pharmacy_team_users } from "../../../../networking/urlEndPoints"

import Axios from "../../../../networking/intraceptor"

export const avrListRequest = (types) => ({
    type: LIST_ARV_DATA_REQUEST,
    types: types
})

export const avrListSuccess = (data, types) => ({
    type: LIST_ARV_DATA_SUCCESS,
    payload: data,
    types: types
})

export const avrListFailure = (error, types) => ({
    type: LIST_ARV_DATA_FAILURE,
    payload: error,
    types: types
})

export const fetchARVList = (type, params) => {

    return async (dispatch) => {

        dispatch(avrListRequest(type))
        try {
            const response = await Axios.get(`${get_arv_lists}`, {
                params
            })
            if (response?.status === 200) {
                dispatch(avrListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(avrListFailure(error?.response, type))
            }
        }
    }
}


export const fetchParticularTeamUserList = (type, id) => {
    return async (dispatch) => {
        dispatch(avrListRequest(type))
        try {
            const response = await Axios.get(`${on_boarding_user_profile}/${id}`)
            if (response?.status === 200) {

                dispatch(avrListSuccess(response?.data, type))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(avrListFailure(error?.response, type))
            }
        }
    }
}


export const fetchPharmacyTeamsUsersDropdownList = (type, params) => {
    return async (dispatch) => {
        dispatch(avrListRequest(type))
        try {
            const response = await Axios.get(`${pharmacy_team_users}`, { params })
            if (response?.status === 200) {
                dispatch(avrListSuccess(response?.data, type))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(avrListFailure(error?.response, type))
            }
        }
    }
}



export const fetchRxAdded = (type, id,date) => {
    return async (dispatch) => {
        dispatch(avrListRequest(type))
        try {
            const response = await Axios.get(`${patient_rxdelivered_diffrence}?patient_id=${id}&search_date=${date}`)
            if (response?.status === 200) {

                dispatch(avrListSuccess(response?.data, type))

            } else {
                throw new Error(response)
            }
        } catch (error) {
            if (error?.response) {
                dispatch(avrListFailure(error?.response, type))
            }
        }
    }
}