import React, { useEffect, useState } from 'react'
import { Sidebar } from '../../../components/common'

import { useCustomDispatch, useCustomLocation, useCustomNavigate } from '../../../hooks'
import { LoadingView } from '../../../components/common'

import { authToken } from '../../../storage/authToken'
import { ErrorMessages } from '../../../components/error/alertMessages'
import { useCustomSelectors } from '../../../services/selectors/allSelectors'
import { useCustomLoadingStates } from '../../../hooks/customStates'

import { fetchCheckOutInLogsList } from '../../../services/actions/admin/team/fetchTeamsList'
import { useAdminSelectors } from '../../../services/selectors/adminModuleSelectors'
import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'

import { ArchivedDelveriesDateRangePicker } from '../../../components/pharmacy/form/dateRangePicker'

import * as useEffectHelper from '../../../utils/helper/useEffectsHelper'
import * as Common from '../../../components/common'

import Button from '../../../components/common/button'
import ChatBox from '../../../components/chat/chatbox'

export const requestListTableHeading = [
    { text: "USER NAME", classNames: ['p-3'] },
    { text: "CHECKIN TIME", classNames: ['p-3'] },
    { text: "CHECKOUT TIME", classNames: ['p-3'] },
    { text: "BREAKIN TIME", classNames: ['p-3'] },
    { text: "BREAKOUT TIME", classNames: ['p-3'] },
    { text: "IP ADDRESS", classNames: ['p-3'] },
];

export const TableRowData = ({ value,email, className1 = 'p-3' }) => {
    return (<td scope="row" className={className1}>
        <p>{value}</p>
        <p>{email}</p>
    </td>)
}

const CheckInOutLogs = () => {

    const token = authToken()

    const dispatch = useCustomDispatch()
    const location = useCustomLocation()
    const navigate = useCustomNavigate()
    const startDateRef = React.useRef()
    const endDateRef = React.useRef()

    const { TeamListResponse } = useAdminSelectors()
    const { adminModule } = useCustomSelectors()

    const teamCheckInOutRequestList = TeamListResponse?.data?.data

    const { apiCallMade, setApiCallMade } = useCustomLoadingStates()

    const searchParams = new URLSearchParams();
    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)
    const [formData, setFormData] = useState({ startDate: '', endDate: '' })
    const [reset, setReset] = useState(false);

    useEffect(() => {
        if (!apiCallMade && token && adminModule === 1) {
            dispatch(fetchCheckOutInLogsList('check_in_out_logs'))
            setApiCallMade(true)
        }
    }, [])

    const handlePagination = (event, value) => {
        const params = {
            page: value || pageNumber,
        };

        if (value) {
            setPageNumber(value);
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            navigate(newUrl);
        }

        dispatch(fetchCheckOutInLogsList('check_in_out_logs', params))
    }

    const handleFilter = () => {
        const params = {
            from_date: formData.startDate,
            to_date: formData.endDate,
            status: formData.status,
            search: formData.allEmployee
        };

        dispatch(fetchCheckOutInLogsList('check_in_out_logs', params))
    }

    const handleReset = () => {
        searchParams.delete('page');
        setReset(true)
        setFormData({ ...formData, startDate: '', endDate: '' })
        setTimeout(() => {
            setReset(false)
        }, 500);

        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl);
        dispatch(fetchCheckOutInLogsList('check_in_out_logs'))
    }

    useEffectHelper.useArchivedDelveriesDateRangePickerEffect(formData, setFormData, startDateRef, endDateRef)

    return (
        <>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">
                    <div className="grid grid-cols-12 gap-4">
                        <Sidebar />
                        <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content" id="allRequest">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className="flex flex-wrap lg:flex-nowrap gap-3">

                                            <div className='flex flex-0 lg:w-2/12 md:w-6/12 sm:w-8/12 w-full mb-4' style={{ gridTemplateColumns: '2fr 2fr 7px 2fr 2fr', width: '350px' }}>

                                                <ArchivedDelveriesDateRangePicker startDateRef={startDateRef} endDateRef={endDateRef}
                                                    formData={formData} reset={reset} setFormData={setFormData}
                                                />
                                            </div>

                                            {/* <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '142px' }}>
                                                <BlisterDropDown formData={formData}
                                                    setFormData={setFormData} name='status' value={formData.status} reset={reset} placeholder='All Status'
                                                    filterList={['All Status', 'Approved', 'Rejected', 'Pending']}
                                                />
                                            </div> */}

                                            {/* <div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '200px' }}>
                                                <BlisterDropDown formData={formData} setFormData={setFormData} name='allEmployee' value={formData?.allEmployee} filterList={teamCheckInOutRequestList?.users} reset={reset} placeholder='All Employees' />
                                            </div> */}

                                            <div className='' style={{ height: '42px' }}>
                                                <Button handleFilter={handleFilter} color='white' />
                                            </div>

                                            <div className=' ' style={{ height: '42px' }}>
                                                <Button handleFilter={handleReset} bgColor='white' width='70px' text='Reset' />
                                            </div>

                                        </div>

                                        {
                                            TeamListResponse?.loading && TeamListResponse?.type === 'check_in_out_logs' ?
                                                <div className="flex justify-center items-center mt-60 mb-80">
                                                    <LoadingView />
                                                </div>
                                                :

                                                TeamListResponse?.error !== null && TeamListResponse?.type === 'check_in_out_logs' ?
                                                    <ErrorMessages />


                                                    : teamCheckInOutRequestList?.checkinlogs?.length === 0 && TeamListResponse?.type === 'check_in_out_logs' ?
                                                        <p className='text-gray-500'>No Record Found.</p>

                                                        : TeamListResponse?.type === 'check_in_out_logs' ?

                                                            <div className="relative overflow-x-auto border rounded-lg">
                                                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 whitespace-nowrap">
                                                                    <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                                        <tr>
                                                                            {requestListTableHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            teamCheckInOutRequestList?.checkinlogs?.map((value, index) => {

                                                                                return (<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                                                                                    <TableRowData value={value?.user_name?.charAt(0).toUpperCase() + value?.user_name?.slice(1)} email={value?.email}/>

                                                                                    <td className="p-3">
                                                                                            <p className="rubik-400">{value.checkin_time}</p>
                                                                                           
                                                                                    </td>

                                                                                    <td className="p-3">
                                                                                        <p className="">{value.checkout_time}</p>
                                                                                    </td>

                                                                                    <td className="p-3">
                                                                                            <p className="rubik-400">{value.breakin_time}</p>
                                                                                           
                                                                                    </td>

                                                                                    <td className="p-3">
                                                                                        <p className="">{value.breakout_time}</p>
                                                                                    </td>

                                                                                    <td className="p-4">
                                                                                       <p className="">Break Out IP : {value.breakout_ip}</p>
                                                                                       <p className=""> Break In IP : {value.breakin_ip}</p>
                                                                                        <p className="">Check Out IP : {value.checkout_ip}</p>
                                                                                        <p className="">Check In IP : {value.checkin_ip}</p>
                                                                                    </td>


                                                                                </tr>)
                                                                            })}
                                                                    </tbody>
                                                                </table>

                                                                {TeamListResponse && TeamListResponse?.error !== null ? null : <>
                                                                    {teamCheckInOutRequestList?.checkinlogs?.length !== 0 && <>
                                                                        {teamCheckInOutRequestList?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={teamCheckInOutRequestList?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={handlePagination}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}
                                                            </div>
                                                            : null
                                        }
                                    </div>
                                </div>

                                {/* <Projects /> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ChatBox />
        </>
    )
}

export default CheckInOutLogs;