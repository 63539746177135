import React, {
    useEffect,
    useState,
    useCallback
} from 'react'

import { Errors } from '../../../components/error/alertMessages';
import { useCustomLoadingStates } from '../../../hooks/customStates';

import Select from "react-select";
import Axios from '../../../networking/intraceptor';
import { useCustomSelectors } from '../../../services/selectors/allSelectors';

export const PharmacyDepartments = ({ setFormData, logData, editLog }) => {

    const [departmentList, setDepartmentList] = useState([]);
    const { apiCallMade, setApiCallMade } = useCustomLoadingStates()

    const [selectedOptions, setSelectedOptions] = useState(null)
    const [departmentData, setDepartmentData] = useState({ loading: false, data: [], error: null })

    const handleSelectChange = (selectedValues) => {
        setSelectedOptions(selectedValues)
        setFormData((prevStat) => ({ ...prevStat, department_name: selectedValues?.length > 0 ? selectedValues : '' }))
    };

    useEffect(() => {
        const clinics = departmentData?.data?.department || [];
        const formattedDepartmentsData = clinics?.map((value) => ({
            label: value.name,
            value: value.id,
        }));

        setDepartmentList(formattedDepartmentsData);
    }, [departmentData])

    useEffect(() => {
        if (editLog) {
            const formattedDepartmentsData = logData?.departments?.map((value, idx) => ({
                label: value?.name,
                value: value?.id,
            }));
            setSelectedOptions(formattedDepartmentsData);
            setFormData((prevStat) => ({ ...prevStat, department_name: formattedDepartmentsData?.length > 0 ? formattedDepartmentsData : '' }))
        }
    }, [logData, editLog])

    const handleGetClinicList = async () => {
        if (!apiCallMade) {
            setDepartmentData((prevStat) => ({ ...prevStat, loading: true, data: [], error: null }))
            try {
                const response = await Axios.get(`pharmacy-user-departments`)
                if (response?.status === 200) {
                    setDepartmentData((prevStat) => ({ ...prevStat, loading: false, data: response?.data?.data, error: null }))
                } else {
                    throw new Error(response)
                }
            } catch (error) {
                if (error?.response) {
                    setDepartmentData((prevStat) => ({ ...prevStat, loading: false, data: [], error: error?.response }))
                }
            }
            setApiCallMade(true)
        }
    }

    return (
        <div className="relative" onClick={handleGetClinicList}>
            <Select
                isMulti
                options={departmentList}
                classNamePrefix="react-select"
                onChange={handleSelectChange}
                value={selectedOptions}
                isSearchable={true}
                className="basic-multi-select"
                placeholder='Select Department'
                noOptionsMessage={() => departmentData.loading ? 'Loading...' : 'No options'}
            />

            {departmentData?.error !== null &&
                <div className='mt-2'>
                    <Errors error='Something went wrong when fetching the clinics. Please try again or reload the page.' />
                </div>
            }
        </div>
    )
}

export const TextErrorInput = ({ formData,
    setFormData,
    label,
    errorMessage,
    textType = 'text',
    enable = false,
    name = "" }) => {

    return (<div className="relative w-full">
        <input type={textType} id={name} autoComplete="off"
            onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
            name={name}
            value={formData[name]}
            disabled={enable}
            className={`block ${errorMessage && 'border-red-500'} ${enable && 'bg-gray-100'} pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
            dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
            bg-white `}
            placeholder=""
        />
        <label htmlFor={name}
            className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                        origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                        peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                        peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
            {errorMessage ? '' : label}
        </label>
        {errorMessage && <p className='text-red-500 fs-12 rubik-400'>{errorMessage}</p>}
    </div>)
}


export const PharmacyPermissions = ({
    index,
    selectedDepartments,
    setFormData,
    logData,
    editLog,
    onPermissionChange,
    permissionData // This is now an array of permission objects
}) => {
    const [permissionList, setPermissionList] = useState([]);
    const [currentSelections, setCurrentSelections] = useState(selectedDepartments || []);

    const handleSelectChange = useCallback((selectedValues) => {
        setCurrentSelections(selectedValues);
        setFormData((prevState) => {
            const newDepartmentSelections = Array.isArray(prevState.permissionSelections)
                ? [...prevState.permissionSelections]
                : [];

            newDepartmentSelections[index] = {
                ...newDepartmentSelections[index],
                selectedDepartments: selectedValues
            };

            return { ...prevState, permissionSelections: newDepartmentSelections };
        });

        if (onPermissionChange) {
            onPermissionChange(selectedValues, index);
        }
    }, [index, setFormData, onPermissionChange]);

    useEffect(() => {
        // Format the permissionData directly, as it's now an array of permission objects
        const formattedPermissionsData = permissionData.map((item) => ({
            label: item.value,
            value: item.key,
        }));

        setPermissionList(formattedPermissionsData);
    }, [permissionData]);

    useEffect(() => {
        if (editLog && logData?.departments) {
            const formattedPermissionsData = logData.departments.map((value) => ({
                label: value?.value,
                value: value?.key,
            }));
            handleSelectChange(formattedPermissionsData);
        }
    }, [logData, editLog, handleSelectChange]);

    useEffect(() => {
        setCurrentSelections(selectedDepartments || []);
    }, [selectedDepartments]);

    return (
        <div className="relative">
            <Select
                isMulti
                options={permissionList}
                classNamePrefix="react-select"
                onChange={handleSelectChange}
                value={currentSelections}
                isSearchable={true}
                className="basic-multi-select"
                placeholder='Select Permissions'
                noOptionsMessage={() => permissionList.length === 0 ? 'No options available' : 'No options'}
            />
        </div>
    );
};

export const PharmacyEmployee = ({
    employeeListData = [],
    index,
    selectedEmployees,
    setFormData,
    logData,
    editLog,
    onEmployeeChange
}) => {
    const [employeeList, setEmployeeList] = useState([]);
    const [currentSelections, setCurrentSelections] = useState(selectedEmployees || []);
    const handleSelectChange = useCallback((selectedValues) => {
        setCurrentSelections(selectedValues);
        setFormData((prevState) => {
            const newEmployeeSelections = Array.isArray(prevState.employeeSelections)
                ? [...prevState.employeeSelections]
                : [];

            newEmployeeSelections[index] = {
                ...newEmployeeSelections[index],
                selectedEmployees: selectedValues
            };

            return { ...prevState, employeeSelections: newEmployeeSelections };
        });
        if (onEmployeeChange) {
            onEmployeeChange(selectedValues, index);
        }
    }, [index, setFormData, onEmployeeChange]);

    useEffect(() => {
        if (Array.isArray(employeeListData) && employeeListData.length > 0) {
            const formattedEmployeesData = employeeListData.map((item) => ({
                label: item?.name ?? 'Unknown',
                value: item?.user_id ?? '',
            }));
            setEmployeeList(formattedEmployeesData);
        } else {
            setEmployeeList([]);
        }
    }, [employeeListData]);

    useEffect(() => {
        if (editLog && logData && Array.isArray(logData.employees) && logData.employees.length > 0) {
            const formattedEmployeesData = logData.employees.map((value) => ({
                label: value?.name ?? 'Unknown',
                value: value?.id ?? '',
            }));
            handleSelectChange(formattedEmployeesData);
        }
    }, [logData, editLog, handleSelectChange]);

    useEffect(() => {
        setCurrentSelections(selectedEmployees || []);
    }, [selectedEmployees]);

    return (
        <div className="relative">
            <Select
                isMulti
                options={employeeList}
                classNamePrefix="react-select"
                onChange={handleSelectChange}
                value={currentSelections}
                isSearchable={true}
                className="basic-multi-select"
                placeholder='Select Employees'
                noOptionsMessage={() => employeeList.length === 0 ? 'No employee data available' : 'No options'}
            />

            {employeeList.length === 0 &&
                <div className='mt-2'>
                    <Errors error='No employee data available. Please check the data source.' />
                </div>
            }
        </div>
    );
};

export const PharmacyAllEmployees = ({ setFormData, logData, editLog }) => {

    const { profileData } = useCustomSelectors()
    const [employeesList, setEmployeesList] = useState([]);
    const { apiCallMade, setApiCallMade } = useCustomLoadingStates()

    const [selectedOptions, setSelectedOptions] = useState(null)
    const [pharmacyEmployees, setPharmacyEmployees] = useState({ loading: false, data: [], error: null })

    const handleSelectChange = (selectedValues) => {
        setSelectedOptions(selectedValues)
        setFormData((prevStat) => ({ ...prevStat, employeesID: selectedValues?.length > 0 ? selectedValues : '' }))
    };

    useEffect(() => {
        const formattedData = pharmacyEmployees?.data || [];
        const formattedDepartmentsData = formattedData
            ?.filter(value => profileData.user_id !== value.user_id)
            ?.map(value => ({
                label: value.name,
                value: value.user_id
            }));

        setEmployeesList(formattedDepartmentsData);
    }, [pharmacyEmployees]);

    // useEffect(() => {
    //     if (editLog) {
    //         const formattedDepartmentsData = logData?.departments?.map((value, idx) => ({
    //             label: value?.name,
    //             value: value?.id,
    //         }));
    //         setSelectedOptions(formattedDepartmentsData);
    //         setFormData((prevStat) => ({ ...prevStat, employeesID: formattedDepartmentsData?.length > 0 ? formattedDepartmentsData : '' }))
    //     }
    // }, [logData, editLog])

    const handleGetClinicList = async () => {
        if (!apiCallMade) {
            setPharmacyEmployees((prevStat) => ({ ...prevStat, loading: true, data: [], error: null }))
            try {
                const response = await Axios.get(`pharmacy-chat-user-list`)
                if (response?.status === 200) {
                    setPharmacyEmployees((prevStat) => ({ ...prevStat, loading: false, data: response?.data?.data, error: null }))
                } else {
                    throw new Error(response)
                }
            } catch (error) {
                if (error?.response) {
                    setPharmacyEmployees((prevStat) => ({ ...prevStat, loading: false, data: [], error: error?.response }))
                }
            }
            setApiCallMade(true)
        }
    }

    return (
        <div className="relative" onClick={handleGetClinicList}>
            <Select
                isMulti
                options={employeesList}
                classNamePrefix="react-select"
                onChange={handleSelectChange}
                value={selectedOptions}
                isSearchable={true}
                className="basic-multi-select"
                placeholder='Select Employees'
                noOptionsMessage={() => pharmacyEmployees.loading ? 'Loading...' : 'No options'}
            />

            {pharmacyEmployees?.error !== null &&
                <div className='mt-2'>
                    <Errors error='Something went wrong when fetching the clinics. Please try again or reload the page.' />
                </div>
            }
        </div>
    )
}