import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    BlisterStartDate,
    StartDate
} from '../../components/pharmacy/dispense/dispense';

import {
    AddBillingNote,
    AddPatientNote,
    PatientCheckRefills,
    PrintAllDispenseData,
    PrintLabelsData
} from '../../services/actions/pharmacy/blisters/addBlisterPackData';

import { useCustomDispatch } from '../../hooks';
import { useBlisterSelectors } from '../../services/selectors/blisterSelectors';

import { ModalCloseIcon } from '../../assets/svg/icons';
import { useDispenseSelectors } from '../../services/selectors/dispenseSelectors';

import { LoadingView } from '../../components/common';
import { ErrorMessages } from '../../components/error/alertMessages';

import * as useEffectHelper from '../../utils/helper/useEffectsHelper';
import * as Modals from '../../assets/js/custome'

// ------------------------------------Blister Packs Page View Medicine Details Models ----------------------------

export const BlisterPackDrugList = ({ setShowModal, showModal, onClose = null, patientProfileData, setShowToast }) => {

    const modalRef = useRef(null);
    // useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'rxModal', modalRef)
    const dispatch = useCustomDispatch()
    const { dispenseListDataResponse } = useDispenseSelectors();
    const { AddBlisterPackResponse } = useBlisterSelectors()

    const patientMedicineList = dispenseListDataResponse?.data?.data?.data

    const blisterSubRowListHeading = [
        { text: "checkbox", classNames: ['p-3'] },
        { text: "RX#", classNames: ['p-3'] },
        { text: "DRUG", classNames: ['p-3'] },
        { text: "DOCTOR", classNames: ['p-3'] },
        { text: "PREV (DAYS)", classNames: ['p-3'] },
        { text: "QTY [PREV]", classNames: ['p-3'] },
        { text: "REFFILS LEFT", classNames: ['p-3'] },
        { text: "QTY LEFT", classNames: ['p-3'] },
        { text: "SIG", classNames: ['p-3'] },
        { text: "ALLERGY / PNET / DTP", classNames: ['p-3'] },
        { text: "PREP", classNames: ['p-3'] },
        { text: "PAT ID/CONSULT", classNames: ['p-3'] },
        { text: "FINAL CHECK", classNames: ['p-3'] },
    ];

    const [allChecked, setAllChecked] = useState(false);
    const [checkedItems, setCheckedItems] = useState(Array(patientMedicineList?.length).fill(false));
    const [rxData, setRxData] = useState({ rxNumber: '', drug: '', doctor: '', prevDay: '', qty_prev: '', refills_left: '', qty_left: '', sig: '' })
    const [checkedRXNumbers, setCheckedRXNumbers] = useState([]);



    const handleHeaderCheckboxChange = (event) => {
        const checked = event.target.checked;
        setAllChecked(checked);
        setCheckedItems(Array(patientMedicineList.length).fill(checked));
        if (checked) {
            const allRXNumbers = patientMedicineList.map((value, idx) => value.rxnumber);
            setCheckedRXNumbers(allRXNumbers);
        } else {
            setCheckedRXNumbers([]);
        }
    };

    const handleCheckboxChange = (idx, rxNumber) => {
        const newCheckedItems = [...checkedItems];
        newCheckedItems[idx] = !newCheckedItems[idx];
        setCheckedItems(newCheckedItems);
        if (newCheckedItems[idx]) {
            setCheckedRXNumbers(prevState => [...prevState, rxNumber]);
        } else {
            setCheckedRXNumbers(prevState => prevState.filter(num => num !== rxNumber));
        }
    };

    useEffect(() => {
        if (dispenseListDataResponse?.loading && dispenseListDataResponse?.type === 'patient_drug_list') {
            setCheckedItems([])
            setAllChecked(false)
            setCheckedRXNumbers([])
        }
    }, [dispenseListDataResponse])

    return (
        <React.Fragment>

            {showModal.rxModal && (
                <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center 
                      justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100" style={{ zIndex: '9999999' }}>
                    <div ref={modalRef} className="relative max-h-full overflow-x-auto scroll-box" style={{ width: '80%' }}>
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">

                                </h3>
                                <button
                                    onClick={onClose ? onClose : () => { }}
                                    type="button"
                                    className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <ModalCloseIcon />
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>

                            <div className="container mt-2">
                                {dispenseListDataResponse?.loading && dispenseListDataResponse?.type === 'patient_drug_list' ?
                                    <div className="flex justify-center items-center  pt-40 pb-60">
                                        <LoadingView />
                                    </div>
                                    : dispenseListDataResponse?.error !== null && dispenseListDataResponse?.type === 'patient_drug_list' ?
                                        <div className='pb-40'>
                                            <ErrorMessages />
                                        </div> :
                                        patientMedicineList?.length === 0 && dispenseListDataResponse?.type === 'patient_drug_list' ?
                                            <div className='flex items-center justify-center  pb-20 pt-20'>
                                                <p className='rubik-400 text-gray-500'>No Record Available.</p>
                                            </div>
                                            :
                                            patientMedicineList?.length > 0 && dispenseListDataResponse?.type === 'patient_drug_list' ?
                                                <div className="shadow-md relative overflow-x-auto p-5">
                                                    {checkedRXNumbers?.length > 0 && <div className='flex gap-2'>

                                                        <button style={{ height: '30px' }} type="button"
                                                            onClick={() => { return Modals.handleShowModal(setShowModal, showModal, 'printLabelModel') }}
                                                            className="flex  w-34 h-full items-center mb-2 justify-center text-white bg-green-500 
																border border-gray-300 focus:outline-none hover:bg-green-400 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-gray-800 ">
                                                            Print Label
                                                        </button>

                                                        <button style={{ height: '30px' }} type="button"
                                                            onClick={() => dispatch(PrintAllDispenseData('print_all_dispense_data', patientProfileData.patient_id, checkedRXNumbers))}
                                                            className="flex  w-34 h-full items-center mb-2 justify-center text-white bg-red-500
																border border-gray-300 focus:outline-none hover:bg-red-400 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-gray-800 ">
                                                            {AddBlisterPackResponse?.loading && AddBlisterPackResponse?.type === 'print_all_dispense_data' ? 'Processing...' : 'Print All Dispense'}
                                                        </button>
                                                    </div>
                                                    }

                                                    <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto" style={{ borderCollapse: 'collapse', border: '1px solid lightgray' }}>
                                                        <thead className="text-xs text-white uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400">
                                                            <tr>
                                                                {blisterSubRowListHeading?.map((header, index) => (
                                                                    <th key={index} scope="col" className={`${header?.classNames?.join(" ")}`}>

                                                                        {header.text === "checkbox" ?
                                                                            <input type="checkbox" className='w-5 h-5 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 
                                                                                dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 -mr-2'

                                                                                checked={allChecked}
                                                                                onChange={handleHeaderCheckboxChange} />
                                                                            : header.text === "ALLERGY / PNET / DTP" ?
                                                                                <p className="rubik-500 fs-14 flex items-center justify-center">
                                                                                    {/* <input type="checkbox" className='w-5 h-5 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 
                                                                                    dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2'/> */}
                                                                                    {header.text}</p>
                                                                                : header.text === "PREP" ?
                                                                                    <p className="rubik-500 fs-14 flex items-center justify-center">
                                                                                        {/* <input type="checkbox" className='w-5 h-5 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 
                                                                                    dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2'/> */}
                                                                                        {header.text}</p>
                                                                                    : header.text === "PAT ID/CONSULT" ?
                                                                                        <p className="rubik-500 fs-14 flex items-center justify-center">
                                                                                            {/* <input type="checkbox" className='w-5 h-5 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 
                                                                                    dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2'/> */}
                                                                                            {header.text}</p>
                                                                                        : header.text === "FINAL CHECKT" ?
                                                                                            <p className="rubik-500 fs-14 flex items-center justify-center">
                                                                                                {/* <input type="checkbox" className='w-5 h-5 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 
                                                                                    dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2'/> */}
                                                                                                {header.text}</p>
                                                                                            :
                                                                                            <p className="rubik-500 fs-14">{header.text}</p>
                                                                        }
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                patientMedicineList?.map((value, idx) => (
                                                                    <tr style={{ borderBottom: '1px solid lightgray' }} key={idx}>

                                                                        <td scope="row" className="p-3" style={{ borderRight: '1px solid lightgray' }}>
                                                                            <input type="checkbox" className='w-5 h-5 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500 
                                                                            dark:focus:ring-blue-600 
                                                                        	dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2'
                                                                                checked={checkedItems[idx]}
                                                                                onChange={() => handleCheckboxChange(idx, value.rxnumber)}
                                                                            />
                                                                        </td>

                                                                        <td scope="row" className="p-3" style={{ borderRight: '1px solid lightgray' }}>
                                                                            <p className="rubik-400 fs-14">{value.rxnumber}
                                                                                <div className='flex'>
                                                                                    <button type="button"
                                                                                        onClick={() => {
                                                                                            return Modals.handleShowModal(setShowModal, showModal, 'addNoteModal'),
                                                                                                setRxData({
                                                                                                    ...rxData,
                                                                                                    rxNumber: value.rxnumber,
                                                                                                    drug: value.drug,
                                                                                                    doctor: value.doctor,
                                                                                                    prevDay: value.prev_days,
                                                                                                    qty_prev: value.qty_prev,
                                                                                                    refills_left: value.refills_left,
                                                                                                    qty_left: value.qty_left,
                                                                                                    sig: value.rxsig
                                                                                                })

                                                                                        }}
                                                                                        className="flex fs-10 h-full  text-white bg-blue-500
																							border border-gray-300   font-medium rounded-lg text-sm px-5 py-0.5   ">
                                                                                        Note
                                                                                    </button>

                                                                                    <button type="button"
                                                                                        onClick={() => {
                                                                                            return Modals.handleShowModal(setShowModal, showModal, 'addBillingModal'),
                                                                                                setRxData({
                                                                                                    ...rxData,
                                                                                                    rxNumber: value.rxnumber,
                                                                                                    drug: value.drug,
                                                                                                    doctor: value.doctor,
                                                                                                    prevDay: value.prev_days,
                                                                                                    qty_prev: value.qty_prev,
                                                                                                    refills_left: value.refills_left,
                                                                                                    qty_left: value.qty_left,
                                                                                                    sig: value.rxsig
                                                                                                })

                                                                                        }}
                                                                                        className="flex fs-10 h-full  text-white bg-yellow-500
																							border border-gray-300   font-medium rounded-lg text-sm px-5 py-0.5   ">
                                                                                        Billing
                                                                                    </button>
                                                                                </div>
                                                                            </p>
                                                                        </td>

                                                                        <td scope="row" className="p-3" style={{ borderRight: '1px solid lightgray' }}>
                                                                            <p className="rubik-400 fs-14">{value.drug}</p>
                                                                        </td>
                                                                        <td scope="row" className="p-3" style={{ borderRight: '1px solid lightgray' }}>
                                                                            <p className="rubik-400 fs-14">{value.doctor}</p>
                                                                        </td>
                                                                        <td scope="row" className="p-3" style={{ borderRight: '1px solid lightgray' }}>
                                                                            <p className="rubik-400 fs-14">{value.prev_days}</p>
                                                                        </td>
                                                                        <td scope="row" className="p-3" style={{ borderRight: '1px solid lightgray' }}>
                                                                            <p className="rubik-400 fs-14">{value.qty_prev}</p>
                                                                        </td>
                                                                        <td scope="row" className="p-3" style={{ borderRight: '1px solid lightgray' }}>
                                                                            <p className="rubik-400 fs-14">{value.refills_left}</p>
                                                                        </td>
                                                                        <td scope="row" className="p-3" style={{ borderRight: '1px solid lightgray' }}>
                                                                            <p className="rubik-400 fs-14">{value.qty_left}</p>
                                                                        </td>
                                                                        <td scope="row" className="p-3" style={{ borderRight: '1px solid lightgray' }}>
                                                                            <p className="rubik-400 fs-14">{value.rxsig}</p>
                                                                        </td>

                                                                        <td scope="row" className="p-3 " style={{ borderRight: '1px solid lightgray' }}>
                                                                            <input type="checkbox" defaultChecked={value.dtp === 1 ? true : false}
                                                                                onChange={(e) => dispatch(PatientCheckRefills('check_refills', e.target.checked, 'dtp', patientProfileData?.patient_id, value.rxnumber, setShowToast))} className='w-5 h-5  text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500 
                                                                            dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 '/>
                                                                        </td>

                                                                        <td scope="row" className="p-3" style={{ borderRight: '1px solid lightgray' }}>
                                                                            <input type="checkbox" defaultChecked={value.prep === 1 ? true : false} className='w-5 h-5 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500 
                                                                                dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 '
                                                                                onChange={(e) => dispatch(PatientCheckRefills('check_refills', e.target.checked, 'prep', patientProfileData?.patient_id, value.rxnumber, setShowToast))} />
                                                                        </td>

                                                                        <td scope="row" className="p-3" style={{ borderRight: '1px solid lightgray' }}>
                                                                            <input type="checkbox" defaultChecked={value.patid === 1 ? true : false} className='w-5 h-5 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500 
                                                                            dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 '
                                                                                onChange={(e) => dispatch(PatientCheckRefills('check_refills', e.target.checked, 'patid', patientProfileData?.patient_id, value.rxnumber, setShowToast))} />
                                                                        </td>

                                                                        <td scope="row" className="p-3" style={{ borderRight: '1px solid lightgray' }}>
                                                                            <input type="checkbox" defaultChecked={value.finalcheck === 1 ? true : false} className='w-5 h-5 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500 
                                                                            	dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 '
                                                                                onChange={(e) => dispatch(PatientCheckRefills('check_refills', e.target.checked, 'finalcheck', patientProfileData?.patient_id, value.rxnumber, setShowToast))} />

                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>

                                                : null}
                            </div>
                        </div>
                    </div>
                </div>
            )
            }

            <AddNote patientProfileData={patientProfileData}
                setShowModal={setShowModal}
                setShowToast={setShowToast}
                showModal={showModal}
                onClose={() => Modals.handleHideModal(setShowModal, showModal, 'addNoteModal')}
                rxData={rxData}
            />

            <AddBilling patientProfileData={patientProfileData}
                setShowModal={setShowModal}
                showModal={showModal}
                setShowToast={setShowToast}
                onClose={() => Modals.handleHideModal(setShowModal, showModal, 'addBillingModal')}
                rxData={rxData}
            />

            <AddPrintLabel patientProfileData={patientProfileData}
                setShowModal={setShowModal}
                showModal={showModal}
                setShowToast={setShowToast}
                onClose={() => Modals.handleHideModal(setShowModal, showModal, 'printLabelModel')}
                rxData={rxData}
                checkedRXNumbers={checkedRXNumbers}
            />

        </React.Fragment >
    )
}

export const AddNote = ({ setShowModal, showModal, onClose = null, patientProfileData, setShowToast, rxData }) => {

    const modalRef = useRef(null);
    const dispatch = useCustomDispatch()
    const { AddBlisterPackResponse } = useBlisterSelectors()
    useEffectHelper.useRemoveModalEffect(setShowModal, showModal, 'addNoteModal', modalRef)

    const [note, setNote] = useState('')
    const [ErrorMessages, setErrorMessages] = useState('')

    const requestData = {
        rxaddonnote: `RXNumber : ${rxData.rxNumber}
  		Date : ${rxData.prevDay}
  		Drug : ${rxData.drug}
  		Doctor : ${rxData.doctor}
 		Sig : ${rxData.sig}
  		Prev Qty : ${rxData.qty_prev}
  		Qty Left : ${rxData.qty_left}
  		Refills Left : ${rxData.refills_left}`,
        rxnote: note,
        patient_id: patientProfileData?.patient_id
    };

    const handleSaveNote = () => {
        if (!note) {
            setErrorMessages('Please add the Note')
        } else {
            setErrorMessages('')
            dispatch(AddPatientNote('add_patient_note', requestData, setShowModal, setShowToast))
            setNote('')
        }
    }

    return (
        <React.Fragment>
            {showModal.addNoteModal && (
                <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center 
                      justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-60 dark:bg-opacity-100" style={{ zIndex: '99999999' }}>
                    <div ref={modalRef} className="relative max-h-full" style={{ width: '30%' }}>
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Note for RX : {patientProfileData.rxNumber}
                                </h3>
                                <button
                                    onClick={onClose ? onClose : () => { }}
                                    type="button"
                                    className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
									text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <ModalCloseIcon />
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>

                            <div className="container">
                                <div className="p-4  py-8">

                                    <div className="did-floating-label-content relative -mt-2">
                                        <label
                                            htmlFor="Phn"
                                            className="ml-2 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
												-translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 
												peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
												peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                        >
                                            Notes
                                        </label>

                                        <div id="note-container" className="form-group full">
                                            <textarea
                                                maxLength="255"
                                                autoFocus
                                                onChange={(e) => {
                                                    setNote(e.target.value)
                                                }}
                                                name="note"
                                                className="w-full pt-2 pl-4 pr-4 fs-16 rounded-md rubik-400 border border-gray-300 text-gray-700"
                                                id="note"
                                                rows={8}
                                            // defaultValue={formData.addNote}
                                            ></textarea>
                                        </div>
                                    </div>

                                    {ErrorMessages &&
                                        <div className='text-red-500'>{ErrorMessages}</div>}

                                    <div className='flex flex-wrap gap-4 mt-2'>

                                        <button onClick={handleSaveNote}
                                            className="rubik-500 fs-16 h-12 rounded-md text-white blueBg-color w-full flex-1 text-sm px-5 py-2.5">
                                            {AddBlisterPackResponse?.loading && AddBlisterPackResponse.type === 'add_patient_note' ? 'Saving...' : 'Save'}

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </React.Fragment >
    )
}

export const AddBilling = ({ setShowModal, showModal, onClose = null, patientProfileData, rxData, setShowToast }) => {

    const modalRef = useRef(null);
    const dispatch = useCustomDispatch()
    const { AddBlisterPackResponse } = useBlisterSelectors()

    const recurrenceData = [
        { label: "Every day", value: '1' },
        { label: "Every Two day", value: '2' },
        { label: "Every Three day", value: '3' },
        { label: "Every Fourth day", value: '4' },
    ];

    const recurrenceWeekday = [
        "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
    ];

    const recurrenceRuleType = [
        { label: "Interval", value: 'interval' },
        { label: "Days", value: 'days' },
    ];

    const [formData, setFormData] = useState({
        recurrence_days: recurrenceData[0].value,
        recurrence_weekdays: '',
        recurrence_day_type: recurrenceRuleType[0].value,
        rx_num: '',
        date: new Date().toISOString().split('T')[0]
    });

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setFormData((prevState) => {
            if (checked) {
                return {
                    ...prevState,
                    recurrence_weekdays: [...prevState.recurrence_weekdays, value]
                };
            } else {
                return {
                    ...prevState,
                    recurrence_weekdays: prevState.recurrence_weekdays.filter((day) => day !== value)
                };
            }
        });
    };

    const handleAddBilling = () => {
        const { rxNumber } = rxData;
        const { patient_id } = patientProfileData;
        const { date, recurrence_day_type, recurrence_days, recurrence_weekdays } = formData;

        const requestedData = {
            rx_num: rxNumber,
            date,
            patient_id
        };

        const recurrenceUpdate = {
            recurrence_day_type,
            ...(recurrence_day_type === 'interval' ? { recurrence_days } : { recurrence_weekdays })
        };

        dispatch(AddBillingNote('add_billing_note', { ...requestedData, ...recurrenceUpdate }, setShowModal, setShowToast));
    };

    return (
        <React.Fragment>
            {showModal.addBillingModal && (
                <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center 
                      justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-60 dark:bg-opacity-100" style={{ zIndex: '99999999' }}>
                    <div ref={modalRef} className="relative max-h-full" style={{ width: '40%' }}>
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Add Billing
                                </h3>
                                <button
                                    onClick={onClose ? onClose : () => { }}
                                    type="button"
                                    className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
									text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <ModalCloseIcon />
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>


                            <div className="p-4 md:p-5 space-y-4">
                                <form>
                                    <div className='col-span-12'>
                                        <StartDate formData={formData}
                                            setFormData={setFormData} />
                                    </div>

                                    <div className='col-span-12 mt-4'>
                                        <h1 className='rubik-500 fs-16 mb-1'>Recurrence</h1>
                                        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-1 flex flex-wrap items-center gap-10 mt-4">
                                            {recurrenceRuleType?.map((label, index) => (
                                                <div key={index} className="flex items-center gap-3">
                                                    <input
                                                        id={`rule-${index}`}
                                                        name="recurrence_day_type"
                                                        type="radio"
                                                        value={label.value}
                                                        checked={formData.recurrence_day_type === label.value}
                                                        onChange={(e) => {
                                                            setFormData({
                                                                ...formData,
                                                                recurrence_day_type: e.target.value
                                                            });
                                                        }}
                                                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                    <label htmlFor={`rule-${index}`}
                                                        className="fs-14 -ml-2 rubik-400  text-sm font-medium text-gray-900 dark:text-gray-300">
                                                        {label.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {formData.recurrence_day_type === 'interval' ?
                                        <div className='col-span-12 mt-2'>
                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-1 flex flex-wrap items-center gap-8 mt-8">
                                                {recurrenceData?.map((label, index) => (
                                                    <div key={index} className="flex items-center gap-3">
                                                        <input
                                                            id={`radio-${index}`}
                                                            name="recurrence_day"
                                                            type="radio"
                                                            value={label.value}
                                                            checked={formData.recurrence_days === label.value}
                                                            onChange={(e) => {
                                                                setFormData({
                                                                    ...formData,
                                                                    recurrence_days: e.target.value
                                                                });
                                                            }}
                                                            className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                        <label htmlFor={`radio-${index}`}
                                                            className="fs-14 -ml-2 rubik-400  text-sm font-medium text-gray-900 dark:text-gray-300">
                                                            {label.label}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div> :
                                        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-1 flex flex-wrap items-center gap-5 mt-4	">
                                            {recurrenceWeekday?.map((label, index) => (
                                                <div key={index} className="flex items-center gap-3">
                                                    <input
                                                        id={`weekday-${index}`}
                                                        name="recurrence_weekday"
                                                        type="checkbox"
                                                        value={label}
                                                        onChange={handleCheckboxChange}
                                                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                    />
                                                    <label htmlFor={`weekday-${index}`}
                                                        className="fs-14 -ml-2 rubik-400  text-sm font-medium text-gray-900 dark:text-gray-300">
                                                        {label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                    <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-1 flex  justify-center items-center gap-3 mt-4	">
                                        <button type="button" onClick={handleAddBilling}
                                            className="flex fs-14 flex items-center justify-center h-full  text-white bg-blue-500
										border border-gray-300   font-medium rounded-lg text-sm px-5 py-2.5   ">
                                            {AddBlisterPackResponse?.loading && AddBlisterPackResponse?.type === 'add_billing_note' ? "Saving..." : 'Save'}
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            )
            }
        </React.Fragment >
    )
}

export const AddPrintLabel = ({ setShowModal, showModal, onClose = null, patientProfileData, checkedRXNumbers }) => {

    const [dates, setDates] = useState([new Date().toISOString().split('T')[0]]);
    const dispatch = useCustomDispatch()

    const addDate = () => {
        setDates([...dates, new Date().toISOString().split('T')[0]]);
    };

    const removeDate = (index) => {
        const updatedDates = [...dates];
        updatedDates.splice(index, 1);
        setDates(updatedDates);
    };

    const updateDate = (index, newDate) => {
        const updatedDates = [...dates];
        updatedDates[index] = newDate;
        setDates(updatedDates);
    };

    const { AddBlisterPackResponse } = useBlisterSelectors()

    const handlePrintPdf = () => {
        const requestData = {
            patient_id: patientProfileData.patient_id,
            rxlabeltoprint: checkedRXNumbers,
            rxdatestoprint: dates
        }
        dispatch(PrintLabelsData('print_label_data', requestData))
    }

    return (
        <React.Fragment>
            {showModal.printLabelModel && (
                <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-60 dark:bg-opacity-100" style={{ zIndex: '99999999' }}>
                    <div className="relative max-h-full" style={{ width: '30%' }}>
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                            <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Add Label Dates:
                                </h3>
                                <button
                                    onClick={onClose ? onClose : () => { }}
                                    type="button"
                                    className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <ModalCloseIcon />
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>

                            <div className="p-4 md:p-5 space-y-4">
                                <form>
                                    <div className='col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 gap-3'>
                                        {dates.map((date, index) => (
                                            <div key={index} className="flex items-center gap-3 mb-4 w-full">
                                                <BlisterStartDate
                                                    date={date}
                                                    setDate={(updatedDate) => updateDate(index, updatedDate)}
                                                />
                                                <button onClick={addDate} type='button' className='p-2 fs-12 rubik-500 bg-gray-500 text-white rounded'>
                                                    Add
                                                </button>
                                                {index !== 0 && (
                                                    <button onClick={() => removeDate(index)} type='button' className='p-2 fs-12 rubik-500 bg-red-500 text-white rounded'>
                                                        Remove
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                    </div>

                                    <div className='col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 gap-3'>
                                        <button type='button' onClick={handlePrintPdf}
                                            className="rubik-500 fs-16 h-10 rounded-md text-white bg-blue-500 w-full flex items-center justify-center flex-1 text-sm px-4 py-2.5">
                                            {AddBlisterPackResponse?.loading && AddBlisterPackResponse?.type === 'print_label_data' ? 'Processing...' : 'Print'}
                                        </button>
                                    </div>

                                </form>
                            </div>


                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}
