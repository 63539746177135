import {
    handleUpdatePharmacyFrontProfileLogo,
    HandleUpdatePharmacyHour,
    HandleUpdatePharmacyProfile,
    HandleUpdatePharmacyServiceProfile
} from "../../services/actions/admin/team/addTeamData";

import { useEffect, useState } from "react";
import { handleHideModal, onPlaceSetPateintAddresses } from "../../assets/js/custome";
import { AddressIcon, CityIcon, EnvelopIcon, LocationIcon, ModalCloseIcon, ProvinceIcon } from "../../assets/svg/icons";
import { useCustomDispatch } from "../../hooks";

import { useAdminSelectors } from "../../services/selectors/adminModuleSelectors";
import { TextEditor } from "../../components/pharmacy/form/textEditor";
import { TextInput } from "../../components/pharmacy/dispense/dispense";
import { initalPatientAddressForm } from "../../utils/constants/formInitialStates";
import { usePlacesWidget } from "react-google-autocomplete";
import { handleUpdatePatientAddress } from "../../utils/data/handleFormDataChange";
import { autoSearchKey } from "../../networking/urlEndPoints";
import { useCustomPatientSelectors } from "../../services/selectors/patientSelectors";

export const UpdatePharmacyLogo = ({ showModal, setShowModal, setShowToast }) => {

    const dispatch = useCustomDispatch()

    const { addTeamDataResponse } = useAdminSelectors()

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChanges = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleUpdateLogo = () => {
        dispatch(handleUpdatePharmacyFrontProfileLogo('update_logo', setShowModal, setShowToast, selectedFile))
    }

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Update Logo
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'pharmacyLogo')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="px-4 pb-4 sm:p-6 sm:pb-4">
                        <div className="grid grid-cols-1 place-items-center">
                            <div className="p-6 md:p-5 space-y-4 flex items-center">
                                <div className="flex items-center">
                                    <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <div className="flex items-center justify-center">
                                            <div className="border-2 border-dashed border-gray-300 p-8 text-center">
                                                {selectedFile ? (
                                                    <div className="relative">
                                                        <img src={URL.createObjectURL(selectedFile)} alt="Selected Logo" className="h-32 w-auto" />
                                                        <button type='text' className='bg-blue-500 rounded-md text-white border-none py-0.5 fs-12 mt-5 rubik-500' onClick={handleUpdateLogo}>
                                                            {addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_logo' ? 'Updating...' : 'Update'}
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={handleFileChanges}
                                                            className="hidden"
                                                            id="uploadLogo"
                                                        />
                                                        <label htmlFor="uploadLogo" className="cursor-pointer">
                                                            <p className="mb-1">Drag and drop file</p>
                                                            <p className="mb-3">OR</p>
                                                            <button className="bg-green-500 text-white px-4 py-2 rounded">SELECT</button>
                                                        </label>
                                                    </>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const UpdatePharmacyFrontProfile = ({ showModal, setShowModal, setShowToast, data }) => {

    const { addTeamDataResponse } = useAdminSelectors()
    const dispatch = useCustomDispatch()
    const [formData, setFormData] = useState({ textForFaxValue: '', pharmacyName: '' })

    const handleUpdateProfile = () => {
        const { textForFaxValue, pharmacyName } = formData
        const reqData = {
            from_type: 'profile_info',
            name: pharmacyName,
            about_pharmacy: textForFaxValue
        }
        if (pharmacyName && textForFaxValue) {
            dispatch(HandleUpdatePharmacyProfile('update_profile', setShowModal, setShowToast, reqData))
        }
    }

    useEffect(() => {
        setFormData((prevStat) => ({
            ...prevStat,
            pharmacyName: data?.pharmacyName,
        }))
    }, [data])

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Update Profile
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'updateProfile')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="px-4 pb-4 sm:p-6 sm:pb-4" style={{ height: '350px' }}>
                        <div className='relative'>
                            <TextInput formData={formData} setFormData={setFormData} name="pharmacyName" label="PHARMACY NAME" />
                        </div>

                        <div className="grid grid-cols-1 place-items-center mt-2">
                            <div className='relative'>
                                <TextEditor formData={formData} setFormData={setFormData} className='w-64' placeholder="About The Pharmacy" height="h-36" />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 place-items-center mt-16">
                            <button type='text' className='bg-blue-500 rounded-md text-white w-full border-none py-0.5 fs-12 mt-5 rubik-500' onClick={handleUpdateProfile}>
                                {addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_profile' ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export const AddMultilePhotosForPharmacy = ({ showModal, setShowModal, setShowToast }) => {
    const dispatch = useCustomDispatch();
    const { addTeamDataResponse } = useAdminSelectors();
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChanges = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const handleAddMultiplePhotos = () => {
        dispatch(handleUpdatePharmacyFrontProfileLogo('add_pharmacy_photos', setShowModal, setShowToast, selectedFiles, 'pharmacy-gallery-images'));
    };

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Update Logo
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'addMultiplePhotos')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
                                text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="px-4 pb-4 sm:p-6 sm:pb-4">
                        <div className="grid grid-cols-1 place-items-center">
                            <div className="p-6 md:p-5 space-y-4 flex items-center">
                                <div className="flex items-center">
                                    <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <div className="flex items-center justify-center">
                                            <div className="border-2 border-dashed border-gray-300 p-8 text-center">
                                                {selectedFiles?.length > 0 ? (<>
                                                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                                                        {selectedFiles.map((file, index) => (
                                                            <div key={index} className="mb-4">
                                                                <img src={URL.createObjectURL(file)} alt={`Selected Logo ${index}`} className="h-32 w-auto" />
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <button type='text' className='bg-blue-500  w-full rounded-md text-white border-none py-0.5 fs-12 mt-5 rubik-500' onClick={handleAddMultiplePhotos}>
                                                        {addTeamDataResponse?.loading && addTeamDataResponse.type === 'add_pharmacy_photos' ? 'Updating...' : 'Update'}
                                                    </button>
                                                </>
                                                ) : (
                                                    <>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            multiple
                                                            onChange={handleFileChanges}
                                                            className="hidden"
                                                            id="uploadLogo"
                                                        />
                                                        <label htmlFor="uploadLogo" className="cursor-pointer">
                                                            <p className="mb-1">Drag and drop files</p>
                                                            <p className="mb-3">OR</p>
                                                            <button className="bg-green-500 text-white px-4 py-2 rounded">SELECT</button>
                                                        </label>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const UpdatePharmacyContactFrontProfile = ({ showModal, setShowModal, setShowToast, data, contactData, pharmacyLoginId }) => {

    const { addTeamDataResponse } = useAdminSelectors()
    const dispatch = useCustomDispatch()
    const { provinceList } = useCustomPatientSelectors()
    const [formData, setFormData] = useState({ manager_name: '', owner_name: '', fax: '', phone: '', website: '' })
    const [updateContactAddress, setUpdateontactAddress] = useState({
        fullAddress: "",
        address: "",
        city: "",
        postalCode: "",
        province: "BC",
        country: "",
        mapUrl: "",
        lat: "",
        long: "",
    })
    const pharmacyInputs = [
        { name: "manager_name", label: "Manager Name" },
        { name: "owner_name", label: "Owner Name" },
        { name: "fax", label: "Fax Number" },
        { name: "phone", label: "Phone Number" },
        // { name: "website", label: "Website" },
    ];

    const handleUpdateProfile = () => {
        const reqData = {
            from_type: 'contact_info',
            lat: updateContactAddress?.lat,
            long: updateContactAddress?.long,
            country: updateContactAddress?.country,
            full_address: updateContactAddress?.fullAddress,
            manager_name: formData?.manager_name,
            owner_name: formData?.owner_name,
            fax: formData?.fax,
            phone: formData?.phone,
            website: formData?.website,
            zip_code: updateContactAddress?.postalCode,
            city: updateContactAddress?.city,
            location: updateContactAddress?.address,
        }

        // if (formData?.manager_name) {
        dispatch(HandleUpdatePharmacyProfile('update_profile', setShowModal, setShowToast, reqData, pharmacyLoginId))
        // }
    }

    const { ref: ref1 } = usePlacesWidget({
        apiKey: autoSearchKey,
        onPlaceSelected: (place) => onPlaceSetPateintAddresses(place, setUpdateontactAddress, updateContactAddress),
        options: {
            types: ["address"],
            componentRestrictions: { country: "ca" },
            limit: 7,
            googleLogo: false
        },
    });

    useEffect(() => {
        setFormData((prevStat) => ({
            ...prevStat,
            manager_name: contactData?.manager_name,
            owner_name: contactData?.owner_name,
            fax: contactData?.fax,
            phone: contactData?.phone,
            website: contactData?.website
        }))
        // setFormData(contactData)

        setUpdateontactAddress({
            fullAddress: contactData?.full_address,
            address: contactData?.address,
            city: contactData?.city,
            postalCode: contactData?.zip_code,
            province: contactData?.province,
            country: contactData?.country,
            // mapUrl: contactData?.,
            lat: contactData?.lat,
            long: contactData?.long,
        })
    }, [data, contactData])

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-6 bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className='relative bg-white rounded-lg shadow-lg dark:bg-gray-700 overflow-auto'>
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Update Contact Detail
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'updateContactProfile')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="px-4 pb-4 sm:p-6 sm:pb-4" style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                        <div className="grid grid-cols-12 gap-4 md:gap-7 lg:gap-1">

                            {pharmacyInputs.map(({ name, label, type }, index) => (
                                <div key={index} className="mb-4 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                    <TextInput formData={formData} setFormData={setFormData} name={name} label={label} />
                                </div>
                            ))}


                            {/* Address Input */}
                            <div className="mb-4 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                <div className="relative">
                                    <AddressIcon />
                                    <input
                                        type="text"
                                        className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                            rounded-lg border border-gray-300 dark:text-white dark:border-gray-600 
                            dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                           cursor-text bg-white`}
                                        onChange={(e) => handleUpdatePatientAddress(e, setUpdateontactAddress, updateContactAddress)}
                                        ref={ref1}
                                        name='fullAddress'
                                        value={updateContactAddress.fullAddress}
                                        id="fullAddress"
                                        placeholder=""
                                    />
                                    <label htmlFor="fullAddress" className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                            -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
                            peer-focus:text-blue-600 peer-focus:dark:text-blue-500`}>
                                        Address
                                    </label>
                                </div>
                            </div>

                            {/* City Input */}
                            <div className="mb-4 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                <div className="relative">
                                    <CityIcon />
                                    <input
                                        type="text"
                                        className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                            rounded-lg border border-gray-300 dark:text-white dark:border-gray-600 
                            dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                           cursor-text bg-white`}
                                        onChange={(e) => handleUpdatePatientAddress(e, setUpdateontactAddress, updateContactAddress)}
                                        name='city'
                                        value={updateContactAddress.city}
                                        id="city"
                                        placeholder=""
                                    />
                                    <label htmlFor="city" className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                            -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
                            peer-focus:text-blue-600 peer-focus:dark:text-blue-500`}>
                                        City
                                    </label>
                                </div>
                            </div>

                            {/* Province Input */}
                            <div className="mb-4 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                <div className="relative">
                                    <ProvinceIcon />
                                    <select
                                        style={{ height: '48px' }}
                                        className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                            rounded-lg border border-gray-300 dark:text-white dark:border-gray-600 
                            dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                           cursor-text bg-white`}
                                        onChange={(e) => handleUpdatePatientAddress(e, setUpdateontactAddress, updateContactAddress)}
                                        value={updateContactAddress.province}
                                        name="province"
                                    >
                                        {provinceList?.data?.map((value, index) => (
                                            <option className="fs-14" value={value.short_name} key={index}>{value.long_name}</option>
                                        ))}
                                    </select>
                                    <label className="did-floating-label"
                                        style={{
                                            color: 'grey', position: 'absolute', top: '-10px',
                                            backgroundColor: 'white'
                                        }}>Province</label>
                                </div>
                            </div>

                            {/* Postal Code Input */}
                            <div className="mb-4 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                <div className="relative">
                                    <LocationIcon />
                                    <input
                                        type="text"
                                        className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                            rounded-lg border border-gray-300 dark:text-white dark:border-gray-600 
                            dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                           cursor-text bg-white`}
                                        onChange={(e) => handleUpdatePatientAddress(e, setUpdateontactAddress, updateContactAddress)}
                                        name='postalCode'
                                        value={updateContactAddress.postalCode}
                                        id="postalCode"
                                        placeholder=""
                                    />
                                    <label htmlFor="postalCode" className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                            -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
                            peer-focus:text-blue-600 peer-focus:dark:text-blue-500`}>
                                        Postal Code
                                    </label>
                                </div>
                            </div>


                            <div className="mb-4 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-4">
                                <div className="relative">
                                    <EnvelopIcon />
                                    <input
                                        type="text"
                                        className={`block pl-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                            rounded-lg border border-gray-300 dark:text-white dark:border-gray-600 
                            dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 
                           cursor-text bg-white`}
                                        onChange={(e) => { setFormData({ ...formData, website: e.target.value }) }}
                                        value={formData?.website}
                                        name='website'
                                        id="website"
                                        placeholder="Website"
                                    />
                                    <label htmlFor="website" className={`ml-12 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                            -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
                            peer-focus:text-blue-600 peer-focus:dark:text-blue-500`}>
                                        Website
                                    </label>
                                </div>
                            </div>
                        </div>


                        {/* Update Button */}
                        <div className="grid grid-cols-1 place-items-center">
                            <button type='button' className='bg-blue-500 rounded-md text-white w-full border-none py-2 text-sm mt-5 transition duration-200 hover:bg-blue-600'
                                onClick={handleUpdateProfile}>
                                {addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_profile' ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};


const UpdatePharmacyOpenHourFrontProfile = ({ showModal, setShowModal,hourData, setShowToast ,pharmacyLoginId}) => {
    const { addTeamDataResponse } = useAdminSelectors();
    const dispatch = useCustomDispatch();

    const [availability, setAvailability] = useState({
        Sunday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
    });

    const convertTo12HourFormat = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        const period = hours >= 12 ? 'PM' : 'AM';
        const adjustedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
        return `${adjustedHours}:${minutes < 10 ? '0' + minutes : minutes || "00"} ${period}`;
    };

    const handleUpdateHours = () => {
        const formData = new FormData();

        if (!availability) {
            console.error("Availability data is missing or invalid.");
            return;
        }

        Object.keys(availability).forEach((day) => {
            const validHours = availability[day].filter(
                (hour) => hour && Object.keys(hour).length !== 0
            );

            formData.append(`${day}[day_status]`, validHours.length > 0);

            validHours.forEach((hour, index) => {
                const hourIndex = index;

                const startTime12 = convertTo12HourFormat(hour.startTime);
                const endTime12 = convertTo12HourFormat(hour.endTime);

                formData.append(`${day}[hour][${hourIndex}][from]`, startTime12);
                formData.append(`${day}[hour][${hourIndex}][to]`, endTime12);
            });
        });

        dispatch(HandleUpdatePharmacyHour('update_profile_hour', setShowModal, setShowToast, formData,pharmacyLoginId));
    };      
       

    const handleToggleAvailability = (day) => {
        setAvailability((prevAvailability) => ({
            ...prevAvailability,
            [day]: prevAvailability[day].length ? [] : [{ startTime: "09:00", endTime: "17:00" }],
        }));
    };

    const handleAddHour = (day) => {
        setAvailability((prevAvailability) => ({
            ...prevAvailability,
            [day]: [
                ...prevAvailability[day],
                { startTime: "09:00", endTime: "17:00" },
            ],
        }));
    };

    const handleDeleteHour = (day, index) => {
        setAvailability((prevAvailability) => {
            const updatedHours = prevAvailability[day].filter((_, i) => i !== index);
            return { ...prevAvailability, [day]: updatedHours };
        });
    };

    const handleHourChange = (day, index, field, value) => {
        setAvailability((prevAvailability) => {
            const updatedHours = [...prevAvailability[day]];
            updatedHours[index][field] = value;
            return { ...prevAvailability, [day]: updatedHours };
        });
    };

    const convertTo24Hour = (time) => {
        const [timePart, modifier] = time.split(' ');
        let [hours, minutes] = timePart.split(':');
        if (modifier === 'PM' && hours !== '12') {
            hours = parseInt(hours) + 12;
        }
        if (modifier === 'AM' && hours === '12') {
            hours = '00';
        }

        hours = hours < 10 ? `0${hours}` : hours;
        return `${hours}:${minutes?.padStart(2, '0') || '00'}`;
    };
    

    useEffect(()=>{
        const formattedAvailability = hourData && hourData?.reduce((acc, { day, start, end }) => {
            const startTime = convertTo24Hour(start);
            const endTime = convertTo24Hour(end);
            acc[day] = [{ startTime, endTime }];
            return acc;
        }, {});

        setAvailability(formattedAvailability);
    },[])

    console.log(availability,"formattedAvailabilityformattedAvailabilityformattedAvailability")


    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-6 bg-black bg-opacity-50">
            <div className="relative p-4 w-full max-w-2xl max-h-full bg-white rounded-lg shadow-lg overflow-auto">
                <div className="flex items-center justify-between p-4 border-b">
                    <h3 className="text-xl font-semibold text-gray-900">Update Open Hour</h3>
                    <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-full p-1"
                    >
                        ✖️
                    </button>
                </div>

                <div className="container mx-auto p-4">
    <form className="space-y-4">
        <table className="min-w-full border-collapse border border-gray-300">
        <tbody>
    {Object.keys(availability).map((day, index) => (
        <tr key={index} className="hover:bg-gray-100">
            <td className="p-2 text-center">{day}</td>
            <td className="p-2 text-center">
                <label className="flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={availability[day].length > 0}
                        onChange={() => handleToggleAvailability(day)}
                        className="hidden"
                    />
                    <div className={`w-14 h-8 rounded-full flex items-center p-1 transition duration-300 ease-in-out ${availability[day].length ? 'bg-blue-500' : 'bg-gray-300'}`}>
                        <div className={`bg-white w-6 h-6 rounded-full shadow-md transform transition duration-300 ease-in-out ${availability[day].length ? 'translate-x-6' : 'translate-x-0'}`} />
                    </div>
                    <span className="ml-2">{availability[day].length > 0 ? 'Open' : 'Closed'}</span>
                </label>
            </td>
            <td colSpan="2">
                {availability[day].map((hour, hourIndex) => (
                    <div key={hourIndex} className="flex items-center mb-3 mt-2">
                        <input
                            type="time"
                            value={hour?.startTime}
                            onChange={(e) =>
                                handleHourChange(day, hourIndex, 'startTime', e.target.value)
                            }
                            className="w-24 p-2 border rounded mr-2"
                        />
                        <input
                            type="time"
                            value={hour.endTime}
                            onChange={(e) =>
                                handleHourChange(day, hourIndex, 'endTime', e.target.value)
                            }
                            className="w-24 p-2 border rounded mr-2"
                        />
                        {hourIndex > 0 && (
                            <button
                                type="button"
                                onClick={() => handleDeleteHour(day, hourIndex)}
                                className="text-red-500 hover:text-red-700 ml-2"
                            >
                                🗑️
                            </button>
                        )}
                    </div>
                ))}
            </td>
            <td className="p-2 text-center">
                <button
                    type="button"
                    onClick={() => handleAddHour(day)}
                    className="bg-blue-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
                >
                    Add Hour
                </button>
            </td>
        </tr>
    ))}
</tbody>

        </table>

        <div className="grid grid-cols-1 place-items-center">
            <button
                type='button'
                className='bg-blue-500 rounded-md text-white w-full border-none py-2 text-sm mt-5 transition duration-200 hover:bg-blue-600'
                onClick={handleUpdateHours}
            >
                {addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_profile' ? 'Updating...' : 'Update'}
            </button>
        </div>
    </form>
</div>





            </div>
        </div>
    );





};

export default UpdatePharmacyOpenHourFrontProfile;

export const UpdatePharmacyServiceFrontProfile = ({ showModal, setShowModal, setShowToast, data, serviceData, pharmacyLoginId }) => {
    const { addTeamDataResponse } = useAdminSelectors();
    const dispatch = useCustomDispatch();
    
    const serviceIds = [
        39, 
        42, 
        3,  
        1,  
        38  
    ];

    const [selectedServices, setSelectedServices] = useState(serviceIds.map(() => false));

    const handleCheckboxChange = (index) => {
        const updatedServices = [...selectedServices];
        updatedServices[index] = !updatedServices[index];
        setSelectedServices(updatedServices);
    };

    const handleUpdateProfile = () => {
        const reqData = {
            services: selectedServices
                .map((isChecked, index) => (isChecked ? serviceIds[index] : null))
                .filter(id => id !== null)
        };

        dispatch(HandleUpdatePharmacyServiceProfile('update_service_profile', setShowModal, setShowToast, reqData, pharmacyLoginId));
    };

    useEffect(() => {
        const selectedIs = serviceData?.map((data)=>data?.id)
        setSelectedServices(selectedIs)
    }, []);

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-6 bg-black bg-opacity-50 dark:bg-opacity-100">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className='relative bg-white rounded-lg shadow-lg dark:bg-gray-700 overflow-auto'>
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Update Service Detail
                        </h3>
                        <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'UpdatePharmacyServiceFrontProfile')}
                            className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <ModalCloseIcon />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="px-4 pb-4 sm:p-6 sm:pb-4" style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                        <div className="flex flex-wrap gap-4">
                            {serviceIds.map((serviceId, index) => (
                                <div key={index} className="flex items-center mb-3 px-3 text-gray-500">
                                    <input
                                        checked={selectedServices[index]}
                                        onChange={() => handleCheckboxChange(index)}
                                        type="checkbox"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        className="ml-2 text-sm font-medium dark:text-gray-500 rubik-400 fs-16"
                                    >
                                        {[
                                            "Addiction Treatment",
                                            "Aids for daily living (bathroom, bedroom safety)",
                                            "Asthma / COPD Management and training",
                                            "Baby Care",
                                            "Braces"
                                        ][index]}
                                    </label>
                                </div>
                            ))}
                        </div>

                        {/* Update Button */}
                        <div className="grid grid-cols-1 place-items-center">
                            <button
                                type='button'
                                className='bg-blue-500 rounded-md text-white w-full border-none py-2 text-sm mt-5 transition duration-200 hover:bg-blue-600 shadow-lg'
                                onClick={handleUpdateProfile}
                            >
                                {addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_profile' ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};










