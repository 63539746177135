import {
	PHARMACY_ALL_DISPENSE_LIST_DATA_REQUEST,
	PHARMACY_ALL_DISPENSE_LIST_DATA_SUCCESS,
	PHARMACY_ALL_DISPENSE_LIST_DATA_FAILURE

} from '../../../constants';

import {
	patient_medicine_list,
	patient_counselling,
	consent_submission_logs,
	pharmacy_drugbilling,
	prescription_dispense,
	dispense_memo,
	upload_prescription,
	pharmacy_route_patient,
	pharmacy_reverse_deliveries,
	sign_ai
} from '../../../../networking/urlEndPoints'

import Axios from '../../../../networking/intraceptor'

export const pharmacyAllDispenseListDataRequest = (types) => ({
	type: PHARMACY_ALL_DISPENSE_LIST_DATA_REQUEST,
	types: types
});

export const pharmacyAllDispenseListDataSuccess = (user, types) => ({
	type: PHARMACY_ALL_DISPENSE_LIST_DATA_SUCCESS,
	payload: user,
	types: types
});

export const pharmacyAllDispenseListDataFailure = (error, types) => ({
	type: PHARMACY_ALL_DISPENSE_LIST_DATA_FAILURE,
	payload: error,
	types: types
})

export const fetchAllDispenseList = (url, type, page, search) => {
	return async (dispatch) => {
		dispatch(pharmacyAllDispenseListDataRequest(type));
		try {
			const response = await Axios.get(`${url}`, { params: { page: page, patient_name: search } })

			if (response?.status === 200) {
				dispatch(pharmacyAllDispenseListDataSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAllDispenseListDataFailure(error?.response, type));
			}
		}
	}
}


export const fetchPatientMedicineList = (patient_id, type) => {
	return async (dispatch) => {
		dispatch(pharmacyAllDispenseListDataRequest(type));
		try {
			const response = await Axios.post(`${patient_medicine_list}`, {
				patient_id: patient_id,
				blister: true
			})

			if (response?.status === 200) {
				dispatch(pharmacyAllDispenseListDataSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAllDispenseListDataFailure(error?.response, type));
			}
		}
	}
}


export const FetchPatientCounsellingList = (params, type) => {
	return async (dispatch) => {

		dispatch(pharmacyAllDispenseListDataRequest(type));
		try {
			const response = await Axios.get(`${patient_counselling}`, { params })

			if (response?.status === 200) {
				dispatch(pharmacyAllDispenseListDataSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAllDispenseListDataFailure(error?.response, type));
			}
		}
	}
}

export const FetchPatientUploadPrescriptionList = (params, type) => {
	return async (dispatch) => {

		dispatch(pharmacyAllDispenseListDataRequest(type));
		try {
			const response = await Axios.get(`${patient_counselling}`, { params })

			if (response?.status === 200) {
				dispatch(pharmacyAllDispenseListDataSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAllDispenseListDataFailure(error?.response, type));
			}
		}
	}
}

export const fetchPatientDrugList = (patient_id, type) => {
	return async (dispatch) => {
		dispatch(pharmacyAllDispenseListDataRequest(type));
		try {
			const response = await Axios.post(`${patient_medicine_list}`, {
				patient_id: patient_id,
			})

			if (response?.status === 200) {
				dispatch(pharmacyAllDispenseListDataSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAllDispenseListDataFailure(error?.response, type));
			}
		}
	}
}

// -------------------------------Fetch Counsent Submission list-------------------

export const FetchCounsentSubmissionList = (params, type) => {
	return async (dispatch) => {

		dispatch(pharmacyAllDispenseListDataRequest(type));
		try {
			const response = await Axios.get(`${consent_submission_logs}`, { params })

			if (response?.status === 200) {
				dispatch(pharmacyAllDispenseListDataSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAllDispenseListDataFailure(error?.response, type));
			}
		}
	}
}

export const FetchTodaysBillingList = (params, type) => {
	return async (dispatch) => {

		dispatch(pharmacyAllDispenseListDataRequest(type));
		try {
			const response = await Axios.get(`${pharmacy_drugbilling}`, { params })

			if (response?.status === 200) {
				dispatch(pharmacyAllDispenseListDataSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAllDispenseListDataFailure(error?.response, type));
			}
		}
	}
}

export const FetchPrescriptionsList = (params, type) => {
	return async (dispatch) => {

		dispatch(pharmacyAllDispenseListDataRequest(type));
		try {
			const response = await Axios.get(`${prescription_dispense}`, { params })

			if (response?.status === 200) {
				dispatch(pharmacyAllDispenseListDataSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAllDispenseListDataFailure(error?.response, type));
			}
		}
	}
}
export const fetchMemo = (type, params) => {
	return async (dispatch) => {
		dispatch(pharmacyAllDispenseListDataRequest(type));
		try {
			const response = await Axios.get(`${dispense_memo}`, {
				params
			})

			if (response?.status === 200) {
				dispatch(pharmacyAllDispenseListDataSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAllDispenseListDataFailure(error?.response, type));
			}
		}
	}
}



export const fetchUploadPrescription = (type, params) => {
	return async (dispatch) => {
		dispatch(pharmacyAllDispenseListDataRequest(type));
		try {
			const response = await Axios.get(`${upload_prescription}`, {
				params
			})

			if (response?.status === 200) {
				dispatch(pharmacyAllDispenseListDataSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAllDispenseListDataFailure(error?.response, type));
			}
		}
	}
}



export const fetchReverseDeliveries = (type, params) => {
	return async (dispatch) => {
		dispatch(pharmacyAllDispenseListDataRequest(type));
		try {
			const response = await Axios.get(`${pharmacy_reverse_deliveries}`, {
				params
			})

			if (response?.status === 200) {
				dispatch(pharmacyAllDispenseListDataSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAllDispenseListDataFailure(error?.response, type));
			}
		}
	}
}

export const FetchTransferConsentSubmissions = (params, type) => {
	return async (dispatch) => {

		dispatch(pharmacyAllDispenseListDataRequest(type));
		try {
			const response = await Axios.get(`pharmacy-transfer-pconcent`, { params })

			if (response?.status === 200) {
				dispatch(pharmacyAllDispenseListDataSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAllDispenseListDataFailure(error?.response, type));
			}
		}
	}
}

export const fetchSignAPI = (type, params) => {
	return async (dispatch) => {
		dispatch(pharmacyAllDispenseListDataRequest(type));
		try {
			const response = await Axios.get(`${sign_ai}`, {
				params
			})

			if (response?.status === 200) {
				dispatch(pharmacyAllDispenseListDataSuccess(response?.data, type));
			} else {
				throw new Error(response);
			}
		} catch (error) {
			if (error?.response) {
				dispatch(pharmacyAllDispenseListDataFailure(error?.response, type));
			}
		}
	}
}