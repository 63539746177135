import React, {
    useEffect,
    useState
} from 'react'

import {
    updatePatientsFailure,
    updatePatientsSuccess
} from '../../../services/actions/patients/updatePatientsProfile'

import {
    AddNotes,
    UpdatePatientsProfileData
} from '../../../models/patients'

import {
    ErrorToast,
    SuccessToastMessage
} from '../../../components/loaders/toastMessage'

import {
    initalModals,
    initalShowToastMessage
} from '../../../utils/constants/formInitialStates'

import { useBlisterSelectors } from '../../../services/selectors/blisterSelectors'
import { fetchMedicationPatientList } from '../../../services/actions/pharmacy/blisters/patientBlisterPackList'

import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'
import { initDropdowns } from 'flowbite'

import { fetchPatientMedicineList } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'
import { RXModal } from '../../../models/dispense/rxListModal'
import { openPatientNoteModal } from '../../../assets/js/custome'
import { TextInput } from '../../../components/pharmacy/dispense/dispense'

import AddBlisterPackModal from '../../../models/blisters/addBlisterPack'
import ChatBox from '../../../components/chat/chatbox'

import * as Common from '../../../components/common'
import * as Hooks from '../../../hooks'
import * as Icons from '../../../assets/svg/icons'
import * as Modals from '../../../assets/js/custome'
import * as routeNames from '../../../routes/routeName'

const blisterListHeading = [
    { text: "NAME", classNames: ['p-3'] },
    { text: "PHN", classNames: ['p-3'] },
    { text: "PHONE", classNames: ['p-3'] },
    { text: "ADDRESS", classNames: ['p-3'] },
    { text: "ACTION", classNames: ['p-3'] },
];

const TableRowData = ({ value, className1 = 'p-3', }) => {
    return (<td scope="row" className={className1}>
        <p>{value}</p>
    </td>)
}

const MedicationPatients = () => {

    const location = Hooks.useCustomLocation()
    const navigate = Hooks.useCustomNavigate()
    const dispatch = Hooks.useCustomDispatch()

    const { patientBlisterPackListResponse, AddBlisterPackResponse } = useBlisterSelectors()
    const blisterList = patientBlisterPackListResponse?.data?.data
    const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

    const [pageNumber, setPageNumber] = useState(initialPage)
    const [showModal, setShowModal] = useState(initalModals)
    const [patientProfileData, setPatientProfileData] = useState(null)
    const [showToast, setShowToast] = useState(initalShowToastMessage)
    const [formData, setFormData] = useState({ search: '' })

    useEffect(() => {
        if (initialPage) {
            setPageNumber(initialPage)
        }
    }, [initialPage])

    useEffect(() => {
        dispatch(fetchMedicationPatientList('medication_patient_list', pageNumber))
        return () => { }
    }, [])

    const handlePagination = (event, value) => {
        if (value) {
            setPageNumber(value);
            const searchParams = new URLSearchParams();
            searchParams.append('page', value);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            dispatch(fetchMedicationPatientList('medication_patient_list', value))
            navigate(newUrl);
        } else if(formData.search) {
            dispatch(fetchMedicationPatientList('medication_patient_list', null, formData.search))
        }
    };

    useEffect(() => {
        initDropdowns()
    }, [blisterList?.data])

    return (
        <React.Fragment>
            <div className='BodyBgColor'>
                <div className="container mx-auto px-4 pt-5 pb-5">

                    <div className="grid grid-cols-12 gap-4">
                        {/* <Common.Sidebar /> */}
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                            <div className="content">
                                <div className="grid grid-cols-12 gap-4 w-full">
                                    <div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
                                                sm:text-center text-center lg:text-left md:text-left">
                                        <h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
                                            <Common.BreadCrumb />
                                        </h2>
                                    </div>
                                </div>

                                <div className="container mt-2">
                                    <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                                        <div className='col-span-12 flex'>
                                            <div className="flex gap-2">

                                                <TextInput label='Search' name='search' formData={formData}
                                                    setFormData={setFormData} />

                                                <button type="button" style={{ backgroundColor: '#3B82F6', width: '100px' }} onClick={handlePagination}
                                                    className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
                                                            border border-gray-300 			focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
                                                            dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Filter
                                                </button>
                                            </div>

                                            <div className="ml-auto">
                                                <button type="button" onClick={() => navigate(routeNames.pharmacy_add_medication_patients)}
                                                    className="rubik-500 rounded-md flex fs-14  h-8 text-white blueBg-color  focus:ring-4 
													focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-1.5 text-center
													 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 justify-center items-center">
                                                    <span className="flex items-center" >
                                                        Add New Patient
                                                    </span>
                                                </button>
                                            </div>
                                        </div>

                                        {patientBlisterPackListResponse?.loading && patientBlisterPackListResponse?.type === 'medication_patient_list' ?
                                            <div className="flex justify-center items-center mt-60 mb-80">
                                                <Common.LoadingView />
                                            </div> :
                                            patientBlisterPackListResponse?.error !== null && patientBlisterPackListResponse?.type === 'medication_patient_list' ?
                                                <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                                    <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                                    <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                                    <button onClick={() => window.location.reload()}
                                                        className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                                        Please Try Again.
                                                    </button>
                                                </div> :
                                                blisterList?.data?.length === 0 && patientBlisterPackListResponse?.type === 'medication_patient_list' ?
                                                    <div className="bg-white rounded-md mt-2">
                                                        <p className="text-gray-500">No record available.</p>
                                                    </div>
                                                    : patientBlisterPackListResponse?.type === 'medication_patient_list' ?
                                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                            <div className="shadow-md border rounded-lg  relative">
                                                                <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                                    <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray' ,background: '#f6f4f4'}}>
                                                                        <tr>
                                                                            {blisterListHeading?.map((header, index) => (
                                                                                <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                                    <p className="rubik-500 fs-14">{header?.text}</p>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            blisterList?.data?.map((value, idx) => (
                                                                                <React.Fragment key={idx}>
                                                                                    <tr style={{ borderBottom: `${'1px solid lightgray'}` }}>

                                                                                        <TableRowData value={value.name} />
                                                                                        <TableRowData value={value.phn} />

                                                                                        <TableRowData value={value.phone} />
                                                                                        <TableRowData value={value.display_address} />

                                                                                        <td scope="row" className="p-3">
                                                                                            <button id={`dropdownDefaultButton_${idx}`} data-dropdown-toggle={`dropdown_${idx}`} className="inline-flex items-center text-white p-2 text-sm font-medium text-center text-gray-900 	bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
                                                                                                <Icons.EditIcon />
                                                                                            </button>

                                                                                            <div id={`dropdown_${idx}`} className="z-10 relative border border-gray-100 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                                                                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby={`dropdownDefaultButton_${idx}`}>

                                                                                                    <li className='btnnn' onClick={() => { return Modals.handleShowModal(setShowModal, showModal, 'rxModal'), dispatch(fetchPatientMedicineList(value.patient_id, 'patient_pending_list')) }}>
                                                                                                        <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                                            <span className="pl-2">View Medicine</span></a>
                                                                                                    </li>

                                                                                                    <li className='btnnn'
                                                                                                        onClick={() => {
                                                                                                            return setPatientProfileData(value),
                                                                                                                Modals.handleShowModal(setShowModal, showModal, 'addBlisterPackModal')
                                                                                                        }}>
                                                                                                        <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                                            <span className="pl-2">Add Speciality Medication</span></a>
                                                                                                    </li>

                                                                                                    <li className='btnnn'
                                                                                                        onClick={() => {
                                                                                                            return setPatientProfileData(value), dispatch(updatePatientsSuccess(null), dispatch(updatePatientsFailure(null))),
                                                                                                                Modals.handleShowModal(setShowModal, showModal, 'showPatientModle')
                                                                                                        }}>

                                                                                                        <a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                                                                            <span className="pl-2">View Edit Details</span>
                                                                                                        </a>
                                                                                                    </li>

                                                                                                    <li onClick={() => { return setPatientProfileData(value) }} className='btnnn'>
                                                                                                        <a onClick={() => openPatientNoteModal(setShowModal, showModal)} className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">

                                                                                                            <span className="pl-2">Add Pharmacy Note</span></a>
                                                                                                    </li>

                                                                                                </ul>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </React.Fragment>)

                                                                            )}
                                                                    </tbody>
                                                                </table>


                                                                {patientBlisterPackListResponse?.error !== null ? null : <>
                                                                    {blisterList?.data?.length !== 0 && <>
                                                                        {blisterList?.pagination?.last_page !== 1 &&
                                                                            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
                                                                                <nav aria-label="Page navigation example">
                                                                                    <ul className="inline-flex -space-x-px text-base h-10">
                                                                                        <CustomPagination
                                                                                            count={blisterList?.pagination?.last_page}
                                                                                            page={pageNumber}
                                                                                            onChange={handlePagination}
                                                                                        />
                                                                                    </ul>
                                                                                </nav>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                                </>}
                                                            </div>

                                                        </div> : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChatBox />

            <Common.Footer />


            {showToast?.patientNoteToast && <SuccessToastMessage status='Note added sucessfully' />}
            {showToast.showToast && AddBlisterPackResponse?.type === 'add_medication_pack' && <SuccessToastMessage status='Added sucessfully' />}
            {showToast.errorToast && AddBlisterPackResponse?.type === 'add_medication_pack' && <ErrorToast status={'Oops! Got an issue.please try to add again.'} />}

            <RXModal showModal={showModal} onClose={() => Modals.handleHideModal(setShowModal, showModal, 'rxModal')} setShowModal={setShowModal} />

            {
                showModal.patientNoteModal &&
                <AddNotes
                    showToast={showToast}
                    setShowToast={setShowToast}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    patientProfileData={patientProfileData}
                    paginationNumber={pageNumber}
                />
            }

            {
                showModal?.showPatientModle &&
                <UpdatePatientsProfileData
                    patientProfileData={patientProfileData}
                    paginationNumber={pageNumber}
                    location={location}
                    setShowModal={setShowModal}
                    showModal={showModal}
                />
            }

            {
                showModal?.addBlisterPackModal &&
                <AddBlisterPackModal
                    patientProfileData={patientProfileData}
                    paginationNumber={pageNumber}
                    location={location}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    setShowToast={setShowToast}
                />
            }

        </React.Fragment>
    )
}

export default MedicationPatients