import React, {
    useEffect,
    useState
} from 'react'

import { currentDate } from '../../utils/constants/formInitialStates';

import * as Hooks from '../../hooks'
import * as Common from '../../components/common'
import * as Modals from "../../assets/js/custome";

import ChatBox from '../../components/chat/chatbox'
import { AddNewDeliveyTimes } from '../../models/pharmacy/adminPopUps';
import { fetchPharmacyDeliverySettingsList } from '../../services/actions/admin/team/fetchTeamsList';
import { useAdminSelectors } from '../../services/selectors/adminModuleSelectors';
import * as Pharmacy from "../../components/pharmacy/form/form";
import { handleAddDeliveryFormValidation } from '../../utils/validations/formsValidations';

export const appUSerListHeading = [
    { text: "Initial Date", classNames: ['p-3'] },
    { text: "End Date", classNames: ['p-3'] },
    { text: "Begin Time", classNames: ['p-3'] },
    { text: "End Time", classNames: ['p-3'] },
    { text: "Action", classNames: ['p-3'] },
];

export const TableRowData = ({ value, className1 = 'p-3' }) => {
    return (<td scope="row" className={className1}>
        <p>{value}</p>
    </td>)
}

const TextInput = ({ formData,
    setErrorMessages,
    setFormData,
    label,
    errorMessage,
    dVal,
    name = "" }) => {

    return (<div className="relative w-full">
        <input type={'time'} id={name} autoComplete="off"
            onChange={(e) => { setErrorMessages(null); setFormData({ ...formData, [name]: e.target.value }) }}
            name={name}
            value={formData[name]}
            className={`block ${(errorMessage?.end_time || errorMessage?.start_time) && 'border-red-500'} pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
            dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
            bg-white `}
            placeholder=""
        />
        <label htmlFor={name}
            className={`ml-4 absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2  
                        origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                        peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                        peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>
            {(errorMessage?.end_time_error || errorMessage?.start_time) ? '' : label}
        </label>
        {errorMessage && <p className='text-red-500 fs-12 rubik-400'>{errorMessage?.end_time_error?.[0]}</p>}
        {errorMessage?.time_gap && <p className='text-red-500 fs-12 rubik-400'>{errorMessage?.time_gap}</p>}
    </div>)
}

const DeliveryModule = () => {
    const params = new URLSearchParams(window.location.search);
    const activeParam = params.get('active');
    const [formVisible, setFormVisible] = useState(null);
    const [formData, setFormData] = useState({
        date: currentDate
    });
    const [errorMessage, setErrorMessages] = useState(null)
    const [errorGTimeDiffMessage, setErrorTimeDiffMessages] = useState(null)

    const dispatch = Hooks.useCustomDispatch()

    const { TeamListResponse, addTeamDataResponse } = useAdminSelectors()

    const [showModal, setShowModal] = React.useState({ AddAnnouncements: false, EditAnnouncements: false, ImgAnnouncements: false, DeleteAnnounceModal: false });

    const appUserListResponse = TeamListResponse
    const appUserList = TeamListResponse?.data?.data?.delivery_setting
    const fetchType = 'setting_delivery_time'

    useEffect(() => {
        if (activeParam == 'delivery_settings') {
        dispatch(fetchPharmacyDeliverySettingsList(fetchType))
        }
        return () => { }
    }, [])

    const handleButtonClick = (idx) => {
        setFormVisible(formVisible === idx ? null : idx);
    };

    const handleSubmit = () => {
        handleAddDeliveryFormValidation(formData, setErrorMessages, errorMessage, dispatch, setFormVisible, setErrorTimeDiffMessages)
    }

    return (
        <React.Fragment>
            <div className="lg:col-span-9 md:col-span-12 sm:col-span-12 col-span-12">

                <div className="content">

                    <div className="container mt-2">
                        <div className="border rounded-lg md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">

                            {appUserListResponse?.loading && appUserListResponse?.type === fetchType ?
                                <div className="flex justify-center items-center mt-60 mb-80">
                                    <Common.LoadingView />
                                </div> :
                                appUserListResponse?.error !== null && appUserListResponse?.type === fetchType ?
                                    <div className="flex flex-col items-center justify-center mt-60 mb-80">
                                        <h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
                                        <p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
                                        <button onClick={() => window.location.reload()}
                                            className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
                                                                focus:outline-none focus:ring-2 focus:ring-red-500">
                                            Please Try Again.
                                        </button>
                                    </div> :
                                    appUserList?.length === 0 && appUserListResponse?.type === fetchType ?
                                        <div className="bg-white rounded-md mt-4">
                                            <p className="text-gray-500">No Data Found.</p>
                                        </div>
                                        : appUserListResponse?.type === fetchType ?
                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                                                <div className="shadow-md border rounded-lg relative">
                                                    <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                                        <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                            <tr>
                                                                {appUSerListHeading?.map((header, index) => (
                                                                    <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                                        <p className="rubik-500 fs-14">{header?.text}</p>
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                appUserList?.map((value, idx) => {
                                                                    const isLastIndex = idx === appUserList.length - 1; // Check if it's the last index
                                                                    return (<React.Fragment key={idx}>
                                                                        <tr style={{ borderBottom: '1px solid lightgray' }}>
                                                                            <TableRowData value={value.initiate_date} />
                                                                            <TableRowData value={value.end_time} />
                                                                            <TableRowData value={value.end_date} />
                                                                            <TableRowData value={value.begin_time} />
                                                                            {(!formVisible && isLastIndex) && <td className='p-3'>
                                                                                <button type="button"
                                                                                    onClick={() => handleButtonClick(idx)}
                                                                                    className="flex justify-center items-center text-white bg-blue-500 hover:bg-blue-600 w-full text-sm px-3 py-2 text-center rounded-lg">
                                                                                    Add</button>
                                                                            </td>}
                                                                        </tr>
                                                                    </React.Fragment>)
                                                                })}
                                                            {(formVisible == 0 || formVisible ) && (
                                                                <tr>
                                                                    <td className="p-3" colSpan={'6'}>
                                                                        <div className='grid grid-cols-3 gap-2'>

                                                                            <TextInput formData={formData} setErrorMessages={setErrorMessages} setFormData={setFormData} dVal={formData?.begin_time} errorMessage={errorMessage} name='begin_time' label='START TIME' textType='time' />

                                                                            <TextInput formData={formData} setErrorMessages={setErrorMessages} setFormData={setFormData} dVal={formData?.end_time} errorMessage={errorMessage} name='end_time' label='END TIME' textType='time' />
                                                                            <div>
                                                                                <Pharmacy.Date
                                                                                    formData={formData}
                                                                                    setFormData={setFormData}
                                                                                />
                                                                                {errorMessage && <p className='text-red-500 fs-12 rubik-400'>{errorMessage?.initial_date?.[0]}</p>}
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center gap-3 w-56 mt-2">
                                                                            <button type="button"
                                                                                onClick={() => setFormVisible(null)}
                                                                                className="flex justify-center items-center w-32 text-white bg-blue-500 hover:bg-blue-600 w-1/3 text-sm px-3 py-2 text-center rounded-lg">
                                                                                Back
                                                                            </button>
                                                                            <button type="button" onClick={() => handleSubmit()}
                                                                                className="flex justify-center items-center text-white bg-green-500 hover:bg-green-600 w-1/3 text-sm px-3 py-2 text-center rounded-lg">
                                                                                {addTeamDataResponse?.loading && addTeamDataResponse?.type === 'update-delivery-time-setting' ? 'Processing...' : 'Submit'}
                                                                            </button>

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> : null}
                        </div>
                    </div>

                </div>
            </div>

            <AddNewDeliveyTimes
                onClose={() =>
                    Modals.handleHideModal(setShowModal, showModal, "AddNewDelivery")
                }
                setShowModal={setShowModal}
                showModal={showModal}
            // page={pageNumber}
            />

            <ChatBox />
            <Common.Footer />
        </React.Fragment>
    )
}

export default DeliveryModule