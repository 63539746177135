import React, {
    useState,
    useRef,
    useEffect,
} from 'react'

import { ModalCloseIcon } from '../../assets/svg/icons'
import { handleHideModal } from '../../assets/js/custome'

import { useRevenueSelector } from '../../services/selectors/revenueSelectors'
import { useCustomDispatch, useCustomLocation } from '../../hooks'
import { AddINHandCashPrintSave, AddNewDepositData, DepositPrintData, PrintHandCashData } from '../../services/actions/pharmacy/revenue/addRevenueData'

import * as routeNames from '../../routes/routeName'

const addDepositListHeading = [
    { text: "Bills", classNames: ['p-3'] },
    { text: "Number", classNames: ['p-3'] },
    { text: "TOTAL", classNames: ['p-3'] },
];

export const AddDepositModal = ({ setShowModal, showModal, depositListData, isEdit }) => {

    const modalRef = useRef()
    const dispatch = useCustomDispatch()
    const location = useCustomLocation()

    const { addRevenueDataResponse } = useRevenueSelector();
    const depositData = depositListData?.diposit_data || {}

    const { change_100_num_of_pack = 0, change_100_total = 0, change_50_num_of_pack = 0, change_50_total = 0,
        change_20_num_of_pack = 0, change_20_total = 0, change_10_num_of_pack = 0, change_10_total = 0,
        change_5_num_of_pack = 0, change_5_total = 0, change_2_num_of_pack = 0, change_2_total = 0,
        change_1_num_of_pack = 0, change_1_total = 0, change_025_num_of_pack = 0, change_025_total = 0,
        change_010_num_of_pack = 0, change_010_total = 0, change_005_num_of_pack = 0, change_005_total = 0,
        super_total = 0
    } = depositData

    const inputFields = [
        { id: '$100', label: '$100', name: 'cash', amount: isEdit ? change_100_num_of_pack : 0, note: isEdit ? change_100_total : 0 },
        { id: '$50', label: '$50', name: 'debitCard', amount: isEdit ? change_50_num_of_pack : 0, note: isEdit ? change_50_total : 0 },
        { id: '$20', label: '$20', name: 'visaCard', amount: isEdit ? change_20_num_of_pack : 0, note: isEdit ? change_20_total : 0 },
        { id: '$10', label: '$10', name: 'masterCard', amount: isEdit ? change_10_num_of_pack : 0, note: isEdit ? change_10_total : 0 },
        { id: '$5', label: '$5', name: 'cheque', amount: isEdit ? change_5_num_of_pack : 0, note: isEdit ? change_5_total : 0 },
        { id: '$5', label: '$5', name: 'autoDebit', amount: isEdit ? change_2_num_of_pack : 0, note: isEdit ? change_2_total : 0 },
        { id: '$1', label: '$1', name: 'cashLeftOver', amount: isEdit ? change_1_num_of_pack : 0, note: isEdit ? change_1_total : 0 },
        { id: '$0.25', label: '$0.25', name: 'cashDeposit', amount: isEdit ? change_025_num_of_pack : 0, note: isEdit ? change_025_total : 0 },
        { id: '$0.10', label: '$0.10', name: 'cashDeposit', amount: isEdit ? change_010_num_of_pack : 0, note: isEdit ? change_010_total : 0 },
        { id: '$0.05', label: '$0.05', name: 'cashDeposit', amount: isEdit ? change_005_num_of_pack : 0, note: isEdit ? change_005_total : 0 },
    ];

    const [inputData, setInputData] = useState(inputFields);
    const [superTotal, setSuperTotal] = useState(isEdit ? super_total : 0)

    const handleChange = (index, key, value) => {
        value = parseFloat(value);
        if (isNaN(value)) {
            value = 0;
        }

        const newFormData = [...inputData];
        newFormData[index][key] = value;

        const numericLabel = parseFloat(newFormData[index].label.substring(1));
        const noteValue = numericLabel * value;

        newFormData[index].note = noteValue;

        setInputData(newFormData);

        let supertotal = 0
        newFormData.forEach(item => {
            supertotal += parseFloat(item.note);
        })
        setSuperTotal(`$${supertotal}`)
    };

    const handlePrintData = () => {

        const requestedData = {
            change_100_num_of_pack: inputData?.[0]?.amount,
            change_100_total: inputData?.[0]?.note,

            change_50_num_of_pack: inputData?.[1]?.amount,
            change_50_total: inputData?.[1]?.note,

            change_20_num_of_pack: inputData?.[2]?.amount,
            change_20_total: inputData?.[2]?.note,

            change_10_num_of_pack: inputData?.[3]?.amount,
            change_10_total: inputData?.[3]?.note,

            change_5_num_of_pack: inputData?.[4]?.amount,
            change_5_total: inputData?.[4]?.note,

            change_2_num_of_pack: inputData?.[5]?.amount,
            change_2_total: inputData?.[5]?.note,

            change_1_num_of_pack: inputData?.[6]?.amount,
            change_1_total: inputData?.[6]?.note,

            change_025_num_of_pack: inputData?.[7]?.amount,
            change_025_total: inputData?.[7]?.note,

            change_010_num_of_pack: inputData?.[8]?.amount,
            change_010_total: inputData?.[8]?.note,

            change_005_num_of_pack: inputData?.[9]?.amount,
            change_005_total: inputData?.[9]?.note,

            super_total: (`${superTotal}`)
        }
        if (location.pathname === routeNames.pharmacy_hand_cash) {
            if (isEdit) {
                dispatch(PrintHandCashData('print_deposit_data', depositListData?.id))
            } else {
                dispatch(AddINHandCashPrintSave('print_deposit_data', requestedData))
            }
        } else if (isEdit) {
            dispatch(DepositPrintData('print_deposit_data', depositListData?.id))
        } else {
            dispatch(AddNewDepositData('print_deposit_data', requestedData))
        }
    }

    return (
        <React.Fragment>
            <div className="fixed top-0 left-0 right-0 z-50 bottom-0 flex items-center justify-center p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 
                            dark:bg-opacity-100">
                <div className="relative p-4 w-full max-w-2xl max-h-full" ref={modalRef}>
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                {location.pathname === routeNames.pharmacy_hand_cash ? isEdit ? 'View Hand Cash' : 'Add Hand Cash' : isEdit ? 'View Deposit' : 'Add Deposit'}
                            </h3>
                            <button type="button" onClick={() => handleHideModal(setShowModal, showModal, 'addDepositModal')}
                                className="text-gray-400 close_btn hover:bg-gray-200 hover:text-gray-900 rounded-full 
								text-sm w-6 h-6 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <ModalCloseIcon />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4 md:p-5 space-y-4">
                            <form>

                                <div className="grid grid-cols-1 gap-4">
                                    <div className="shadow-md border rounded-lg relative">
                                        <table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
                                            <thead className=" uppercase dark:bg-gray-700 dark:text-gray-400 text-gray-900" style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
                                                <tr>
                                                    {addDepositListHeading?.map((header, index) => (
                                                        <th key={index} scope="col" className={header?.classNames?.join(" ")}>
                                                            <p className="rubik-500 fs-14">{header?.text}</p>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {inputData?.map((field, index) => (
                                                    <tr style={{ borderBottom: '1px solid lightgray' }} key={index}>
                                                        <td scope="row" className={'p-1  pl-2'}>
                                                            {field.label}
                                                        </td>
                                                        <td scope="row" className={'p-1 pl-2'}>
                                                            {/* {isEdit ? <p className='p-3'> {field.amount} </p> : */}
                                                            <input
                                                                type="text"
                                                                id={`${field.id}_amount`}
                                                                name={`${field.name}_amount`}
                                                                disabled={isEdit}
                                                                value={field.amount}
                                                                autoComplete='off'
                                                                placeholder="Amount"
                                                                style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none', borderBottom: isEdit && 'none' }}
                                                                className={`block pl-4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent border-b border-gray-300 
                                                                    appearance-none 
                                                                    dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                                                                    bg-white `}
                                                                onChange={(e) => handleChange(index, 'amount', e.target.value)}
                                                                onKeyPress={(e) => { if (isNaN(Number(e.key))) { e.preventDefault(); } }}
                                                            />
                                                            {/* } */}
                                                        </td>
                                                        <td scope="row" className={'p-1 pl-2'}>

                                                            <input
                                                                type="text"
                                                                id={`${field.id}_note`}
                                                                name={`${field.name}_note`}
                                                                value={field.note}
                                                                disabled
                                                                style={{ border: 'none' }}
                                                                placeholder="Note"
                                                                className={`block w-full bg-white text-sm text-gray-900 bg-white rounded-lg border border-gray-300 px-2.5 py-2.5 focus:outline-none focus:border-blue-600`}
                                                                onChange={(e) => handleChange(index, 'note', e.target.value)}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}

                                                <tr style={{ borderBottom: '1px solid lightgray' }}>
                                                    <td scope="row" className={'p-1  pl-2 rubik-500  text-gray-500'}>
                                                        TOTAL
                                                    </td>

                                                    <td scope="row" className={'p-1  pl-2'}>
                                                    </td>

                                                    <td scope="row" className={'p-1  pl-2'}>
                                                        <p className='pl-2'>{superTotal}</p>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>

                                </div>

                                <button type="button" onClick={() => addRevenueDataResponse?.loading ? null : handlePrintData()} style={{ backgroundColor: '#3B82F6' }}
                                    className="flex w-full items-center justify-center text-white bg-red-500 mt-4
										border border-gray-300 hover:bg-red-300  font-medium rounded-lg text-sm px-5 py-1.5  dark:bg-gray-800">
                                    {addRevenueDataResponse?.loading && addRevenueDataResponse?.type === 'print_deposit_data' ? 'Processing...' :
                                        isEdit ? 'Print' : 'Save & Print'}

                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}