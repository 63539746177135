import React, { useState } from 'react'

import {
    Footer,
    LoadingView,
} from '../../components/common'

import { useCustomDispatch } from '../../hooks'
import { SuccessToastMessage } from '../../components/loaders/toastMessage'
import { onhandleValidatePayrollSettingForm } from '../../utils/validations/adminModuleValidations'
import { useAdminSelectors } from '../../services/selectors/adminModuleSelectors'

import ChatBox from '../../components/chat/chatbox'
import * as Pharmacy from "../../components/pharmacy/form/form";

import Button from '../../components/common/button'

const SelectComponent = ({ id, name, formData, setFormData, errorMessage, employeeList, placeholder, padding = 'pb-2.5 pt-4', label = '' }) => {
    return (
        <div className="w-full">
            <label className={`text-gray-600 mb-2`} style={{ fontSize: '14px' }}>
                {label}
            </label>
            <select
                id={id}
                name={name}
                value={formData[name] || ''}
                placeholder={placeholder}
                onChange={(e) => {
                    const selectedValue = e.target.value;
                    setFormData(prevData => ({
                        ...prevData,
                        [name]: selectedValue,
                        weekly_start_day: selectedValue
                    }));

                }}
                className={`block ${errorMessage[name] && 'border-red-500'} scroll-box pl-4 w-full px-2.5 ${padding} text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-pointer 
                bg-white `}
            >
                <option value="" disabled>{placeholder}</option>
                {employeeList?.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

function dayToNumber(day) {
    const days = {
        'monday': 1,
        'tuesday': 2,
        'wednesday': 3,
        'thursday': 4,
        'friday': 5,
        'saturday': 6,
        'sunday': 7
    };

    if (typeof day === 'string') {
        return days[day.toLowerCase()] || 1;
    }

    return (day >= 1 && day <= 7) ? day : 1;
}

const PayrollSetting = ({ data }) => {

    const { addTeamDataResponse, TeamListResponse } = useAdminSelectors()
    const dispatch = useCustomDispatch()

    const [formData, setFormData] = useState({
        payroll_period: 'week',
        payroll_periods_begin_on: (data?.payroll_periods_begin_on),
        hour: data?.hour,
        weekly_start_date: data?.weekly_start_day,
        two_weekly_start_date: data?.two_weekly_start_date,
        first_monthly_day: data?.our_payroll_periods_begin,
        second_monthly_day: data?.second_monthly_day,
        first_monthly_day1: data?.first_monthly_day
    })

    const [errorMessage, setErrorMessages] = useState({
        payroll_period: '',
        hour: '',
        payroll_periods_begin_on: '',
        weekly_start_date: '',
        two_weekly_start_date: '',
        monthly_Start_day: '',
        first_monthly_day: '',
        first_monthly_day1: '',
        second_monthly_day: ''
    })

    const [showToast, setShowToast] = useState()

    React.useEffect(() => {
        setFormData({
            payroll_period: data?.payroll_period,
            hour: data?.hour,
            weekly_start_date: data?.weekly_start_day,
            two_weekly_start_date: data?.payroll_periods_begin_on,
            first_monthly_day: data?.our_payroll_periods_begin,
            second_monthly_day: data?.second_monthly_day,
            date: data?.our_next_payroll_begin,
            payroll_periods_begin_on: dayToNumber(data?.payroll_periods_begin_on),
            first_monthly_day1: data?.first_monthly_day
        })
    }, [data])

    const UpdateData = () => {
        setShowToast('')
        onhandleValidatePayrollSettingForm(formData, setErrorMessages, errorMessage, dispatch, setShowToast)
    }

    const numberList = [];
    const numberList1 = [];

    for (let i = 0; i < 60; i++) {
        const paddedValue = i.toString().padStart(2, '0');
        let label;
        let value;
        if (i < 32 && i > 0) {
            switch (paddedValue) {
                case '01':
                case '21':
                case '31':
                    label = paddedValue + 'st';
                    value = paddedValue + 'st';
                    break;
                case '02':
                case '22':
                    label = paddedValue + 'nd';
                    value = paddedValue + 'nd';
                    break;
                case '03':
                case '23':
                    label = paddedValue + 'rd';
                    value = paddedValue + 'rd';
                    break;
                default:
                    label = paddedValue + 'th';
                    value = paddedValue + 'th';
                    break;
            }
            numberList.push({ value: value, label: label });
        } else {
            // numberList.push({ value: paddedValue, label: paddedValue });
        }
        numberList1.push({ value: paddedValue, label: paddedValue });
    }

    return (
        <>

            <div className="container">
                <div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
                    {TeamListResponse.loading && TeamListResponse.type === 'break_payroll_data_initial' ?
                        <div className='flex justify-center items-center mt-60 mb-60'>
                            <LoadingView />
                        </div>
                        :
                        <>

                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">

                                <SelectComponent
                                    id="payroll_period"
                                    name="payroll_period"
                                    value="payroll_period"
                                    placeholder="Select Value"
                                    formData={formData}
                                    setFormData={setFormData}
                                    errorMessage={errorMessage}
                                    label="Select Payroll Period"
                                    employeeList={[
                                        { value: "week", label: "Every Week" },
                                        { value: "twoWeeks", label: "Every Two Week" },
                                        { value: "month", label: "Once a Month" },
                                        { value: "twiceMonth", label: "Twice a Month" },
                                    ]}
                                />
                                <div>
                                    <label
                                        className={`text-gray-600 mb-1`} style={{ fontSize: '14px' }}>
                                        Hours
                                    </label>
                                    <input label='Hour' name='hour' value={formData?.hour}
                                        height="48px"
                                        className={`block pl-4 ${errorMessage.hour && 'border-red-500'} px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none 
                                dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text 
                                bg-white `}
                                        placeholder="Hours"
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            if (value < 0) value = 0;
                                            if (value > 12) value = 12;
                                            setFormData({ ...formData, hour: value })
                                        }}
                                        type='number' />
                                </div>

                                {formData.payroll_period == 'twiceMonth' && <div>
                                    <label
                                        className={`text-gray-600 mb-1`} style={{ fontSize: '14px' }}>
                                        Our payroll periods begin on
                                    </label>
                                    <div className="flex items-center space-x-2">

                                        <div className="flex flex-col">
                                            <SelectComponent
                                                id="first_monthly_day1"
                                                name="first_monthly_day1"
                                                value="first_monthly_day1"
                                                placeholder="Select Value"

                                                formData={formData}
                                                setFormData={setFormData}
                                                errorMessage={errorMessage}
                                                employeeList={numberList}
                                            />

                                        </div>
                                        <span>-</span>
                                        <div className="flex flex-col">
                                            {/* <label htmlFor="second_monthly_day">Second Month</label> */}

                                            <SelectComponent
                                                id="second_monthly_day"
                                                name="second_monthly_day"
                                                value="second_monthly_day"
                                                placeholder="Slect Value"
                                                formData={formData}
                                                setFormData={setFormData}
                                                errorMessage={errorMessage}
                                                employeeList={numberList}
                                            />
                                        </div>
                                    </div>

                                </div>}

                                {(formData.payroll_period == 'week') && <div>

                                    <div>
                                        <SelectComponent
                                            id="payroll_periods_begin_on"
                                            name="payroll_periods_begin_on"
                                            placeholder="Select Value"
                                            formData={formData}
                                            label="Our payroll periods begin on"
                                            setFormData={setFormData}
                                            errorMessage={errorMessage}
                                            employeeList={[
                                                { value: "1", label: "Monday" },
                                                { value: "2", label: "Tuesday" },
                                                { value: "3", label: "Wednesday" },
                                                { value: "4", label: "Thursday" },
                                                { value: "5", label: "Friday" },
                                                { value: "6", label: "Saturday" },
                                                { value: "7", label: "Sunday" }
                                            ]}
                                        />
                                    </div>

                                </div>}

                                {formData.payroll_period == 'twoWeeks' && <div>

                                    <div>
                                        <label
                                            className={`text-gray-600 mb-1`} style={{ fontSize: '14px' }}>
                                            Our next payroll period begins on
                                        </label>
                                        <Pharmacy.Date
                                            formData={formData}
                                            setFormData={setFormData}
                                        />
                                    </div>
                                </div>}


                                {formData.payroll_period == 'month' && <div>

                                    <SelectComponent
                                        id="first_monthly_day"
                                        name="first_monthly_day"
                                        value="first_monthly_day"
                                        placeholder="Slect Value"
                                        formData={formData}
                                        label='Our payroll periods begin on'
                                        setFormData={setFormData}
                                        errorMessage={errorMessage}
                                        employeeList={numberList}
                                    />
                                </div>}

                            </div>

                            <div className='mt-2'>
                                <Button color='white' width='100px'
                                    text={addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_payroll_setting' ? 'Processing...' : 'Submit'}
                                    handleFilter={() => addTeamDataResponse?.loading && addTeamDataResponse.type === 'update_payroll_setting' ? null : UpdateData()} />
                            </div>
                        </>}

                </div>
            </div>

            <ChatBox />
            <Footer />
            {showToast && <SuccessToastMessage status={showToast} />}

        </>
    )
}

export default PayrollSetting