import React, { useEffect, useState } from 'react'

import { LableRowHeader } from '../../../constants/dispense'

import {
	DeleteLabelsModal,
	MuteRxLabelsModel,
	PrinterLabelSettingModal,
	UpdateDispenseLabel
} from '../../../models/dispense/updateDispenseLabel'

import { initalDispenseLabelsFormData } from '../../../utils/constants/formInitialStates'

import { CustomPagination } from '../../../components/pharmacy/dashboard/pagination'
import { ArchivedDelveriesDateRangePicker } from '../../../components/pharmacy/form/dateRangePicker'
import { fetchDispenseLabelsList } from '../../../services/actions/pharmacy/dispense/labelList'
import { useDispenseSelectors } from '../../../services/selectors/dispenseSelectors'

import { LabelSyncAncFilter, fetchRxLabelsList, pharmacyAddlabelsDataFailure, pharmacyAddlabelsDataSuccess } from '../../../services/actions/pharmacy/dispense/addLabelsData'
import { RXModal } from '../../../models/dispense/rxListModal'
import { pharmacyLabelsPrint } from '../../../services/actions/pharmacy/dispense/addDispenseData'
import { initDropdowns } from 'flowbite'
import { fetchPatientMedicineList } from '../../../services/actions/pharmacy/dispense/fetchAllDispenseListData'

import * as Modals from '../../../assets/js/custome'
import * as Dispense from '../../../components/pharmacy/dispense/dispense'
import * as Hooks from '../../../hooks'
import * as useEffectHelper from '../../../utils/helper/useEffectsHelper'
// import * as path from '../../../networking/urlEndPoints'

import * as Icons from '../../../assets/svg/icons'
import * as Common from '../../../components/common'
import * as routesNames from '../../../routes/routeName'
import * as Toast from '../../../components/loaders/toastMessage'

import ChatBox from '../../../components/chat/chatbox'
import { validateLabelInputsDataFailure, validateLabelInputsDataSuccess } from '../../../services/actions/pharmacy/dispense/labelValidateUpdateInputs'

const DrugDetailList = ({ className_2 = 'rubik-400 fs-14', value, className_1 = 'p-3', style, din = '' }) => {

	return (<td scope="row" className={className_1} style={style}>
		<p className={className_2}>{value}</p>
		{din &&
			<p className='text-gray-700 font-semi rubik-400'>{din}</p>}
	</td>)
}

const DeliveryLabels = () => {

	const startDateRef = React.useRef()
	const endDateRef = React.useRef()

	const location = Hooks.useCustomLocation()
	const dispatch = Hooks.useCustomDispatch()
	const navigate = Hooks.useCustomNavigate()

	const { dispenseLabelListsResponse,
		addLabelsDataResponse,
		deleteDispenseDataResponse,
		addDispenseDataResponse } = useDispenseSelectors()

	const labelList = dispenseLabelListsResponse?.data?.data
	const rxLableList = addLabelsDataResponse?.data?.data

	const [showModal, setShowModal] = React.useState({
		updateDispenseModal: false,
		muteRxModal: false,
		rxModal: false,
		DeleteRxModal: false,
		updateLabelPrint: false,
		showToast: false,
		errorToast: false
	})

	const [formData, setFormData] = React.useState(initalDispenseLabelsFormData)

	const from_date = new URLSearchParams(location.search).get('from_date') || formData?.startDate
	const to_date = new URLSearchParams(location.search).get('to_date') || formData?.endDate
	const initialPage = parseInt(new URLSearchParams(location.search).get('page')) || 1;

	const [pageNumber, setPageNumber] = React.useState(initialPage)
	const [subRowIndex, setSubRowIndex] = useState(null)
	const [rowId, setRowId] = useState(null)
	const [checkedIds, setCheckedIds] = useState([]);
	const [labelId, setLabelId] = useState(null)
	const [rxNumber, setRxNumber] = useState(null)

	React.useEffect(() => {
		setFormData({
			...formData,
			startDate: from_date,
			endDate: to_date
		})
		return () => { }
	}, [from_date, to_date])

	React.useEffect(() => {
		setPageNumber(initialPage)
	}, [initialPage])

	React.useEffect(() => {
		if (formData?.endDate && formData?.startDate) {
			const params = {
				from_date: from_date ? from_date : formData?.startDate,
				to_date: to_date ? to_date : formData?.endDate,
				route: formData?.patientRoutes ? formData?.patientRoutes?.label : 'default',
				page: pageNumber,
				muteunmute: !formData?.muteValue ? 0 : formData?.muteValue?.value
			}
			dispatch(fetchDispenseLabelsList(params))
		}
		return () => { }
	}, [])

	const handleFilterData = (event, value) => {
		const params = {
			from_date: formData.startDate,
			to_date: formData.endDate,
			route: formData?.patientRoutes ? formData?.patientRoutes : 'default',
			page: value,
			rxnumber: formData?.rxnumber,
			search: formData.search,
			muteunmute: !formData?.muteValue ? 0 : formData?.muteValue?.value
		}
		const searchParams = new URLSearchParams()

		searchParams.append('from_date', formData?.startDate)
		searchParams.append('to_date', formData?.endDate)
		setSubRowIndex(null)
		setRxNumber(null)
		setCheckedIds()

		if (value !== undefined) {
			searchParams.append('page', value);
			setPageNumber(value)
		}

		dispatch(fetchDispenseLabelsList(params))
		const newUrl = `${routesNames.pharmacy_delivery_lables}?${searchParams.toString()}`
		navigate(newUrl);
	}

	useEffectHelper.useArchivedDelveriesDateRangePickerEffect(formData, setFormData, startDateRef, endDateRef)

	useEffect(() => {
		if (rxLableList && rxLableList?.length > 0 && addLabelsDataResponse?.type === 'rxlabel-list') {
			const initialCheckedIds = rxLableList?.map(item => item.labelid);
			setCheckedIds(initialCheckedIds)
		}
	}, [rxLableList]);

	const handleCheckboxChange = (labelId) => {
		const isChecked = checkedIds?.includes(labelId);
		if (isChecked && isChecked) {
			setCheckedIds(checkedIds?.filter(id => id !== labelId));
		} else {
			setCheckedIds([...checkedIds, labelId]);
		}
	};

	useEffect(() => {
		initDropdowns()
	}, [labelList])

	const handleResetFilteredData = () => {
		const searchParams = new URLSearchParams();
		setSubRowIndex(null)
		setRxNumber(null)
		setFormData({ ...formData, patientRoutes: null, muteValue: '', rxnumber: '', search: '' })
		searchParams.delete('from_date');
		searchParams.delete('to_date');
		searchParams.delete('page');
		const newUrl = `${routesNames.pharmacy_delivery_lables}?${searchParams.toString()}`;
		navigate(newUrl);
	}

	const handleSyncAndFilter = () => {
		const requestData = {
			from_date: formData.startDate,
			to_date: formData.endDate,
			route: 'default',
			rxnumber: formData?.rxnumber,
			muteunmute: !formData?.muteValue ? 0 : formData?.muteValue?.value
		}

		dispatch(LabelSyncAncFilter('sync_filter_data', requestData, setShowModal))
	}

	return (
		<React.Fragment>
			<div className='BodyBgColor'>
				<div className="container mx-auto px-4 pt-5 pb-5">

					<div className="grid grid-cols-12 gap-4">
						{/* <Common.Sidebar /> */}
						<div className="lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
							<div className="content">
								<div className="grid grid-cols-12 gap-4 w-full">
									<div className="flex mt-5 sm:mt-5 md:mt-5 lg:mt-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 
													sm:text-center text-center lg:text-left md:text-left">
										<h2 className="mt-2 fs-18 rubik-500 dark-color flex relative right-10 mt-2">
											<Common.BreadCrumb />
										</h2>
									</div>
								</div>

								<div className="container mt-2">
									<div className="border rounded-lg  md:py-5 md:px-5 sm:py-5 sm:px-5 lg:py-5 lg:px-5 bg-white p-4">
										<div className="flex flex-wrap lg:flex-nowrap gap-3">
											<div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 w-full'>
												<Dispense.TextInput label='Search' name='search' formData={formData}
													setFormData={setFormData} />
											</div>

											<div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12  w-full'>
												<Dispense.TextInput label='RX Number' name='rxnumber' formData={formData}
													setFormData={setFormData} />
											</div>

											<div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '150px' }}>
												<Dispense.RouteList formData={formData} placeholder='Route'
													setFormData={setFormData} />
											</div>

											<div className='flex-0 lg:w-2/12 md:w-4/12 sm:w-6/12 md:pr-0 w-full' style={{ width: '150px' }}>
												<Dispense.MuteUnMutelist formData={formData}
													setFormData={setFormData} placeholder='Status' />
											</div>

											<div className='flex flex-0 lg:w-5/12 md:w-6/12 sm:w-8/12 w-full' style={{ gridTemplateColumns: '2fr 2fr 7px 2fr 2fr' }}>
												<ArchivedDelveriesDateRangePicker
													startDateRef={startDateRef}
													endDateRef={endDateRef}
													formData={formData}
													setFormData={setFormData} />
											</div>
										</div>

										<div className="flex flex-wrap lg:flex-nowrap gap-3 mt-2">

											<div className=''>
												<button type="button" style={{ backgroundColor: '#3B82F6' }} onClick={handleFilterData}
													className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
																border border-gray-300 			focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
												dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Filter
												</button>
											</div>

											<div className=''>
												<button type="button" style={{ backgroundColor: '#3B82F6', width: '170px' }} onClick={() => addLabelsDataResponse?.loading && addLabelsDataResponse?.type === 'sync_filter_data' ? null : handleSyncAndFilter()}
													className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
																border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
																dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">

													{addLabelsDataResponse?.loading && addLabelsDataResponse?.type === 'sync_filter_data' ? <>
														<Common.Loader /> &nbsp; Sync and Filter </> : 'Sync and Filter'}

												</button>
											</div>

											<div className=''>
												<button type="button"
													onClick={() => Modals.handleShowModal(setShowModal, showModal, 'updateLabelPrint')}
													style={{ backgroundColor: '#3B82F6', width: '165px' }}
													className="flex w-full h-full pl-6 py-4 items-center justify-center text-white bg-white 
																border border-gray-300 	focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
												dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
													Print Label Setting
												</button>

											</div>

											<div className=''>
												<button type="button" style={{ backgroundColor: 'White', width: '90px' }} onClick={handleResetFilteredData}
													className="flex  w-full h-full items-center justify-center text-gray-500 bg-white 
																border border-gray-300 			focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 
												dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Reset
												</button>
											</div>

										</div>


										{dispenseLabelListsResponse?.loading ?
											<div className="flex justify-center items-center mt-60 mb-80">
												<Common.LoadingView />
											</div> :
											dispenseLabelListsResponse?.error !== null ?
												<div className="flex flex-col items-center justify-center mt-60 mb-80">
													<h1 className="text-4xl font-bold text-red-600 mb-2">Oops!</h1>
													<p className="text-lg text-gray-800 mb-2">Something went wrong while fetching the data.</p>
													<button onClick={() => window.location.reload()}
														className="px-4 bg-red-600 text-white rounded hover:bg-red-700 
																	focus:outline-none focus:ring-2 focus:ring-red-500">
														Please Try Again.
													</button>
												</div> :
												labelList?.data?.length === 0 ?
													<div className="bg-white pt-4 rounded-md">
														<p className="text-gray-500">No Record Available.</p>
													</div>
													:
													<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
														<div className="shadow-md border  rounded-lg relative">
															<table className="bl_oatLogHistory w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-auto  ">
																<thead className="text-xs text-white uppercase text-gray-900 dark:bg-gray-700 dark:text-gray-400"
																	style={{ borderBottom: '1px solid lightgray', background: '#f6f4f4' }}>
																	<tr>
																		{LableRowHeader?.map((header, index) => (
																			<th key={index} scope="col" className={header?.classNames?.join(" ")}>
																				<p className="rubik-500 fs-14">{header?.text}</p>
																			</th>
																		))}
																	</tr>
																</thead>

																<tbody>
																	{
																		labelList?.data?.map((deliveryLabel, idx) => (
																			<React.Fragment key={idx}>
																				<tr style={{ borderBottom: `${deliveryLabel.id - 1 && '1px solid lightgray'}` }}>

																					<td scope="row" className="p-3"
																						onClick={(e) => {
																							if (subRowIndex === idx) {
																								setSubRowIndex(null);
																								setRxNumber(null)
																							} else {
																								setSubRowIndex(idx);
																								setRxNumber(deliveryLabel.rx)
																								dispatch(fetchRxLabelsList(deliveryLabel?.id, formData, 'rxlabel-list'));
																							}
																						}}
																					>
																						<div className="rubik-400 fs-14 flex items-center gap-3 btnnn">
																							{/* {idx === subRowIndex ? */}
																							{rxNumber === deliveryLabel.rx ?

																								<Icons.ClosedEyeIcon style={{ marginRight: 10 }} />
																								:
																								<Icons.EyeIcon style={{ marginRight: 10 }} />
																							}
																							<span className='flex-1 -ml-4' style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
																								<span>{deliveryLabel.name}</span>
																								{deliveryLabel?.showflag && <Icons.FlagIcon color='red' />}
																								{deliveryLabel.d_flag && <p>
																									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-red-500 ml-1">
																										<path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 
																										12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
																									</svg>

																								</p>}
																							</span>
																						</div>
																					</td>

																					<DrugDetailList value={deliveryLabel.route} />
																					<DrugDetailList value={deliveryLabel.phn} />
																					<DrugDetailList value={deliveryLabel.rx} />
																					<DrugDetailList value={deliveryLabel.drug} className_1='p-3'
																						din={deliveryLabel.din} style={{ whiteSpace: 'nowrap', display: '', alignItems: '' }} />
																					<DrugDetailList value={deliveryLabel.rx_duration} />

																					<td scope="row" className="p-3 btnnn no-wrap">
																						{deliveryLabel?.scheduled?.map((value, i) => {
																							return <p className="rubik-400 fs-14 inline-block" key={i} style={{ color: value.status === 'active' && 'gray', paddingLeft: '1px' }}>{value.day}</p>
																						})}
																						<p className='no-wrap fs-12 text-red-500'
																							onClick={() => {
																								return setRowId(deliveryLabel),
																									Modals.handleShowModal(setShowModal, showModal, 'updateDispenseModal'),
																									dispatch(validateLabelInputsDataSuccess(null, null)),
																									dispatch(validateLabelInputsDataFailure(null, null)),
																									dispatch(pharmacyAddlabelsDataSuccess(null, null)),
																									dispatch(pharmacyAddlabelsDataFailure(null, null))
																							}}
																						>{deliveryLabel?.scheduke_key === 'Generate Labels' ? 'Generate' : deliveryLabel?.scheduke_key === 'Update' ? 'Update' : ''}</p>
																					</td>

																					<td className="px-6 py-4 btnnn relative flex items-center justify-center">
																						{/* {idx === subRowIndex && rxLableList?.length > 0 && checkedIds?.length > 0 && */}
																						{rxNumber === deliveryLabel.rx && rxLableList?.length > 0 && checkedIds?.length > 0 &&

																							<button onClick={() => dispatch(pharmacyLabelsPrint(deliveryLabel.id, checkedIds, 'label_sheet'))}
																								className=' mb-2 bg-red-100 text-red-800 text-xs font-medium px-1.5 py-0.5 rounded dark:bg-gray-700 
																						 			dark:text-blue-400 fs-10 border border-red-400 flex items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-center'
																								type='button'>
																								{addDispenseDataResponse?.loading && addDispenseDataResponse?.type === 'label_sheet' ? <Common.ProfilePicLoader /> : <Icons.PrintIcons />}

																							</button>
																						}
																						<button id={`dropdownDefaultButton_${idx}`} data-dropdown-toggle={`dropdown_${idx}`} className="inline-flex items-center text-white p-2 text-sm font-medium text-center text-gray-900 	bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
																							<Icons.EditIcon />
																						</button>

																						<div id={`dropdown_${idx}`} className="z-10 relative border border-gray-100 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
																							<ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby={`dropdownDefaultButton_${idx}`}>

																								<li onClick={() => { return setRowId(deliveryLabel), setSubRowIndex(null), setRxNumber(null), Modals.handleShowModal(setShowModal, showModal, 'muteRxModal') }}>
																									<a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
																										<span className="pl-2">{deliveryLabel?.mute === 1 ? 'Unmute Rx' : 'Mute Rx'}</span>
																									</a>
																								</li>

																								<li onClick={() => { return Modals.handleShowModal(setShowModal, showModal, 'rxModal'), dispatch(fetchPatientMedicineList(deliveryLabel.patient_id, 'patient_pending_list')) }}>
																									<a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
																										<span className="pl-2">View Medicine</span></a>
																								</li>

																								<li onClick={() => { return setLabelId(deliveryLabel.id), Modals.handleShowModal(setShowModal, showModal, 'DeleteRxModal') }}>
																									<a className="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
																										<span className="pl-2">Delete</span>
																									</a>
																								</li>

																							</ul>
																						</div>
																					</td>
																				</tr>

																				{/* {subRowIndex === idx && */}
																				{rxNumber === deliveryLabel.rx &&
																					<tr className={` bg-gray-200`}
																						style={{ backgroundColor: 'rgb(249, 249, 249)' }}>
																						<td scope='row' colSpan={9}>
																							{/* {(addLabelsDataResponse?.loading && subRowIndex === idx && addLabelsDataResponse?.type === 'rxlabel-list') ? */}
																							{(addLabelsDataResponse?.loading && rxNumber === deliveryLabel.rx && addLabelsDataResponse?.type === 'rxlabel-list') ?

																								<div className="flex justify-center items-center p-5">
																									<Common.ProfilePicLoader />
																								</div> :
																								// rxLableList?.length === 0 && subRowIndex === idx && addLabelsDataResponse?.type === 'rxlabel-list' ? null :
																								rxLableList?.length === 0 && rxNumber === deliveryLabel.rx && addLabelsDataResponse?.type === 'rxlabel-list' ? null :

																									addLabelsDataResponse?.type === 'rxlabel-list' ?
																										<div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4 p-3'>
																											{
																												rxLableList?.map((row, idx) => (
																													<div className='relative bg-white shadow-md rounded-md p-3' key={idx}>
																														<div className="relative flex items-center justify-between">
																															<div className="relative flex items-center">
																																<input
																																	id={`date-${row.labelid}`}
																																	type="checkbox"
																																	checked={checkedIds?.includes(row?.labelid)}
																																	onChange={() => handleCheckboxChange(row.labelid)}
																																	className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 
																																	dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 fs-16 rubik-400"/>

																																<label htmlFor={`date-${row.labelid}`} className="w-full ml-2 text-sm font-medium text-gray-900 
																																dark:text-gray-300 fs-14 rubik-400 cursor-pointer">{row.label_date}</label>
																															</div>

																														</div>
																														<p className='my-2'>{row.rxsig}</p>
																														<div className='flex'>
																															{row.processing && <p className='mr-2 '>P[{row.processing}]</p>}
																															{row.wit && <p className='mr-2 '>W[{row.wit}]</p>}
																															{row.carry && <p className='mr-2 '>C[{row.carry}]</p>}
																															{row.quantity && <p className='mr-2 '>T[{row.quantity}]</p>}

																														</div>

																														{row.dosetype && <p className='mr-2 text-red-600'>{row.dosetype}</p>}
																														{row.printed && <p className='mr-2 text-purple-600'>{row.printed}</p>}
																													</div>
																												))
																											}
																										</div> : null}
																						</td>
																					</tr>}
																			</React.Fragment>
																		))
																	}
																</tbody>
															</table>

															{dispenseLabelListsResponse?.error !== null ? null : <>
																{labelList?.data?.length !== 0 && <>
																	{labelList?.pagination?.last_page !== 1 &&
																		<div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex lg:justify-end sm:justify-center md:justify-center justify-center mt-5">
																			<nav aria-label="Page navigation example">
																				<ul className="inline-flex -space-x-px text-base h-10">
																					<CustomPagination
																						count={labelList?.pagination?.last_page}
																						page={pageNumber}
																						onChange={(event, value) => {
																							handleFilterData(event, value)
																						}}
																					/>
																				</ul>
																			</nav>
																		</div>
																	}
																</>}
															</>}

														</div>
													</div>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ChatBox />

			<UpdateDispenseLabel showModal={showModal}
				labelData={rowId}
				onClose={() => Modals.handleHideModal(setShowModal, showModal, 'updateDispenseModal')}
				setShowModal={setShowModal}
				setRxNumber={setRxNumber}
				data={formData}
			/>

			<PrinterLabelSettingModal showModal={showModal}
				onClose={() => Modals.handleHideModal(setShowModal, showModal, 'updateLabelPrint')}
				setShowModal={setShowModal}

			/>


			<DeleteLabelsModal showModal={showModal} labelId={labelId}
				onClose={() => Modals.handleHideModal(setShowModal, showModal, 'DeleteRxModal')}
				setShowModal={setShowModal}
				formData={formData}
				pageNumber={pageNumber}
			/>

			<MuteRxLabelsModel
				showModal={showModal}
				onClose={() => Modals.handleHideModal(setShowModal, showModal, 'updateDispenseModal')}
				setShowModal={setShowModal}
				patientData={rowId}
				formData={formData}
				pageNumber={pageNumber}
			/>

			<RXModal showModal={showModal} onClose={() => Modals.handleHideModal(setShowModal, showModal, 'rxModal')} setShowModal={setShowModal} />

			{
				showModal?.showToast && deleteDispenseDataResponse?.type === 'DeleteRxModal' &&
				<Toast.SuccessToastMessage status='Deleted Successfully' />
			}

			{
				showModal?.showToast && addLabelsDataResponse?.type === 'sync_filter_data' &&
				<Toast.SuccessToastMessage status='Updated Successfully' />
			}

			{
				showModal?.errorToast && addLabelsDataResponse?.type === 'sync_filter_data' &&
				<Toast.ErrorToastMessage status='Got an issue while syncing data.please try again' />
			}


			{
				showModal?.showToast && addLabelsDataResponse?.type === 'muterxvalue' &&
				<Toast.SuccessToastMessage status='Updated Successfully' />
			}

			{
				showModal?.showToast && addLabelsDataResponse?.type === 'print_label_setting' &&
				<Toast.SuccessToastMessage status='Updated Successfully' />
			}

			<Common.Footer />
		</React.Fragment>
	)
}

export default DeliveryLabels