import React, { useEffect, useRef, useState } from "react";

import { initFlowbite } from "flowbite";
import { useCustomSelectors } from "../../services/selectors/allSelectors";
import { SearchBar } from "./searchBar";

import { handleLogoutPharmacy } from "../../services/actions/main/logout";
import { removeWorkSpaceTab } from "../../utils/helper/helper";
import { CandaTimeZone } from "./timeZone";

import ResponsiveSidebar from "./responsiveSidebar";

import * as images from "../../assets/images/index";
import * as constants from "../../constants/index";
import * as Hooks from "../../hooks";
import * as useEffectHelper from "../../utils/helper/useEffectsHelper";

const Header = () => {

	const dropdownRef = useRef(null);
	const navigate = Hooks.useCustomNavigate();
	const { authData, pharmacyLoginData, adminModule } = useCustomSelectors();

	const [isDropdownOpen, setDropdownOpen] = Hooks.useCustomState(false);
	const [tooltipVisible, setTooltipVisible] = Hooks.useCustomState(null);
	const [loading, setLoading] = Hooks.useCustomState(false);
	const [isModalOpen, setModalOpen] = Hooks.useCustomState(false);
	const [copiedLink, setCopiedLink] = useState('Copy Attendance Link')

	useEffect(() => {
		initFlowbite();
	}, [authData]);

	useEffectHelper.useHeaderEffect(setDropdownOpen, dropdownRef);
	const HeaderdropDownItems = constants.HeaderdropDownItems(
		navigate,
		setDropdownOpen,
		setModalOpen,
		isModalOpen
	);

	const handleCopy = (link) => {
		const tempInput = document.createElement('input');
		tempInput.value = link;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand('copy');
		document.body.removeChild(tempInput)

		setCopiedLink('Copied')
		setTimeout(() => {
			setCopiedLink('Copy Attendance Link')
		}, 3000)
	}

	return (
		<>
			{/* <div className="bg-blue-600 Oat_HeaderBg fixed w-full z-50"> */}
			<div className="bg-blue-600 Oat_HeaderBg">
				<div className="container mx-auto px-4 pt-5 pb-5">
					<div className="space-x-full flex items-center gap-4 lg:gap-8">
						<div className="inline-block" onClick={removeWorkSpaceTab}>
							<img
								src={images.oatrx_logoWhite}
								className="text-left btnnn"
								alt=""
								onClick={() => navigate("/dashboard")}
							/>
						</div>

						<SearchBar />

						<div className="inline-block ml-auto flex items-center gap-4 lg:gap-2">
							<CandaTimeZone />

							<button className="hidden lg:flex">
								<svg onMouseEnter={() => setTooltipVisible(8)}
									onMouseLeave={() => setTooltipVisible(null)} style={{ width: '27px', marginTop: '-2px' }} onClick={() => handleCopy(`${process.env.REACT_APP_emplyoee_attendace_link}${pharmacyLoginData?.slug}/tm`)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6  btnnn text-white">
									<path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
								</svg>

								{tooltipVisible === 8 && (
									<div style={{ background: 'rgb(170 180 225 / 72%)', border: '1px solid white' }}
										className="tooltipBefore items-center justify-center mt-14 -ml-8 pl-4 pr-4 fs-12  z-10 visible px-0 py-1 ml-0 
												text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-lg opacity-100 tooltip 
												dark:bg-gray-700">
										{copiedLink}
									</div>
								)}
							</button>

							{constants?.createNavigationButtonsWithTooltips?.map(
								(value, index) => (
									<React.Fragment key={index}>
										{adminModule === 1 && (value.tooltipsValue === 'Time Off Request' || value.tooltipsValue === 'Tasks Assignments' || value.tooltipsValue === 'Appreciations') ? null :
											<button
												onMouseEnter={() => setTooltipVisible(index)}
												onMouseLeave={() => setTooltipVisible(null)}
												className="hidden lg:flex py-3 px-1 border-none rounded-full lg:rounded-none bg-white lg:bg-transparent bg-opacity-10 flex items-center gap-4"
											>
												<div
													onClick={() => {
														navigate(value.route);
														removeWorkSpaceTab();
													}}
												>
													{value.icon}
												</div>
												{tooltipVisible === index && (
													<div
														style={{ background: 'rgb(170 180 225 / 72%)', border: '1px solid white' }}
														onClick={() => {
															navigate(value.route);
															removeWorkSpaceTab();
														}}
														className="tooltipBefore items-center justify-center mt-14 -ml-8 pl-4 pr-4 fs-12 z-10 visible px-0 py-1 ml-0 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-lg opacity-100 tooltip dark:bg-gray-700"
													>
														{value.tooltipsValue}
													</div>
												)}
											</button>}
									</React.Fragment>
								)
							)}

							<div className="flex items-center block appearance-none w-34 bg-transparent lg:bg-white bg-opacity-25 text-white py-2 px-2 border-none rounded-full lg:rounded-none lg:border ot_HeadDropdwon">
								<div className="relative">
									<button
										className="hidden sm:hidden md:hidden lg:flex flex items-center text-sm font-medium text-white rounded-full md:mr-0 dark:text-white"
										type="button"
										onClick={() => setDropdownOpen(!isDropdownOpen)}
									>
										<span className="sr-only">Open user menu</span>
										<div className="relative inline-flex items-center justify-center w-8 h-8 mr-2 overflow-hidden yellowBg-color rounded-sm">
											<span className="light-color rubik-500 fs-22">
												<img
													src={authData?.data?.pharmacy[0]?.logo}
													className="rounded-sm"
												/>
											</span>
										</div>
										{authData?.data?.pharmacyname}
										<svg
											className="w-2.5 h-2.5 ml-2.5"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 10 6"
										>
											<path
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="m1 1 4 4 4-4"
											/>
										</svg>
									</button>

									{isDropdownOpen && (
										<div
											ref={dropdownRef}
											className={`z-50 absolute  ${isDropdownOpen ? "block" : "hidden"
												} ot_headerDropdown w-44`}
										>
											{authData?.data?.pharmacy?.map((value, index) => {
												return (
													<div
														className="flex items-center gap-2 px-5 py-2 pb-3"
														key={index}
													>
														<div className="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden blueLightBg-color rounded-sm">
															<span className="light-color rubik-500 fs-24">
																<img src={value.logo} className="" />
															</span>
														</div>
														<div>
															<h3 className="dark-color rubik-500 fs-14">
																{value.name}
															</h3>
															<p className="dark-color rubik-400 fs-16">
																{value.city}
															</p>
														</div>
													</div>
												);
											})}

											<ul
												className="py-2 text-sm text-gray-700 dark:text-gray-200"
												aria-labelledby="dropdownInformdropdownAvatarNameButtonationButton"
											>
												{HeaderdropDownItems?.map((item, index) => (
													<li
														key={index}
														onClick={item.onClick}
														className="btnnn"
													>
														<a className="flex gap-4 items-center px-5 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white grey-color rubik-400 fs-16">
															{item.icon}
															{item.label}
														</a>
													</li>
												))}
											</ul>
										</div>
									)}
								</div>

								<button
									style={{ width: "32px", height: "32px" }}
									className="block sm:block md:block lg:hidden flex items-center text-sm font-medium text-white rounded-full md:mr-0 dark:text-white"
									type="button"
									onClick={() => setDropdownOpen(!isDropdownOpen)}
								>
									<span className="sr-only">Open user menu</span>
									<div className="relative inline-flex items-center justify-center  overflow-hidden  rounded-full">
										<img src={authData?.data?.pharmacy[0]?.logo} />
									</div>
								</button>
							</div>
						</div>

						<button
							className="block lg:hidden md:block sm:block text-white px-3 py-3 mr-1 rounded-full bg-white bg-opacity-10 bg-transparent h-12 flex items-center"
							type="button"
							data-drawer-target="drawer-navigation"
							data-drawer-show="drawer-navigation"
							aria-controls="drawer-navigation"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M23 19V16.5H15.6667V19H23ZM23 12.75V10.25H15.6667H8.33333V12.75H15.6667H23ZM23 6.5V4H1V6.5H23Z"
									fill="white"
								/>
							</svg>
						</button>

						<ResponsiveSidebar />
					</div>
				</div>
			</div>

			{isModalOpen && (
				<div style={{ zIndex: '9999999' }} className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center 
														p-6 overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 dark:bg-opacity-100">
					<div className="relative w-full max-w-md max-h-full">
						<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
							<button onClick={() => setModalOpen(false)}
								type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 
								hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
								<svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
								</svg>
								<span className="sr-only">Close modal</span>
							</button>
							<div className="p-6 text-center">
								<svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
									fill="none" viewBox="0 0 20 20">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
								</svg>
								<h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
									Are you sure you want to logout ?
								</h3>
								<button
									onClick={() => handleLogoutPharmacy(setLoading, navigate)}
									style={{ backgroundColor: "#3A52BB" }}
									type="button"
									className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
								>
									{loading ? "Processing..." : "Yes, I'm sure "}
								</button>
								<button
									onClick={() => setModalOpen(false)}
									data-modal-hide="popup-modal"
									type="button"
									className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
								>
									No, cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Header;
