import * as path from '../../networking/urlEndPoints'
import { addDriverData } from '../../services/actions/pharmacy/dispense/addDispenseData';
import * as dispense from '../../services/actions/pharmacy/dispense'

const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

export const handleDispenseNewCustomDelivery = (
	formData,
	setErrorMessages,
	errorMessages,
	drugDetails,
	setDrugDetailsError,
	drugDetailsError,
	dispatch,
	navigate
) => {

	const specificValidations = {
		patientName: 'Patient is required',
		rational: (formData?.recurrence === true && formData?.rational?.length === 0) ? 'Duration is required' : ''
	}

	const allValidations = { ...specificValidations };

	const newErrorMessages = Object.fromEntries(
		Object.entries(allValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		]))

	if (formData?.recurrence === true && formData?.rational?.length === 0) {
		setErrorMessages({
			...errorMessages,
			...specificValidations,
		});
		return
	}

	if (!drugDetails) {
		setDrugDetailsError('Drug is required')
		return
	}

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	if (allDataPresent) {
		setDrugDetailsError('')
		const requestedData = {
			drug: drugDetails,
			date: formData.date,
			patient: formData.patientName.value,
		}

		if (formData.recurrence) {
			requestedData.recurrence_duration = formData.rational
			requestedData.recurrence = formData.recurrence ? 1 : 0
		}
		dispatch(dispense.addDispenseData(path.pharmacy_addnew_custom_delivery, requestedData, null, 'add-new-custom-delivery', navigate))
	}

}

export const handleDispenseAddDriveForm = (formData, setErrorMessages, errorMessages, setShowModal, dispatch, selectedFile) => {
	const specificValidations = {
		name: 'Name is required',
		email: 'Email is required',
		phone: !formData?.phone ? 'Phone is required' : formData?.phone?.length !== 10 ? 'Phone should be 10 digits' : null,
		passcode: !formData?.passcode ? 'Passcode is required' : formData?.passcode?.length !== 4 ? 'Passcode should be 4 digits' : null,
	};

	const newErrorMessages = Object.fromEntries(
		Object.entries(specificValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	});

	if (formData?.passcode?.length !== 4 || formData?.phone?.length !== 10) {
		setErrorMessages({
			...errorMessages,
			...specificValidations,
		});
		return;
	}

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');
	if (allDataPresent) {
		dispatch(addDriverData(path.pharmacy_addnew_delivery_driver, formData, setShowModal, 'pharmacy-add-driver', selectedFile))
	}
};


export const handleUpdateDispenseAddDriveForm = (formData, setErrorMessages, errorMessages, setShowModal, dispatch, selectedFile, id) => {

	const specificValidations = {
		name: 'Name is required',
		email: 'Email is required',
	}

	const newErrorMessages = Object.fromEntries(
		Object.entries(specificValidations).map(([field, message]) => [
			field,
			validateField(field, formData[field], message),
		])
	);

	setErrorMessages({
		...errorMessages,
		...newErrorMessages,
	})

	const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

	if (allDataPresent) {
		dispatch(addDriverData(`${path.pharmacy_update_driver_detail}/${id}`, formData, setShowModal, 'pharmacy-update-driver', selectedFile))
	}
};
